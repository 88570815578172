const lang = {
  // header
  "성명을 기입해 주세요.": {
    _en: "Name",
  },
  검색: {
    _en: "Search",
  },
  // state Button
  "대기 중": {
    _en: "Waiting",
  },
  "검진 완료": {
    _en: "Completed",
  },
  "저장 대기 중": {
    _en: "Saving",
  },
  // Double Click Modal
  생년월일: {
    _en: "Date of birth",
  },
  검진일자: {
    _en: "Date of checkup",
  },
  "정상 사진과 비교하기": {
    _en: "View with normal images",
  },
  "신규 Report 작성": {
    _en: "Create new report",
  },
  "이름 또는 ID": {
    _en: "Name or ID",
  },
};

export default lang;
