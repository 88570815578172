import styled from "styled-components";

// img
import IconArrowBottom from "resources/image/icon/icon_arrow_bottom_big.svg";
import IconArrowTop from "resources/image/icon/icon_arrow_top_big.svg";

const Container = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: calc(100vw * (82 / 1920));
  height: calc(100vw * (750 / 1920));
  overflow: hidden;

  & img {
    width: 100%;
    height: 100%;
    object-fit: fill;
  }

  & .title {
    position: absolute;
    left: 50%;
    font-size: calc(100vw * (25 / 1920));
    z-index: 2;
  }

  & .swiperContainer {
    position: relative;

    & .title {
      top: calc(50% - calc(100vw * (305 / 1920)));
      transform: translate(-50%, -50%);
    }
  }

  & .mySwiper {
    margin: 0;

    & .slider {
      box-shadow: none;
      opacity: 0.5;
      transition: 0.3s;
      border-radius: calc(100vw * (9 / 1920));
      overflow: hidden;

      &.emptySlider {
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: calc(100vw * (25 / 1920));
        text-align: center;
        background-color: #292929;

        & br {
          display: none;
        }
      }
    }

    & .swiper-slide-active {
      opacity: 1;
    }

    &.recentSwiper {
      height: calc(100vw * (760 / 1920));
    }
  }

  & .swiper-button-prev,
  .swiper-button-next {
    position: absolute;
    left: 50%;
    width: calc(100vw * (100 / 1920));
    height: calc(100vw * (100 / 1920));
    transform: translateX(-50%);
    z-index: 1;
    cursor: pointer;

    &::after {
      width: 100%;
      height: 100%;
      content: "";
    }
  }

  & .swiper-button-prev {
    /* top: calc(100vw * (404 / 1920)); */
    top: calc(100vw * (2 / 1920));
    background: url(${IconArrowTop}) no-repeat center / cover;
  }

  & .swiper-button-next {
    top: auto;
    /* bottom: calc(100vw * (386 / 1920)); */
    bottom: 0;
    background: url(${IconArrowBottom}) no-repeat center / cover;
  }

  & .normalContainer {
    position: relative;

    & .title {
      top: calc(100vw * (-35 / 1920));
      transform: translateX(-50%);
    }
  }

  & .imgContainer {
    width: calc(100vw * (560 / 1920));
    height: calc(100vw * (560 / 1920));
  }

  @media (max-width: 1366px) {
    height: calc(100vw * (850 / 1920));

    & .recentSwiper {
      height: calc(100vw * (680 / 1920));
    }

    & .swiper-button-prev {
      top: calc(100vw * (9 / 1920));
    }
  }

  @media (max-width: 1180px) {
    & .swiper-button-prev {
      top: calc(100vw * (12 / 1920));
    }
  }
`;

export { Container };

export default { Container };
