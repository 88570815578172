import styled from "styled-components";

// img
import Logo from "resources/image/icon/logo.svg";

const Wrap = styled.div`
  width: 100%;
  min-height: 100vh;
  padding: calc(100vw * (100 / 1920)) calc(100vw * (32 / 1920)) 0;
  background: url(${Logo}) no-repeat left calc(100vw * (30 / 1920)) bottom
    calc(100vw * (30 / 1920)) / contain;
  background-size: calc(100vw * (204 / 1920)) calc(100vw * (36 / 1920));
  background-color: #000;

  & .table_bar {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: calc(100vw * (30 / 1920));
  }
  & .table_bar > button {
    position: absolute;
    height: calc(100vw * (48 / 1920));
    font-size: calc(100vw * (26 / 1920));
    font-weight: 500;
    border-radius: calc(100vw * (10 / 1920));
  }
  & .add {
    right: 0;
    width: calc(100vw * (250 / 1920));
    height: calc(100vw * (48 / 1920));
    background: #008ad1;
    /* padding-left: calc(100vw * (20 / 1920)); */
  }
  & .inputListWrap {
    display: flex;
    align-items: center;
    /* margin-top: calc(100vw * (50 / 1920)); */
    margin-block: calc(100vw * (20 / 1920));
    justify-content: end;

    & .inputList {
      display: flex;
      align-items: center;

      &:first-child {
        margin-right: calc(100vw * (81 / 1920));
      }

      &:nth-of-type(2) {
        margin-right: calc(100vw * (17 / 1920));
      }

      & .inputTitle {
        margin-right: calc(100vw * (17 / 1920));
        font-size: calc(100vw * (24 / 1920));
        font-weight: 700;
      }

      & .inputContainer {
        width: calc(100vw * (230 / 1920));
        height: calc(100vw * (32 / 1920));
        margin-left: calc(100vw * (7 / 1920));

        & input {
          font-size: calc(100vw * (20 / 1920));
        }
      }
      & .inputContainer.name {
        width: calc(100vw * (250 / 1920));
        margin-right: 0;
        margin-left: 0;
      }

      & .searchButton {
        display: flex;
        justify-content: center;
        align-items: center;
        /* width: calc(100vw * (41 / 1920)); */
        width: calc(100vw * (71 / 1920));
        height: calc(100vw * (33 / 1920));
        margin-left: calc(100vw * (18 / 1920));
        background: #ffffff;
        border-radius: calc(100vw * (7 / 1920));

        & .iconWrap {
          width: calc(100vw * (21 / 1920));
          height: calc(100vw * (21 / 1920));

          & img {
            display: block;
            width: 100%;
          }
        }
      }
    }
  }
`;

export { Wrap };

export default { Wrap };
