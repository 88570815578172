import { BASE_URL } from "service/connector";

export const IMAGE_SRC = `${BASE_URL}src/`;
export const PATH = `${BASE_URL}`;
export const IMAGE_REF = `${BASE_URL}ref/`;

export const swiperOption = {
  option: {
    rotate: -65,
    stretch: 60,
    depth: 300,
    modifier: 1,
    slideShadows: true,
  },
  ProOption: {
    rotate: -65,
    stretch: 55,
    depth: 200,
    modifier: 1,
    slideShadows: true,
  },
  AirOption: {
    rotate: -65,
    stretch: 45,
    depth: 200,
    modifier: 1,
    slideShadows: true,
  },
};
