import { Wrap } from "pages/PatientsPage/styles";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { getCookie } from "service/connector";

const IndexRoute = () => {
  const navigate = useNavigate();

  useEffect(() => {
    const keepState = JSON.parse(localStorage.getItem("keepState"));
    if (!keepState) {
      navigate("/login");
    } else {
      const auth = Number(getCookie("auth"));
      if (auth === null || auth === undefined) {
        navigate("/login");
      } else {
        if (auth === 0) {
          navigate("/admin");
        } else if (auth === 1) {
          navigate("/checkup/new");
        } else if (auth === 2) {
          navigate("/patient");
        } else {
          navigate("/login");
        }
      }
    }
  }, []);

  return <Wrap />;
};

export default IndexRoute;
