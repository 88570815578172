import styled from "styled-components";

// img
import IconChecked from "resources/image/icon/icon_checked_line.svg";

const Container = styled.div`
  & .checkboxListWrap {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    gap: calc(100vw * (50 / 1920));

    & .checkboxList,
    .iconButton {
      font-size: calc(100vw * (26 / 1920));
      font-weight: 700;

      & input {
        display: none;

        &:checked + label .checkboxContainer::after {
          display: block;
        }
      }

      & label {
        display: flex;
        align-items: center;
        cursor: pointer;
      }

      & .checkboxContainer {
        position: relative;
        display: inline-block;
        width: calc(100vw * (22 / 1920));
        height: calc(100vw * (22 / 1920));
        margin-right: calc(100vw * (9 / 1920));
        border: calc(100vw * (4 / 1920)) solid #fff;

        &::after {
          position: absolute;
          top: calc(100vw * (-8 / 1920));
          left: calc(100vw * (-3 / 1920));
          display: none;
          width: calc(100vw * (27 / 1920));
          height: calc(100vw * (20 / 1920));
          background: url(${IconChecked}) no-repeat center / contain;
          content: "";
        }
      }
    }
  }
`;

export { Container };

export default { Container };
