import styled from "styled-components";

const Container = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  background-color: #000;
  z-index: 4;

  & img {
    display: block;
    width: 100%;
    object-fit: cover;
  }

  & .subHeaderWrap {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: calc(100vw * (1856 / 1920));
    height: calc(100vw * (100 / 1920));
    margin: 0 auto;
    padding-top: calc(100vw * (18 / 1920));
    border-bottom: calc(100vw * (3 / 1920)) solid #ffffff;
  }

  & .leftButtonContainer {
    display: flex;
    align-items: center;
    width: calc(100vw * (150 / 1920));

    & .button {
      // display: block;
      width: calc(100vw * (36 / 1920));
    }

    & .backButton {
      width: calc(100vw * (35 / 1920));
      height: calc(100vw * (35 / 1920));
      margin-right: 5%;
    }

    & .reloadButton {
      width: calc(100vw * (35 / 1920));
      height: calc(100vw * (35 / 1920));
      margin-right: 3%;
      margin-top: 2%;
    }

    & .homeButton {
      width: calc(100vw * (50 / 1920));
      height: calc(100vw * (50 / 1920));
    }
  }

  & .tabListWrap {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    display: flex;
    width: max-content;
    font-size: calc(100vw * (32 / 1920));
    font-weight: 700;
    color: #fff;

    & a {
      display: flex;
      justify-content: center;
      align-items: center;
      width: calc(100vw * (231 / 1920));
      height: calc(100vw * (62 / 1920));
      border-radius: calc(100vw * (6 / 1920));

      &.on {
        color: #000;
        background-color: #fff;
      }
    }
  }

  & .rightButtonContainer {
    display: flex;
    align-items: center;

    & .button {
      display: block;
      width: calc(100vw * (36 / 1920));
    }

    & .listButton {
      position: relative;
      margin-right: calc(100vw * (8 / 1920));

      & .button {
        height: calc(100vw * (41 / 1920));
      }

      & .reportModal {
        position: absolute;
        top: calc(100vw * (20 / 1920));
        right: calc(100vw * (10 / 1920));
        width: calc(100vw * (291 / 1920));
        max-height: calc(100vw * (468 / 1920));
        font-size: calc(100vw * (25 / 1920));
        font-weight: 700;
        text-align: center;
        background: #292929;
        border-radius: calc(100vw * (10 / 1920));
        overflow: auto;

        & .newReportButton {
          width: 100%;
          padding: calc(100vw * (9 / 1920)) 0;
          font-weight: 700;
        }

        & .reportModalListWrap {
          border-top: 1px solid #ffffff;

          & .reportModalList {
            width: 100%;
            padding: calc(100vw * (8 / 1920)) 0;
            cursor: pointer;

            &:not(:last-child) {
              border-bottom: 1px dashed #ffffff;
            }
          }
        }
      }
    }

    & .settingButton {
      height: calc(100vw * (33 / 1920));
    }
  }
`;

export { Container };

export default { Container };
