import { useCallback, useRef } from "react";

/**
 * @param {clickEvent} doubleClick
 * @param {clickEvent} [click]
 * @param {UseDoubleClickOptions} [options]
 * @returns {clickEvent}
 */
const useDoubleClick = (doubleClick, click, options) => {
  /** @type {UseDoubleClickOptions} */
  options = {
    timeout: 200,
    ...options,
  };

  /** @type {{ current: number }} */
  const clickTimeout = useRef();

  const clearClickTimeout = () => {
    if (clickTimeout) {
      clearTimeout(clickTimeout.current);
      clickTimeout.current = null;
    }
  };

  return useCallback(
    (event, data, id) => {
      clearClickTimeout();
      if (click && /** @type {React.UIEvent} */ (event).detail === 1) {
        clickTimeout.current = setTimeout(() => {
          click(event, data);
        }, options.timeout);
      }
      if (/** @type {React.UIEvent} */ (event).detail % 2 === 0) {
        doubleClick(event, data,id);
      }
    },
    [click, doubleClick, options.timeout]
  );
};

/**
 * @callback clickEvent
 * @param {React.SyntheticEvent} [event]
 */

/**
 * @typedef {Object} UseDoubleClickOptions
 * @prop {number} [timeout]
 */

export default useDoubleClick;
