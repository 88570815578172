import Header from "./Header";
import SubHeader from "./SubHeader";
import SettingHeader from "./SettingHeader";
import NumberIcon from "./NumberIcon";
import ReportHeader from "./ReportHeader";

export { Header, SubHeader, SettingHeader, NumberIcon, ReportHeader };

export default {
  Header,
  SubHeader,
  SettingHeader,
  NumberIcon,
  ReportHeader,
};
