import { useNavigate } from "react-router-dom";
import { api } from "service";
import { useAtom } from "jotai";
import { authAtom } from "store/atoms";
import { getCookie } from "service/connector";
import { useState, useEffect } from "react";

export const useAuth = () => {
  const [userData, setUserData] = useAtom(authAtom);

  const navigate = useNavigate();

  useEffect(() => {
    if (getCookie("jwt").length < 1) {
      if (!window.location.pathname.includes("login")) {
        alert("세션이 만료되어 로그아웃 되었습니다.");
        navigate(`/login`);
        window.location.reload();
      } else {
        return;
      }
    }
    if (userData == -1) {
      if (getCookie("auth").length > 0) {
        setUserData((f) => {
          f.auth = Number(getCookie("auth"));
          return { ...f };
        });
      }
    }
    // if(window.location.pathname.includes("login")){
    //   navigate(`/reservation`);
    // }
  }, []);

  const refresh = async () => {
    let result = await api.login.refresh();

    if (result?.accessToken) {
      document.cookie = `scope=${result.scope}`;
      document.cookie = `jwt=${result.data}`;
      document.cookie = `refresh=${result.data}`;
      // userQuery.refetch();
      // navigate(`/`);
    } else {
      alert(result?.error);
      return false;
    }
  };

  const login = async (obj) => {
    let result = await api.login.login(JSON.stringify(obj));

    if (result?.auth != null && result?.auth > -1) {
      document.cookie = `scope=${result.scope}`;
      document.cookie = `jwt=${result.accessToken}`;
      document.cookie = `refresh=${result.refreshToken}`;
      document.cookie = `auth=${result.auth}`;
      // userQuery.refetch();
      // navigate(`/`);

      setUserData(result?.auth);

      return result?.auth;
    } else {
      return -1;
    }
  };

  const logout = async () => {
    // document.cookie = "scope=;"
    document.cookie = "jwt=; expires=Thu, 01 Jan 1999 00:00:10 GMT; path=/;";
    document.cookie =
      "refresh=; expires=Thu, 01 Jan 1999 00:00:10 GMT; path=/;";
    document.cookie = "auth=; expires=Thu, 01 Jan 1999 00:00:10 GMT; path=/;";
    window.location.href = "/login";
    // window.location.href = ""
    localStorage.removeItem("keepState");
  };

  // const isLogin = (userData && userData.username) ? true : false;

  return { login, logout, refresh };
};
