import styled from "styled-components";

const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: calc(100vw * (960 / 1920));
  margin-top: calc(100vw * (-5 / 1920));

  & img {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  & .wrap {
    position: relative;
    width: calc(100vw * (448 / 1920));
    height: calc(100vw * (386 / 1920));
    

    & .stomachPart {
      position: absolute;
      width: calc(100vw * (200 / 1920));
      height: calc(100vw * (200 / 1920));

      &::after {
        position: absolute;
        height: 1px;
        background-color: #a1a1a1;
        content: "";
        z-index: 1;
      }

      &.stomachPart1 {
        top: calc(100vw * (-238 / 1920));
        left: calc(100vw * (-39 / 1920));

        &::after {
          bottom: calc(100vw * (-45 / 1920));
          right: calc(100vw * (-88 / 1920));
          width: calc(100vw * (111 / 1920));
          transform: rotate(54deg);
        }
      }

      &.stomachPart2 {
        top: calc(100vw * (-138 / 1920));
        left: calc(100vw * (-332 / 1920));

        &::after {
          top: calc(100vw * (166 / 1920));
          right: calc(100vw * (-390 / 1920));
          width: calc(100vw * (409 / 1920));
          transform: rotate(25deg);
        }
      }

      &.stomachPart3 {
        bottom: calc(100vw * (41 / 1920));
        left: calc(100vw * (-425 / 1920));

        &::after {
          top: calc(100vw * (116 / 1920));
          right: calc(100vw * (-245 / 1920));
          width: calc(100vw * (250 / 1920));
          transform: rotate(19deg);
        }
      }

      &.stomachPart4 {
        bottom: calc(100vw * (-215 / 1920));
        left: calc(100vw * (-288 / 1920));

        &::after {
          top: calc(100vw * (-21 / 1920));
          right: calc(100vw * (-212 / 1920));
          width: calc(100vw * (240 / 1920));
          transform: rotate(139deg);
        }
      }

      &.stomachPart5 {
        bottom: calc(100vw * (-253 / 1920));
        left: calc(100vw * (10 / 1920));

        &::after {
          top: calc(100vw * (-56 / 1920));
          right: calc(100vw * (-16 / 1920));
          width: calc(100vw * (121 / 1920));
          transform: rotate(114deg);
        }
      }

      &.stomachPart6 {
        top: calc(100vw * (50 / 1920));
        left: calc(100vw * (-103 / 1920));

        &::after {
          bottom: calc(100vw * (35 / 1920));
          right: calc(100vw * (-169 / 1920));
          width: calc(100vw * (181 / 1920));
          transform: rotate(30deg);
        }
      }

      &.stomachPart7 {
        bottom: calc(100vw * (-253 / 1920));
        right: calc(100vw * (-50 / 1920));

        &::after {
          top: calc(100vw * (-54 / 1920));
          left: calc(100vw * (6 / 1920));
          width: calc(100vw * (126 / 1920));
          transform: rotate(59deg);
        }
      }

      &.stomachPart8 {
        bottom: calc(100vw * (-153 / 1920));
        right: calc(100vw * (-344 / 1920));

        &::after {
          top: calc(100vw * (-62 / 1920));
          left: calc(100vw * (-206 / 1920));
          width: calc(100vw * (228 / 1920));
          transform: rotate(35deg);
        }
      }

      &.stomachPart9 {
        top: calc(100vw * (-126 / 1920));
        right: calc(100vw * (-370 / 1920));

        &::after {
          bottom: calc(100vw * (41 / 1920));
          left: calc(100vw * (-249 / 1920));
          width: calc(100vw * (260 / 1920));
          transform: rotate(-24deg);
        }
      }

      &.stomachPart10 {
        top: calc(100vw * (-238 / 1920));
        right: calc(100vw * (-85 / 1920));

        &::after {
          bottom: calc(100vw * (-81 / 1920));
          left: calc(100vw * (-114 / 1920));
          width: calc(100vw * (175 / 1920));
          transform: rotate(-66deg);
        }
      }

      &.stomachPart11 {
        top: calc(100vw * (110 / 1920));
        right: calc(100vw * (-467 / 1920));

        &::after {
          top: calc(100vw * (57 / 1920));
          left: calc(100vw * (-383 / 1920));
          width: calc(100vw * (383 / 1920));
          transform: rotate(-2deg);
        }
      }

      &.stomachPart12 {
        bottom: calc(100vw * (-253 / 1920));
        right: calc(100vw * (-600 / 1920));
      }
    }

    & .stomachPartImageWrap {
      height: 100%;
      border-radius: calc(100vw * (9 / 1920));
      filter: drop-shadow(
        calc(100vw * (5 / 1920)) calc(100vw * (5 / 1920))
        calc(100vw * (4 / 1920)) rgba(0, 0, 0, 0.5)
      );
      overflow: hidden;
    }
  }
`;

export { Container };

export default { Container };
