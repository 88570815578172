import styled from "styled-components";

const Container = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  z-index: 8;

  & .stomachContainer {
    width: calc(100vw * (89 / 1920));
    height: calc(100vw * (72 / 1920));
    transform: scale(1);
    transition: 0.7s;
    transform-origin: top right;
    z-index: 2;

    & img {
      width: calc(100vw * (89 / 1920));
      height: calc(100vw * (72 / 1920));
    }
  }
`;

export { Container };

export default { Container };
