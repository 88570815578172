import Styles from "./styles";
import { getCookie } from "service/connector";

export default function NumberIcon({ type, number, lite_type, screen }) {
  const scope = getCookie("scope");

  // console.log("type_numberIcon => ", type);
  // console.log("scope => ", scope);

  const renderClassName = (type) => {
    if (
      scope === "lite" &&
      screen == "abnormality" &&
      (type === 1 || type === 2 || type === 3 || type === 0)
    ) {
      return "cancer";
    }

    switch (type) {
      case -1:
        return "none";
      case 1:
        return "cancer";
      case 2:
        return "adenoma";
      case 3:
        return "polyp";
      case 4:
        return "IM";
      case 5:
        return "green";
    }
  };

  return (
    (number !== 12 ? (
    <Styles.Container className={"numberIcon " + renderClassName(type)}>
      {number}
    </Styles.Container>) : (
    <Styles.Container className={"numberIcon " + renderClassName(-1)}>
    </Styles.Container>)
    )
  );
}
