import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { common, layout } from "components";
import { TableAdmin } from "./components";
import { useModals, useLanguage } from "hooks";
import { useQuery } from "react-query";
import { api } from "service";
import Styles from "./styles";
import { ui } from "components";
import utils from "utils";

import IconSearch from "resources/image/icon/icon_search.svg";

export default function AdminPage() {
  const modalOption = useModals();
  const navigate = useNavigate();
  const lang = useLanguage("Admin");

  const [pageList, setPageList] = useState([1, 2, 3, 4, 5]);
  const [page, setPage] = useState(1);
  const [maxPage, setMaxPage] = useState(12);
  const [maxCount, setMaxCount] = useState(0);
  const [tableData, setTableData] = useState([]);
  const [madeUserCount, setMadeUserCount] = useState(0);
  const [availableUserCount, setAvailableUserCount] = useState(0);
  const pageSize = 5;

  const [filterInfo, setFilterInfo] = useState({ page: page });
  const { isLoading, isError, data, error, refetch } = useQuery(
    ["admin_list", filterInfo],
    () => api.admin.getAdminUser(filterInfo),
    {
      onSuccess: (res) => {
        if (!res) {
          let list = [1];
          setPageList(list);
          setMaxPage(1);
          setTableData([]);
        }
        let e = JSON.parse(JSON.stringify(res));
        e.totalLicenceCount && setAvailableUserCount(e.totalLicenceCount);
        e.totalRecordCount && setMadeUserCount(e.totalRecordCount);
        if (e.length < 1) {
          setPageList([1]);
          setPage(1);
          setMaxPage(1);
        } else {
          let start = 1;
          let end = e.totalPageCount;

          let list = [];

          for (let i = start; i <= end; i++) list.push(i);

          setPageList(list);
          setMaxPage(e.totalPageCount);
          setTableData(e.list);
        }
      },

      refetchOnWindowFocus: false,
    }
  );

  const onKeyUpEnter = (e) => {
    if (e.key == "Enter") {
      getAdminList();
    }
  };

  const getAdminList = async (isInit) => {
    let res;

    // res = await api.patient.getList(filterInfo);

    if (isInit) {
      let obj = {
        page: 1,
        search: modalOption?.searchWord,
      };
      res = await api.admin.getAdminUser(obj);
    } else {
      console.log(filterInfo);
      res = await api.admin.getAdminUser(filterInfo);
    }

    if (res) {
      console.log("user? => ", res.list);
      setTableData(JSON.parse(JSON.stringify(res.list)));
    } else {
      setTableData([]);
    }
  };

  return (
    <Styles.Wrap>
      <common.Header
        title={"USERS"}
        search={false}
        logout={true}
        modalOption={modalOption}
        type={"admin"}
        setFilterInfo={setFilterInfo}
      />
      <div>
        <form>
          <ul className="inputListWrap">
            <li className="inputList">
              <p className="inputTitle">{lang.getLang("이름")}</p>
              <div className="inputContainer">
                <ui.input.BasicInput
                  isEnter={true}
                  value={filterInfo.search}
                  setValue={(e) => {
                    setFilterInfo((f) => {
                      f.search = e;
                      return { ...f };
                    });
                  }}
                  placeholder={lang.getLangString("이름 또는 ID")}
                  keyUp={(e) => {
                    onKeyUpEnter(e);
                  }}
                />
              </div>
              <button
                type="button"
                className="searchButton"
                onClick={() => {
                  getAdminList();
                }}
              >
                <span className="iconWrap">
                  <img src={IconSearch} alt="search" />
                </span>
              </button>
            </li>

            {/* <li className="inputList">
              <p className="inputTitle">{lang.getLang("생년월일")}</p>
              <div className="inputContainer birth">
                <div className="calendarButtonContainer">
                  <ui.button.CalendarButton
                    dateChange={filterInfo.birthday}
                    setDateChange={(e) => {
                      setFilterInfo((f) => {
                        f.birthday = e;
                        return { ...f };
                      });
                    }}
                  />
                </div>
                <ui.input.BasicInput
                  value={utils.etc.numberToDate(filterInfo.birthday).resultText}
                  setValue={(e) => {
                    setFilterInfo((f) => {
                      f.birthday = e;
                      return { ...f };
                    });
                  }}
                  placeholder={"생년월일을 선택해 주세요."}
                />
              </div>
            </li> */}

            {/* <li className="inputList">
              <p className="inputTitle">{lang.getLang("휴대폰 번호")}</p>
              <div className="inputContainer">
                <ui.input.BasicInput
                  isEnter={true}
                  value={filterInfo.phone}
                  setValue={(e) => {
                    setFilterInfo((f) => {
                      f.phone = e;
                      return { ...f };
                    });
                  }}
                  placeholder={"010-0000-0000"}
                  keyUp={(e) => {
                    onKeyUpEnter(e);
                  }}
                />
              </div>

              <button
                type="button"
                className="searchButton"
                onClick={() => {
                  getAdminList();
                }}
              >
                <span className="iconWrap">
                  <img src={IconSearch} alt="search" />
                </span>
              </button>
            </li> */}
          </ul>
        </form>
      </div>
      <TableAdmin
        list={tableData}
        setFilterInfo={setFilterInfo}
        refetch={refetch}
      />
      <div className="table_bar">
        <layout.Pagination
          list={pageList}
          page={page}
          setPage={setPage}
          maxPage={maxPage}
          setPageList={setPageList}
          pageSize={pageSize}
          setFilterInfo={setFilterInfo}
        />
        <button
          type="button"
          className="add"
          // style={{paddingInline:10}}
          onClick={() => {
            if (madeUserCount < availableUserCount) {
              navigate("/user/new");
            } else {
              alert(`계정은 최대 ${availableUserCount}까지 생성가능합니다.`);
            }
            // if (tableData.length > 3) {
            //   alert("계정은 최대 3개까지 생성가능합니다.");
            // } else {
            //   navigate("/user/new");
            // }
          }}
        >
          {lang.getLang("+ 유저 생성")} ({madeUserCount}/{availableUserCount})
        </button>
      </div>
    </Styles.Wrap>
  );
}
