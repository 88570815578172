import useDoubleClick from "hooks/useDoubleClick";
import useImageActiveList from "hooks/useImageActiveList";
import useKeyDown from "hooks/useKeyDown";
import useModals from "hooks/useModals";
import { useEffect } from "react";

const useImageDoubleClickModalFunction = () => {
  const modalOption = useModals();

  const { isKeyDown } = useKeyDown(["Meta", "Control"]);

  const {
    imageActiveList,
    isActiveWithImageId,
    setImageActiveList,
    pushImageActiveList,
    removeImageActiveItem,
    resetImageActiveList,
  } = useImageActiveList();

  const showDoubleClickModal = (id) =>
    modalOption.setDoubleClickModal((e) => ({
      ...e,
      show: true,
      id:id,
      onModalClose() {
        resetImageActiveList();
      },
    }));

  const onClickImage = (item) => {
    const isActive = isActiveWithImageId(item.imageId);

    if (isActive) {
      if (imageActiveList.length > 1 && !isKeyDown) {
        setImageActiveList([item]);
        return;
      }
      removeImageActiveItem(item.imageId);
    } else {
      if (!isKeyDown) {
        setImageActiveList([item]);
      } else {
        pushImageActiveList(item);
      }
    }
  };

  const onDoubleClickImage = (item, id) => {
    const isActive = isActiveWithImageId(item.imageId);

    if (!isActive) {
      setImageActiveList([item]);
    }
    showDoubleClickModal(id);
  };

  const hybridClickImage = useDoubleClick(
    (event, item,id) => onDoubleClickImage(item,id),
    (event, item,id) => onClickImage(item,id),
    100
  );

  useEffect(() => {
    return () => resetImageActiveList();
  }, []);

  return {
    isActiveWithImageId,
    hybridClickImage,
  };
};

export default useImageDoubleClickModalFunction;
