import Styles from "./styles";
import { forwardRef, useEffect, useMemo, useRef, useState } from "react";
import { useLanguage, useModals } from "hooks";
import { useConfig } from "hooks/useConfig";

// 여기서 수정해
// img
import IconClose from "resources/image/icon/icon_close.svg";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { api } from "service";
import useImageFilterInfo from "hooks/useImageFilterInfo";
import { useNavigate, useParams } from "react-router-dom";
import { IMAGE_SRC } from "constants/common";
import useForwardRef from "hooks/useForwardRef";
import useImageActiveList from "hooks/useImageActiveList";
import { formatImageWithPath } from "utils/common";

const ContextMenuModal = forwardRef(
  (
    {
      isModalOpen,
      onClose,
      onClickOutSide,
      priority,
      imageId,
      lesion,
      landmark,
      imageName,
      path,
      xy,
      setXY,
      notUseDoubleClickModal,
    },
    rootRef
  ) => {
    const ref = useForwardRef(rootRef);
    const { id, studyId } = useParams();
    const queryClient = useQueryClient();
    const lang = useLanguage("Image");
    const navigate = useNavigate();
    const configInfo = useConfig();

    const modalOption = useModals();
    const [isSubModalOpen, setIsSubModalOpen] = useState(false);

    const { imageFilterInfo, setWithAutoPhotoCheck } = useImageFilterInfo();

    const { setImageActiveList, resetImageActiveList } = useImageActiveList();

    const [isAddedReportImage, setIsAddedReportImage] = useState(false);
    const { mutate: landmarkChangeMutate } = useMutation(
      (forChangeLandmark) =>
        api.image.putLandmarkChange({
          landmark: forChangeLandmark,
          imageId,
          priority,
          sequence: 0,
          imageName,
          path,
        }),
      {
        onSuccess() {
          queryClient.invalidateQueries(["image_list", imageFilterInfo]);
        },
      }
    );
    const { data: reportImagesData = {} } = useQuery(
      "settings/report-images",
      () => api.setting.getSettingsReportImages(),
      {
        refetchOnWindowFocus: false,
      }
    );

    const { mutate: putReportImagesMutate } = useMutation(
      ({ path }) => api.setting.putSettingsReportImages({ path }),
      {
        onSuccess() {
          queryClient.invalidateQueries("settings/report-images");
          queryClient.invalidateQueries(["reports-images", "select-images"]);
        },
      }
    );
    const { mutate: deleteReportImagesMutate } = useMutation(
      ({ path }) => api.setting.deleteSettingsReportImages({ path }),
      {
        onSuccess() {
          queryClient.invalidateQueries("settings/report-images");
          queryClient.invalidateQueries(["reports-images", "select-images"]);
        },
      }
    );

    const reportImageList = useMemo(
      () =>
        Object.entries(reportImagesData)
          .filter(([_, value]) => value)
          .map(([_, value]) => value),
      [reportImagesData]
    );

    // data
    const landmarkListData = [
      {
        id: 1,
        text: lang.getLang("식도"),
      },
      {
        id: 2,
        text: lang.getLang("위식도 접합부"),
      },
      {
        id: 3,
        text: lang.getLang("십이지장 2부"),
      },
      {
        id: 4,
        text: lang.getLang("십이지장 구부"),
      },
      {
        id: 5,
        text: lang.getLang("위 전정부"),
      },
      {
        id: 6,
        text: lang.getLang("위 각부"),
      },
      {
        id: 7,
        text: lang.getLang("위 체부(하-중부)"),
      },
      {
        id: 8,
        text: lang.getLang("위 체부(중-상부)"),
      },
      {
        id: 9,
        text: lang.getLang("위 저부(반전)"),
      },
      {
        id: 10,
        text: lang.getLang("위 본문부(반전)"),
      },
      {
        id: 11,
        text: lang.getLang("위 체부(반전)"),
      },
      {
        id: 12,
        text: lang.getLang("lesions"),
      },
    ];

    const bottomListData = [
      {
        title: lang.getLang("위 아이콘으로 보기"),
        beforeLinkCallback() {
          setWithAutoPhotoCheck(false);
        },
        url: `/image/stomach/${id}/${studyId}`,
        on: true,
      },
      {
        title: lang.getLang("History 페이지로 보기"),
        url: `/history/${id}/${studyId}/${landmark}?imageId=${imageId}`,
        on: landmark !== 12,
      },
      {
        id: 3,
        title: lang.getLang("Lesion 페이지로 보기"),
        url: `/lesion/${id}/${studyId}/5?imageId=${imageId}`,
        on: lesion && lesion.length > 0,
      },
    ];

    const handleClickUpdateLandmark = (forChangeLandmark) => {
      landmarkChangeMutate(forChangeLandmark);
    };

    // 외부 영역 클릭 시 닫기
    const handleClickOutSide = (e) => {
      if (isModalOpen && !ref?.current?.contains(e.target)) {
        onClickOutSide?.(false);
      }
    };

    useEffect(() => {
      if (isModalOpen) {
        document.addEventListener("click", handleClickOutSide);
        return () => {
          document.removeEventListener("click", handleClickOutSide);
          document.getElementById("imageContainer")?.classList.remove("hidden");
        };
      }
    }, [isModalOpen]);

    useEffect(() => {
      if (reportImageList.length >= 3) {
        setIsAddedReportImage(true);
      }
    }, [reportImageList]);

    // 모달 오픈 시 스크롤 막기
    useEffect(() => {
      if (isModalOpen === true) {
        document.getElementById("imageContainer")?.classList.add("hidden");
      }
    }, [isModalOpen]);

    // xy 좌표
    useEffect(() => {
      if (xy.x + ref.current?.offsetWidth > window.innerWidth) {
        setXY({
          ...xy,
          x: xy.x - ref.current?.offsetWidth,
        });
      }
      if (xy.y + ref.current?.offsetHeight > window.innerHeight) {
        setXY({
          ...xy,
          y: xy.y - ref.current?.offsetHeight,
        });
      }
    }, [xy]);

    useEffect(() => {}, [landmarkListData]);

    return (
      <Styles.Container ref={ref} style={{ left: xy.x, top: xy.y }}>
        <ul className="topListWrap">
          <li
            className="topList"
            onMouseOver={() => {
              setIsSubModalOpen(true);
            }}
            onMouseLeave={() => {
              setIsSubModalOpen(false);
            }}
          >
            <p>{lang.getLang("랜드마크 위치 변경")}</p>

            {isSubModalOpen && (
              <div className="subModal">
                <ul className="landmarkListWrap">
                  {landmarkListData?.map((item, index) => {
                    return (
                      <li
                        className={
                          "landmarkList" + (item.id === landmark ? " on" : "")
                        }
                        key={"landmarkList" + index}
                        onClick={() => {
                          handleClickUpdateLandmark(item.id);
                          onClickOutSide?.();
                        }}
                      >
                        {index + 1}. {item.text}
                      </li>
                    );
                  })}
                </ul>
              </div>
            )}
          </li>
          {landmark !== 12 && (
            <li
              className={notUseDoubleClickModal ? "disabled" : ""}
              onClick={() => {
                // console.log("imageId => ", imageId);
                // console.log("imageName => ", imageName);
                // console.log("landmark => ", landmark);
                // console.log("lesion => ", lesion);
                // console.log("path => ", path);
                // console.log("priority => ", priority);
                if (notUseDoubleClickModal) return;
                onClickOutSide?.();
                setImageActiveList([
                  {
                    imageId,
                    imageName,
                    landmark,
                    lesion,
                    path,
                    priority,
                  },
                ]);
                modalOption.setDoubleClickModal((e) => ({
                  ...e,
                  show: true,
                  onModalClose() {
                    resetImageActiveList();
                  },
                }));
              }}
            >
              {lang.getLang("이미지 크게 보기")}
            </li>
          )}
        </ul>

        <div className="middleListWrap">
          <p
            onClick={() => {
              if (isAddedReportImage) return;
              putReportImagesMutate(
                { path },
                {
                  onSuccess() {
                    console.log(reportImageList.length);
                    // 여기서 이미지 하나 Add할때마다 isAddedReportImage를 true로 변환 하는데
                    // 10여줄 아래의 코드를 보면 isAddedReportImage가 false일때만 add할수 있도록 되어있고
                    // 모달이 열릴때 useState default false로 해놔서 모달이 새로 열릴때만 add할수 있도록
                    // 코드가 구현되어 있었음
                    // 그래서 아래 코드를 주석처리하고
                    // setIsAddedReportImage(true);
                    // useEffect에 reportImageList.length >= 3 일때 setIsAddedReportImage(true);로 처리함
                  },
                  onError(response) {
                    alert(response.error);
                  },
                }
              );
            }}
          >
            {lang.getLang("리포트용 사진으로 선택")}({reportImageList.length}/3)
          </p>

          <ul className="reportImgListWrap">
            {reportImageList?.length < 3 && !isAddedReportImage && (
              <li className="reportImgList addReportImgContainer">
                <p className="addReportText">
                  {lang.getLang("선택 시 해당")} <br />{" "}
                  {lang.getLang("위치에 사진 추가")}
                </p>
                <img src={formatImageWithPath(path)} alt={imageName} />
              </li>
            )}
            {reportImageList.reverse()?.map((path) => {
              return (
                <li className="reportImgList" key={"reportImgList" + path}>
                  <img src={IMAGE_SRC + path} alt="stomach" />
                  <button
                    type="button"
                    className="deleteButton"
                    onClick={() => {
                      deleteReportImagesMutate(
                        { path },
                        {
                          onSuccess() {
                            setIsAddedReportImage(false);
                          },
                        }
                      );
                    }}
                  >
                    <img src={IconClose} alt="del" />
                  </button>
                </li>
              );
            })}
          </ul>
        </div>

        <ul className="bottomListWrap">
          {bottomListData
            .filter((el) =>
              configInfo.setting.isSimpleMode ? el.id !== 3 : true
            )
            .map((item, index) => {
              console.log(imageId);
              console.log("item과 index는?", item, index);
              return (
                <li
                  className={"bottomList" + (!item.on ? " on" : "")}
                  key={"bottomList" + index}
                  onClick={() => {
                    console.log("item.url은?", item.url);
                    if (!item.url) return;
                    if (!item.on) return;
                    item?.beforeLinkCallback?.();
                    navigate(item.url);
                  }}
                >
                  {item.title}
                </li>
              );
            })}
        </ul>
      </Styles.Container>
    );
  }
);

export default ContextMenuModal;
