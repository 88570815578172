import styled from "styled-components";

const Container = styled.div`
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  padding-bottom: calc(100vw * (18 / 1920));
  margin-bottom: calc(100vw * (10 / 1920));

  &::after {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: calc(100vw * (6 / 1920));
    border-radius: calc(100vw * (4 / 1920));
    content: "";
  }

  &.green {
    &::after {
      background-color: #04b24a;
    }

    & .percent,
    .percent span {
      color: #04b24a;
    }
  }

  &.yellow {
    &::after {
      background-color: #fcb711;
    }

    & .percent,
    .percent span {
      color: #fcb711;
    }
  }

  &.red {
    &::after {
      background-color: #cc004c;
    }

    & .percent,
    .percent span {
      color: #cc004c;
    }
  }

  &.orange {
    &::after {
      background-color: #f37021;
    }

    & .percent,
    .percent span {
      color: #f37021;
    }
  }

  &.purple {
    &::after {
      background-color: #6460aa;
    }

    & .percent,
    .percent span {
      color: #6460aa;
    }
  }

  & .title {
    font-size: calc(100vw * (36 / 1920));
    font-weight: 700;
  }

  & .checkContainer {
    font-size: calc(100vw * (26 / 1920));
    font-weight: 700;
  }

  & .percent {
    position: absolute;
    right: 0;
    bottom: calc(100vw * (-50 / 1920));
    font-size: calc(100vw * (40 / 1920));
    font-weight: bold;

    & span {
      font-size: calc(100vw * (28 / 1920));
    }
  }
`;

export { Container };

export default { Container };
