const lang = {
  한국어: {
    _en: "Korean",
  },
  "위 내시경 예후관리 시스템": {
    _en: "Prognostic Management System",
  },
  "프리베노틱스 AI 예후관리(Preveno-G)": {
    _en: "Preveno-G",
  },
  "아이디 또는 비밀번호를 잘못 입력하였습니다.": {
    _en: "The ID or password does not match.",
  },
  "입력하신 내용을 다시 확인해 주세요.": {
    _en: "Please try again",
  },
  제조자: {
    _en: "Manufacturer",
  },
  "제조자 주소": {
    _en: "Address",
  },
  "품목명 (등급)": {
    _en: "Item name(Class)",
  },
  "제품명 (모델명)": {
    _en: "Product(model)",
  },
  사용목적: {
    _en: "Intended use",
  },
  포장단위: {
    _en: "Packaging unit",
  },
  버전: {
    _en: "Version",
  },
  "업데이트 일자": {
    _en: "Updated date",
  },
  제조허가번호: {
    _en: "Manufacturing certification number",
  },
  제조업허가번호: {
    _en: "Manufacturer certification number",
  },
  제조번호: {
    _en: "Number of manufacture",
  },
  제조일자: {
    _en: "Date of manufacture",
  },
};

export default lang;
