import { useParams } from "react-router-dom";
import Styles from "./styles";

// img
import IconStomach from "resources/image/icon/icon_stomach.svg";
import { useQuery } from "react-query";
import { api } from "service";
import { IMAGE_SRC } from "constants/common";

export default function LandmarkSection() {
  const { studyId } = useParams();
  const { data: reportsLandmarkData = {} } = useQuery(
    ["reports-images", studyId, "landmark"],
    () =>
      api.reports.getReportsImages({
        medicalstudyId: studyId,
        check: "landmark",
      }),
    {
      refetchOnWindowFocus: false,
    }
  );

  const reportsLandmarkList = Object.values(reportsLandmarkData);

  return (
    <>
      <Styles.Container>
        <div className="wrap">
          {reportsLandmarkList?.map((item, index) => {
            if (item[index + 1]?.path == null) return null;

            return (
              <div
                className={"stomachPart " + "stomachPart" + (index + 1)}
                key={"stomachPartImageWrap" + index}
              >
                <div className="stomachPartImageWrap">
                  <img
                    src={IMAGE_SRC + item[index + 1]?.path}
                    alt={"stomachPart" + (index + 1)}
                  />
                </div>
              </div>
            );
          })}
          <div className="stomachImageWrap">
            <img src={IconStomach} alt="stomach" />
          </div>
        </div>
      </Styles.Container>
    </>
  );
}
