import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import ui from "components/ui";
import Styles from "./styles";
import { useAuth } from "hooks/useAuth";

// img
import IconHome from "resources/image/icon/icon_home.svg";
import IconSetting from "resources/image/icon/icon_setting.svg";

export default function SettingHeader({
  title,
  logout,
  search,
  searchClickEvent,
}) {
  const navigate = useNavigate();
  const [searchWord, setSearchWord] = useState("");
  const authInfo = useAuth();

  return (
    <Styles.Container>
      <button
        type="button"
        className="home"
        onClick={() => {
          navigate(-1);
        }}
      >
        <img src={IconHome} alt="home" />
      </button>

      <h2>{title}</h2>

      <button
        type="button"
        className="setting"
        onClick={() => {
          navigate("/settings");
        }}
      >
        <img src={IconSetting} alt="setting" />
      </button>
    </Styles.Container>
  );
}
