import React, { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { TableCheckup } from "./components";
import { getCookie } from "service/connector";
import { ui } from "components";
import { useLanguage, useModals } from "hooks";
import StateButton from "components/ui/button/StateButton";
import Styles from "./styles";
import { useAuth } from "hooks/useAuth";
import { api } from "service";
import { useQuery } from "react-query";
import { compareDates, getTodayDate } from "utils/date";
import utils from "utils";
import dayjs from "dayjs";
import { useQueryClient } from "react-query";

// img
import IconTime from "resources/image/icon/icon_time.svg";
import IconUser from "resources/image/icon/icon_user.svg";
import IconSearch from "resources/image/icon/icon_search.svg";
import IconFilter from "resources/image/icon/icon_filter.svg";
import IconReset from "resources/image/icon/icon_reset.svg";
import IconSetting from "resources/image/icon/icon_setting.svg";
import IconUpload from "resources/image/icon/icon_file_browser.svg";
import { WindowCenterAndWidth } from "dwv";

export default function CheckupNewPage() {
  const modalOption = useModals();
  const lang = useLanguage("Checkup");
  const authInfo = useAuth();
  const navigate = useNavigate(); // query parameter 업데이트 하기 위해 가져옴
  // const queryParams = new URLSearchParams();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const hasQueryParams = Array.from(queryParams.keys()).length > 0;

  const [dataList, setDataList] = useState({
    page: 1,
    data: [],
  });

  const [filteredData, setFilteredData] = useState([]);

  const [filterInfo, setFilterInfo] = useState({
    page: 1,
    // idOrName: "",
    // endDate: dayjs().format("YYYY-MM-DD"),
    // startDate: dayjs().format("YYYY-MM-DD"),
  });

  const boardQuery = useQuery(
    ["board_info", authInfo],
    () => api.doctor.getBoard(),
    {
      refetchOnWindowFocus: false,
    }
  );

  const getPatientList = async (isInit) => {
    let res;
    try {
      if (isInit) {
        let obj = {
          page: 1,
          idOrName: modalOption?.searchWord,
          startDate: dayjs().format('YYYYMMDD'),
          endDate:dayjs().format('YYYYMMDD')
        };
        res = await api.doctor.getList(obj, 1);
      } else {
        let filter = filterInfo;
        if (!filter.idOrName) {
          delete filter.idOrName;
          queryParams.delete("idOrName");
        } else {
          queryParams.set("idOrName", filter.idOrName);
        }

        if (filter.startDate) {
          filter.startDate = filter.startDate.replace(/-/gi, "");
          queryParams.set("startDate", filter.startDate);
        } else {
          delete filter.startDate;
          queryParams.delete("startDate");
        }

        if (filter.endDate) {
          filter.endDate = filter.endDate.replace(/-/gi, "");
          queryParams.set("endDate", filter.endDate);
        } else {
          delete filter.endDate;
          queryParams.delete("endDate");
        }

        res = await api.doctor.getList(filterInfo, 1);
        console.log("res => ", res);
        navigate({
          pathname: window.location.pathname,
          search: queryParams.toString(),
        });
      }

      if (res.length) {
        setFilteredData(JSON.parse(JSON.stringify(res)));
      } else {
        alert("검색결과가 없습니다.");
        setFilteredData([]);
      }
    } catch (e) {
      setFilteredData([]);
    }
  };

  const patientsQuery = useQuery(
    ["doctor_patients_list", filterInfo],
    () => api.doctor.getList(filterInfo, dataList?.page),
    {
      onSuccess: (res) => {
        if (res) {
          let page = filterInfo?.page;

          let obj = dataList?.data;
          let data = JSON.parse(JSON.stringify(res));
          if (data != null && data.length > 0) {
            for (let i = 0; i < data.length; i++) {
              obj.push(data[i]);
            }
          }
          setDataList((f) => {
            f.data = obj;
            f.page = page + 1;
            return { ...f };
          });

          //한 페이지당 5개밖에 안나와서 임시처리
          // if (page < 3) {
          //   setFilterInfo((f) => {
          //     f.page = page + 1;
          //     return { ...f };
          //   });
          // }
        }
      },
      refetchOnWindowFocus: false,
    }
  );

  function onScroll(e) {
    let target = e.target;
    let inner_height = target.clientHeight; // target의 높이
    let scroll_height = target.scrollTop; // 스크롤 한 높이
    let all_height = target.scrollHeight; // 마진 제거한 전체 스크롤 높이

    if (inner_height + scroll_height + 0.5 >= all_height) {
      let page = filterInfo?.page;

      setFilterInfo((f) => {
        // f.page = page + 1;
        return { ...f };
      });
    }
  }

  const idOrNameRegexTestFunc = async () => {
    if (!filterInfo.idOrName || filterInfo.idOrName == "") {
      setFilterInfo((current) => {
        const { idOrName, ...rest } = current;
        return rest;
      });
      return true;
    } else {
      const regexSearchIdOrName = /^([0-9]{1,20}|[a-zA-Z가-힣\s]{2,20})$/;
      if (regexSearchIdOrName.test(filterInfo.idOrName)) {
        return true;
      } else {
        alert("숫자만 또는 한글영문 2~20자까지 검색 가능합니다.");
        return false;
      }
    }
  };

  const [searchWord, setSearchWord] = useState("");

  const onKeyUpEnter = (e) => {
    if (e.key == "Enter") {
      idOrNameRegexTestFunc().then((val) => {
        if (val) {
          getPatientList();
        } else {
          return false;
        }
      });
    }
  };

  const [selectedFiles, setSelectedFiles] = useState([]);
  const [uploadData, setUploadData] = useState(null);

  const handleFileChange = (event) => {
    const files = event.target.files;
    let selectedFiles = [];
    for (let i = 0; i < files.length; i++) {
      selectedFiles.push(files[i]);
    }
    setSelectedFiles(selectedFiles);

    event.target.value = null;
  };

  useEffect(() => {
    if (selectedFiles.length) {
      // console.log("selectedFiles => ", selectedFiles);
      try {

        modalOption.setUploadFileModal((e) => ({
          ...e,
          show: true,
          data: selectedFiles,
          onModalClose() {
            getPatientList();
            setSelectedFiles([]);
          },
        }));
      }
      catch (error) {
        console.error(error);
      }

    }
  }, [selectedFiles]);

  useEffect(() => {
    // console.log("==checkup new scope : ", getCookie("scope"));
    const hasStartDate = queryParams.has("startDate");
    const hasEndDate = queryParams.has("endDate");
    const hasIdOrName = queryParams.has("idOrName");

    const name = hasIdOrName ? queryParams.get("idOrName") : "";
    const end = hasEndDate ? queryParams.get("endDate") : "";
    const start = hasStartDate ? queryParams.get("startDate") : "";

    if (hasQueryParams) {
      // console.log("has params: ", name, start, end);
      // setFilterInfo({
      //   page: 1,
      //   idOrName: name,
      //   endDate: end,
      //   startDate: start,
      // });
      setFilterInfo((prevState) => ({
        ...prevState,
        ...(hasIdOrName && { idOrName: name }), // name 있으면 idOrName 추가
        ...(start && { startDate: start }), // start가 있으면 startDate를 추가
        ...(end && { endDate: end }), // end가 있으면 endDate를 추가
      }));
    } else {
      // console.log("no params");
    }
  }, [location.search]);

  return (
    <Styles.Container>
      <div className="side_box">
        <div className="logout_box">
          <button
            type="button"
            className="logout"
            onClick={() => {
              // navigate("/login");
              authInfo.logout();
            }}
          >
            Logout
          </button>
        </div>
        <div className="side_inner">
          <div className="doctor_box">
            <p className="doctor">{boardQuery?.data?.userName}</p>
            <span>{`ID: ${boardQuery?.data?.userId}`} </span>
          </div>
          <div className="treatment_box">
            <div className="treat_title">
              <div>
                <div className="timeIconWrap">
                  <img src={IconTime} alt="time" />{" "}
                </div>
                <p>{lang.getLang("오늘 미검진 현황")}</p>
              </div>
              <div>
                <div className="userIconWrap">
                  <img src={IconUser} alt="user" />{" "}
                </div>
                <span>
                  {lang.getLang("총 ")}
                  {`${
                    boardQuery?.data?.statusBoard?.length
                  }${lang.getLangString("명")}`}
                </span>
              </div>
            </div>

            <ul className="treat_list">
              {boardQuery?.data?.statusBoard?.map((item, index) => {
                return (
                  <li key={index}>
                    <p>{item?.pateintName}</p>
                    <StateButton type={item?.status} />
                  </li>
                );
              })}
            </ul>
          </div>
        </div>
      </div>

      <div className="content_box">
        <div className="content_title">
          <span>
            {getTodayDate(true)?.split(" ")[0]}{" "}
            {lang.getLang(getTodayDate(true)?.split(" ")[1])}
          </span>
          <h2>STUDY</h2>
          <button
            type="button"
            className="button settingButton"
            onClick={() => {
              navigate("/settings");
            }}
          >
            <img src={IconSetting} alt="setting" />
          </button>
          {/* <div className="search">
            <button
              type="button"
              className="search_btn"
              onClick={() => {
                modalOption.setSearchCheckupModal((e) => {
                  e.show = true;
                  e.searchWord = searchWord;
                  return { ...e };
                });
              }}
            >
              <span className="iconWrap">
                <img src={IconFilter} alt="filter" />
              </span>

              {lang.getLang("필터")}
            </button>
          </div> */}
        </div>
        <div className="filter_box">
          <form>
            <ul className="inputListWrap">
              <li className="inputList" key={111}>
                <p className="inputTitle">{lang.getLang("이름 / 환자 ID")}</p>
                <div className="inputContainer name">
                  <ui.input.BasicInput
                    placeholder={lang.getLangString("이름 또는 ID")}
                    isEnter={true}
                    value={filterInfo.idOrName}
                    setValue={(e) => {
                      setFilterInfo((f) => {
                        f.idOrName = e;
                        return { ...f };
                      });
                    }}
                    keyUp={(e) => {
                      onKeyUpEnter(e);
                    }}
                  />
                </div>
              </li>

              <li className="inputList" key={222}>
                <p
                  className="inputTitle"
                  onClick={() => {
                    console.log(filterInfo);
                  }}
                >
                  {lang.getLang("검진일")}
                </p>
                <ui.button.CalendarButton
                  dateChange={filterInfo.startDate}
                  setDateChange={(e) => {
                    setFilterInfo((f) => {
                      f.startDate = e;
                      f.endDate = e;
                      return { ...f };
                    });
                  }}
                />
                <div className="inputContainer">
                  <ui.input.BasicInput
                    value={
                      filterInfo.startDate
                        ? utils.etc.numberToDate(filterInfo.startDate)
                            .resultText
                        : ""
                    }
                    // value={
                    //   filterInfo.startDate &&
                    //   utils.etc.numberToDate(filterInfo.startDate).resultText
                    // }
                    setValue={(e) => {
                      setFilterInfo((f) => {
                        f.startDate = e;
                        f.endDate = e;
                        return { ...f };
                      });
                    }}
                    placeholder={
                      filterInfo.startDate ? "" : dayjs().format("YYYY-MM-DD")
                    }
                  />
                </div>
              </li>

              <li className="inputList" key={333}>
                {/* <p className="inputTitle">~</p>
                <ui.button.CalendarButton
                  dateChange={filterInfo.endDate}
                  setDateChange={(e) => {
                    setFilterInfo((f) => {
                      f.endDate = e;
                      return { ...f };
                    });
                  }}
                />
                <div className="inputContainer">
                  <ui.input.BasicInput
                    value={
                      filterInfo.endDate
                        ? utils.etc.numberToDate(filterInfo.endDate).resultText
                        : ""
                    }
                    // value={
                    //   filterInfo.endDate &&
                    //   utils.etc.numberToDate(filterInfo.endDate).resultText
                    // }
                    setValue={(e) => {
                      setFilterInfo((f) => {
                        f.endDate = e;
                        return { ...f };
                      });
                    }}
                    placeholder={
                      filterInfo.endDate ? "" : dayjs().format("YYYY-MM-DD")
                    }
                  />
                </div> */}
                <button
                  type="button"
                  className="searchButton"
                  onClick={() => {
                    if (
                      !filterInfo.startDate ||
                      !filterInfo.endDate ||
                      compareDates(filterInfo.startDate, filterInfo.endDate)
                      // new Date(filterInfo.startDate) <=
                      //   new Date(filterInfo.endDate)
                    ) {
                      idOrNameRegexTestFunc().then((val) => {
                        if (val) {
                          getPatientList();
                        } else {
                          return false;
                        }
                      });
                    } else {
                      alert("종료일이 시작일보다 빠를수 없습니다.");
                    }
                  }}
                >
                  <span className="iconWrap">
                    <img src={IconSearch} alt="search" />
                  </span>
                </button>

                <button
                  type="button"
                  className="searchButton"
                  onClick={() => {
                    setFilteredData([]);
                    setFilterInfo({
                      page: 1,
                    });
                    navigate("/checkup/new");
                    // window.location.reload();
                  }}
                >
                  <span className="iconResetWrap">
                    <img src={IconReset} alt="reset" />
                  </span>
                </button>

                <input
                  type="file"
                  multiple
                  // accept="image/*"
                  style={{ display: "none" }}
                  onChange={(e) => {
                    handleFileChange(e);
                  }}
                  
                />
                <button
                  type="button"
                  className="searchButton"
                  onClick={() => {
                    document.querySelector("input[type='file']").click();
                  }}
                >
                  <span className="iconUploadWrap">
                    <img src={IconUpload} alt="upload" />
                  </span>
                </button>
              </li>
            </ul>
          </form>
        </div>

        <TableCheckup
          list={filteredData.length ? filteredData : dataList.data}
          isScroll={true}
          onScroll={(e) => {
            onScroll(e);
          }}
          onDeleteFile={(studyId) => {
            getPatientList();
          }}
        />
      </div>
    </Styles.Container>
  );
}
