import Styles from "./styles";
import layout from "components/layout";
import ReportChangeIMCheckedSection from "components/layout/ReportChangeIMCheckedSection";
import { useLanguage } from "hooks";
import { useConfig } from "hooks/useConfig";
import { useEffect, useMemo, useState } from "react";
import { useQuery } from "react-query";
import { api } from "service";
import { formatHeatmapWithPath, lesionColorToType } from "utils/common";

export default function ChangeIMModal({ modalClose, modalOption }) {
  const {
    landmark: landmarkProps,
    studyId,
    lesionScore,
    lesionType,
    onClickChangeIM,
  } = modalOption;
  const [landmark, setLandMark] = useState(null);
  const lang = useLanguage("Report");

  if (!landmark) {
    setLandMark(landmarkProps);
  }

  const [lesionIndex, setLesionIndex] = useState(0);

  const configInfo = useConfig();
  const [checkLesion, setCheckLesion] = useState(
    configInfo.configData.lesionMark
  );

  useEffect(() => {
    setCheckLesion(configInfo.configData.lesionMark);
  }, [configInfo.configData]);

  const { data: settingsData, isLoadingSettings } = useQuery(
    ["settings"],
    () => api.setting.getSetting(),
    {
      refetchOnWindowFocus: false,
    }
  );

  const {
    data: IMData = {},
    isLoading: isLoadingIM,
    isSuccess: isSuccessIM,
  } = useQuery(
    ["lesions-im-path", studyId],
    () => api.image.getLesionsIMPath({ medicalstudyId: studyId }),
    {
      refetchOnWindowFocus: false,
    }
  );

  const imSensitivity = settingsData?.imSensitivity;

  const canSelectStomach = [5, 6, 7, 8, 11];
  const notSelectedSection = Object.keys(IMData)
    .map((keyword) => {
      const preProperty = "scoreLandmark";
      if (canSelectStomach.some((item) => preProperty + item === keyword)) {
        const score = IMData[keyword];
        const colorType = score >= 100 - imSensitivity ? "yellow" : "green";
        return {
          landmark: Number(keyword.split(preProperty)[1]),
          type: colorType,
        };
      }
      return null;
    })
    .filter((item) => item);

  const lesionsIM = useMemo(() => {
    return IMData[landmark] || [];
  }, [IMData, landmark]);

  const currentLesion = useMemo(() => {
    return lesionsIM[lesionIndex] || null;
  }, [lesionsIM, lesionIndex]);

  const currentHitMapSrc = useMemo(() => {
    if (!currentLesion) return;
    const path = currentLesion.path;
    return formatHeatmapWithPath(path);
  }, [currentLesion]);

  const IMScore = IMData?.IMScore;
  const lesionScoreType = notSelectedSection?.find(
    (item) => item.landmark === Number(landmark)
  )?.type;

  const imGifLesionType =
    settingsData.imSensitivity > IMData.IMScore ? "yellow" : "green";

  const { data: lesionJsonData, isFetching: isFetchingLesionsJson } = useQuery(
    ["lesions-path-json", "IM", landmark, studyId],
    async () => {
      const promises = lesionsIM?.map(async ({ path }) => {
        return await api.image.getLesionJson({ path });
      });

      return await Promise.all(promises);
    },
    {
      enabled: isSuccessIM && checkLesion,
      refetchOnWindowFocus: false,
    }
  );
  const matchingLesionJsonData = useMemo(() => {
    if (!lesionJsonData) return [];
    // return lesionJsonData?.map((item, index) => {
    //   const findLesionJsonData = item?.filter(
    //     (item) => item.lesion === "im"
    //   )[0];

    //   return findLesionJsonData;
    // });

    return lesionJsonData?.map((item) => {
      const findLesionJsonData = item.lesion_metaplasia.filter(
        (metaplasia) => metaplasia.metaplasia_type === "im"
      )[0];

      return findLesionJsonData;
    });
  }, [lesionJsonData, IMData]);

  const [renderedImgList, setRenderedImgList] = useState([]);
  const {
    data: lesionsPathData = [],
    isSuccess: isSuccessLesionsPath,
    isLoading: isLoadingLesions,
  } = useQuery(
    ["lesions-im-path", studyId],
    () => api.image.getLesionsIMPath({ medicalstudyId: studyId }),
    {
      refetchOnWindowFocus: false,
      onSuccess(responseLesionPath) {
        let resList = responseLesionPath["5"];
        resList = resList.concat(responseLesionPath["8"]);
        resList = resList.concat(responseLesionPath["6"]);
        resList = resList.concat(responseLesionPath["7"]);
        resList = resList.concat(responseLesionPath["11"]);

        const initialRenderedImgList = resList.map((item) => ({
          imageId: item?.imageId ? item.imageId : "9999999",
          // imageId: item.imageId,
          naturalWidth: 0,
          naturalHeight: 0,
        }));
        setRenderedImgList(initialRenderedImgList);
      },
    }
  );

  if (isLoadingSettings || isLoadingIM) return <></>;
  return (
    <Styles.Container>
      <div
        className="overlay"
        onClick={() => {
          modalClose();
        }}
      ></div>

      <div className="inner">
        <div className="imContainer">
          <layout.ScoreSection
            noAni
            landmark={landmark}
            onChangeSelectedSection={(landmark) => {
              setLandMark(landmark);
            }}
            score={IMScore}
            scoreType={imGifLesionType}
            numberType={lesionScoreType}
            lesionType={currentLesion?.lesionType}
            notSelectedSection={notSelectedSection}
            screen={"heatmap"}
          />
          <ReportChangeIMCheckedSection
            landmark={landmark}
            studyId={studyId}
            IMData={IMData}
            lesionScoreType={lesionScoreType}
            lesionIndex={lesionIndex}
            setLesionIndex={setLesionIndex}
            checkLesion={checkLesion}
            onChangeChecked={(check) => {
              setCheckLesion(check);
            }}
            matchingLesionJsonData={matchingLesionJsonData}
            isFetchingLesionsJson={isFetchingLesionsJson}
            renderedImgList={renderedImgList}
            setRenderedImgList={setRenderedImgList}
          />
        </div>

        <div className="buttonContainer">
          <button
            type="button"
            className="submitButton"
            onClick={() => {
              modalClose();
              onClickChangeIM({ selected: currentLesion });
            }}
          >
            {lang.getLang("사진 변경")}
          </button>
          <button type="button" className="cancelButton" onClick={modalClose}>
            {lang.getLang("취소")}
          </button>
        </div>
      </div>
    </Styles.Container>
  );
}
