import { useLanguage } from "hooks";
import Styles from "./styles";
import { getCookie } from "service/connector";

import LesionTitle from "../LesionTitle";

// swiper
import { Swiper, SwiperSlide } from "swiper/react";
import { EffectCoverflow, Navigation } from "swiper";
import "swiper/css";
import "swiper/css/effect-coverflow";
import "swiper/css/navigation";

import { useMediaQuery } from "react-responsive";

// img
import { IMAGE_REF, IMAGE_SRC, swiperOption } from "constants/common";
import { useMemo, useState, useEffect, useRef } from "react";
import { formatHeatmapWithPath } from "utils/common";
import SVG from "components/common/SVG";

export default function ReportChangeIMCheckedSection({
  paddingSize,
  viewPort,
  landmark,
  IMData,
  lesionIndex,
  setLesionIndex,
  lesionScoreType,
  checkLesion,
  onChangeChecked,
  matchingLesionJsonData,
  isFetchingLesionsJson,
  renderedImgList, 
  setRenderedImgList
}) {
  const [scope, setScope] = useState("lite")
  const swiperRef = useRef(null)
  const [currentIndex, setCurrentIndex] = useState(0)

  useEffect(() => {
    setScope(getCookie('scope'))
  }, [])

  const lang = useLanguage("Lesion");
  const isPro = useMediaQuery({
    query: "(max-width: 1366px)",
  });
  const isAir = useMediaQuery({
    query: "(max-width: 1180px)",
  });

  const lesionsIM = useMemo(() => {
    return IMData[landmark] || [];
  }, [IMData, landmark]);

  const currentLesion = useMemo(() => {
    return lesionsIM[lesionIndex] || null;
  }, [lesionsIM, lesionIndex]);

  const currentHitMapSrc = useMemo(() => {
    if (!currentLesion) return;
    const path = currentLesion.path;
    return formatHeatmapWithPath(path);
  }, [currentLesion]);

  useEffect(() => {
    if (swiperRef.current) {
      swiperRef.current.swiper.slideTo(0);
    }
  }, [landmark]);

  return (
    <Styles.Container
      paddingSize={paddingSize}
      viewPort={viewPort}
    >
      <div className="leftWrap">
        <div className="swiperContainer">
          {
            renderedImgList && renderedImgList.length > 0 ? 
            <Swiper
              ref={swiperRef}
              direction={"vertical"}
              effect={"coverflow"}
              grabCursor={true}
              initialSlide={0}
              navigation={true}
              centeredSlides={true}
              slidesPerView={"auto"}
              coverflowEffect={
                isAir
                  ? swiperOption.AirOption
                  : isPro
                  ? swiperOption.ProOption
                  : swiperOption.option
              }
              onSlideChange={(swiper) => {
                setLesionIndex(swiper.activeIndex);
              }}
              modules={[EffectCoverflow, Navigation]}
              className="swiper"
            >
              {lesionsIM?.map(({ path, imageId }, index) => {

                const jsonMap = matchingLesionJsonData[index]?.polygon;
                const imgData = renderedImgList[index];

                return (
                  <SwiperSlide 
                    className="imgContainer slider"
                    key={"slider" + imageId?imageId:"9999"}
                  >
                    <img 
                      src={IMAGE_SRC + path} 
                      alt="stomach" 
                      onLoad={(event) => {
                        const { naturalWidth, naturalHeight } =
                          event.currentTarget;
                        setRenderedImgList((prev) => {
                            return prev?.map((item) => {
                              if (imageId === item.imageId) {
                                return {
                                  ...item,
                                  naturalWidth,
                                  naturalHeight,
                                };
                              }
                            return item;
                          });
                        });
                      }}
                    />
                    {!isFetchingLesionsJson && checkLesion && 
                      jsonMap?.map((jsonData) => (
                        <SVG.Polygon
                          data={jsonData || []}
                          width={imgData?.naturalWidth}
                          height={imgData?.naturalHeight}
                        />
                      ))}
                  </SwiperSlide>
                );
              })}
            </Swiper>
            :
            null
          }
          
        </div>

        <div className="imgSingleContainer">
          <img src={currentHitMapSrc} alt="stomach" />
        </div>

        <div className="imgSingleContainer">
          <img src={IMAGE_REF + landmark + ".png"} alt="stomach" />
        </div>
      </div>
    </Styles.Container>
  );
}
