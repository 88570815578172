import styled from "styled-components";

const Container = styled.div`
  position: relative;
  margin-top: calc(100vw * (177 / 1920));
  width: calc(100vw * (471 / 1920));
  height: calc(100vw * (411 / 1920));

  & .numberIconContainer {
    position: absolute;
    z-index: 3;
    pointer-events: none;

    & .line {
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      z-index: 1;

      & img {
        width: 100%;
        height: 100%;
        object-fit: fill;
      }
    }
  }

  & .numberIconContainer:first-child {
    top: calc(100vw * (42 / 1920));
    left: calc(100vw * (212 / 1920));

    & .line {
      top: calc(100vw * (-86 / 1920));
      left: calc(100vw * (-432 / 1920));
      width: calc(100vw * (444 / 1920));
      height: calc(100vw * (212 / 1920));
    }
  }

  & .numberIconContainer:nth-of-type(2) {
    top: calc(100vw * (105 / 1920));
    left: calc(100vw * (229 / 1920));

    & .line {
      top: calc(100vw * (-49 / 1920));
      left: calc(100vw * (-449 / 1920));
      width: calc(100vw * (452 / 1920));
      height: calc(100vw * (137 / 1920));
    }
  }

  & .numberIconContainer:nth-of-type(3) {
    top: calc(100vw * (301 / 1920));
    left: calc(100vw * (21 / 1920));

    & .line {
      top: 0;
      left: calc(100vw * (-240 / 1920));
      width: calc(100vw * (244 / 1920));
      height: calc(100vw * (42 / 1920));
    }
  }

  & .numberIconContainer:nth-of-type(4) {
    top: calc(100vw * (286 / 1920));
    left: calc(100vw * (91 / 1920));

    & .line {
      top: calc(100vw * (95 / 1920));
      left: calc(100vw * (-310 / 1920));
      width: calc(100vw * (318 / 1920));
      height: calc(100vw * (149 / 1920));
    }
  }

  & .numberIconContainer:nth-of-type(5) {
    top: calc(100vw * (319 / 1920));
    left: calc(100vw * (184 / 1920));

    & .line {
      top: calc(100vw * (153 / 1920));
      left: calc(100vw * (-403 / 1920));
      width: calc(100vw * (409 / 1920));
      height: calc(100vw * (254 / 1920));
    }
  }

  & .numberIconContainer:nth-of-type(6) {
    top: calc(100vw * (265 / 1920));
    left: calc(100vw * (255 / 1920));

    & .line {
      top: calc(100vw * (-47 / 1920));
      left: calc(100vw * (-475 / 1920));
      width: calc(100vw * (482 / 1920));
      height: calc(100vw * (126 / 1920));
    }
  }

  & .numberIconContainer:nth-of-type(7) {
    top: calc(100vw * (316 / 1920));
    left: calc(100vw * (326 / 1920));

    & .line {
      top: calc(100vw * (81 / 1920));
      right: calc(100vw * (-328 / 1920));
      width: calc(100vw * (333 / 1920));
      height: calc(100vw * (113 / 1920));
    }
  }

  & .numberIconContainer:nth-of-type(8) {
    top: calc(100vw * (206 / 1920));
    left: calc(100vw * (411 / 1920));

    & .line {
      top: calc(100vw * (58 / 1920));
      right: calc(100vw * (-242 / 1920));
      width: calc(100vw * (257 / 1920));
      height: calc(100vw * (81 / 1920));
    }
  }

  & .numberIconContainer:nth-of-type(9) {
    top: calc(100vw * (77 / 1920));
    left: calc(100vw * (362 / 1920));

    & .line {
      top: calc(100vw * (-29 / 1920));
      right: calc(100vw * (-291 / 1920));
      width: calc(100vw * (303 / 1920));
      height: calc(100vw * (90 / 1920));
    }
  }

  & .numberIconContainer:nth-of-type(10) {
    top: calc(100vw * (116 / 1920));
    left: calc(100vw * (270 / 1920));

    & .line {
      top: calc(100vw * (-126 / 1920));
      right: calc(100vw * (-383 / 1920));
      width: calc(100vw * (393 / 1920));
      height: calc(100vw * (267 / 1920));
    }
  }

  & .numberIconContainer:nth-of-type(11) {
    top: calc(100vw * (176 / 1920));
    left: calc(100vw * (314 / 1920));

    & .line {
      top: calc(100vw * (-1 / 1920));
      right: calc(100vw * (-340 / 1920));
      width: calc(100vw * (345 / 1920));
      height: calc(100vw * (43 / 1920));
    }
  }
`;

const NumberIconWrap = styled.div`
  position: relative;
  z-index: 2;

  &.on {
    opacity: ${(props) => (props.isHover ? "1" : "0.5")};
  }
`;

const Line = styled.div`
  pointer-events: none;

  &.on {
    opacity: ${(props) => (props.isHover ? "1" : "0.5")};
  }
`;

export { Container, Line, NumberIconWrap };

export default { Container, Line, NumberIconWrap };
