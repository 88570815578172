import Styles from "./styles";
import layout from "components/layout";
import common from "components/common";
import { useLanguage } from "hooks";
import { useMemo, useState, useEffect } from "react";

// img
import { useQuery } from "react-query";
import { api } from "service";
import moment from "moment";
import utils from "utils";
import { IMAGE_REF } from "constants/common";
import StaticMiniStomachContainer from "./StaticMiniStomachContainer";

export default function ChangeHistoryModal({ modalClose, modalOption }) {
  const {
    id,
    studyId,
    landmark: landmarkProps,
    onClickChangeHistory,
  } = modalOption;
  const lang = useLanguage("Report");

  const [landmark, setLandmark] = useState(null);
  const [recentDate, setRecentDate] = useState(null);
  const [setsortedHistoryComplete, setSetsortedHistoryComplete] =
    useState(false);

  if (!landmark) {
    setLandmark(landmarkProps);
  }

  const [selectedHistory, setSelectedHistory] = useState({
    recentlySelected: null,
    pastSelected: null,
  });

  const { data: historyData, isLoaing: isLoadingHistoryData } = useQuery(
    ["history_list", landmark],
    () => api.history.getList({ patientId: studyId, landmark }),
    { refetchOnWindowFocus: false, enabled: !!landmark }
  );

  const sortHistoryData = useMemo(() => {
    if (!historyData) return [];
    return Object.entries(historyData)
      .sort((a, b) => {
        const _a = Number(moment(a[0]).format("YYYYMMDD"));
        const _b = Number(moment(b[0]).format("YYYYMMDD"));
        return _b - _a;
      })
      ?.map(([key, value]) => ({ date: key, list: value }));
  }, [historyData]);

  const recentlyImageData = sortHistoryData[0];

  const sortPastHistoryData = sortHistoryData.slice(1);

  const pastImageData = useMemo(() => {
    if (!sortHistoryData?.length) return [];
    const getLongestPastImageDataLength = sortPastHistoryData.reduce(
      (maxLength, innerArray) => {
        const innerArrayLength = innerArray?.list?.length || 0;
        return Math.max(maxLength, innerArrayLength);
      },
      0
    );

    return utils.etc.transposeArray(
      Array(sortPastHistoryData?.length)
        .fill([])
        ?.map((_, oneIndex) =>
          Array(getLongestPastImageDataLength)
            .fill({})
            .map((_, twoIndex) => {
              const data = sortPastHistoryData[oneIndex].list[twoIndex];
              if (!data) return null;
              return {
                historyDate: sortPastHistoryData[oneIndex].date,
                ...data,
              };
            })
        )
    );
  }, [historyData]);

  // data
  const imageData = useMemo(
    () => ({
      normalImage: IMAGE_REF + landmark + ".png",
      recentlyImageData,
      pastImageData,
      pastDateList: sortPastHistoryData?.map((item) => item.date) || [],
    }),
    [landmark, historyData]
  );

  if (isLoadingHistoryData) return <></>;

  return (
    <Styles.Container>
      <div
        className="overlay"
        onClick={() => {
          modalClose();
        }}
      ></div>

      <div className="inner">
        {/* <div className="miniStomachContainer">
          <div className="stomachContainer">
            <layout.MiniStomach
              selectedSection={landmark}
              setSelectedSection={setLandmark}
            />
          </div>

          <div className="numberIconContainer">
            <common.NumberIcon number={5} />
            <p className="landmarkName">{stomachListData[4]}</p>
          </div>
        </div> */}
        <StaticMiniStomachContainer
          landmark={landmark}
          setLandmark={setLandmark}
        />

        <div className="historyContainer">
          {imageData.pastImageData?.length ? (
            <layout.PastHistory
              data={imageData}
              landmark={landmark}
              onChangeHistorySelected={(data) => {
                setSelectedHistory(data);
              }}
            />
          ) : (
            <layout.NoPastHistory
              data={imageData}
              landmark={landmark}
              onChangeHistorySelected={(data) => {
                setSelectedHistory(data);
              }}
            />
          )}
        </div>

        <div className="buttonContainer">
          <button
            type="button"
            className="submitButton"
            onClick={() => {
              console.log("selectedHistory ====> ", selectedHistory);
              let histories = selectedHistory;
              histories.recentDateInfo = imageData?.recentlyImageData?.date;
              // onClickChangeHistory(selectedHistory);
              onClickChangeHistory(histories);
              modalClose();
            }}
          >
            {lang.getLang("사진 변경")}
          </button>
          <button type="button" className="cancelButton" onClick={modalClose}>
            {lang.getLang("취소")}
          </button>
        </div>
      </div>
    </Styles.Container>
  );
}
