import styled from "styled-components";

// img
import ChkOn from "resources/image/icon/icon_checked.svg";
import ChkOff from "resources/image/icon/icon_unchecked.svg";

const Container = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  width: 100%;
  min-height: 100vh;
  padding: 0 calc(100vw * (180 / 1920));

  & .languageButtonContainer {
    position: absolute;
    top: calc(100vw * (26 / 1920));
    left: calc(100vw * (32 / 1920));
    z-index: 1;

    & .languageButton {
      width: calc(100vw * (36 / 1920));
      height: calc(100vw * (33 / 1920));
    }

    & .languageModal {
      position: absolute;
      top: calc(100vw * (19 / 1920));
      left: calc(100vw * (27 / 1920));
      background: #292929;
      border-radius: calc(100vw * (10 / 1920));

      & li {
        padding: calc(100vw * (14 / 1920)) calc(100vw * (28 / 1920));
        font-size: calc(100vw * (25 / 1920));
        font-weight: 700;
        text-align: center;
        cursor: pointer;

        &:first-child {
          border-bottom: 1px solid #fff;
        }
      }
    }
  }

  & video {
    display: block;
    width: 100%;
    height: 100vh;
    position: absolute;
    left: 0;
    top: 0;
    object-fit: fill;
  }

  & .logo {
    position: absolute;
    top: calc(100vw * (60 / 1920));
    right: calc(100vw * (60 / 1920));
    width: calc(100vw * (204 / 1920));
    cursor: pointer;
  }
  & .login_inner {
    position: relative;
    display: flex;
    justify-content: space-between;
    width: 100%;
  }
  & .login_inner * {
    color: #fff;
  }

  & .login_title h2 {
    font-size: calc(100vw * (80 / 1920));
    font-weight: bold;
  }
  & .login_title p {
    font-size: calc(100vw * (40 / 1920));
    margin-top: calc(100vw * (12 / 1920));
  }

  & .login_box {
    width: fit-content;
  }
  & .login_input {
    display: flex;
    gap: calc(100vw * (18 / 1920));
    width: calc(100vw * (660 / 1920));
  }
  & .login_input_inner {
    width: calc(100vw * (494 / 1920));
  }
  & .input_row {
    display: flex;
    flex-direction: column;
    gap: calc(100vw * (20 / 1920));
  }
  & .input_row input {
    width: 100%;
    height: calc(100vw * (76 / 1920));
    font-size: calc(100vw * (32 / 1920));
    font-weight: 300;
  }

  & .login_input button {
    width: calc(100vw * (150 / 1920));
    height: calc(100vw * (172 / 1920));
    background: #fff;
    color: #000;
    font-size: calc(100vw * (32 / 1920));
    border-radius: calc(100vw * (12 / 1920));
    font-weight: bold;
  }
  & .checkbox {
    position: relative;
    margin-top: calc(100vw * (14 / 1920));
  }
  & .checkbox input {
    position: absolute;
    left: 0;
    top: 0;
    width: 1px;
    height: 1px;
  }
  & .checkbox input:checked + label::before {
    background-image: url(${ChkOn});
  }
  & .checkbox label {
    display: flex;
    align-items: center;
    font-size: calc(100vw * (20 / 1920));
    cursor: pointer;
  }
  & .checkbox label::before {
    content: "";
    display: block;
    width: calc(100vw * (26 / 1920));
    height: calc(100vw * (26 / 1920));
    background: url(${ChkOff}) no-repeat center/100%;
    margin-right: calc(100vw * (10 / 1920));
  }
`;

export { Container };

export default { Container };
