import connector from "service/connector";

function getFilterStr(parameter){
  let filter = parameter;
  return new URLSearchParams(filter).toString();
}
// 관리자 유저조회
const getAdminUser = async (parameter) => {
  console.log(getFilterStr(parameter))
  return await connector.authConnector(
    `admins?${getFilterStr(parameter)}`,
    "GET"
  );
}
// 유저생성
const postSignupUser = async (data) => {
  return await connector.authConnector(`admins/signup`,"POST",data);
}
// 유저삭제
const deleteUser = async (id) => {
  return await connector.authConnector(`admins/${id}`,"DELETE");
}
// 아이디 중복검사
const getChecksId = async (id) => {
  return await connector.authConnector(`admins/checks/${id}`,"GET");
}
// 비밀번호 변경
const PutChangePassword = async (id,pw) => {
  return await connector.authConnector(`admins/${id}`,"PUT",pw);
}


export {
  getAdminUser, postSignupUser, deleteUser, getChecksId, PutChangePassword
};


export default {
  getAdminUser, postSignupUser, deleteUser, getChecksId, PutChangePassword
}