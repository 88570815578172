import common from "components/common";
import Styles from "./styles";
import { useLanguage } from "hooks";
import ScoreContainer from "../ScoreContainer";
import NoHoverStomach from "../NoHoverStomach";
import { lesionColorToType } from "utils/common";

export default function ScoreSection({
  notSelectedSection,
  landmark,
  onChangeSelectedSection,
  noClickEvent,
  noAni,
  score,
  scoreType,
  numberType,
  screen
}) {
  const lang = useLanguage("Report");

  const stomachListData = [
    lang.getLang("식도"),
    lang.getLang("위식도 접합부"),
    lang.getLang("십이지장 2부"),
    lang.getLang("십이지장 구부"),
    lang.getLang("위 전정부"),
    lang.getLang("위 각부"),
    lang.getLang("위체부(하-중부)"),
    lang.getLang("위체부(중-상부)"),
    lang.getLang("위저부(반전)"),
    lang.getLang("위 분문부(반전)"),
    lang.getLang("위체부(반전)"),
  ];

  console.log("numberType", numberType);
  console.log("scoreType", scoreType);
  console.log("score", score);
  return (
    <Styles.Container>
      <div className="numberIconContainer">
        {landmark !== 12 ? (
          <>
            <common.NumberIcon
              type={lesionColorToType(numberType)}
              number={landmark}
              lite_type={'cancer'}
              screen={screen}
            />
            <p className="landmarkText">{stomachListData[landmark - 1]}</p>
          </>
        ): (
          <>
            <common.NumberIcon
              type={lesionColorToType(6)}
              number={12}
              lite_type={'cancer'}
              screen={screen}
            />
            <p className="landmarkText"></p>
          </>
        )}
      </div>

      <div className="stomachContainer">
        <NoHoverStomach
          noClickEvent={noClickEvent}
          selectedSection={landmark}
          onChangeSelectedSection={onChangeSelectedSection}
          notSelectedSection={notSelectedSection}
          screen={screen}
        />
      </div>

      {/* <ScoreContainer big type={scoreType} noAni={noAni} score={score} lite_type={'im_section'} screen={screen} /> */}
      <ScoreContainer big type={numberType} noAni={noAni} score={score} lite_type={'im_section'} screen={screen} />
    </Styles.Container>
  );
}
