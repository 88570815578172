import Styles from "./styles";

export default function BasicModal({ modalOption,modalClose }) {
  return (
    <Styles.Container>
      <div className="overlay" onClick={() => {modalClose();}}></div>

      <div className="inner">
        <p>{modalOption.title}</p>
        <button type="button" onClick={() => {modalClose();}}>{modalOption.button}</button>
      </div>
    </Styles.Container>
  );
}

