export function calculateViewBox(coordinates) {
  const { min, max } = findMinMax(coordinates);

  const viewBoxWidth = max.x - min.x;
  const viewBoxHeight = max.y - min.y;

  const viewBox = `${min.x} ${min.y} ${viewBoxWidth} ${viewBoxHeight}`;

  return viewBox;
}

function findMinMax(coordinates) {
  let minX = Number.POSITIVE_INFINITY;
  let minY = Number.POSITIVE_INFINITY;
  let maxX = Number.NEGATIVE_INFINITY;
  let maxY = Number.NEGATIVE_INFINITY;

  coordinates.forEach(([x, y]) => {
    minX = Math.min(minX, x);
    minY = Math.min(minY, y);
    maxX = Math.max(maxX, x);
    maxY = Math.max(maxY, y);
  });

  return {
    min: { x: minX, y: minY },
    max: { x: maxX, y: maxY },
  };
}
