import styled from "styled-components";

const Container = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  height: 100dvh;
  z-index: 15;

  & .inner {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: calc(100vw * (1820 / 1920));
    height: calc(100vw * (900 / 1920));
    padding: calc(100vw * (17 / 1920)) 0 calc(100vw * (14 / 1920));
    background: #292929;

    & .miniStomachContainer {
      position: relative;
      display: flex;
      align-items: center;
      align-self: flex-start;
      margin-left: calc(100vw * (21 / 1920));

      & .stomachContainer {
        width: calc(100vw * (89 / 1920));
        height: calc(100vw * (72 / 1920));
        transform: scale(1);
        transition: 0.7s;
        transform-origin: top right;
        z-index: 2;

        & img {
          width: calc(100vw * (89 / 1920));
          height: calc(100vw * (72 / 1920));
        }
      }

      & .numberIconContainer {
        display: flex;
        align-items: center;
        margin-left: calc(100vw * (20 / 1920));
        font-size: calc(100vw * (30 / 1920));
        font-weight: 700;

        & .landmarkName {
          margin-left: calc(100vw * (13 / 1920));
        }
      }
    }

    & .historyContainer .sliderContainer .swiperContainer.sub {
      width: calc(100vw * (635 / 1920));

      & .swiper-button-prev {
        left: calc(100vw * (-25 / 1920));
      }

      & .swiper-button-next {
        left: auto;
        right: calc(100vw * (-25 / 1920));
      }
    }

    & .buttonContainer {
      display: flex;
      justify-content: flex-end;
      gap: calc(100vw * (11 / 1920));
      margin: auto calc(100vw * (12 / 1920)) 0 auto;

      & button {
        width: calc(100vw * (200 / 1920));
        height: calc(100vw * (48 / 1920));
        font-size: calc(100vw * (26 / 1920));
        font-weight: 700;
        border-radius: calc(100vw * (11 / 1920));
      }

      & .submitButton {
        background: #0075ff;
      }

      & .cancelButton {
        background: #848b99;
      }
    }
  }

  & .overlay {
    width: 100%;
    height: 100%;
    background: rgba(34, 34, 34, 0.6);
  }
`;

export { Container };

export default { Container };
