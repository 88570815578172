import styled from "styled-components";

const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: calc(100vw * (38 / 1920));
  padding: calc(100vw * (12 / 1920)) 0;
  & .prev_btn,
  .next_btn {
    font-size: calc(100vw * (24 / 1920));
  }
  & .paginationList {
    display: flex;
    gap: calc(100vw * (14 / 1920));
  }
  & .paginationList button {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: calc(100vw * (24 / 1920));
  }
  & .paginationList button.on {
    border-bottom: 1px solid #fff;
  }
  & .paginationList button.on:after {
    content: "";
    position: absolute;
    top: 50%;
    left: 50%;
    display: block;
    width: calc(100vw * (18 / 1920));
    height: calc(100vw * (18 / 1920));
    background: #3e85ff;
    transform: translate(-50%, -50%) rotate(-45deg);
    z-index: -1;
  }
`;

export { Container };

export default { Container };
