import { IMAGE_REF, IMAGE_SRC } from "constants/common";
import moment from "moment";
import { v4 } from "uuid";
import { getCookie } from "service/connector";

export const lesionTypeToColor = (type) => {
  switch (type) {
    case 1:
    case "1":
      return "red";
    case 2:
    case "2":
      return "orange";
    case 3:
    case "3":
      return "purple";
    case 4:
    case 6:
      return "black";
    case "4":
    case "im":
      return "yellow";
    default:
      return "green";
  }
};

export const lesionColorToType = (type) => {
  switch (type) {
    case "red":
      return 1;
    case "orange":
      return 2;
    case "purple":
      return 3;
    case "yellow":
      return 4;
    case "green":
      return 5;
    case "black":
      return 6;
    default:
      return 0;
  }
};

export const lesionTypeOrderClass = (lesionList = [], IMValue) => {
  // JASON 
  // const typePriority = [1, 2, 3];
  const typePriority = [1, 2, 3, 4];

  for (const type of typePriority) {
    const item = lesionList?.find((lesion) => lesion.type === type);
    if (item) {
      switch (item.type) {
        case 1:
        case "c":
          return "cancer";
        case 2:
        case "a":
          return "adenoma";
        case 3:
        case "p":
          return "polyp";
        case 4:
        case "im":
          if (item.score > 100 - IMValue) {
            return "IM";
          } else {
            return "";
          }
        default:
          return "";
      }
    }
  }

  return ""; // 해당하는 type이 없는 경우의 기본값 설정
};

export const lesionTypeListOrderNumber = (typeList = []) => {
  // JASON 
  // const typePriority = [1, 2, 3];
  const typePriority = [1, 2, 3, 4];

  for (const type of typePriority) {
    const item = typeList?.find((lesionType) => lesionType === type);
    if (item) {
      switch (item) {
        case 1:
        case "c":
          return 1;
        case 2:
        case "a":
          return 2;
        case 3:
        case "N":
          return 3;
        case 4:
        case "im":
          return 4;
        default:
          return null;
      }
    }
  }

  return null; // 해당하는 type이 없는 경우의 기본값 설정
};

export const lesionTypeForJsonFirstLetter = (type) => {
  switch (type) {
    case 1:
    case "c":
      return "c";
    case 2:
    case "a":
      return "a";
    case 3:
    case "N":
      return "N";
    case 4:
    case "im":
    default:
      return "IM";
  }
};

export const formatHeatmapWithPath = (path) => {
  if (!path) return "";

  // const hitmap = path.split(".")[0] + "_h." + path.split(".")[1];
  // const hitmap =
  //   (path.split(".")[0] + "_h.").replace(/jpg/g, "heatmap") +
  //   path.split(".")[1];
  const hitmap =
    (path.split(".jpg")[0] + "_h").replace(/jpg/g, "heatmap") + ".jpg";

  return IMAGE_SRC + hitmap;
};

export const formatNormalImageWithLandmark = (landmark) => {
  return IMAGE_REF + (landmark == 12 ? 1 : landmark) + ".png";
};

export const formatImageWithPath = (path) => {
  return IMAGE_SRC + path;
};

/**
 * @description Record<"YYYY-MM-DD", {landmark:number, path:string}> 타입을 배열로 변경하는 함수
 */
export const sortHistoryObjectToArray = (historyData) => {
  if (!historyData) return [{ date: "", content: {} }];
  return Object.entries(historyData)
    .sort((a, b) => {
      const _a = Number(moment(a[0]).format("YYYYMMDD"));
      const _b = Number(moment(b[0]).format("YYYYMMDD"));
      return _b - _a;
    })
    .map(([key, value]) => ({ date: key, content: value }));
};

export const responseCommentDataToList = (commentObject) => {
  if (!commentObject) return [];

  const commentList = [];

  Object.keys(commentObject).forEach((key, index) => {
    const idx = index + 1;
    const commentId = commentObject["commentId" + idx];
    const comment = commentObject["comment" + idx];
    const type = commentId !== null ? "CATEGORY" : "CUSTOM";

    let id = v4();

    if (type === "CATEGORY") {
      id = commentId?.subId;
    }

    if (!comment) return;

    commentList.push({
      id,
      comment,
      type,
    });
  });

  return commentList.filter((item) => item.comment !== null);
};

export const extractXYValues = (transformValue) => {
  const regex = /translate\((-?\d*\.?\d+)px,\s*(-?\d*\.?\d+)px\)/;
  const match = transformValue?.match(regex);

  if (match && match.length >= 3) {
    const x = parseFloat(match[1]);
    const y = parseFloat(match[2]);

    return { x, y };
  }

  return { x: 0, y: 0 }; // 추출 실패 시 기본값 반환
};

export function isNumeric(value) {
  return /^-?\d+$/.test(value);
}
