import styled from "styled-components";

// img
import Logo from "resources/image/icon/logo.svg";
import IconChecked from "resources/image/icon/icon_checked_line.svg";

const Container = styled.div`
  position: relative;
  display: flex;
  width: 100%;
  min-height: 100vh;
  padding: calc(100vw * (106 / 1920)) calc(100vw * (32 / 1920))
    calc(100vw * (0 / 1920));
  background: url(${Logo}) no-repeat left calc(100vw * (30 / 1920)) bottom
    calc(100vw * (30 / 1920)) / contain;
  background-size: calc(100vw * (204 / 1920)) calc(100vw * (36 / 1920));
  background-color: #000;
  overflow: auto;

  & img {
    width: 100%;
    height: 100%;
    object-fit: fill;
  }

  & .leftContainer {
    flex-shrink: 0;
    margin-right: calc(100vw * (65 / 1920));

    & .userDataContainer > div {
      flex-direction: column;      
      align-items: flex-start;
    }
  }

  & .rightContainer {
    flex-grow: 1;
    padding-top: calc(100vw * (65 / 1920));

    & .checkListWrap {
      display: flex;
      gap: calc(100vw * (39 / 1920));
      margin-bottom: calc(100vw * (32 / 1920));

      & .checkList {
        & input {
          display: none;

          &:checked + label .checkboxContainer::after {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            width: calc(100vw * (28 / 1920));
            height: calc(100vw * (21 / 1920));
            background: url(${IconChecked}) no-repeat center / contain;
            content: "";
          }

          &:disabled + label {
            opacity: 0.3;
          }
        }

        & label {
          display: flex;
          align-items: center;
          cursor: pointer;
          font-size: calc(100vw * (36 / 1920));
          font-weight: bold;

          & .checkboxContainer {
            position: relative;
            display: inline-block;
            width: calc(100vw * (40 / 1920));
            height: calc(100vw * (40 / 1920));
            margin-right: calc(100vw * (18 / 1920));
            background-color: #303030;
            border-radius: calc(100vw * (6 / 1920));
          }

          & .checkboxName span {
            font-size: calc(100vw * (24 / 1920));
          }
        }
      }
    }
  }
  // (결함) 버튼 위치 조정
  & .imAnalysisButton {
    /* position: absolute; */
    /* bottom: 73px; */
    /* bottom: 10px; */
    /* right: 50px; */
    margin-right: 50px;
    /* margin-top: -50px;
    z-index: 999; */
    /* margin-bottom: -150px; */
    float: right;
    width: 213px;
    height: 44px;
    font-size: 24px;
    font-weight: bold;
    background-color: #008ad1;
    border-radius: 10px;
  }
`;

export { Container };

export default { Container };
