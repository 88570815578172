//숫자 3자리수 콤마 코드
function numberWithCommas(x) {
  try {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  } catch (e) {
    return "0";
  }
}

//한글 검증
const checkKoreanLang = (ch) => {
  let c = ch.charCodeAt(0);
  if (0x1100 <= c && c <= 0x11ff) return true;
  if (0x3130 <= c && c <= 0x318f) return true;
  if (0xac00 <= c && c <= 0xd7a3) return true;
  return false;
};

// 배열 방향 전환
function transposeArray(arr) {
  if (arr.length <= 0) return [];
  return arr[0].map((col, i) => arr.map((row) => row[i]));
}

// 신규 배열 방향 전환
function transposeNewArray(arr) {
  if (arr.length <= 0) return [];

  let maxIndex = 0;
  let maxLength = 0;
  arr.map((v,i)=> { 
    if(v.length > maxLength){
      maxIndex = i;
      maxLength = v.length;
    }
  })

  return arr[maxIndex].map((col, i) => arr.map((row) => row[i]));
}


// 배열 방향 전환
function numberToDate(num) {
  num = num.replace(/[^0-9]/g,'');
  let year = num.slice(0,4);
  let month = num.slice(4,6);
  let day = num.slice(6,8);
  let resultText = year + (month ? "-" + month : "") + (day ? "-" + day : "");
  return {resultText, num};
}

const booleanToNum = (boolean) => {
  return boolean ? 1 : 0;
};

export { numberWithCommas, checkKoreanLang, transposeArray,transposeNewArray, booleanToNum, numberToDate };

export default {
  numberWithCommas,
  checkKoreanLang,
  transposeArray,
  transposeNewArray,
  booleanToNum,
  numberToDate,
};
