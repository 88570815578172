import { atom } from "jotai";

const defaultModalAtom = atom({ show: false, zIndex: 20 });
const basicModalAtom = atom({ show: false, zIndex: 20 });
const toastModalAtom = atom({ show: false, zIndex: 20 });
const changePassowrdModalAtom = atom({ show: false, zIndex: 20 });
const userDeleteModalAtom = atom({ show: false, zIndex: 20 });
const searchAdminModalAtom = atom({ show: false, zIndex: 20 });
const searchPatientModalAtom = atom({ show: false, zIndex: 20 });
const searchCheckupModalAtom = atom({ show: false, zIndex: 20 });
const moreResultModalAtom = atom({ show: false, zIndex: 20 });
const contextMenuModalAtom = atom({ show: false, zIndex: 20 });
const doubleClickModalAtom = atom({ show: false, zIndex: 20 });
const changeImageModalAtom = atom({ show: false, zIndex: 20 });
const changeHistoryModalAtom = atom({ show: false, zIndex: 20 });
const changeIMModalAtom = atom({ show: false, zIndex: 20 });
const changeAbnormalityModalAtom = atom({ show: false, zIndex: 20 });
const loadingModalAtom = atom({ show: false, zIndex: 20 });
const softwareInformationModalAtom = atom({ show: false, zIndex: 20 });
const uploadFileModalAtom = atom({ show: false, zIndex: 20 });
const uploadFileDeleteModalAtom = atom({ show: false, zIndex: 20 });

export {
  defaultModalAtom,
  basicModalAtom,
  toastModalAtom,
  changePassowrdModalAtom,
  userDeleteModalAtom,
  searchAdminModalAtom,
  searchPatientModalAtom,
  searchCheckupModalAtom,
  moreResultModalAtom,
  contextMenuModalAtom,
  doubleClickModalAtom,
  changeImageModalAtom,
  changeHistoryModalAtom,
  changeIMModalAtom,
  changeAbnormalityModalAtom,
  loadingModalAtom,
  softwareInformationModalAtom,
  uploadFileModalAtom,
  uploadFileDeleteModalAtom,
};

export default {
  defaultModalAtom,
  basicModalAtom,
  toastModalAtom,
  changePassowrdModalAtom,
  userDeleteModalAtom,
  searchAdminModalAtom,
  searchPatientModalAtom,
  searchCheckupModalAtom,
  moreResultModalAtom,
  contextMenuModalAtom,
  doubleClickModalAtom,
  changeImageModalAtom,
  changeHistoryModalAtom,
  changeIMModalAtom,
  changeAbnormalityModalAtom,
  loadingModalAtom,
  softwareInformationModalAtom,
  uploadFileModalAtom,
  uploadFileDeleteModalAtom,
};
