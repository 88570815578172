import Styles from "./styles";
import PicturesTitle from "../PicturesTitle";

// img
import ImgStomach from "resources/image/report/stomach.png";

import { useQuery } from "react-query";
import { api } from "service";
import { useParams } from "react-router-dom";
import { IMAGE_SRC } from "constants/common";
import { useEffect } from "react";
import { useReport } from "hooks/useReport";
import { useUpdateEffect } from "hooks/useUpdateEffect";
import { useLanguage } from "hooks";

export default function LandmarkSection(props) {
  const { studyId } = useParams();
  const reportInfo = useReport();
  const lang = useLanguage("Report");

  const { data: reportsLandmarkData = {} } = useQuery(
    ["reports-images", studyId, "landmark"],
    () =>
      api.reports.getReportsImages({
        medicalstudyId: studyId,
        check: "landmark",
      }),
    {
      refetchOnWindowFocus: false,
    }
  );

  const reportsLandmarkList = Object.values(reportsLandmarkData);

  useEffect(() => {
    return () => {
      reportInfo.setReportWriteData((prev) => {
        const { landmark, ...rest } = prev;
        const isLandmark = prev.isLandmark;
        return {
          ...rest,
          ...(isLandmark && { landmark }),
        };
      });
    };
  }, []);

  return (
    <>
      <PicturesTitle
        colorTitle={"위 부위별 대표 사진"}
        paddingSize={props.paddingSize}
        viewPort={props.viewPort}
      />

      <Styles.Container
        paddingSize={props.paddingSize}
        viewPort={props.viewPort}
      >
        <div className="wrap">
          {/* {reportsLandmarkList?.map((item, index) => {
            const pathData = item[index + 1].path
            return (
                <div
                  className={"stomachPart " + "stomachPart" + (index + 1)}
                  key={"stomachPartImageWrap" + index}
                >
                  <div className="stomachPartImageWrap">
                    <img
                      src={IMAGE_SRC + item[index + 1]?.path}
                      alt={"stomachPart" + (index + 1)}
                    />
                  </div>
                </div>
            );
          })} */}
          {reportsLandmarkList?.map((item, index) => {
            const pathData = item[index + 1].path;
            return (
              pathData && (
                <div
                  className={"stomachPart " + "stomachPart" + (index + 1)}
                  key={"stomachPartImageWrap" + index}
                >
                  <div className="stomachPartImageWrap">
                    <img
                      src={IMAGE_SRC + pathData}
                      alt={"stomachPart" + (index + 1)}
                    />
                  </div>
                </div>
              )
            );
          })}
          <div className="stomachImageWrap">
            <img src={ImgStomach} alt="stomach" />
          </div>
        </div>
      </Styles.Container>
    </>
  );
}
