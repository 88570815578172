
import Styles from "./styles";
import layout from "components/layout";
import { useLanguage } from "hooks";
import { useMemo, useState, useEffect } from "react";
import { useQuery } from "react-query";
import { api } from "service";
import { lesionTypeToColor } from "utils/common";
import ReportChangeAbnormalityCheckedSection from "../ReportChangeAbnormalityCheckedSection";

export default function ChangAbnormalImage(props) {

  const { studyId, score, type, onChangeAbnormality, onChangeCancel } = props;
  const lang = useLanguage("Report");

  const [selectedAbnormalityData, setSelectedAbnormalityData] = useState(null);
  const [lesionIndex, setLesionIndex] = useState(0);

  const {
    data: lesionsPathData = [],
    isSuccess: isSuccessLesionsPath,
    isLoading: isLoadingLesions,
  } = useQuery(
    ["lesions-abnormality-path", studyId],
    () => api.image.getLesionsAbnormalityPath({ studyId: studyId }),
    {
      refetchOnWindowFocus: false,
    }
  );

  const [landmark, setLandmark] = useState(null);
  const [currentImage, setCurrentImage] = useState(null);
  const [currentLesion, setCurrentLesion] = useState(null);
  const [lesionColorType, setLesionColorType] = useState(null);
  const [notSelectedSection, setNotSelectedSection] = useState(null);

  useEffect(() => {
    if (lesionsPathData.length > 0) {

      const landmark = lesionsPathData[lesionIndex]?.landmark;
      const currentLesion = lesionsPathData[lesionIndex]?.lesion?.[0];
      const lesionColorType = lesionTypeToColor(currentLesion?.type);

      const notSelectedSection = [
        {
          landmark: Number(landmark),
          type: lesionColorType,
        },
      ];

      setLandmark(landmark);
      setCurrentImage(landmark);
      setCurrentLesion(currentLesion);
      setLesionColorType(lesionColorType);
      setNotSelectedSection(notSelectedSection);
    }
  }, [lesionIndex, lesionsPathData]);

  if (isLoadingLesions || !lesionsPathData) return <div></div>;

  return (
    <Styles.Container
      paddingSize={props.paddingSize}
      viewPort={props.viewPort}
    >
      <div className="wrap">
        <div className="leftContainer">
          <div className="stomachWrap">
            <layout.NoHoverStomachReport
              selectedSection={landmark}
              notSelectedSection={notSelectedSection}
              noClickEvent
              opacityBg
              landmark={landmark}
              score={currentLesion?.score}
              scoreType={lesionColorType}
              numberType={lesionColorType}
              lesionType={currentLesion?.lesionType}
              screen={"abnormality"}
              />
          </div>
          <layout.LesionAnimationContainer
            noAni={true}
            score={currentLesion?.score}
            type={lesionColorType}
            viewPort={props.viewPort}
            lite_type={"im_section"}
            screen={"heatmap"}
          />
        </div>
        <div className="rightContainer">
          <ReportChangeAbnormalityCheckedSection
            paddingSize={props.paddingSize}
            viewPort={props.viewPort}
            landmark={landmark}
            studyId={studyId}
            lesionIndex={lesionIndex}
            onLesionIndex={setLesionIndex}
            onChangeAbnormality={({ selected, polygon }) => {
              setSelectedAbnormalityData({ selected, polygon });
            }}
          />
        </div>
      </div>

      <div className="buttonContainer">
          <button
            type="button"
            className="submitButton"
            onClick={() => {
              onChangeAbnormality({ data: selectedAbnormalityData });
            }}
          >
            {lang.getLang("사진 변경")}
          </button>
          <button type="button" className="cancelButton" onClick={onChangeCancel}>
            {lang.getLang("취소")}
          </button>
        </div>
    </Styles.Container>
  );
}