import { useQuery } from "react-query";
import Styles from "./styles";
import { useLanguage } from "hooks";

// img
import IconClose from "resources/image/icon/icon_close.svg";
import ImgBarCode from "resources/image/img_bar_code.png";
import { api } from "service";
import { IMAGE_SRC, PATH } from "constants/common";

export default function SoftwareInformationModal({ modalOption, modalClose }) {
  const lang = useLanguage("Login");

  const { data: swInfo = {} } = useQuery(
    "settings/sw-info",
    () => api.setting.getSwInfomation(),
    {
      refetchOnWindowFocus: false,
    }
  );

  const listData = [
    {
      title: lang.getLang("제조자"),
      text: swInfo.manufacturer,
    },
    {
      title: lang.getLang("제조자 주소"),
      text: swInfo.address,
    },
    {
      title: lang.getLang("품목명 (등급)"),
      text: swInfo.itemName,
    },
    {
      title: lang.getLang("제품명 (모델명)"),
      text: swInfo.product,
    },
    {
      title: lang.getLang("사용목적"),
      text: swInfo.intendedUse,
    },
    {
      title: lang.getLang("포장단위"),
      text: swInfo.packagingUnit,
    },
    {
      title: lang.getLang("버전"),
      text: swInfo.ver,
    },
    {
      title: lang.getLang("업데이트 일자"),
      text: swInfo.updatedDate,
    },
    {
      title: lang.getLang("제조허가번호"),
      text: swInfo.manufacturingCertNum,
    },
    {
      title: lang.getLang("제조업허가번호"),
      text: swInfo.manufacturerCertNum,
    },
    {
      title: lang.getLang("제조번호"),
      text: swInfo.numberOfManufacture,
    },
    {
      title: lang.getLang("제조일자"),
      text: swInfo.dateOfManufacture,
    },
  ];
  return (
    <Styles.Container>
      <div
        className="overlay"
        onClick={() => {
          modalClose();
        }}
      ></div>

      <div className="inner">
        <div className="titleContainer">
          <p>software information</p>
          <button
            type="button"
            className="closeButton"
            onClick={() => {
              modalClose();
            }}
          >
            <img src={IconClose} alt="IconClose" />
          </button>
        </div>

        <div className="wrap">
          <ul className="list_Wrap">
            {listData?.map((item, index) => {
              return (
                <li className="list" key={"list" + index}>
                  <p className="title">{item.title}</p>
                  <p className="text">{item.text}</p>
                </li>
              );
            })}
          </ul>

          <p className="warningText">
            <span>System integrity checking completed</span>
            <br />본 제품은 ‘의료기기’임
          </p>

          <div className="imgWrap">
            <img src={PATH + "sw-information/images/" + swInfo.uid} alt="ImgBarCode" />
          </div>
        </div>
      </div>
    </Styles.Container>
  );
}
