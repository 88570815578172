import Styles from "./styles";
import { useLanguage } from "hooks";
import { api } from "service";
import { useMutation, useQueryClient } from "react-query";

// img
import IconDoctor from "resources/image/icon/icon_doctor.svg";
import IconNurse from "resources/image/icon/icon_nurse.svg";

export default function UploadFileDeleteModal({ modalOption, modalClose }) {
  const lang = useLanguage("Admin");
  // const { auth } = modalOption;
  // const authType = () => {
  //   switch (auth) {
  //     // 의사
  //     case 1:
  //       return 0;
  //     // 간호사
  //     case 2:
  //       return 1;
  //   }
  // };
  // const userData = {
  //   // type 0 : 의사 , 1 : 간호사
  //   type: authType(auth),
  //   id: "PREVENOTICS3",
  //   name: "정프베",
  //   text: "이 사용자 계정을 삭제하면 맞춰진 병변 tHRESHOLD 및 관련 설정 데이터가 모두 제거됩니다.  경고문구 경고문구 경고문구 경고문구 경고문구 경고문구",
  // };

  const { mutate: DeleteMutate } = useMutation((studyId) => api.studies.deleteUploadedFile(studyId), {
    onSuccess: () => {
      modalOption.onModalClose();
      modalClose();
    },
    OnError: () => {
      modalOption.onModalClose();
      modalClose();
    }
  });

  return (
    <Styles.Container>
      <div
        className="overlay"
        onClick={() => {
          modalClose();
        }}
      ></div>

      <div className="inner">
        <p className="title">{lang.getLang("업로드 된 이미지를 삭제하시겠습니까?")}</p>

        <div className="buttonContainer">
          <button
            className="deleteButton"
            type="button"
            onClick={() => {
              DeleteMutate(modalOption.studyId);
            }}
          >
            {lang.getLang("확인")}
          </button>

          <button
            className="cancelButton"
            type="button"
            onClick={() => {
              modalOption.onModalCancel();
              modalClose();
            }}
          >
            {lang.getLang("취소")}
          </button>
        </div>
      </div>
    </Styles.Container>
  );
}
