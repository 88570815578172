import Styles from "./styles";
import { useEffect, useState } from "react";
import { layout } from "components";
import { useConfig } from "hooks/useConfig";
import { getCookie } from "service/connector";

export default function FilterContainer({
  patientsData,
  abnormality,
  IMValue,
  onChangeValues,
  studyId,
  id,
  navigate,
  type,
}) {
  const [scope, setScope] = useState("lite");

  const configInfo = useConfig();

  const filterListData = [
    {
      label: "Abnormality",
      name: "abnormalSensitivity",
      value: abnormality,
    },
    {
      label: "IM",
      name: "imSensitivity",
      value: IMValue,
    },
  ];

  useEffect(() => {
    setScope(getCookie("scope"));
  }, []);

  return (
    <Styles.Container>
      <div className="infoContainer">
        <layout.UserDataList
          select
          patientsData={patientsData}
          id={id}
          studyId={studyId}
          type={type}
          navigate={navigate}
        />
      </div>

      {!configInfo.setting.isSimpleMode && (
        <ul className="filterListWrap">
          {filterListData?.map((item, index) => {
            return (
              <li className="filterList" key={"filterList" + index}>
                {/* JASON 제거 */}
                {/* <label className={item.label}>
                  {(scope == "lite") & (item.label == "IM")
                    ? "Heatmap"
                    : item.label}
                </label>

                <div className="inputContainer">
                  <span className="centerLine"></span>
                  <input
                    name={item.name}
                    type="range"
                    value={item.value}
                    onChange={(e) => {
                      const wrapper = e.target.closest(".filterListWrap");

                      const inputRange = wrapper.querySelectorAll("input");
                      const values = {};
                      Array.from(inputRange).forEach((el) => {
                        values[el.name] = Number(el.value);
                      });

                      onChangeValues(values);
                    }}
                  />
                </div> */}
              </li>
            );
          })}
        </ul>
      )}
    </Styles.Container>
  );
}
