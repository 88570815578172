import connector from "service/connector";

const getStomach = async (data) => {
  return await connector.connectFetchController(
    "images/json/:patientId/:medicalstudyId/:imageName",
    "GET",
    data
  );
};

export { getStomach };

export default { getStomach };
