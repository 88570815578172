import Styles from "./styles";
import PicturesTitle from "../PicturesTitle";
import { useLanguage } from "hooks";

// img
import { useQuery } from "react-query";
import { useParams } from "react-router-dom";
import { api } from "service";
import { useMemo } from "react";
import { formatImageWithPath } from "utils/common";
import { useReport } from "hooks/useReport";

export default function SelectSection() {
  const lang = useLanguage("Report");

  const { id, studyId } = useParams();

  // const {
  //   data: reportsSelectImagesData = {},
  //   isLoading: isLoadingReportsSelectImages,
  // } = useQuery(
  //   ["reports-images", "select-images", studyId],
  //   () =>
  //     api.reports.getReportsImages({
  //       medicalstudyId: studyId,
  //       check: "select-images",
  //     }),
  //   {
  //     refetchOnWindowFocus: false,
  //   }
  // );


  const reportInfo = useReport();

  // const { data: reportsData = {}, isLoading: isLoadingReports } = useQuery(
  //   ["reports", studyId],
  //   () => api.reports.getReports({ medicalstudyId: studyId }),
  //   {
  //     refetchOnWindowFocus: false,
  //     staleTime: 5 * 1000 * 60,
  //   }
  // );

  const reportImageList = useMemo(() => {
    
    const selectImage = reportInfo.reportData?.selectImage;
    const selectImageList = [
      selectImage?.imagePath1,
      selectImage?.imagePath2,
      selectImage?.imagePath3,
    ];

    return selectImageList;

  }, [reportInfo.reportData]);
  if (!reportInfo.reportData) return;

  return (
    <Styles.Container>
      <div className="wrap">
        <div className="leftContainer"></div>

        <div className="rightContainer">
          {reportImageList?.map((path, index) => {
            return (
              <div className="imgContainer" key={"imgContainer" + index}>
                <div className="imgWrap imgBox">

                  { path ? <img src={formatImageWithPath(path)} alt="stomach" /> : <div className="noImg">No Image</div> }
                  
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </Styles.Container>
  );
}
