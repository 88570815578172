import styled, { css } from "styled-components";
import IconDelete from "../../../../resources/image/icon/icon_delete_uploaded.svg";

const State = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: calc(100vw*(138/1920)); height: calc(100vw*(46/1920));
  border-radius: calc(100vw*(46/1920));
  overflow: hidden;
  margin: 0 auto;

  & p {display: flex; align-items: center; justify-content: center; width: 100%; height: 100%;
    font-size: calc(100vw*(24/1920)); font-weight: 600; text-align: center;
    color: #ffffff; background-color: #4D525B;}
  & p.gr {background-color: #009B6D;}
  & p.org {background-color: #ff9900;}
  & p.bl {background-color: #008AD1;}
  & p.overlay {
    // 우상단에 X 아이콘 추가
    position: absolute; 
    right: calc(100vw*(45/1920));
    top: calc(100vw*(-5/1920));
    width: calc(100vw*(46/1920)); height: calc(100vw*(46/1920));
    background: url(${IconDelete}) no-repeat center; background-size: calc(100vw*(30/1920));
  }
`;

export { State };

export default {
  State,
};
