import connector from "service/connector";

function getFilterStr(parameter){
  let filter = parameter;
  return new URLSearchParams(filter).toString();
}

const getList = async (parameter) => {
  return await connector.authConnector(`patients?${getFilterStr(parameter)}`,"GET");
}

const getInfo = async (parameter) => {
  return await connector.authConnector(`patients/detail/${parameter}`,"GET");
}

const insertPatients = async (parameter) => {
  return await connector.authConnector(`patients`,"POST", parameter);
}

const updatePatients = async (id,parameter) => {
  return await connector.authConnector(`patients/detail/${id}`,"PUT", parameter);
}

const recordsPatients = async (id) => {
  return await connector.authConnector(`studies/${id}`,"POST");
}
export {
  getList,
  getInfo,
  insertPatients,
  updatePatients,
  recordsPatients,
};


export default {
  getInfo,
  getList,
  insertPatients,
  updatePatients,
  recordsPatients,
}