import styled from "styled-components";

const Container = styled.div`
  padding: calc(${(props)=>props.viewPort}vw * (45 / 1920)) calc(${(props)=>props.viewPort}vw * (22 / 1920))
  calc(${(props)=>props.viewPort}vw * (31 / 1920));
  background-color: white;
  // border: calc(${(props)=>props.viewPort}vw * (5 / 1920)) solid #d9d9d9;

  & .img {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: fill;
  }

  & .wrap {
    display: flex;

    & .leftContainer {
      flex-shrink: 0;
      margin-right: calc(${(props)=>props.viewPort}vw * (24 / 1920));

      & .stomachWrap {
        width: calc(${(props)=>props.viewPort}vw * (230 / 1920));
        height: calc(${(props)=>props.viewPort}vw * (240 / 1920));
        margin-top: calc(${(props)=>props.viewPort}vw * (50 / 1920));
        margin-bottom: calc(${(props)=>props.viewPort}vw * (50 / 1920));

        & img {
          width: calc(${(props)=>props.viewPort}vw * (230 / 1920));
          height: calc(${(props)=>props.viewPort}vw * (220 / 1920));
        }
      }
    }

    & .rightContainer {
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      grid-gap: calc(${(props)=>props.viewPort}vw * (26 / 1920));
      width: 100%;

      & .imgContainer {
        background-color: #A1A1A1;
        border-radius: calc(${(props)=>props.viewPort}vw * (9 / 1920));

        & .imgWrap {
          height: calc(${(props)=>props.viewPort}vw * (470 / 1920));
          margin: calc(${(props)=>props.viewPort}vw * (10 / 1920)) calc(${(props)=>props.viewPort}vw * (15 / 1920));
          margin-bottom: calc(${(props)=>props.viewPort}vw * (30 / 1920));
          filter: drop-shadow(
            calc(${(props)=>props.viewPort}vw * (5 / 1920)) calc(${(props)=>props.viewPort}vw * (5 / 1920))
              calc(${(props)=>props.viewPort}vw * (4 / 1920)) rgba(0, 0, 0, 0.5)
          );
          border-radius: calc(${(props)=>props.viewPort}vw * (9 / 1920));
          overflow: hidden;
        }

        & .text {
          margin-left: calc(${(props)=>props.viewPort}vw * (15 / 1920));
          font-size: calc(${(props)=>props.viewPort}vw * (36 / 1920));
          font-weight: 700;
          color: #000;
        }
      }
    }
  }

  & .changeButton {
    display: flex;
    justify-content: center;
    align-items: center;
    width: calc(100vw * (200 / 1920));
    height: calc(100vw * (48 / 1920));
    margin: calc(100vw * (14 / 1920)) 0 0 auto;
    font-size: calc(100vw * (26 / 1920));
    font-weight: 700;
    background-color: #0075ff;
    border-radius: calc(100vw * (11 / 1920));
`;

export { Container };

export default { Container };
