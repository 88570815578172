import countAtom from "./countAtom";
import modalAtom from "./modalAtom";
import loadAtom from "./loadAtom";
import authAtom from "./authAtom";
import languageAtom from "./languageAtom";
import { imageWithAutoPhotoCheckAtom } from "./imageAtom";
import configAtom from './configAtom';
import reportAtom from './reportAtom';
import tempReportAtom from './tempReportAtom';

export default {
  countAtom,
  modalAtom,
  loadAtom,
  authAtom,
  languageAtom,
  imageWithAutoPhotoCheckAtom,
  configAtom,
  reportAtom,
  tempReportAtom,
};

export {
  countAtom,
  modalAtom,
  loadAtom,
  authAtom,
  languageAtom,
  imageWithAutoPhotoCheckAtom,
  configAtom,
  reportAtom,
  tempReportAtom,
};
