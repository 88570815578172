import { useState, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import ui from "components/ui";
import { useModals, useLanguage } from "hooks";
import Styles from "./styles";
import { useAuth } from "hooks/useAuth";
// img
import BgVideo from "resources/image/login_bg.mp4";
import Logo from "resources/image/icon/logo.svg";
import IconSetting from "resources/image/icon/icon_setting_gray.svg";

export default function LoginPage() {
  const navigate = useNavigate();
  const modalOption = useModals();
  const lang = useLanguage("Login");
  const ref = useRef();

  const [isLanguageModalOpen, setIsLanguageModalOpen] = useState(false);
  const [id, setId] = useState("");
  const [password, setPassword] = useState("");
  const [keepSate, setKeepSate] = useState(false);

  const auth = useAuth();

  // 외부 영역 클릭 시 닫기
  const handleClickOutSide = (e) => {
    if (isLanguageModalOpen && !ref?.current?.contains(e.target)) {
      setIsLanguageModalOpen(false);
    }
  };

  useEffect(() => {
    if (isLanguageModalOpen) {
      document.addEventListener("click", handleClickOutSide);
      return () => {
        document.removeEventListener("click", handleClickOutSide);
      };
    }
  }, [isLanguageModalOpen]);

  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    if (params.has('error')) {
        const errorMessage = params.get('error');
        alert(errorMessage);
    }
  }, [])

  // 로그인
  const loginClick = async () => {
    let obj = {
      id: id,
      password: password,
    };

    let result = await auth.login(obj);

    if (keepSate) {
      localStorage.setItem("keepState", JSON.stringify(true));
    } else {
      if (!!localStorage.getItem("keepState")) {
        localStorage.removeItem("keepState");
      }
    }

    if (result != -1) {
      if (result == 0) navigate("/admin");
      else if (result == 1) navigate("/checkup/new");
      else if (result == 2) navigate("/patient");
    } else {
      modalOption.setBasicModal((e) => {
        e.show = true;
        e.title = (
          <>
            {lang.getLang("아이디 또는 비밀번호를 잘못 입력하였습니다.")} <br />
            {lang.getLang("입력하신 내용을 다시 확인해 주세요.")}
          </>
        );
        e.button = <>{lang.getLang("확인")}</>;
        return { ...e };
      });
    }
  };

  const onCheckEnter = (e) => {
    if (e.key === "Enter") {
      loginClick();
    }
  };

  return (
    <Styles.Container>
      <div className="languageButtonContainer" ref={ref}>
        <button
          type="button"
          className="languageButton"
          onClick={(e) => {
            setIsLanguageModalOpen(!isLanguageModalOpen);
          }}
        >
          <img src={IconSetting} alt="setting" />
        </button>

        {isLanguageModalOpen && (
          <ul className="languageModal">
            <li
              onClick={() => {
                setIsLanguageModalOpen(false);
                lang.setCountry("");
                localStorage.setItem("lang", "");
              }}
            >
              {lang.getLang("한국어")}
            </li>
            <li
              onClick={() => {
                setIsLanguageModalOpen(false);
                lang.setCountry("_en");
                localStorage.setItem("lang", "_en");
              }}
            >
              English
            </li>
          </ul>
        )}
      </div>

      <video autoPlay playsInline muted loop>
        <source src={BgVideo} type="video/mp4" />
      </video>
      <h1
        className="logo"
        onClick={() => {
          modalOption.setSoftwareInformationModal((e) => {
            e.show = true;
            return { ...e };
          });
        }}
      >
        <img src={Logo} alt="logo" />
      </h1>
      <div className="login_inner">
        <div className="login_title">
          <h2>{lang.getLang("위 내시경 환자관리 시스템")}</h2>
          <p>{lang.getLang("프리베노틱스 환자 관리(Preveno-G)")}</p>
        </div>

        <div className="login_box">
          <form className="login_input" onKeyPress={onCheckEnter}>
            <div className="login_input_inner">
              <div className="input_row">
                <ui.input.BasicInput
                  type={"text"}
                  value={id}
                  setValue={setId}
                  placeholder={"ID"}
                />
                <ui.input.BasicInput
                  type={"password"}
                  value={password}
                  setValue={setPassword}
                  placeholder={"Password"}
                />
              </div>
              <div className="checkbox">
                <input
                  type="checkbox"
                  id="keep_sign"
                  value={keepSate}
                  onChange={(e) => {
                    setKeepSate(e.target.checked);
                  }}
                />
                <label htmlFor="keep_sign">Keep me signed in </label>
              </div>
            </div>
            <button
              type="button"
              onClick={() => {
                loginClick(false);
              }}
            >
              Log in
            </button>
          </form>
        </div>
      </div>
    </Styles.Container>
  );
}
