import Styles from "./styles";
import ui from "components/ui";
import { useState, useEffect } from "react";
import { useLanguage } from "hooks";
import { api } from "service";

export default function ChangePasswordModal({ modalOption, modalClose }) {
  const lang = useLanguage("Admin");

  const [newPasswordValue, setNewPasswordValue] = useState("");
  const [confirmPasswordValue, setConfirmPasswordValue] = useState("");

  const [pwCheckState, setPwCheckState] = useState(-1);
  const [rePwCheckState, setRePwCheckState] = useState(-1);

  const [isDisabled, setIsDisabled] = useState(true);

  // 비밀번호 validation
  function passwordCheck() {
    const pwRegEx = /^(?=.*[a-zA-Z])(?=.*[!@#$%^*+=-])(?=.*[0-9]).{8,15}$/;
    let result = pwRegEx.test(newPasswordValue);
    let isMatch = newPasswordValue == confirmPasswordValue;
    setPwCheckState(result ? 1 : 0);
    setRePwCheckState(isMatch);

    return result && isMatch;
  }

  // 비밀번호 변경
  const changePasswordClick = async () => {
    if (passwordCheck() == false) {
      return;
    }

    let obj = { password: confirmPasswordValue };

    let result = await api.admin.PutChangePassword(modalOption.id, obj);
    if (result.message == "user pw has been changed") {
      modalClose();
    }
  };
  // button disabled
  useEffect(() => {
    if (pwCheckState == 1 && rePwCheckState) {
      setIsDisabled(false);
    } else {
      setIsDisabled(true);
    }
  }, [pwCheckState, rePwCheckState]);

  return (
    <Styles.Container>
      <div
        className="overlay"
        onClick={() => {
          modalClose();
        }}
      ></div>

      <form className="inner">
        <p className="title">{lang.getLang("비밀번호 변경")}</p>

        <ul className="infoListWrap">
          <li className="infoList">
            <span className="listTitle">ID</span> :{" "}
            <span>{modalOption.displayID}</span>
          </li>

          <li className="infoList">
            <span className="listTitle">{lang.getLang("이름")}</span> :{" "}
            <span>{modalOption.name}</span>
          </li>
        </ul>

        <ul className="inputListWrap">
          <li className="inputList">
            <div className="inputContainer">
              <p className="listTitle">{lang.getLang("새로운 비밀번호 *")}</p>

              <div className="inputWrap">
                <ui.input.BasicInput
                  type={"password"}
                  value={newPasswordValue}
                  setValue={setNewPasswordValue}
                  placeholder={lang.getLangString(
                    "* 영문 대문자, 소문자, 숫자, 특수문자를 적어도 1개씩 포함한 4-20자"
                  )}
                  onBlur={() => passwordCheck(newPasswordValue)}
                />
              </div>
            </div>

            {pwCheckState == 0 && (
              <p className="errorMessage">
                {lang.getLang(
                  "* 영문 대문자, 소문자, 숫자, 특수문자를 적어도 1개씩 포함한 4-20자"
                )}
              </p>
            )}
          </li>

          <li className="inputList">
            <div className="inputContainer">
              <p className="listTitle">
                {lang.getLang("새로운 비밀번호 확인 *")}
              </p>

              <div className="inputWrap">
                <ui.input.BasicInput
                  type={"password"}
                  value={confirmPasswordValue}
                  setValue={setConfirmPasswordValue}
                  placeholder={lang.getLangString(
                    "* 위의 비밀번호를 다시 입력해주세요."
                  )}
                  onBlur={() => {
                    setRePwCheckState(newPasswordValue == confirmPasswordValue);
                  }}
                />
              </div>
            </div>

            {!rePwCheckState && (
              <p className="errorMessage">
                {lang.getLang("비밀번호가 일치하지 않습니다.")}
              </p>
            )}
          </li>
        </ul>

        <div className="buttonContainer">
          <button
            className="updateButton"
            type="button"
            disabled={
              newPasswordValue.length == 0 || confirmPasswordValue.length == 0
            }
            onClick={() => {
              changePasswordClick();
            }}
          >
            {lang.getLang("업데이트")}
          </button>

          <button
            className="cancelButton"
            type="button"
            onClick={() => {
              modalClose();
            }}
          >
            {lang.getLang("취 소")}
          </button>
        </div>
      </form>
    </Styles.Container>
  );
}
