import { useAtom } from "jotai";
import { useEffect } from "react";
import { getCookie } from "service/connector";
import { authAtom } from "store/atoms";

const useUserData = () => {
  const [userData, setUserData] = useAtom(authAtom);

  useEffect(() => {
    const auth = getCookie("auth");
    setUserData({
      auth: Number(auth),
    });
  }, []);

  return {
    userData,
  };
};

export default useUserData;
