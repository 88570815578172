import ui from "components/ui";
import Styles from "./styles";
import { getCookie } from "service/connector";
import { useEffect, useState } from "react";

export default function LesionTitle({
  type,
  title,
  checkTitle,
  id,
  percent,
  checkLesion,
  onChangeChecked,
  screen
}) {

  const [scope, setScope] = useState('lite')

  useEffect(() => {
    setScope(getCookie('scope'))
  }, [])
  
  const renderClass = (type) => {
    
    if (scope === 'lite' && screen === 'abnormality' && (
      type === 'red' || type === 'orange' || type == 'purple'
    )) {
      return "red"
    }

    switch (type) {
      case "red":
        return "red";

      case "orange":
        return "orange";

      case "purple":
        return "purple";
      
      case "yellow":
        return "yellow";
      
      case "green":
      default:
        return "green";
    }
  };

  return (
    <Styles.Container className={renderClass(type)}>
      <p className="title">{title}</p>

      {checkTitle && (
        <div className="checkContainer">
          {checkTitle !== "Decision Area" &&
            <ui.input.BasicCheckbox
              text={checkTitle}
              checked={checkLesion}
              onChange={(e) => {
                onChangeChecked(e.target.checked);
              }}
              id={id}
            />
          }
          {/* <ui.input.BasicCheckbox
            text={checkTitle}
            checked={checkLesion}
            // onChange={(e) => {
            //   onChangeChecked(e.target.checked);
            // }}
            id={id}
          /> */}
        </div>
      )}

      {/* {percent && scope == "plus" && (
        <p className="percent">
          {percent} <span>%</span>
        </p>
      )} */}
    </Styles.Container>
  );
}
