import styled from "styled-components";

const Container = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: 16;

  & .inner {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: calc(100vw * (1044 / 1920));
    max-height: 95vh;
    padding-bottom: calc(100vw * (16 / 1920));
    font-size: calc(100vw * (24 / 1920));
    color: #ffffff;
    background: #292929;
    border-radius: calc(100vw * (20 / 1920));
    overflow: hidden;
  }

  & .titleContainer {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    padding: calc(100vw * (19 / 1920)) calc(100vw * (25 / 1920))
      calc(100vw * (13 / 1920)) calc(100vw * (40 / 1920));
    font-size: calc(100vw * (24 / 1920));
    font-weight: bold;
    border-bottom: calc(100vw * (3 / 1920)) solid #fff;
  }

  & .wrap {
    overflow: auto;
  }

  & .list {
    display: flex;
    align-items: center;
    min-height: calc(100vw * (63 / 1920));
    padding: calc(100vw * (14 / 1920)) calc(100vw * (17 / 1920));
    border-bottom: calc(100vw * (3 / 1920)) solid #424447;

    &:nth-of-type(5) {
      align-items: flex-start;
    }

    & .title {
      flex-shrink: 0;
      width: calc(100vw * (248 / 1920));
      line-height: 1.2;
    }

    & .text {
      line-height: 1.2;
    }
  }

  & .warningText {
    margin-bottom: calc(100vw * (7 / 1920));
    text-align: right;
    line-height: 1.2;

    & span {
      color: #ff0000;
    }
  }

  & .imgWrap {
    width: calc(100vw * (705 / 1920));
    height: auto;
    margin: 0 auto;
  }

  & .closeButton {
    width: calc(100vw * (35 / 1920));
    height: calc(100vw * (35 / 1920));
  }

  & .overlay {
    width: 100%;
    height: 100%;
  }
`;

export { Container };

export default { Container };
