import Layout from "./Layout";
import Pagination from "./Pagination";
import Stomach from "./Stomach";
import MiniStomach from "./MiniStomach";
import UserDataListAbnoramlity from "./UserDataListAbnoramlity";
import UserDataList from "./UserDataList";
import ReportTitle from "./ReportTitle";
import ScoreContainer from "./ScoreContainer";
import ImageList from "./ImageList";
import APTContainer from "./APTContainer";
import NoPastHistory from "./NoPastHistory";
import PastHistory from "./PastHistory";
import ScoreSection from "./ScoreSection";
import IMCheckedSection from "./IMCheckedSection";
import AbnormalityCheckedSection from "./AbnormalityCheckedSection";
import LesionTitle from "./LesionTitle";
import NoHoverStomach from "./NoHoverStomach";
import NoHoverStomachReport from "./NoHoverStomachReport";
import DwvComponent from './DwvComponent';
import LesionAnimationContainer from './LesionAnimationContainer';

export {
  Layout,
  Pagination,
  Stomach,
  MiniStomach,
  UserDataList,
  UserDataListAbnoramlity,
  ReportTitle,
  ScoreContainer,
  ImageList,
  APTContainer,
  NoPastHistory,
  PastHistory,
  ScoreSection,
  IMCheckedSection,
  AbnormalityCheckedSection,
  LesionTitle,
  NoHoverStomach,
  NoHoverStomachReport,
  DwvComponent,
  LesionAnimationContainer,
};

export default {
  Layout,
  Pagination,
  Stomach,
  MiniStomach,
  UserDataListAbnoramlity,
  UserDataList,
  ReportTitle,
  ScoreContainer,
  ImageList,
  APTContainer,
  NoPastHistory,
  PastHistory,
  ScoreSection,
  IMCheckedSection,
  AbnormalityCheckedSection,
  LesionTitle,
  NoHoverStomach,
  NoHoverStomachReport,
  DwvComponent,
  LesionAnimationContainer,
};
