import { useMemo, useRef, useState, useEffect } from "react";
import Styles from "./styles";
import { useLanguage } from "hooks";
import { useNavigate, useParams } from "react-router-dom";
import { StomachSection } from "./components";
import { getCookie } from "service/connector";

// img
import { IMAGE_SRC } from "constants/common";
import useIsBeautifulDndMount from "hooks/useIsBeautifulDndMount";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import { lesionTypeOrderClass, lesionTypeListOrderNumber } from "utils/common";
import { useMutation, useQueryClient } from "react-query";
import { api } from "service";
import { DwvComponent } from "components/layout";

export default function StomachContainer({
  listData,
  filterInfo,
  patientsData,
  IMValue
}) {
  const navigate = useNavigate();
  const lang = useLanguage("Image");

  const [isDragging, setIsDragging] = useState(false);
  const [hoverSection, setHoverSection] = useState("");
  const [notSelectedSection, setNotSelectedSection] = useState(false);
  const [scope, setScope] = useState("lite");

  const isBeautifulDndMount = useIsBeautifulDndMount();

  const { id, studyId } = useParams();

  const stomachListData = useMemo(
    () => [
      {
        landmark: 1,
        landmarkTitle: lang.getLang("식도 - Esophagus"),
        listData: listData.filter((item) => item.landmark === 1),
      },
      {
        landmark: 2,
        landmarkTitle: lang.getLang("위식도 접합부 - Esophagogastric junction"),
        listData: listData.filter((item) => item.landmark === 2),
      },
      {
        landmark: 6,
        landmarkTitle: lang.getLang("위 각부 - Angle"),
        listData: listData.filter((item) => item.landmark === 6),
      },
      {
        landmark: 3,
        landmarkTitle: lang.getLang("십이지장 2부 - Duodenum 2nd portion"),
        listData: listData.filter((item) => item.landmark === 3),
      },
      {
        landmark: 4,
        landmarkTitle: lang.getLang("십이지장 구부 - Duodenum bulb"),
        listData: listData.filter((item) => item.landmark === 4),
      },
      {
        landmark: 5,
        landmarkTitle: lang.getLang("위 전정부 - Antrum"),
        listData: listData.filter((item) => item.landmark === 5),
      },
      {
        landmark: 10,
        landmarkTitle: lang.getLang("위 본문부(반전) - Reftroflexion cardia"),
        listData: listData.filter((item) => item.landmark === 10),
      },
      {
        landmark: 9,
        landmarkTitle: lang.getLang("위 저부(반전) - Retroflexion fundus"),
        listData: listData.filter((item) => item.landmark === 9),
      },
      {
        landmark: 11,
        landmarkTitle: lang.getLang("위 체부(반전) - Retroflexion body"),
        listData: listData.filter((item) => item.landmark === 11),
      },
      {
        landmark: 8,
        landmarkTitle: lang.getLang("위 체부(중-상부) - Body, MB-HB"),
        listData: listData.filter((item) => item.landmark === 8),
      },
      {
        landmark: 7,
        landmarkTitle: lang.getLang("위 체부(하-중부) - Body, LB-MB"),
        listData: listData.filter((item) => item.landmark === 7),
      },
      {
        // 이거 12 맞는지 확인
        landmark: 12,
        landmarkTitle: "others",
        listData: listData.filter((item) => item.landmark === 12),
      },
    ],
    [listData, lang]
  );

  const landmarkTypeList = [...stomachListData]
    ?.sort((a, b) => a.landmark - b.landmark)
    ?.map(({ listData = [] }) => {
      const typeList = [];
      listData.forEach(({ lesion = [] }) =>
        lesion.forEach(({ type, score }) => {
          if (scope === "lite") {
            if (type === 1 || type === 2 || type === 3) {
              typeList.push(1);
            } else if (type === 4 && score > 100 - IMValue) {
              typeList.push(type);
            }
          } else {
            if (type === 4 && score > 100 - IMValue) {
              typeList.push(type);
            } else {
              typeList.push(type);
            }
          }
        })
      );
      return lesionTypeListOrderNumber(typeList);
    });

  const queryClient = useQueryClient();
  const { mutate: landmarkChangeMutate } = useMutation(
    (changeProps) =>
      api.image.putLandmarkChange({
        landmark: changeProps.landmark,
        imageId: changeProps.imageId,
        priority: changeProps.priority,
        sequence: changeProps.sequence,
        imageName: changeProps.imageName,
        path: changeProps.path,
      }),
    {
      async onMutate(variable) {
        await queryClient.cancelQueries(["image_list", filterInfo]);

        const previousImageList = queryClient.getQueryData([
          "image_list",
          filterInfo,
        ]);

        let targetValue = null;
        let resultList = [];
        let list = previousImageList;

        list = list.filter((value, index) => {
          if (value.imageId === variable.imageId) {
            targetValue = value;
            return false;
          }
          return true;
        });

        let noneLandmarkList = list.filter(
          (value) => value.landmark != variable.landmark
        );
        let landmarkList = list.filter(
          (value) => value.landmark == variable.landmark
        );

        targetValue.sequence = variable.sequence;
        targetValue.landmark = variable.landmark;

        landmarkList = [
          ...landmarkList.slice(0, variable.sequence),
          targetValue,
          ...landmarkList.slice(variable.sequence),
        ];

        resultList = noneLandmarkList.concat(landmarkList);
        queryClient.setQueryData(["image_list", filterInfo], resultList);

        return () =>
          queryClient.setQueryData(["image_list", filterInfo], resultList);
      },
      onSettled(response) {
        if (!response?.message) {
          throw new Error(response.error);
        }
      },
      onError(error, values, rollback) {
        if (rollback) rollback();
      },
    }
  );

  useEffect(() => {
    setScope(getCookie("scope"));
  }, []);

  return (
    <Styles.Container>
      <DragDropContext
        onDragStart={() => {
          setIsDragging(true);
        }}
        onDragEnd={(props) => {
          setIsDragging(false);

          let imageData = listData.filter(
            (value) => value.imageId === Number(props.draggableId)
          );

          const imageName = imageData[0].imageName;
          const path = imageData[0].path;

          const targetImageId = Number(props.draggableId);

          const forLandmark = Number(props?.destination?.droppableId);

          if (!forLandmark) return;

          landmarkChangeMutate({
            imageId: targetImageId,
            landmark: forLandmark,
            sequence: props.destination.index,
            priority: 0,
            imageName,
            path,
          });
        }}
      >
        <ul className="leftListWrap listWrap">
          {stomachListData.slice(0, 6)?.map((item, index) => {
            return (
              <>
                <Styles.LandmarkBox
                  className={
                    "leftList list" +
                    (hoverSection ? " on" : "")
                  }
                  isHover={hoverSection === item.landmark || hoverSection === `${item.landmark}`}
                  key={"leftList" + index}
                  onMouseEnter={(e) => {
                    setHoverSection(item.landmark);
                    setNotSelectedSection(true);
                  }}
                  onMouseLeave={(e) => {
                    setHoverSection("");
                    setNotSelectedSection(false);
                  }}
                  onClick={() => {
                    if (isDragging == false)
                      navigate(`/history/${id}/${studyId}/${item.landmark}`);
                  }}
                >
                  <p className="landmark">{item.landmarkTitle}</p>
                  <div className="imgListWrapContainer">
                    {isBeautifulDndMount && (
                      <Droppable
                        droppableId={String(item.landmark)}
                        direction="horizontal"
                      >
                        {(provided) => (
                          <ul
                            ref={provided.innerRef}
                            {...provided.droppableProps}
                            className="imgListWrap"
                            onWheel={(e) => {
                              e.preventDefault();
                              e.target.scrollLeft += e.deltaY;
                            }}
                          >
                            {item.listData?.map((current, index) => {
                              let lesionOrder = lesionTypeOrderClass(
                                current.lesion, IMValue
                              );

                              if (scope == "lite") {
                                if (lesionOrder == "adenoma" || lesionOrder == "polyp") {
                                  lesionOrder = "cancer";
                                }
                              }

                              return (
                                // 병변 우선 순위
                                // cancer(type1) > adenoma(type2) > polyp(type3) > IM(type4)

                                <Draggable
                                  draggableId={String(current.imageId)}
                                  index={index}
                                  key={"imageList" + current.imageId}
                                >
                                  {(provided, snapshot) => {
                                    if (snapshot.isDragging) {
                                      provided.draggableProps.style = {
                                        ...provided.draggableProps.style,
                                        top: provided.draggableProps.style
                                          .offsetTop,
                                      };
                                    }
                                    return (
                                      <li
                                        ref={provided.innerRef}
                                        {...provided.draggableProps}
                                        {...provided.dragHandleProps}
                                        key={"imageList" + current.imageId}
                                      >
                                        <div
                                          className={`imgList ${lesionOrder}`}
                                        >
                                          {current?.imageName?.includes("d") ? (
                                            <DwvComponent
                                              index={current.imageId}
                                              item={current}
                                            />
                                          ) : (
                                            <img
                                              src={IMAGE_SRC + current.path}
                                              alt="stomach"
                                            />
                                          )}
                                        </div>
                                      </li>
                                    );
                                  }}
                                </Draggable>
                              );
                            })}
                          </ul>
                        )}
                      </Droppable>
                    )}
                  </div>
                </Styles.LandmarkBox>
              </>
            );
          })}
        </ul>

        <div className="stomachContainer">
          <div className="userInfoContainer">
            <p className="userName">{patientsData.name}</p>

            <div className="dateContainer">
              <p className="birthContainer">
                <span className="dateTitle">{lang.getLang("생년월일")}</span>
                {patientsData.birthday}
              </p>
              <p>/</p>
              <p className="examinationContainer">
                <span className="dateTitle">{lang.getLang("검진일자")}</span>
                {patientsData?.studies?.map((item) => {
                  return (
                    studyId == item.studyId && item.studyDate.split(" ")[0]
                  );
                })}
              </p>
            </div>
          </div>

          <div className="stomachWrap">
            {/* {console.log("landmarkTypeList", landmarkTypeList)} */}
            <StomachSection
              hoverSection={hoverSection}
              setHoverSection={setHoverSection}
              notSelectedSection={notSelectedSection}
              setNotSelectedSection={setNotSelectedSection}
              landmarkTypeList={landmarkTypeList}
              onClickStomachSection={({ landmark }) => {
                navigate(`/history/${id}/${studyId}/${landmark}`);
              }}
            />
          </div>
        </div>

        <ul className="rightListWrap listWrap">
          {stomachListData.slice(6, 12)?.map((item, index) => {
            // item.listdata = [~~~~ imageName. path]
            return (
              <Styles.LandmarkBox
                className={
                  "rightList list" +
                  (hoverSection ? " on" : "")
                }
                isHover={hoverSection === item.landmark || hoverSection === `${item.landmark}`}
                key={"rightList" + index}
                onMouseEnter={(e) => {
                  setHoverSection(item.landmark);
                  setNotSelectedSection(item.landmark);
                }}
                onMouseLeave={(e) => {
                  setHoverSection("");
                  setNotSelectedSection("");
                }}
                onClick={() => {
                  if (isDragging) return;

                  if (item.landmark === 12) {
                    navigate(`/lesion/${id}/${studyId}/5`);
                    return;
                  }
                  navigate(`/history/${id}/${studyId}/${item.landmark}`);
                }}
              >
                <p className="landmark">{item.landmarkTitle}</p>
                <div className="imgListWrapContainer">
                  {isBeautifulDndMount && (
                    <Droppable
                      droppableId={String(item.landmark)}
                      direction="horizontal"
                    >
                      {(provided) => (
                        <ul
                          ref={provided.innerRef}
                          {...provided.droppableProps}
                          className="imgListWrap"
                          onWheel={(e) => {
                            e.preventDefault();
                            e.target.scrollLeft += e.deltaY;
                          }}
                        >
                          {item.listData?.map((current, index) => {
                            let lesionOrder = lesionTypeOrderClass(
                              current.lesion, IMValue
                            );

                            if (scope === "lite") {
                              if (
                                lesionOrder === "adenoma" ||
                                lesionOrder === "polyp"
                              ) {
                                lesionOrder = "cancer";
                              }
                            }
                            return (
                              <Draggable
                                draggableId={String(current.imageId)}
                                index={index}
                                key={"imageList" + current.imageId}
                              >
                                {(provided, snapshot) => {
                                  if (snapshot.isDragging) {
                                    provided.draggableProps.style = {
                                      ...provided.draggableProps.style,
                                      left: provided.draggableProps.style
                                        .offsetLeft,
                                      top: provided.draggableProps.style
                                        .offsetTop,
                                    };
                                  }
                                  return (
                                    <li
                                      ref={provided.innerRef}
                                      {...provided.draggableProps}
                                      {...provided.dragHandleProps}
                                      key={"imgList" + current.imageId}
                                    >
                                      <div className={`imgList ${lesionOrder}`}>
                                        {current?.imageName?.includes("d") ? (
                                          <DwvComponent
                                            index={current.imageId}
                                            item={current}
                                          />
                                        ) : (
                                          <img
                                            src={IMAGE_SRC + current.path}
                                            alt="stomach"
                                          />
                                        )}
                                      </div>
                                    </li>
                                  );
                                }}
                              </Draggable>
                            );
                          })}
                          {provided.placeholder}
                        </ul>
                      )}
                    </Droppable>
                  )}
                </div>
              </Styles.LandmarkBox>
            );
          })}
        </ul>
      </DragDropContext>
    </Styles.Container>
  );
}
