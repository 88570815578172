import styled from "styled-components";

const Container = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  /* height: calc(100vh - calc(100vw * (100 / 1920))); */
  padding: calc(100vw * (23 / 1920)) calc(100vw * (40 / 1920));
  overflow: auto;

  & .listWrap {
    flex-shrink: 0;
    & .list {
      position: relative;
      width: calc(100vw * (460 / 1920));
      min-height: calc(100vw * (138 / 1920));
      padding: calc(100vw * (9 / 1920)) calc(100vw * (20 / 1920));
      font-size: calc(100vw * (20 / 1920));
      border: calc(100vw * (2 / 1920)) solid #ffffff;
      filter: drop-shadow(
        0px calc(100vw * (4 / 1920)) calc(100vw * (4 / 1920))
          rgba(0, 0, 0, 0.25)
      );
      border-radius: calc(100vw * (20 / 1920));
      cursor: pointer;

      & img {
        width: 100%;
        height: 100%;
        object-fit: fill;
      }

      &:not(:last-child) {
        margin-bottom: calc(100vw * (10 / 1920));
      }

      & .landmark {
        margin-bottom: calc(100vw * (10 / 1920));
      }

      & .imgListWrap {
        position: relative;
        display: flex;
        gap: calc(100vw * (22 / 1920));
        /* overflow-x: auto; */
        overflow-x: scroll;
        min-height: calc(100vw * (88 / 1920));

        &::-webkit-scrollbar {
          height: 10px;
        }
        &::-webkit-scrollbar-thumb {
          border-top: 2px solid transparent;
          border-bottom: 2px solid transparent;
          border-right: 2px solid transparent;
          border-left: 2px solid transparent;
        }

        & .imgList {
          top: auto !important;
          left: auto !important;
          flex-shrink: 0;
          width: calc(100vw * (80 / 1920));
          height: calc(100vw * (80 / 1920));
          background: #d9d9d9;
          box-shadow: calc(100vw * (5 / 1920)) calc(100vw * (5 / 1920))
            calc(100vw * (4 / 1920)) rgba(0, 0, 0, 0.5);
          border-radius: calc(100vw * (20 / 1920));
          overflow: hidden;

          &.cancer {
            border: calc(100vw * (5 / 1920)) solid #cc004c;
          }

          &.adenoma {
            border: calc(100vw * (5 / 1920)) solid #f37021;
          }

          &.polyp {
            border: calc(100vw * (5 / 1920)) solid #04B24A;
          }

          &.IM {
            border: calc(100vw * (5 / 1920)) solid #fcb711;
          }
        }
      }
    }
  }

  & .stomachContainer {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    align-items: center;

    & .userInfoContainer {
      display: flex;
      align-items: center;

      & .userName {
        margin-right: calc(100vw * (22 / 1920));
        font-size: calc(100vw * (40 / 1920));
        font-weight: bold;
      }

      & .dateContainer {
        display: flex;
        font-size: calc(100vw * (20 / 1920));

        & .birthContainer {
          margin-right: calc(100vw * (10 / 1920));
        }

        & .examinationContainer {
          margin-left: calc(100vw * (10 / 1920));
        }

        & .dateTitle {
          margin-right: calc(100vw * (6 / 1920));
        }
      }
    }
  }
`;

const LandmarkBox = styled.div`
  &.on {
    opacity: ${(props) => (props.isHover ? "1" : "0.5")};
  }
`;

export { Container ,LandmarkBox };

export default { Container, LandmarkBox};
