import { useConfig } from "hooks/useConfig";
import Styles from "./styles";

// img
import IconLoading from "resources/image/icon/icon_loading.svg";
import IconLoadingGif from "resources/image/gif/process_loading.gif";
import { useLanguage } from "hooks";

export default function LoadingModal({ modalOption, modalClose }) {

  const lang = useLanguage("Checkup");
  const configInfo = useConfig();

  return (
    <Styles.Container>
      <div className="overlay"></div>

      <div className="inner">
        <p>{lang.getLangString(modalOption.title)}</p>
        <div className="loadingIconWrap">
          <img src={configInfo.configData.isGIF ?  IconLoadingGif : IconLoading} alt="IconLoading" />
        </div>
        <button
          type="button"
          onClick={() => {
            modalClose();
            {
              modalOption.buttonClickEvent && modalOption.buttonClickEvent();
            }
          }}
        >
        {lang.getLangString(modalOption.button)}
        </button>
      </div>
    </Styles.Container>
  );
}
