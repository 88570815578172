import styled from "styled-components";

// img
import Logo from "resources/image/icon/logo.svg";
import IconBack from "resources/image/icon/icon_back.svg";

const Container = styled.div`
  display: flex;
  align-items: flex-start;
  gap: calc(100vw * (58 / 1920));
  position: relative;
  width: 100%;
  min-height: 100vh;
  padding: calc(100vw * (18 / 1920)) calc(100vw * (32 / 1920)) 0;
  background: url(${Logo}) no-repeat left calc(100vw * (30 / 1920)) bottom
    calc(100vw * (30 / 1920)) / contain;
  background-size: calc(100vw * (204 / 1920)) calc(100vw * (36 / 1920));
  background-color: #000;

  /* side box */
  & .side_box {
    width: calc(100vw * (415 / 1920));
  }
  & .logo {
    width: calc(100vw * (200 / 1920));
  }
  & .logout_box {
    display: flex;
    align-items: center;
    width: calc(100% - calc(100vw * (15 / 1920)));
    height: calc(100vw * (82 / 1920));
    border-bottom: calc(100vw * (3 / 1920)) solid #ffffff;
  }
  & .logout {
    font-size: calc(100vw * (26 / 1920));
    font-weight: bold;
    text-align: center;
    background: url(${IconBack}) no-repeat center left/calc(100vw * (28/1920));
    padding-left: calc(100vw * (42 / 1920));
  }
  & .settingButton {
    height: calc(100vw * (33 / 1920));
  }

  & .side_inner {
    width: 100%;
    // height:100%
    height: calc(88vh - calc(100vw * (110 / 1920)));
    padding: calc(100vw * (56 / 1920)) calc(100vw * (15 / 1920))
      calc(100vw * (80 / 1920)) 0;
    overflow-y: auto;
    overflow: overlay;
  }
  & .side_inner::-webkit-scrollbar-thumb {
    background: rgba(56, 56, 56, 0.5);
  }
  & .side_inner::-webkit-scrollbar {
    width: calc(100vw * (10 / 1920));
    height: calc(100vw * (10 / 1920));
  }

  & .doctor_box {
    width: 100%;
    margin-bottom: calc(100vw * (65 / 1920));

    & .doctor {
      margin-bottom: calc(100vw * (12 / 1920));
      font-size: calc(100vw * (50 / 1920));
      font-weight: bold;
    }

    & span {
      font-size: calc(100vw * (24 / 1920));
      color: #848b99;
    }
  }
  & .treatment_box {
    width: 100%;
  }
  & .treat_title {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    & > div {
      display: flex;
      align-items: center;
      gap: calc(100vw * (8 / 1920));
    }
    & .timeIconWrap {
      width: calc(100vw * (29 / 1920));
      height: calc(100vw * (29 / 1920));
    }
    & .userIconWrap {
      width: calc(100vw * (24 / 1920));
      height: calc(100vw * (28 / 1920));
    }
    & span {
      font-size: calc(100vw * (28 / 1920));
    }
    & p {
      font-size: calc(100vw * (28 / 1920));
      font-weight: 600;
    }
  }
  & .treat_list {
    display: flex;
    flex-direction: column;
    gap: calc(100vw * (20 / 1920));
    width: 100%;
    margin-top: calc(100vw * (26 / 1920));

    & li {
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 100%;
      height: calc(100vw * (78 / 1920));
      padding: calc(100vw * (16 / 1920)) calc(100vw * (20 / 1920));
      background-color: #ffffff;
      border-radius: calc(100vw * (6 / 1920));
    }
    & li > p {
      font-size: calc(100vw * (30 / 1920));
      color: #000;
      font-weight: 600;
    }
    & li > div {
      margin: 0;
    }
    & li > div p {
      font-size: calc(100vw * (24 / 1920));
    }
  }

  /* 검진 리스트 */
  & .content_box {
    width: calc(100% - calc(100vw * (458 / 1920)));
    height: calc(1vh - calc(100vw * (110 / 1920)));
  }
  & .content_title {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    height: calc(100vw * (82 / 1920));
    border-bottom: calc(100vw * (3 / 1920)) solid #ffffff;
  }
  & .content_title h2 {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-size: calc(100vw * (32 / 1920));
    font-weight: bold;
  }
  & .content_title span {
    display: block;
    font-size: calc(100vw * (24 / 1920));
  }
  & .search {
    position: relative;
    width: calc(100vw * (390 / 1920));
    height: calc(100vw * (60 / 1920));
  }
  & .search input {
    font-size: calc(100vw * (24 / 1920));
    padding-right: calc(100vw * (144 / 1920));
  }

  & .search_btn {
    position: absolute;
    top: calc(100vw * (6 / 1920));
    right: calc(100vw * (6 / 1920));
    display: flex;
    justify-content: center;
    align-items: center;
    width: calc(100vw * (130 / 1920));
    height: calc(100vw * (48 / 1920));
    font-size: calc(100vw * (25 / 1920));
    background-color: #ffffff;
    color: #000000;
    border-radius: calc(100vw * (6 / 1920));
    font-weight: bold;

    & .iconWrap {
      display: inline-block;
      width: calc(100vw * (30 / 1920));
      height: calc(100vw * (30 / 1920));
      margin-right: calc(100vw * (10 / 1920));
    }
  }
  & .inputListWrap {
    display: flex;
    align-items: center;
    /* margin-top: calc(100vw * (50 / 1920)); */
    margin-block: calc(100vw * (20 / 1920));

    & .inputList {
      display: flex;
      align-items: center;

      &:first-child {
        margin-right: calc(100vw * (81 / 1920));
      }

      &:nth-of-type(2) {
        margin-right: calc(100vw * (17 / 1920));
      }

      & .inputTitle {
        margin-right: calc(100vw * (17 / 1920));
        font-size: calc(100vw * (24 / 1920));
        font-weight: 700;
      }

      & .inputContainer {
        width: calc(100vw * (230 / 1920));
        height: calc(100vw * (32 / 1920));
        margin-left: calc(100vw * (7 / 1920));

        & input {
          font-size: calc(100vw * (20 / 1920));
        }
      }
      & .inputContainer.name {
        width: calc(100vw * (250 / 1920));
        margin-right: 0;
        margin-left: 0;
      }
      & .searchButton {
        display: flex;
        justify-content: center;
        align-items: center;
        width: calc(100vw * (52 / 1920));
        height: calc(100vw * (30 / 1920));
        margin-left: calc(100vw * (12 / 1920));
        background: #ffffff;
        border-radius: calc(100vw * (7 / 1920));

        & .iconWrap {
          width: calc(100vw * (21 / 1920));
          height: calc(100vw * (21 / 1920));

          & img {
            display: block;
            width: 100%;
          }
        }

        & .iconResetWrap {
          width: calc(100vw * (24 / 1920));
          height: calc(100vw * (24 / 1920));

          & img {
            display: block;
            width: 100%;
          }
        }

        & .iconUploadWrap {
          width: calc(100vw * (32 / 1920));
          height: calc(100vw * (26 / 1920));

          & img {
            display: block;
            width: 100%;
          }
        }
      }
    }
  }
`;

export { Container };

export default { Container };
