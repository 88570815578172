import moment from "moment";
import Styles from "./styles";
import { useLanguage } from "hooks";
import { useQuery } from "react-query";

// img
import IconClose from "resources/image/icon/icon_close.svg";
import { api } from "service";
import { responseCommentDataToList } from "utils/common";

export default function MoreResultModal({ modalClose, modalOption }) {
  const { studyId } = modalOption;
  const lang = useLanguage("Patient");

  const { data: reportsData = {}, isLoading: isLoadingReports } = useQuery(
    ["reports", studyId],
    () => api.reports.getReports({ medicalstudyId: studyId }),
    {
      refetchOnWindowFocus: false,
      staleTime: 5 * 1000 * 60,
    }
  );

  const reportCommentList = responseCommentDataToList(reportsData.comment);

  const detailData = [
    {
      title: "검진 번호",
      detail: studyId,
    },
    {
      title: "검진 일자",
      detail: moment(reportsData.studyDate).format("YYYY.MM.DD"),
    },
    {
      title: "검진 결과",
      resultListData: [
        {
          resultListDetailData: reportCommentList,
        },
      ],
    },
  ];

  return (
    <Styles.Container>
      <button type="button" className="closeButton" onClick={modalClose}>
        <img src={IconClose} alt="close" />
      </button>

      <h2 className="title">{lang.getLang("검진 기록 상세 보기")}</h2>

      <ul className="detailListWrap">
        {detailData?.map((item, index) => {
          return (
            <li className="detailList" key={"detailList" + index}>
              <p className="listTitle">{lang.getLang(item.title)}</p>

              <div className="detail">
                {item.detail
                  ? item.detail
                  : item.resultListData?.map((current, idx) => {
                      return (
                        <ul
                          className="resultListWrap"
                          key={"resultListWrap" + idx}
                        >
                          {current.resultListDetailData?.map((item, i) => {
                            return <li key={i}>{item.comment}</li>;
                          })}
                        </ul>
                      );
                    })}
              </div>
            </li>
          );
        })}
      </ul>
    </Styles.Container>
  );
}
