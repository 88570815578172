import connector from "service/connector";

function getFilterStr(parameter){
  let filter = parameter;
  return new URLSearchParams(filter).toString();
}

const login = async (parameter) => {
  return await connector.connectFetchController(`users/signin`,"POST",parameter);
}

const refresh = async () => {
  return await connector.authConnector(`users/refresh`,"GET");
}

export {
  login,
  refresh,
};


export default {
  login,
  refresh,
}