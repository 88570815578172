import styled from "styled-components";

// img
import IconArrowBottom from "resources/image/icon/icon_arrow_bottom.svg";

export const Container = styled.div`
  position: relative;
  align-items: center;
  gap: calc(50vw * (25 / 1920));
  z-index: 3;
  // top: calc(100vw * (-3 / 1920));

  & .patientName {
    font-size: calc(100vw * (40 / 1920));
    font-weight: 700;
  }

  & .dateContainer {
    font-size: calc(100vw * (20 / 1920));
    position: relative;
    top: calc(100vw * (9 / 1920));
  }

  & .birthDate {
    // 최대폭 제한
    width: calc(100vw * (300 / 1920));
    margin-right: calc(80vw * 20 / 1920));
    margin-bottom: calc(100vw * (2 / 1920));
  }

  & .dateTitle {
    width: calc(100vw * (66 / 1920));
    margin-right: calc(80vw * (20 / 1920));
  }

  & .date {
    position: relative;
    padding-right: calc(100vw * (20 / 1920));

    &.on {
      background: url(${IconArrowBottom}) no-repeat center right /
        calc(100vw * (18 / 1920)) calc(100vw * (10 / 1920));
    }
  }

  & .checkUpDate {
    position: relative;
    z-index: 2;
    cursor: pointer;

    &.on > p {
      border-bottom: 1px dashed #fff;
    }

    & > p {
      position: relative;
      display: flex;
      align-items: center;
      padding-bottom: calc(100vw * (3 / 1920));
      z-index: 2;
      border-bottom: 1px dashed transparent;
    }

    & .optionListContainer {
      position: absolute;
      top: calc(100vw * (-3 / 1920));
      left: 50%;
      transform: translateX(-50%);
      width: calc(100% + calc(100vw * (23 / 1920)));
      max-height: 80vh;
      padding: calc(100vw * (28 / 1920)) 0 calc(100vw * (7 / 1920))
        calc(100vw * (93 / 1920));
      font-size: calc(100vw * (20 / 1920));
      border: 1px solid #fff;
      background-color: #000;
      z-index: 1;

      & .optionListWrap {
        width: fit-content;
        height: 100%;
        margin-right: calc(100vw * (12 / 1920));
        margin-left: auto;
        /* overflow: hidden;
        overflow-y: scroll; */
      }

      & .optionList {
        width: calc(100vw * (112 / 1920));

        &:not(:last-child) {
          margin-bottom: calc(100vw * (10 / 1920));
        }
      }
    }
  }

  & img {
    width: 100%;
    height: 100%;
    object-fit: fill;
  }
`;
