import { useEffect, useState } from "react";
import { common, layout } from "components";
import Styles from "./styles";
import { useNavigate } from "react-router-dom";
import { useModals, useLanguage } from "hooks";
import { api } from "service";
import { useMutation, useQuery, useQueryClient } from "react-query";
import utils from "utils";
import { useConfig } from "hooks/useConfig";

export default function SettingsPage() {
  const modalOption = useModals();
  const lang = useLanguage("Settings");
  const navigate = useNavigate();
  const [hoverSection, setHoverSection] = useState(1);
  const [notSelectedSection, setNotSelectedSection] = useState(false);

  const configInfo = useConfig();
  const [settingData, setSettingData] = useState({});

  useEffect(() => {
    setSettingData(configInfo.configData);
  }, [configInfo.configData]);

  // map
  const stomachListData = [
    lang.getLang("식도"),
    lang.getLang("위식도 접합부"),
    lang.getLang("십이지장 2부"),
    lang.getLang("십이지장 구부"),
    lang.getLang("위 전정부"),
    lang.getLang("위 각부"),
    lang.getLang("위체부(하-중부)"),
    lang.getLang("위체부(중-상부)"),
    lang.getLang("위저부(반전)"),
    lang.getLang("위 분문부(반전)"),
    lang.getLang("위체부(반전)"),
  ];

  if (configInfo.configQuery.isLoading) return;

  return (
    <Styles.Container>
      <common.SettingHeader
        title={"SETTINGS"}
        logout={true}
        search={true}
        searchClickEvent={() => {
          modalOption.setSearchPatientModal((e) => {
            e.show = true;
            return { ...e };
          });
        }}
      />
      <div className="setting_box">
        <div className="inner">
          <div className="row">
            <div className="title_box">
              <p className="title">
                <span>Image</span> {lang.getLang("페이지")}
              </p>
              <span>
                {lang.getLang(
                  "Image 페이지 시작 시 첫 화면을 지정할 수 있습니다."
                )}
              </span>
            </div>

            <div className="inner_box">
              <div className="radiobox">
                <input
                  type="radio"
                  id="image_value"
                  name="image_setting"
                  checked={!settingData?.stomachView}
                  onChange={(e) => {
                    setSettingData((f) => ({ ...f, stomachView: false }));
                  }}
                />
                <label htmlFor="image_value">
                  {lang.getLang("이미지만 보기")}
                </label>
              </div>
              <div className="radiobox">
                <input
                  type="radio"
                  id="icon_value"
                  name="image_setting"
                  checked={settingData?.stomachView}
                  onChange={(e) => {
                    setSettingData((f) => ({ ...f, stomachView: true }));
                  }}
                />
                <label htmlFor="icon_value">
                  {lang.getLang("위 아이콘으로 보기")}
                </label>
              </div>
            </div>
          </div>

          <div className="row">
            <div className="title_box">
              <p className="title">
                <span>History</span> {lang.getLang("페이지")}
              </p>
              <span>
                {lang.getLang(
                  "History 페이지 시작 시 첫 화면의 랜드마크를 지정할 수 있습니다."
                )}
              </span>
            </div>

            <div className="stomach_box">
              <div className="stomachContainer">
                <layout.Stomach
                  hoverSection={hoverSection}
                  setHoverSection={setHoverSection}
                  settingData={settingData}
                  setSettingData={setSettingData}
                  notSelectedSection={notSelectedSection}
                  setNotSelectedSection={setNotSelectedSection}
                />
              </div>

              <ul className="stomachListWrap">
                {stomachListData.map((item, index) => {
                  return (
                    <li
                      className={
                        "stomachList" +
                        (settingData?.startLandmark === `${index + 1}` ||
                        settingData?.startLandmark === index + 1
                          ? " on"
                          : "")
                      }
                      key={"stomachList" + index}
                      onMouseEnter={() => {
                        setHoverSection(index + 1);
                      }}
                      onMouseLeave={() => {
                        setHoverSection("");
                      }}
                      onClick={() => {
                        setSettingData((f) => ({
                          ...f,
                          startLandmark: index + 1,
                        }));
                      }}
                    >
                      <common.NumberIcon number={index + 1} />

                      <p>{item}</p>
                    </li>
                  );
                })}
              </ul>
            </div>
          </div>
          {!configInfo.setting.isSimpleMode && (
            <div className="row">
              <div className="title_box">
                <p className="title">
                  <span>Abnormality</span> {lang.getLang("페이지")}
                </p>
                <span>
                  {lang.getLang(
                    "Abnormality 페이지에서 이상부위의 위치 표시 여부를 지정할 수 있습니다."
                  )}
                </span>
              </div>

              <div className="inner_box">
                <div className="radiobox">
                  <input
                    type="radio"
                    id="lesion_setting_1"
                    name="lesion_setting"
                    checked={settingData?.lesionMark}
                    onChange={(e) => {
                      setSettingData((f) => ({ ...f, lesionMark: true }));
                    }}
                  />
                  <label htmlFor="lesion_setting_1">
                    {lang.getLang("이상부위 위치 및 Decision Area 표시")}
                  </label>
                </div>
                <div className="radiobox">
                  <input
                    type="radio"
                    id="lesion_setting_2"
                    name="lesion_setting"
                    checked={!settingData?.lesionMark}
                    onChange={(e) => {
                      setSettingData((f) => ({ ...f, lesionMark: false }));
                    }}
                  />
                  <label htmlFor="lesion_setting_2">
                    {lang.getLang("감추기")}
                  </label>
                </div>
              </div>
            </div>
          )}

          <div className="row">
            <div className="title_box">
              <p className="title">
                <span>{lang.getLang("Image 크게보기")}</span>{" "}
                {lang.getLang("페이지")}
              </p>
              <span>
                {lang.getLang(
                  "이미지 크게보기 화면에서 정상 이미지와 함께 보기 여부를 지정할 수 있습니다."
                )}
              </span>
            </div>

            <div className="inner_box">
              <div className="radiobox">
                <input
                  type="radio"
                  id="image_with_setting_1"
                  name="image_with_setting"
                  checked={!settingData?.withNormal}
                  onChange={(e) => {
                    setSettingData((f) => ({ ...f, withNormal: false }));
                  }}
                />
                <label htmlFor="image_with_setting_1">
                  {lang.getLang("클릭한 이미지만 보기")}
                </label>
              </div>
              <div className="radiobox">
                <input
                  type="radio"
                  id="image_with_setting_2"
                  name="image_with_setting"
                  checked={settingData?.withNormal}
                  onChange={() => {
                    setSettingData((f) => ({ ...f, withNormal: true }));
                  }}
                />
                <label htmlFor="image_with_setting_2">
                  {lang.getLang("정상 이미지와 함께 보기")}
                </label>
              </div>
            </div>
          </div>

          {!configInfo.setting.isSimpleMode && (
            <div className="row">
              <div className="title_box">
                <p className="title">
                  <span>{lang.getLang("아이콘 애니메이션 효과")}</span>
                </p>
                <span>
                  {lang.getLang(
                    "Lesion 페이지에서 병변 이름과 확률이 표시되는 아이콘의 애니메이션을 여부를 지정할 수 있습니다."
                  )}
                </span>
              </div>

              <div className="inner_box">
                <div className="radiobox">
                  <input
                    type="radio"
                    id="icon_setting_1"
                    name="icon_setting"
                    checked={settingData?.isGIF}
                    onChange={() => {
                      setSettingData((f) => ({ ...f, isGIF: true }));
                    }}
                  />
                  <label htmlFor="icon_setting_1">
                    {lang.getLang("GIF로 보기(애니메이션 연출)")}
                  </label>
                </div>
                <div className="radiobox">
                  <input
                    type="radio"
                    id="icon_setting_2"
                    name="icon_setting"
                    checked={!settingData?.isGIF}
                    onChange={(e) => {
                      setSettingData((f) => ({ ...f, isGIF: false }));
                    }}
                  />
                  <label htmlFor="icon_setting_2">
                    {lang.getLang("정지된 이미지로 보기")}
                  </label>
                </div>
              </div>
            </div>
          )}
        </div>

        <div className="button_box">
          <button
            className="cancel"
            onClick={() => {
              navigate(-1);
            }}
          >
            {lang.getLang("취소")}
          </button>
          <button
            className="save"
            onClick={() => {
              configInfo.onUpdateSetting(settingData);
            }}
          >
            {lang.getLang("적용")}
          </button>
        </div>
      </div>
    </Styles.Container>
  );
}
