import Styles from "./styles";

// img
import GifProcessGreen from "resources/image/gif/process_green_crop.gif";
import PngProcessGreen from "resources/image/gif/process_green_crop.png";
import GifProcessOrange from "resources/image/gif/process_orange_crop.gif";
import PngProcessOrange from "resources/image/gif/process_orange_crop.png";
import GifProcessYellow from "resources/image/gif/process_yellow_crop.gif";
import PngProcessYellow from "resources/image/gif/process_yellow_crop.png";
import GifProcessRed from "resources/image/gif/process_red_crop.gif";
import PngProcessRed from "resources/image/gif/process_red_crop.png";
import GifProcessPurple from "resources/image/gif/process_purple_crop.gif";
import PngProcessPurple from "resources/image/gif/process_purple_crop.png";
import { useConfig } from "hooks/useConfig";
import { getCookie } from "service/connector";

export default function LesionAnimationContainer({ big, small, type, score, viewPort, lite_type, screen }) {

  const configInfo = useConfig();
  const noAni = !configInfo.configData.isGIF;
  
  const renderGif = (type) => {
    const scope = getCookie('scope')

    // const size = big ? "abnormality" : "ab-\nnormality"

    if (scope === 'lite' && screen === 'abnormality') {
      if (type === 'red' || type === 'orange' || type === 'purple') {
        return (
          <>
            <div className="animationContainer">
              <p className="gifTitle">abnormality</p>
            </div>
            <div className="gifWrap">
              <img src={noAni ? PngProcessRed : GifProcessRed} alt="gif" />
            </div>
          </>
        );  
      } else if (type === "yellow") {
        return (
            <>
              <div className="animationContainer">
                <p className="gifTitle">
                  abnormality
                </p>
              </div>

              <div className="gifWrap">
                <img
                  src={noAni ? PngProcessYellow : GifProcessYellow}
                  alt="gif"
                />
              </div>
            </>
          );
      } else if (type === "green") {
        return (
            <>
              <div className="animationContainer">
                <p className="gifTitle">
                  abnormality
                </p>
              </div>

              <div className="gifWrap">
                <img src={noAni ? PngProcessGreen : GifProcessGreen} alt="gif" />
              </div>
            </>
          );
      }
      
    }
    
    switch (type) {
      // cancer
      case "red":
        return (
          <>
            <div className="animationContainer">
              <p className="gifTitle">Cancer</p>
            </div>

            <div className="gifWrap">
              <img src={noAni ? PngProcessRed : GifProcessRed} alt="gif" />
            </div>
          </>
        );
      // adenoma
      case "orange":
        return (
          <>
            <div className="animationContainer">
              <p className="gifTitle">Adenoma</p>
            </div>

            <div className="gifWrap">
              <img
                src={noAni ? PngProcessOrange : GifProcessOrange}
                alt="gif"
              />
            </div>
          </>
        );
      // polyp
      case "purple":
        return (
          <>
            <div className="animationContainer">
              <p className="gifTitle">NON NEOPLASM</p>
            </div>

            <div className="gifWrap">
              <img
                src={noAni ? PngProcessGreen : GifProcessGreen}
                alt="gif"
              />
            </div>
          </>
        );
      case "polyp":
        return (
          <>
            <div className="animationContainer">
              <p className="gifTitle">NON NEOPLASM</p>
            </div>

            <div className="gifWrap">
              <img
                src={noAni ? PngProcessGreen : GifProcessGreen}
                alt="gif"
              />
            </div>
          </>
        );

      // im
      case "yellow":
        if (scope === 'lite' && screen === 'heatmap') {
          return (
            <>
              <div className="animationContainer">
                <p className="gifTitle">
                  Heatmap
                </p>
              </div>

              <div className="gifWrap">
                <img
                  src={noAni ? PngProcessYellow : GifProcessYellow}
                  alt="gif"
                />
              </div>
            </>
          );
        }
        return (
          <>
            <div className="animationContainer">
              <p className="gifTitle">
                Intestinal <br />
                Metaplasia
              </p>
            </div>

            <div className="gifWrap">
              <img
                src={noAni ? PngProcessYellow : GifProcessYellow}
                alt="gif"
              />
            </div>
          </>
        );
      // im
      case "green":
      default:
        if (scope === 'lite' && screen === 'heatmap') {
          return (
            <>
              <div className="animationContainer">
                <p className="gifTitle">
                  Heatmap
                </p>
              </div>

              <div className="gifWrap">
                <img src={noAni ? PngProcessGreen : GifProcessGreen} alt="gif" />
              </div>
            </>
          );
        }
        return (
          <>
            <div className="animationContainer">
              <p className="gifTitle">
                Intestinal <br />
                Metaplasia
              </p>
            </div>

            <div className="gifWrap">
              <img src={noAni ? PngProcessGreen : GifProcessGreen} alt="gif" />
            </div>
          </>
        );
    }
  };
  return (
    <Styles.Container className={big ? "big" : small ? "small" : ""} viewPort={viewPort}>
      {renderGif(type)}
    </Styles.Container>
  );
}
