import styled from "styled-components";

// img
import IconCheckBlue from "resources/image/icon/icon_check_blue_02.svg";

// const Container = styled.div`
//   position: relative;
//   min-height: 100vh;
//   /* padding: calc(100vw * (38 / 1920)) calc(100vw * (50 / 1920)) calc(100vw * (42 / 1920)); */
//   padding: calc(100vw * (38 / 1920)) calc(100vw * (${(props) => {
//     if (props.paddingSize === "small") {
//       return 350
//     } else if (props.paddingSize === "midium") {
//       return 200
//     } else {
//       return 50
//     }
  
//     return 50
//   }} / 1920)) calc(100vw * (42 / 1920));
//   background-color: #fff;

//   & img {
//     width: 100%;
//     height: 100%;
//     object-fit: fill;
//   }

//   & .picturesSection {
//     & .titleContainer {
//       margin-bottom: calc(100vw * (50 / 1920));
//     }

//     & .checkListWrap {
//       display: flex;
//       align-items: center;
//       /* gap: calc(100vw * (157 / 1920)); */
//       gap: calc(100vw * (${(props) => {
//         if (props.paddingSize === "small") {
//           return 50
//         } else if (props.paddingSize === "midium") {
//           return 100
//         } else {
//           return 157
//         }
//       }} / 1920));
//       padding: 0 calc(100vw * (91 / 1920));
//       /* justify-content: space-around; */

//       & .checkList {
//         & input[type="checkbox"] {
//           display: none;

//           &:checked + label {
//             & .checkContainer::after {
//               position: absolute;
//               top: calc(100vw * (-9 / 1920));
//               right: calc(100vw * (-17 / 1920));
//               width: calc(100vw * (62 / 1920));
//               height: calc(100vw * (46 / 1920));
//               background: url(${IconCheckBlue}) no-repeat center / cover;
//               content: "";
//             }

//             & .checkListtext {
//               color: #0075ff;
//               font-weight: bold;
//             }
//           }
//         }

//         & label {
//           display: flex;
//           align-items: center;
//           cursor: pointer;

//           & .checkContainer {
//             position: relative;
//             display: inline-block;
//             min-width: calc(100vw * (51 / 1920));
//             min-height: calc(100vw * (51 / 1920));
//             margin-right: calc(100vw * (9 / 1920));
//             border: calc(100vw * (2 / 1920)) solid #b8b8b8;
//           }

//           & .checkListtext {
//             font-size: calc(100vw * (31 / 1920));
//             color: #303943;
//           }
//         }
//       }
//     }
//   }

//   & .section {
//     margin-top: calc(100vw * (152 / 1920));
//   }

//   & .buttonListWrap {
//     display: flex;
//     justify-content: flex-end;
//     gap: calc(100vw * (13 / 1920));
//     margin-top: calc(100vw * (148 / 1920));

//     & button {
//       width: calc(100vw * (140 / 1920));
//       height: calc(100vw * (44 / 1920));
//       font-size: calc(100vw * (24 / 1920));
//       font-weight: bold;
//       border-radius: calc(100vw * (10 / 1920));
//     }

//     & .cancelButton {
//       background-color: rgba(0, 0, 0, 0.4);
//     }

//     & .saveButton {
//       background-color: rgba(0, 0, 0, 0.7);
//     }

//     & .viewButton {
//       background-color: #000;
//     }
//   }
// `;

const Container = styled.div`
  position: relative;
  min-height: ${(props)=>props.viewPort}vh;
  /* padding: calc(${(props) => props.viewPort}vw * (38 / 1920)) calc(${(props) => props.viewPort}vw * (50 / 1920)) calc(${(props) => props.viewPort}vw * (42 / 1920)); */
   //   padding: calc(100vw * (38 / 1920)) calc(100vw * (50 / 1920)) calc(100vw * (42 / 1920));
   padding: calc(100vw * (38 / 1920)) calc(100vw * (${(props) => {
     if (props.paddingSize === "small") {
       return 350
     } else if (props.paddingSize === "midium") {
       return 200
     } else {
       return 50
     }
  
//     return 50
  }} / 1920)) calc(100vw * (42 / 1920));
  background-color: #fff;

  & img {
    width: 100%;
    height: 100%;
    object-fit: fill;
  }

  & .picturesSection {
    & .titleContainer {
      margin-bottom: calc(${(props)=>props.viewPort}vw * (50 / 1920));
    }

    & .checkListWrap {
      display: flex;
      align-items: center;
      gap: calc(${(props)=>props.viewPort}vw * (157 / 1920));
      padding: 0 calc(${(props)=>props.viewPort}vw * (91 / 1920));
      /* justify-content: space-around; */

      & .checkList {
        & input[type="checkbox"] {
          display: none;

          &:checked + label {
            & .checkContainer::after {
              position: absolute;
              top: calc(${(props)=>props.viewPort}vw * (-9 / 1920));
              right: calc(${(props)=>props.viewPort}vw * (-17 / 1920));
              width: calc(${(props)=>props.viewPort}vw * (62 / 1920));
              height: calc(${(props)=>props.viewPort}vw * (46 / 1920));
              background: url(${IconCheckBlue}) no-repeat center / cover;
              content: "";
            }

            & .checkListtext {
              color: #0075ff;
              font-weight: bold;
            }
          }
        }

        & label {
          display: flex;
          align-items: center;
          cursor: pointer;

          & .checkContainer {
            position: relative;
            display: inline-block;
            width: calc(${(props)=>props.viewPort}vw * (51 / 1920));
            height: calc(${(props)=>props.viewPort}vw * (51 / 1920));
            margin-right: calc(${(props)=>props.viewPort}vw * (9 / 1920));
            border: calc(${(props)=>props.viewPort}vw * (2 / 1920)) solid #b8b8b8;
          }

          & .checkListtext {
            font-size: calc(${(props)=>props.viewPort}vw * (31 / 1920));
            color: #303943;
          }
        }
      }
    }
  }

  & .section {
    margin-top: calc(${(props)=>props.viewPort}vw * (152 / 1920));
  }

  & .buttonListWrap {
    display: flex;
    justify-content: flex-end;
    gap: calc(100vw * (13 / 1920));
    margin-top: calc(100vw * (148 / 1920));

    & button {
      width: calc(100vw * (140 / 1920));
      height: calc(100vw * (44 / 1920));
      font-size: calc(100vw * (24 / 1920));
      font-weight: bold;
      border-radius: calc(100vw * (10 / 1920));
    }

    & .cancelButton {
      background-color: rgba(0, 0, 0, 0.4);
    }

    & .saveButton {
      background-color: rgba(0, 0, 0, 0.7);
    }

    & .viewButton {
      background-color: #000;
    }
  }
`;

export { Container };

export default { Container };
