import styled from "styled-components";

const Container = styled.div`
  position: relative;

  & .wrap {
    display: flex;
    width: 100%;

    & .leftContainer {
      flex-shrink: 0;
      margin-right: calc(${(props)=>props.viewPort}vw * (24 / 1920));

      & .numberIconContainer {
        display: flex;
        justify-content: center;
        align-items: center;
        margin-bottom: calc(${(props)=>props.viewPort}vw * (25 / 1920));

        & .landmarkName {
          margin-left: calc(${(props)=>props.viewPort}vw * (10 / 1920));
          font-size: calc(${(props)=>props.viewPort}vw * (30 / 1920));
          font-weight: 700;
          color: #ffffff;
        }
      }

      & .stomachWrap {
        width: calc(${(props)=>props.viewPort}vw * (230 / 1920));
        height: calc(${(props)=>props.viewPort}vw * (240 / 1920));
        margin-bottom: calc(${(props)=>props.viewPort}vw * (250 / 1920));

        & img {
          width: calc(${(props)=>props.viewPort}vw * (230 / 1920));
          height: calc(${(props)=>props.viewPort}vw * (220 / 1920));
        }
      }
    }

    & .rightContainer {
      display: flex;
      width: 100%;
    }

    & .historyContainer .sliderContainer .swiperContainer.sub {
      width: calc(100vw * (635 / 1920));

      & .swiper-button-prev {
        left: calc(100vw * (-25 / 1920));
      }

      & .swiper-button-next {
        left: auto;
        right: calc(100vw * (-25 / 1920));
      }
    }
  }

  & .buttonContainer {
    display: flex;
    justify-content: flex-end;
    gap: calc(100vw * (11 / 1920));

    margin: calc(${(props)=>props.viewPort}vw * ${props=>props.viewPort == 60 ? 110 : 130} / 1920) calc(${(props)=>props.viewPort}vw * (12 / 1920)) 0 auto;
    & button {
      width: calc(100vw * (200 / 1920));
      height: calc(100vw * (48 / 1920));
      font-size: calc(100vw * (26 / 1920));
      font-weight: 700;
      border-radius: calc(100vw * (11 / 1920));
    }

    & .submitButton {
      background: #0075ff;
    }

    & .cancelButton {
      background: #848b99;
    }
  }

`;

export { Container };

export default { Container };
