import Styles from "./styles";
import { common } from "components";
import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import { useModals, useLanguage } from "hooks";
import { useParams } from "react-router-dom";
import { useQuery, useQueryClient } from "react-query";
import { checkKoreanLang } from "utils/etc";

import { api } from "service";
import { calcAge } from "utils/date";

function PatientDetailPage() {
  const navigate = useNavigate();
  const modalOption = useModals();
  const lang = useLanguage("Patient");
  const { id } = useParams();
  const [data, setData] = useState({});
  const [inputMode, setInputMode] = useState(null);
  const resetInputMode = () => setInputMode(null);

  const queryClient = useQueryClient();

  useQuery(["patients_info", id], () => api.patient.getInfo(id), {
    onSuccess: (res) => {
      let e = JSON.parse(JSON.stringify(res));
      setData(e);
    },
    refetchOnWindowFocus: false,
  });

  const { data: settingsData } = useQuery(
    ["settings"],
    api.setting.getSetting,
    {
      refetchOnWindowFocus: false,
    }
  );

  const onUpdatePatient = async () => {
    if (!data) return;
    resetInputMode();
    const { name, birthday, sex, phone, memo } = { ...data };

    const updatePatientData = {
      name,
      birthday,
      sex: sex.toLowerCase(),
      phone,
      memo,
    };

    try {
      await api.patient.updatePatients(id, updatePatientData);
      queryClient.invalidateQueries(["patients_info", id]);
    } catch (errorRes) {
      switch (errorRes.error) {
        case "invalid birthday":
          alert("올바른 생년월일을 입력해주세요.");
          return;
        case "invalid sex":
          alert("성별을 정확하게 입력해주세요.");
          return;
        case "invalid phone":
          alert("핸드폰 번호는 -를 포함하여 전체로 입력해주세요.");
          return;
        default:
          alert("환자 정보 업데이트에 실패했습니다.");
          return;
      }
    }
  };

  const onCreateRecords = async () => {
    if (id == null) return;

    let res = await api.patient.recordsPatients(id);

    if (res) {
      console.log(res);
      navigate("/patient");
    } else {
      alert("검진 등록에 실패했습니다.");
    }
  };

  const patientData = {
    name: data?.name,
    infoListData: [
      {
        title: "환자 ID",
        info: data?.INDEX,
      },
      {
        title: "생년월일",
        info:
          inputMode === "birthday" ? (
            <input
              value={data?.birthday}
              onChange={(event) => {
                setData((prev) => ({
                  ...prev,
                  birthday: event.target.value,
                }));
              }}
              onBlur={onUpdatePatient}
              autoFocus
            />
          ) : (
            <span
              onClick={() => {
                setInputMode("birthday");
              }}
            >
              {data?.birthday}
            </span>
          ),
      },
      {
        title: "성별",
        info:
          inputMode === "sex" ? (
            <input
              value={data?.sex?.toUpperCase()}
              onChange={(event) => {
                setData((prev) => ({
                  ...prev,
                  sex: event.target.value,
                }));
              }}
              onBlur={onUpdatePatient}
              autoFocus
            />
          ) : (
            <span
              onClick={() => {
                setInputMode("sex");
              }}
            >
              {data?.sex?.toUpperCase()}
            </span>
          ),
      },
      {
        title: "나이",
        info: (data?.birthday ? calcAge(data?.birthday) : "00") + "세",
      },
      {
        title: "휴대폰 번호",
        info:
          inputMode === "phone" ? (
            <input
              value={data?.phone}
              onChange={(event) => {
                setData((prev) => ({
                  ...prev,
                  phone: event.target.value,
                }));
              }}
              onBlur={onUpdatePatient}
              autoFocus
            />
          ) : (
            <span
              onClick={() => {
                setInputMode("phone");
              }}
            >
              {data?.phone}
            </span>
          ),
      },
      {
        title: "접수 메모",
        info: (
          <textarea
            value={data?.memo}
            onBlur={() => onUpdatePatient()}
            onChange={(e) => {
              setData((prev) => ({
                ...prev,
                memo: e.target.value,
              }));
            }}
            placeholder="*  메모를 입력해주세요."
          ></textarea>
        ),
      },
    ],
  };

  return (
    <>
      <common.Header title={"PATIENTS"} home url={"/"} />
      <Styles.Container>
        <div className="wrap">
          <div className="leftContainer">
            <p className="patientName">{patientData.name}</p>

            <ul className="infoListWrap">
              {patientData.infoListData?.map((item, index) => {
                return (
                  <li className="infoList" key={"infoList" + index}>
                    <p className="infoTitle">{lang.getLang(item.title)}</p>

                    <div className="info">{item.info}</div>
                  </li>
                );
              })}
            </ul>
          </div>

          <div className="rightContainer">
            <h2 className="resultTitle">{lang.getLang("검진 기록")}</h2>

            <ul className="listWrap">
              <li className="list titleBox">
                <div className="title number">{lang.getLang("검진 번호")}</div>
                <div className="title date">{lang.getLang("검진 일자")}</div>
                <div className="title result">{lang.getLang("검진 결과")}</div>
              </li>

              {data?.studies?.map((item, index) => {
                return (
                  <li
                    className="list"
                    key={"list" + index}
                    onClick={(e) => {
                      if (e.target.className == "moreButton") return;
                      if (!item.diagnosis) return;
                      !!settingsData?.stomachView
                        ? navigate(
                            `/image/stomach/${data.INDEX}/${item.studyId}`
                          )
                        : navigate(
                            `/image/detail/${data.INDEX}/${item.studyId}`
                          );
                    }}
                  >
                    <div className="box number">{item.studyId}</div>
                    <div className="box date">
                      {item.studyDate
                        ? item.studyDate.split(" ")[0].replaceAll("-", ".")
                        : ""}
                    </div>
                    <div className="box result">
                      <span>{item.diagnosis}</span>
                      {item.diagnosis && (
                        <button
                          type="button"
                          className="moreButton"
                          onClick={() => {
                            modalOption.setMoreResultModal((e) => {
                              return {
                                ...e,
                                show: true,
                                studyId: item.studyId,
                              };
                            });
                          }}
                        >
                          {lang.getLang("더보기")}
                        </button>
                      )}
                    </div>
                  </li>
                );
              })}
              {[1, 2, 3, 4, 5, 6, 7]?.map((item, index) => {
                if (index < data?.studies?.length) return;
                return <li className="list" key={"list" + index}></li>;
              })}
            </ul>

            <div className="bottomButtonContainer">
              <button
                type="button"
                className="cancelButton"
                onClick={() => {
                  navigate("/patient");
                }}
              >
                {lang.getLang("취소")}
              </button>

              <button
                type="button"
                className="submitButton"
                onClick={() => {
                  onCreateRecords();
                }}
              >
                {lang.getLang("검진 등록")}
              </button>
            </div>
          </div>
        </div>
      </Styles.Container>
    </>
  );
}

export default PatientDetailPage;
