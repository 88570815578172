import layout from "components/layout";
import common from "components/common";
import { useLanguage } from "hooks";
import { useState } from "react";

const StaticMiniStomachContainer = ({ landmark, setLandmark }) => {
  const lang = useLanguage("Report");

  const [isStomachHover, setIsStomachHover] = useState(false);
  const [hoverSection, setHoverSection] = useState(false);

  const stomachListData = [
    lang.getLang("식도"),
    lang.getLang("위식도 접합부"),
    lang.getLang("십이지장 2부"),
    lang.getLang("십이지장 구부"),
    lang.getLang("위 전정부"),
    lang.getLang("위 각부"),
    lang.getLang("위체부(하-중부)"),
    lang.getLang("위체부(중-상부)"),
    lang.getLang("위저부(반전)"),
    lang.getLang("위 분문부(반전)"),
    lang.getLang("위체부(반전)"),
  ];
  return (
    <div
      className="miniStomachContainer"
      onMouseOver={() => {
        setIsStomachHover(true);
      }}
      onMouseLeave={() => {
        setIsStomachHover(false);
      }}
      onClick={() => {
        setIsStomachHover(false);
      }}
    >
      <div className="stomachContainer">
        <layout.MiniStomach
          hoverSection={hoverSection}
          setHoverSection={setHoverSection}
          selectedSection={landmark}
          setSelectedSection={setLandmark}
          onClickStomachSection={({ landmark }) => {
            setLandmark(landmark);
          }}
        />
      </div>

      <div className="numberIconContainer">
        <common.NumberIcon
          number={
            isStomachHover && hoverSection !== "" ? hoverSection : landmark
          }
        />
        <p className="landmarkName">
          {isStomachHover && hoverSection !== ""
            ? stomachListData[hoverSection - 1]
            : stomachListData[landmark - 1]}
        </p>
      </div>
    </div>
  );
};

export default StaticMiniStomachContainer;
