import Styles from "./styles";
import { useLanguage } from "hooks";
import { ui } from "components";
import { useConfig } from "hooks/useConfig";

export default function ImgCheckContainer({
  withAutoPhotoCheck,
  stomachIconCheck,
  onChangeCheckRadio,
}) {
  const lang = useLanguage("Image");
  const configInfo = useConfig();

  const checkListData = [
    {
      id: "withAutoPhoto",
      checked: withAutoPhotoCheck,
      text: lang.getLang("자동 촬영 이미지 같이 보기"),
    },
    {
      id: "stomachCheck",
      checked: stomachIconCheck,
      text: lang.getLang("위 아이콘으로 보기"),
    },
  ];

  return (
    <Styles.Container>
      <ul className="checkboxListWrap">
        {checkListData
          .filter((el) =>
            configInfo.setting.isSimpleMode ? el.id !== "withAutoPhoto" : true
          )
          .map((item, index) => {
            return (
              <li className="checkboxList" key={"checkboxList" + index}>
                <ui.input.BasicCheckbox
                  id={item.id}
                  checked={item.checked}
                  onChange={(e) => {
                    onChangeCheckRadio({
                      checkId: item.id,
                      checked: e.target.checked,
                    });
                  }}
                  text={item.text}
                  onClick={item.onClick}
                />
              </li>
            );
          })}
      </ul>
    </Styles.Container>
  );
}
