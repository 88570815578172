import Styles from "./styles";
import PicturesTitle from "../PicturesTitle";
import { common, layout } from "components";
import { useLanguage, useModals } from "hooks";

// img
import { useParams } from "react-router-dom";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { api } from "service";
import {
  formatHeatmapWithPath,
  formatImageWithPath,
  formatNormalImageWithLandmark,
  sortHistoryObjectToArray,
  lesionTypeToColor,
} from "utils/common";
import moment from "moment";
import { useReport } from "hooks/useReport";
import { useState, useEffect } from "react";
import ChangeHistory from "./components/ChangeHistory";

export default function HistorySection(props) {
  const lang = useLanguage("Report");
  const modalOption = useModals();
  const { id, studyId } = useParams();

  const reportInfo = useReport();
  const [sectionType, setSectionType ] = useState("view"); // view, edit
  const [landmark, setLandmark] = useState(null);
  const [path, setPath] = useState(null);
  const [date, setDate] = useState(null);

  const [imgListData, setImgListData] = useState(null);

  useEffect(() => {
    if (reportInfo.reportData?.history) {
      setLandmark(reportInfo.reportData.history.landmark);
    }
  }, [reportInfo.reportData]);

  const { isLoading: isLoadingReportsHistory } = useQuery(
    ["reports-images", studyId, "history", landmark],
    () =>
      api.reports.getReportsImages({
        medicalstudyId: studyId,
        check: "history",
        landmark: landmark,
      }),
    {
      onSuccess(responseHistoryData) {
        console.log("responseHistoryData", responseHistoryData);

        const [recentlyImageData, prevImageData] =
          sortHistoryObjectToArray(responseHistoryData);

        // setLandmark(recentlyImageData?.content.landmark);
        // setPath(recentlyImageData?.content.path);
        // setDate(recentlyImageData?.date);

        //map
        const createRecentlyImageData = {
          img: formatImageWithPath(recentlyImageData?.content.path),
          text: moment(recentlyImageData?.date).isValid() ? moment(recentlyImageData?.date).format("YYYY.MM.DD") : lang.getLang("X"),
        };

        const presetImageListData = [createRecentlyImageData];
        if (prevImageData) {
          presetImageListData.push({
            img: formatImageWithPath(prevImageData?.content.path),
            text: moment(prevImageData?.date).format("YYYY.MM.DD"),
          });
        }

        if (presetImageListData.length === 1) {
          presetImageListData.push({
            img: formatImageWithPath("null"),
            text: lang.getLang("X"),
          });
        }

        const newimgListData = [
          ...presetImageListData,
          {
            img: formatNormalImageWithLandmark(recentlyImageData?.content.landmark),
            text: "Normal",
          },
        ];

        if (imgListData === null) {
          setImgListData(newimgListData);
          setLandmark(recentlyImageData?.content.landmark);
          setPath(recentlyImageData?.content.path);
          setDate(recentlyImageData?.date);

          reportInfo.setReportWriteData((prev) => ({
            ...prev,
            history: {
              landmark: recentlyImageData?.content.landmark,
              recentPath: recentlyImageData?.content.path,
              recentStudyDate: recentlyImageData?.date,
              prevPath: prevImageData?.content.path,
              prevStudyDate: prevImageData?.date,
            },
          }));
        }

      },
      refetchOnWindowFocus: false,
    }
  );

  const reportsChangeHistoryMutation = useMutation(
    ({ studyId, recentStudy, recentImageId, prevImageId }) =>
      api.reports.putReportsPriorityChangeHistory({
        studyId,
        recentStudy,
        recentImageId,
        prevImageId,
      }),
  );

  // data
  const stomachListData = [
    lang.getLang("식도"),
    lang.getLang("위식도 접합부"),
    lang.getLang("십이지장 2부"),
    lang.getLang("십이지장 구부"),
    lang.getLang("위 전정부"),
    lang.getLang("위 각부"),
    lang.getLang("위체부(하-중부)"),
    lang.getLang("위체부(중-상부)"),
    lang.getLang("위저부(반전)"),
    lang.getLang("위 분문부(반전)"),
    lang.getLang("위체부(반전)"),
  ];

  const notSelectedSection = [
    { landmark, type: lesionTypeToColor(1) },
  ];

  if (isLoadingReportsHistory) return <></>;

  return (
    <>
      <PicturesTitle
        colorTitle={"History"}
        subTitle={lang.getLang("같은 위치를 시간순으로 추적/관찰합니다.")}
        paddingSize={props.paddingSize}
        viewPort={props.viewPort}
      />

      {sectionType === "view" ?
      <Styles.Container 
        paddingSize={props.paddingSize}
        viewPort={props.viewPort}
      >
        <div className="wrap">
          <div className="leftContainer">
            <div className="numberIconContainer">
              <common.NumberIcon number={landmark} />
              <p className="landmarkName">{stomachListData[landmark]}</p>
            </div>

            <div className="stomachWrap">
              <layout.NoHoverStomachReport
                selectedSection={landmark}
                notSelectedSection={notSelectedSection}
                opacityBg
                noClickEvent
                screen={"abnormality"}
              />
            </div>
          </div>

          <div className="rightContainer">
            {imgListData?.map((item) => {
              const itemUrl = item.img.endsWith("null") ? null : item.img;
              return (
                <div className="imgContainer" key={"imgContainer" + item.img}>
                  <div className="imgWrap">
                    {itemUrl === null && (
                      <div className="noImageBox">
                        <span>
                          <p className="noImageBoxTitle"> 
                            {lang.getLang("과거 내시경 검진 기록이 없습니다.")}
                          </p>
                        </span>
                      </div>
                    )}
                    {itemUrl !== null && <img src={item.img} alt="stomach" />}
                  </div>

                  <p className="text">{item.text}</p>
                </div>
              );
            })}
          </div>
        </div>

        <button
          type="button"
          className="changeButton"
          onClick={() => {

            setSectionType("edit");
            // modalOption.setChangeHistoryModal((e) => {
            //   return {
            //     ...e,
            //     show: true,
            //     id,
            //     studyId,
            //     landmark,
            //     onClickChangeHistory({
            //       recentlySelected,
            //       pastSelected,
            //       recentDateInfo,
            //     }) {
            //       // console.log("mmmmmmmm :", recentDateInfo);

            //       reportsChangeHistoryMutation.mutate(
            //         {
            //           studyId: studyId,
            //           recentStudy: recentDateInfo,
            //           recentImageId: recentlySelected?.imageId
            //             ? recentlySelected?.imageId
            //             : pastSelected?.imageId,
            //           prevImageId: recentlySelected?.imageId
            //             ? pastSelected?.imageId || null
            //             : null,
            //         },
            //         {
            //           onSuccess(response) {
            //             reportInfo.setReportWriteData((prev) => ({
            //               ...prev,
            //               history: response,
            //             }));
            //           },
            //         }
            //       );
            //     },
            //   };
            // });
          }}
        >
          {lang.getLang("사진 변경")}
        </button>
      </Styles.Container>
      : 
      <Styles.EditContainer
        paddingSize={props.paddingSize}
        viewPort={props.viewPort}
      >
        <div className="editContainer">
          <ChangeHistory
            viewPort={props.viewPort}
            paddingSize={props.padding}
            studyId={studyId}
            landmark={landmark}
            onClickChangeHistory={({ selectedHistory }) => {

              console.log("selectedHistory ====> ", selectedHistory);

              const pastImageData = {
                img: formatImageWithPath(selectedHistory?.pastSelected.path),
                text: moment(selectedHistory?.pastSelected.historyDate).format("YYYY.MM.DD"),
              };

              const recentImageData = {
                img: formatImageWithPath(selectedHistory?.recentSelected.path),
                text: moment(selectedHistory?.recentDateInfo).format("YYYY.MM.DD"),
              };

              const normalImageData = {
                img: formatNormalImageWithLandmark(landmark),
                text: "Normal",
              };

              const newImgListData = [
                pastImageData,
                recentImageData,
                normalImageData,
              ];

              setImgListData(newImgListData);

              reportInfo.setReportWriteData((prev) => ({
                ...prev,
                history: {
                  landmark: landmark,
                  recentPath: selectedHistory?.recentSelected.path,
                  recentStudyDate: selectedHistory?.recentDateInfo,
                  prevPath: selectedHistory?.pastSelected.path,
                  prevStudyDate: selectedHistory?.pastSelected.historyDate,
                },
              }));


              setSectionType("view");
            }}
            onCancel={
              () => {
                setSectionType("view");
              } 
            }
          />
        </div>
      </Styles.EditContainer>
      }
    </>
  );
}
