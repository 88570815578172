import { useState, useEffect, useRef } from "react";
import { useNavigate, Link, useLocation, useParams } from "react-router-dom";
import Styles from "./styles";
import { useAuth } from "hooks/useAuth";
import { useConfig } from "hooks/useConfig";
import { useReport } from "hooks/useReport";

// img
import IconHome from "resources/image/icon/icon_home.svg";
import IconList from "resources/image/icon/icon_list.svg";
import IconSetting from "resources/image/icon/icon_setting.svg";
import IconBack03 from "resources/image/icon/icon_back_03.svg";
import IconReload from "resources/image/icon/icon_reload.svg";

import { useQuery } from "react-query";
import { api } from "service";
import moment from "moment";
import useUserData from "hooks/useUserData";
import { useLanguage } from "hooks";

export default function SubHeader() {
  const { id, studyId } = useParams();
  const configInfo = useConfig();
  const navigate = useNavigate();
  const location = useLocation();
  const ref = useRef();
  const [isLoad, setIsLoad] = useState("N");
  const { userData } = useUserData();
  const lang = useLanguage("Common");
  
  const reportInfo = useReport();

  useEffect(() => {
    if (isLoad == "N") setIsLoad("Y");
  }, []);

  useEffect(() => {
    if (isLoad == "N") return;

    if (userData?.auth != 1 && userData?.auth != 2)
      alert("페이지 접근 권한이 없습니다.");

    if (userData?.auth == -1) navigate("/login");
    else if (userData.auth == 0) navigate("/admin");
  }, [userData]);

  const [isReportModalOpen, setIsReportModalOpen] = useState(false);

  const { data: patientsData } = useQuery(
    ["patient_info"],
    () => api.patient.getInfo(id),
    {
      refetchOnWindowFocus: false,
    }
  );
  const { data: settingsData } = useQuery(
    ["settings"],
    api.setting.getSetting,
    {
      refetchOnWindowFocus: false,
    }
  );

  // map
  const tabListData = [
    {
      text: "Image",
      url: "image",
      path: !!settingsData?.stomachView
        ? `/stomach/${id}/${studyId}`
        : `/detail/${id}/${studyId}`,
    },
    {
      text: "History",
      url: "history",
      path: `/${id}/${studyId}/${settingsData?.startLandmark || 1}`,
    },
    {
      text: "Abnormality",
      url: "lesion",
      path: `/${id}/${studyId}/landmark`,
    },
  ];

  const reportListData = patientsData?.studies;

  // 외부 영역 클릭 시 닫기
  const handleClickOutSide = (e) => {
    if (isReportModalOpen && !ref?.current?.contains(e.target)) {
      setIsReportModalOpen(false);
    }
  };

  useEffect(() => {
    if (isReportModalOpen) {
      document.addEventListener("click", handleClickOutSide);
      return () => {
        document.removeEventListener("click", handleClickOutSide);
      };
    }
  }, [isReportModalOpen]);

  return (
    <Styles.Container>
      <div className="subHeaderWrap">
        <div className="leftButtonContainer">
          <button
            type="button"
            className="backButton"
            onClick={() => {
              navigate(-1);
            }}
          >
            <img src={IconBack03} alt="back" />
          </button>

          <button
            type="button"
            className="reloadButton"
            onClick={() => {
              // setKey(key + 1);
              window.location.reload();
            }}
          >
            <img src={IconReload} alt="reload" />
          </button>

          <button
            type="button"
            className="homeButton"
            onClick={() => {
              if (userData.auth == 1) navigate("/checkup/new");
              else if (userData.auth == 2) navigate("/patient");
            }}
          >
            <img src={IconHome} alt="home" />
          </button>
        </div>

        <ul className="tabListWrap">
          {tabListData
            .filter((el) =>
              configInfo.setting.isSimpleMode ? el.text !== "Lesion" : true
            )
            .map((item, index) => {
              return (
                <li className="tabList" key={"tabList" + index}>
                  <Link
                    to={`/${item.url}` + `${item.path ? item.path : ""}`}
                    reloadDocument={false}
                    className={location.pathname.includes(item.url) ? "on" : ""}
                  >
                    {item.text}
                  </Link>
                </li>
              );
            })}
        </ul>

        <div className="rightButtonContainer">
          <div className="listButton" ref={ref}>
            <button
              type="button"
              className="button"
              onClick={() => {
                setIsReportModalOpen(!isReportModalOpen);
              }}
            >
              <img src={IconList} alt="list" />
            </button>

            {isReportModalOpen && (
              <div className="reportModal">
                <button
                  type="button"
                  className="newReportButton"
                  onClick={() => {
                    reportInfo.clearReportData();
                    navigate(`/report/${id}/${studyId}`);
                  }}
                >
                  {lang.getLangString("신규 Report 작성")}
                </button>

                <ul className="reportModalListWrap">
                  {reportListData?.map(({ studyId, studyDate }) => {
                    return (
                      <li
                        className="reportModalList"
                        key={"reportModalList" + studyId}
                        onClick={() => {
                          reportInfo.clearReportData();
                          navigate(`/report/${id}/${studyId}`);
                        }}
                      >
                        {moment(studyDate).format("YYYY-MM-DD")}
                      </li>
                    );
                  })}
                </ul>
              </div>
            )}
          </div>

          <button
            type="button"
            className="button settingButton"
            onClick={() => {
              navigate("/settings");
            }}
          >
            <img src={IconSetting} alt="setting" />
          </button>
        </div>
      </div>
    </Styles.Container>
  );
}
