const { useAtom } = require("jotai");
const { tempReportAtom } = require("store/atoms");

export const useTempReport = () => {
  const [tempReportData, setTempReportData] = useAtom(tempReportAtom);

  const updateTempReportData = (data) => {
    setTempReportData(data);
  }

  return { tempReportData, updateTempReportData };
};
