import StateButton from "components/ui/button/StateButton";
import Styles from "./styles";
import { useNavigate } from "react-router-dom";
import { useLanguage, useModals } from "hooks";
import { api } from "service";
import { useQuery } from "react-query";
import React, { useEffect, useMemo, useCallback, useState } from "react";
import { 
  flexRender,
  createColumnHelper,
  getCoreRowModel,
  getPaginationRowModel,
  useReactTable,
} from "@tanstack/react-table";
import { set } from "lodash";
import { setSelectionRange } from "@testing-library/user-event/dist/utils";

export default function TableCheckup({
  list,
  modalClose,
  searchCheckupModal,
  isScroll,
  onScroll,
  onDeleteFile,
}) {
  const navigate = useNavigate();
  const modalOption = useModals();
  const lang = useLanguage("Checkup");

  const { data: settingsData } = useQuery(
    ["settings"],
    () => api.setting.getSetting(),
    {
      refetchOnWindowFocus: false,
    }
  );

  const [patientList, setPatientList] = useState([]);
  const [deleteUploadedFile, setDeleteUploadedFile] = useState({});
  const [checkupDelete, setCheckupDelete] = useState({
    patientId: undefined,
    studyId: undefined,
  });
  const [rowSelection, setRowSelection] = useState({}) //manage your own row selection state
  useEffect(() => {
    setPatientList(list);
  }, [list]);


  // x 버튼 클릭시 호출
  const handleDeleteUploadedFile = (e, row) => {
    setDeleteUploadedFile(row.cell.row.original.studyId);
  };

  const columnHelper = createColumnHelper();

  const columnDef = [
    columnHelper.accessor("patientIDInPACS", {
      header: "ID",
      size: 120,
    }),
    columnHelper.accessor("patientName", {
      header: "Name",
      size: 120,
      cell: (cell) => {
        const limit = 6;
        return (
          <div>
            {cell.row.original.patientName.length > limit ? (
              <div>
                <div className="text">
                {cell.row.original.patientName.substring(0, limit)}...
                </div>
                <div className="hoverPatientName">
                  {cell.row.original.patientName}
                </div>
              </div>
            ) : (
              cell.row.original.patientName
            )}
          </div>
        );
      }
    }),
    columnHelper.accessor("patientSex", {
      header: "Sex",
      size: 50,
      cell: (cell) => {
        return cell.row.original?.patientSex ? cell.row.original.patientSex.toUpperCase() : "N";
      },
    }),
    columnHelper.accessor("patientBirthdate", {
      header: "Birth Date",
      size: 120,
      cell: (cell) => {
        return cell.row.original.patientBirthdate ? cell.row.original.patientBirthdate : "0000-00-00";
      }
    }),
    columnHelper.accessor("studyDate", {
      header: "Exam Date",
      size: 120,
    }),
    columnHelper.accessor("studyDescription", {
      header: "Description",
      size: 300,
      cell: (cell) => {
        const limit = 20;
        return (
          <div>
            {cell.row.original.studyDescription.length > limit ? (
              <div>
                <div className="text">
                  {cell.row.original.studyDescription.substring(0, limit)}...
                </div>
                <div className="hoverModal">
                  {cell.row.original.studyDescription}
                </div>
              </div>
            ) : (
              cell.row.original.studyDescription
            )}
          </div>
        );
      }
    }),
    columnHelper.accessor("status", {
      header: "Status",
      size: 100,
      cell: (cell) => {
        return (
          <StateButton type={cell.row.original.status} callback={(e) => handleDeleteUploadedFile(e, cell)} />
        );
      }
    }),
  ];

  useEffect(() => {
    if (deleteUploadedFile !== undefined || checkupDelete?.patientId !== undefined || checkupDelete?.studyId !== undefined) {
      // TableRow 클릭 시 삭제 버튼 클릭 여부 체크 결과 삭제 버튼 클릭한 경우
      if (deleteUploadedFile === checkupDelete.studyId) {

        // let res = modalOption.setUploadFileDeleteModal((e) => {
        //   e.show = true;
        //   e.studyId = checkupDelete.studyId;
        //   return { ...e };
        // });
        modalOption.setUploadFileDeleteModal((e) => ({
          ...e,
          show: true,
          studyId: checkupDelete.studyId,
          onModalClose() {
            setDeleteUploadedFile(undefined);
            setCheckupDelete({
              patientId: undefined,
              studyId: undefined,
            });
            onDeleteFile(checkupDelete.studyId);
          },
          onModalCancel() {
            setDeleteUploadedFile(undefined);
            setCheckupDelete({
              patientId: undefined,
              studyId: undefined,
            });
          }
        }));

      } else {
        // TableRow 클릭 시 삭제 버튼 클릭 여부 체크 결과 Row 만 클릭한 경우
        if (checkupDelete?.patientId !== undefined && checkupDelete?.studyId !== undefined) {
          if (!!settingsData?.stomachView) {
            navigate(
              `/image/stomach/${checkupDelete?.patientId}/${checkupDelete?.studyId}`
            );
          } else {
            navigate(
              `/image/detail/${checkupDelete?.patientId}/${checkupDelete?.studyId}`
            );
          }
        }
      };
    }
  }, [deleteUploadedFile, checkupDelete]);

  const table = useReactTable({
    data: patientList,
    columns: columnDef,
    enableMultiRowSelection: false, //only allow a single row to be selected at once
    enableRowSelection: row => row.original.status >= 0,
    getCoreRowModel: getCoreRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    manualPagination: false, //turn off client-side pagination
    rowCount: patientList.length,
  });


  return (
    <Styles.Container>
      { patientList && patientList.length > 0 ? (
        <div className="study">
          <table>
            <thead>
              {table.getHeaderGroups().map(headerGroup => (
                <tr key={headerGroup.id}>
                  {headerGroup.headers.map(header => (
                    <th key={header.id}>
                      {header.isPlaceholder
                        ? null
                        : flexRender(
                            header.column.columnDef.header,
                            header.getContext()
                          )}
                    </th>
                  ))}
                </tr>
              ))}
            </thead>
            <tbody>
              {table.getCoreRowModel().rows.map(row => (
                <tr key={row.id}>
                  {row.getVisibleCells().map(cell => (
                    <td key={cell.id} onClick={() => {

                        setRowSelection(row.index); //set the selected row index

                        if (row.original.status === 1 || row.original.status === 0) {
                          if (!!settingsData?.stomachView) {
                            navigate(
                              `/image/stomach/${row.original.patientId}/${row.original.studyId}`
                            );
                          } else {
                            navigate(
                              `/image/detail/${row.original.patientId}/${row.original.studyId}`
                            );
                          }
                        }

                        if (row.original.status === 4 ) {

                          if (cell.column.columnDef.header != "Status") {
                            if (!!settingsData?.stomachView) {
                              navigate(
                                `/image/stomach/${row.original.patientId}/${row.original.studyId}`
                              );
                            } else {
                              navigate(
                                `/image/detail/${row.original.patientId}/${row.original.studyId}`
                              );
                            }
                          } else {
                            // 이미지 업로드 완료된 환자 클릭시
                            setCheckupDelete({
                              patientId: row.original.patientId,
                              studyId: row.original.studyId,
                            });
                          }
                        }

                        if (row.original.status === 2) {
                          // 내시경 결과가 없는 환자 클릭시
                          modalOption.setDefaultModal((e) => {
                            e.show = true;
                            e.title = (
                              <>
                                {lang.getLang("내시경 검진 대기중인 환자입니다.")}
                                <br />
                                {lang.getLang(
                                  "내시경 검진을 진행 후 클릭해주세요."
                                )}
                              </>
                            );
                            e.button = <>{lang.getLang("확인")}</>;
                            e.buttonClickEvent = false;
                            return { ...e };
                          });
                        }

                        if (row.original.status === 3) {
                          // 대기중인 환자 클릭시
                          modalOption.setDefaultModal((e) => {
                            e.show = true;
                            e.title = (
                              <>
                                {lang.getLang("내시경 검진 대기중인 환자입니다.")}
                                <br />
                                {lang.getLang(
                                  "내시경 검진을 진행 후 클릭해주세요."
                                )}
                              </>
                            );
                            e.button = <>{lang.getLang("확인")}</>;
                            e.buttonClickEvent = false;
                            return { ...e };
                          });
                        }
                    }}>
                      {flexRender(
                        cell.column.columnDef.cell,
                        cell.getContext()
                      )}
                    </td>
                  ))}
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      ) : null}
    </Styles.Container>
  );
}
