import styled from "styled-components";

// img
import IconBack from "resources/image/icon/icon_back.svg";

const Container = styled.div`
  width: calc(100vw*(1856/1920)); height: calc(100vw*(82/1920));
`;

export { Container };

export default { Container };
