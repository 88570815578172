import { useState, useEffect } from "react";
import { useNavigate, Link } from "react-router-dom";
import Styles from "./styles";
import { useAuth } from "hooks/useAuth";
import { useReport } from "hooks/useReport";

// img
import Logo from "resources/image/icon/logo.svg";
import IconBack from "resources/image/icon/icon_back_02.svg";

export default function ReportHeader({ title }) {
  const navigate = useNavigate();
  const authInfo = useAuth();
  const reportInfo = useReport();

  return (
    <Styles.Container>
      <button
        type="button"
        className="backButton"
        onClick={() => {
          reportInfo.setReportWriteData((prev) => (
            {
              ...prev,
              comment: prev.comment_new,
            }
          ));

          navigate(-1);
        }}
      >
        <span className="iconWrap">
          <img src={IconBack} alt="home" />
        </span>
        Back
      </button>

      <p className="title">{title}</p>

      <div className="logoWrap">
        <img src={Logo} alt="logo" />
      </div>
    </Styles.Container>
  );
}
