import { useAtom } from "jotai";
import atoms from "store/atoms";
import React, { useState, useEffect } from "react";
import languageStore from "store/language";

export default function useLanguage(page) {
  const language = languageStore[page];
  const [country, setLanguageAtom] = useAtom(atoms.languageAtom);


  useEffect(()=>{
    let lang = localStorage.getItem("lang");
    if(lang && lang == "_en"){
      setCountry("_en");
    }else{
      setCountry("");
    }
  },[])

  function getLang(text) {
    try {
      let resultText = language[text][country] ? language[text][country] : text;
      let splitStr = resultText.split("<br/>");
      let splitLength = splitStr.length;
      return splitStr.map((v, i) => {
        return (
          <React.Fragment key={i}>
            {v}
            {splitLength != i + 1 ? <br /> : null}
          </React.Fragment>
        );
      });
    } catch (e) {
      return text;
    }
  }
  function getLangString(text) {
    try {
      let resultText = language[text][country] ? language[text][country] : text;
      return resultText;
    } catch (e) {
      return text;
    }
  }

  function setCountry(_country) {
    setLanguageAtom(_country);
  }

  return {
    getLang,
    setCountry,
    country,
    getLangString,
  };
}
