import React, { useState } from "react";
import Styles from "./styles";
import _stomach from "_stomach";

//img
import ImgCorpusBg from "resources/image/corpus_stomach_bg.png";
import ImgStomachBg from "resources/image/antrum_stomach_bg.png";
import ImgStomachBg02 from "resources/image/stomach_bg_02.png";
import ImgStomach01 from "resources/image/stomach_01.png";
import ImgStomach02 from "resources/image/stomach_02.png";
import ImgStomach03 from "resources/image/stomach_03.png";
import ImgStomach04 from "resources/image/stomach_04.png";
import ImgStomach05 from "resources/image/stomach_05.png";
import ImgStomach06 from "resources/image/stomach_06.png";
import ImgStomach07 from "resources/image/stomach_07.png";
import ImgStomach08 from "resources/image/stomach_08.png";
import ImgStomach09 from "resources/image/stomach_09.png";
import ImgStomach10 from "resources/image/stomach_10.png";
import ImgStomach11 from "resources/image/stomach_11.png";
import ImgStomachGreen01 from "resources/image/stomach_green/stomach_01.png";
import ImgStomachGreen02 from "resources/image/stomach_green/stomach_02.png";
import ImgStomachGreen03 from "resources/image/stomach_green/stomach_03.png";
import ImgStomachGreen04 from "resources/image/stomach_green/stomach_04.png";
import ImgStomachGreen05 from "resources/image/stomach_green/stomach_05.png";
import ImgStomachGreen06 from "resources/image/stomach_green/stomach_06.png";
import ImgStomachGreen07 from "resources/image/stomach_green/stomach_07.png";
import ImgStomachGreen08 from "resources/image/stomach_green/stomach_08.png";
import ImgStomachGreen09 from "resources/image/stomach_green/stomach_09.png";
import ImgStomachGreen10 from "resources/image/stomach_green/stomach_10.png";
import ImgStomachGreen11 from "resources/image/stomach_green/stomach_11.png";
import ImgStomachYellow01 from "resources/image/stomach_yellow/stomach_01.png";
import ImgStomachYellow02 from "resources/image/stomach_yellow/stomach_02.png";
import ImgStomachYellow03 from "resources/image/stomach_yellow/stomach_03.png";
import ImgStomachYellow04 from "resources/image/stomach_yellow/stomach_04.png";
import ImgStomachYellow05 from "resources/image/stomach_yellow/stomach_05.png";
import ImgStomachYellow06 from "resources/image/stomach_yellow/stomach_06.png";
import ImgStomachYellow07 from "resources/image/stomach_yellow/stomach_07.png";
import ImgStomachYellow08 from "resources/image/stomach_yellow/stomach_08.png";
import ImgStomachYellow09 from "resources/image/stomach_yellow/stomach_09.png";
import ImgStomachYellow10 from "resources/image/stomach_yellow/stomach_10.png";
import ImgStomachYellow11 from "resources/image/stomach_yellow/stomach_11.png";

export default function AnalysisStomach({
  selectedSection,
  score,
  type,
  imSensitivity,
}) {
  console.log(11, selectedSection, score, type);
  const [stomachData, setStomachData] = useState(_stomach.stomachImageList);
  const imageListData = [
    ImgStomach01,
    ImgStomach02,
    ImgStomach03,
    ImgStomach04,
    ImgStomach05,
    ImgStomach06,
    ImgStomach07,
    ImgStomach08,
    ImgStomach09,
    ImgStomach10,
    ImgStomach11,
  ];
  const greenImageListData = [
    ImgStomachGreen01,
    ImgStomachGreen02,
    ImgStomachGreen03,
    ImgStomachGreen04,
    ImgStomachGreen05,
    ImgStomachGreen06,
    ImgStomachGreen07,
    ImgStomachGreen08,
    ImgStomachGreen09,
    ImgStomachGreen10,
    ImgStomachGreen11,
  ];
  const yellowImageListData = [
    ImgStomachYellow01,
    ImgStomachYellow02,
    ImgStomachYellow03,
    ImgStomachYellow04,
    ImgStomachYellow05,
    ImgStomachYellow06,
    ImgStomachYellow07,
    ImgStomachYellow08,
    ImgStomachYellow09,
    ImgStomachYellow10,
    ImgStomachYellow11,
  ];

  const renderImg = (item, type) => {
    console.log(type);
    switch (type ? type : item.type) {
      case "green":
        return greenImageListData[item.id - 1];
      case "yellow":
        return yellowImageListData[item.id - 1];
      default:
        return imageListData[item.id - 1];
    }
  };

  return (
    <Styles.Container>
      <div className="stomachSection">
        {stomachData.map((item, index) => {
          let percent = selectedSection.stomachListData.filter(
            (v) => v.number == item.id
          )[0]?.percent;
          percent = percent ? percent : 0;
          console.log(1234, percent);
          // (결함) 페이지 출력 Default 오류
          return (
            <div className="stomachParts" key={"stomachParts" + index}>
              <div className="stomachSectionDiv">
                <Styles.StomachSectionImg
                  alt="section"
                  // src={renderImg(item, percent >= score ? "yellow" : "green")}
                  src={renderImg(
                    item,
                    percent >= 100 - imSensitivity ? "yellow" : "green"
                  )}
                  id={item.id}
                  polygon={item.polygon}
                  transformorigin={item.transformorigin}
                  isSelected={
                    selectedSection.selectedSection.includes(`${item.id}`) ||
                    selectedSection.selectedSection.includes(item.id)
                  }
                />
              </div>
            </div>
          );
        })}
      </div>
      <img
        className={"stomachBaseImg" + (selectedSection ? " on" : "")}
        src={type == "antrum" ? ImgStomachBg : ImgCorpusBg}
        alt="stomach"
      />
    </Styles.Container>
  );
}
