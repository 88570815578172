import { getCookie } from "service/connector";
import { useEffect, useState } from "react";

export default function LesionCheckList({
  checkListData,
  selectCheckList,
  setSelectCheckList,
}) {


  const [scope, setScope] = useState("lite");

  useEffect(() => {
    setScope(getCookie('scope'))
  }, [])
  return (
    <ul className="checkListWrap">
      {checkListData?.map((item, index) => {
        return (
          <li className="checkList" key={"checkList" + index}>
            <input
              type="radio"
              id={item.id}
              checked={selectCheckList === item.id}
              onChange={(e) => {
                setSelectCheckList(item.id);
              }}
              disabled={item.disabled}
            />
            <label htmlFor={item.id}>
              <span className="checkboxContainer"></span>
              {/* <span className="checkboxName">{ scope == 'lite' ? "히트맵" :  item.text}</span> */}
              <span className="checkboxName">{item.text}</span>
            </label>
          </li>
        );
      })}
    </ul>
  );
}
