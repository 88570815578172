import { atom } from "jotai";

const reportAtom = atom({
  "status": false,
  "isComment": false,
  "isLandmark": false,
  "isIM": false,
  "isAbnormality": false,
  "isHistory": false,
  "isSelectImage": false,
});

reportAtom.debugLabel = "reportAtom";

export default reportAtom;
