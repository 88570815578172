import styled from "styled-components";

const Container = styled.div`
  position: fixed;
  bottom: calc(100vw * (47 / 1920));
  left: 50%;
  transform: translateX(-50%);
  background: linear-gradient(#000 0 0) padding-box, var(--gradient) border-box;
  border: calc(100vw * (2 / 1920)) solid transparent;
  border-radius: calc(100vw * (16 / 1920));
  overflow: hidden;

  & .inner {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: calc(100vw * (70 / 1920));
    padding: calc(100vw * (24 / 1920)) calc(100vw * (32 / 1920));
    background: linear-gradient(
      90deg,
      rgba(71, 118, 230, 0.7) 0%,
      rgba(142, 84, 233, 0.7) 100%
    );
  }
`;

export { Container };

export default { Container };
