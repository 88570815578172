import { useEffect, useState } from "react";

const useKeyDown = (keyArray = []) => {
  const [isKeyDown, setIsKeyDown] = useState(false);
  useEffect(() => {
    const handleKeyDown = (e) => {
      const checkKeyDown = keyArray.some((key) => e.key === key);
      setIsKeyDown(checkKeyDown);
    };

    const handleKeyUp = () => setIsKeyDown(false);
    window.addEventListener("keydown", handleKeyDown);
    window.addEventListener("keyup", handleKeyUp);

    return () => {
      window.removeEventListener("keydown", handleKeyDown);
      window.addEventListener("keyup", handleKeyUp);
    };
  }, []);

  return {
    isKeyDown,
  };
};

export default useKeyDown;
