import styled from "styled-components";

const Container = styled.div`
  position: absolute;
  top: calc(100vw * (147 / 1920));
  right: calc(100vw * (32 / 1920));
  width: calc(100vw * (1150 / 1920));
  height: calc(100vw * (780 / 1920));
  padding: calc(100vw * (62 / 1920)) calc(100vw * (34 / 1920)) 0;
  background: #292929;
  border-radius: calc(100vw * (20 / 1920));

  & .closeButton {
    position: absolute;
    top: calc(100vw * (3 / 1920));
    right: calc(100vw * (3 / 1920));
    width: calc(100vw * (35 / 1920));
    height: calc(100vw * (35 / 1920));

    & img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  & .title {
    padding-bottom: calc(100vw * (15 / 1920));
    font-size: calc(100vw * (40 / 1920));
    font-weight: 700;
    border-bottom: calc(100vw * (3 / 1920)) solid #fff;
  }

  & .detailListWrap {
    & .detailList {
      display: flex;
      font-size: calc(100vw * (24 / 1920));

      &:not(:last-child) {
        border-bottom: calc(100vw * (2 / 1920)) dashed rgba(255, 255, 255, 0.3);
      }

      & .listTitle {
        flex-shrink: 0;
        width: calc(100vw * (240 / 1920));
        padding: calc(100vw * (15 / 1920));
        font-size: calc(100vw * (26 / 1920));
        font-weight: 700;
        border-right: calc(100vw * (2 / 1920)) dashed rgba(255, 255, 255, 0.3);
      }

      & .detail {
        max-height: calc(100vw * (535 / 1920));
        padding: calc(100vw * (17 / 1920));
        word-break: break-all;
        overflow: auto;

        &::-webkit-scrollbar {
          width: calc(100vw * (16 / 1920));
        }

        &::-webkit-scrollbar-track {
          background-color: #1c1c1c;
          border-radius: 999px;
          margin: calc(100vw * (15 / 1920)) 0;
        }

        &::-webkit-scrollbar-thumb {
          background-color: #2b2c30;
          border-radius: 999px;
          border: calc(100vw * (4 / 1920)) solid transparent;
        }

        & .resultListWrap {
          padding-left: calc(100vw * (20 / 1920));
          list-style: disc;

          &:not(:last-child) {
            margin-bottom: calc(100vw * (40 / 1920));
          }

          & li {
            line-height: 1.67;

            &:not(:last-child) {
              margin-bottom: calc(100vw * (10 / 1920));
            }
          }
        }
      }
    }
  }
`;

export { Container };

export default { Container };
