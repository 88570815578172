import styled from "styled-components";

const Container = styled.div`
  padding: calc(${(props)=>props.viewPort}vw * (45 / 1920)) calc(${(props)=>props.viewPort}vw * (22 / 1920))
  calc(${(props)=>props.viewPort}vw * (31 / 1920));
  width: 100%;
  height: 100%;

  & .wrap {
    display: flex;

    & .leftContainer {
      margin-right: calc(${(props)=>props.viewPort}vw * (24 / 1920));
      height: calc(${(props)=>props.viewPort}vw * (650 / 1920));

      & .stomachWrap {
        width: calc(${(props)=>props.viewPort}vw * (230 / 1920));
        height: calc(${(props)=>props.viewPort}vw * (240 / 1920));
        margin-top: calc(${(props)=>props.viewPort}vw * (50 / 1920));
        margin-bottom: calc(${(props)=>props.viewPort}vw * (50 / 1920));

        & img {
          width: calc(${(props)=>props.viewPort}vw * (230 / 1920));
          height: calc(${(props)=>props.viewPort}vw * (220 / 1920));
        }
      }
    }
    
    & .rightContainer {
      display: flex;
      width: 100%;
      height: calc(${(props)=>props.viewPort}vw * (650 / 1920));
    }
  }

  & .buttonContainer {
    position: relative;
    display: flex;
    gap: calc(${(props)=>props.viewPort}vw * (20 / 1920));
    justify-content: flex-end;

    & button {
      width: calc(100vw * (200 / 1920));
      height: calc(100vw * (48 / 1920));
      font-size: calc(100vw * (26 / 1920));
      font-weight: 700;
      border-radius: calc(100vw * (11 / 1920));
    }

    & .submitButton {
      background: #0075ff;
    }

    & .cancelButton {
      background: #848b99;
    }
  }
`;

export { Container };

export default { Container };
