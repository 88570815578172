import Styles from "./styles";
import ui from "components/ui";
import { useState } from "react";
import { useLanguage } from "hooks";
import { useMediaQuery } from "react-responsive";

// swiper
import { Swiper, SwiperSlide } from "swiper/react";
import { EffectCoverflow, Navigation } from "swiper";
import "swiper/css";
import "swiper/css/effect-coverflow";
import "swiper/css/navigation";

// img
import IconClose from "resources/image/icon/icon_close.svg";
import { IMAGE_REF, IMAGE_SRC, swiperOption } from "constants/common";
import useImageActiveList from "hooks/useImageActiveList";
import { useQuery } from "react-query";
import { api } from "service";
import { DwvComponent } from "components/layout";
import { useParams } from "react-router-dom";

export default function DoubleClickModal({ modalClose, modalOption }) {
  const { onModalClose } = modalOption ?? {};
  const lang = useLanguage("Common");

  const { imageActiveList: imageData } = useImageActiveList();
  const [comparisonCheck, setComparisonCheck] = useState(false);

  const { data: patientsData = {} } = useQuery(
    ["patients_info", modalOption.id],
    () => api.patient.getInfo(modalOption.id),
    {
      refetchOnWindowFocus: false,
    }
  );

  const { data: _imageNormalData = {} } = useQuery(
    ["image-normal"],
    () => api.image.getNormal(),
    {
      refetchOnWindowFocus: false,
    }
  );

  useQuery(["settings"], api.setting.getSetting, {
    refetchOnWindowFocus: false,
    onSuccess(responseData) {
      setComparisonCheck(responseData.withNormal);
    },
  });

  const imageNormalData = Object.values(_imageNormalData);

  const option = {
    rotate: -65,
    stretch: 160,
    depth: 300,
    modifier: 1,
    slideShadows: true,
  };
  const ProOption = {
    rotate: -65,
    stretch: 120,
    depth: 200,
    modifier: 1,
    slideShadows: true,
  };
  const AirOption = {
    rotate: -65,
    stretch: 80,
    depth: 200,
    modifier: 1,
    slideShadows: true,
  };
  const isPro = useMediaQuery({
    query: "(max-width: 1366px)",
  });
  const isAir = useMediaQuery({
    query: "(max-width: 1180px)",
  });

  const renderImageContainer = (type) => {
    switch (type) {
      case 0:
        const imageFirstData = imageData[0];
        const imageLandmark = imageFirstData.landmark;
        const imageSrc = IMAGE_SRC + imageFirstData.path;
        return (
          <>
            {comparisonCheck ? (
              <div className="comparisonContainer">
                <div className="imgContainer">
                  <img src={imageSrc} alt="stomach" />
                </div>

                <Swiper
                  direction={"vertical"}
                  effect={"coverflow"}
                  grabCursor={true}
                  initialSlide={imageLandmark - 1}
                  centeredSlides={true}
                  slidesPerView={"auto"}
                  coverflowEffect={
                    isAir ? AirOption : isPro ? ProOption : option
                  }
                  modules={[EffectCoverflow, Navigation]}
                  navigation={true}
                  className="mySwiper"
                >
                  {[1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11].map((id, index) => {
                    return (
                      <SwiperSlide key={id} className="imgContainer slider">
                        <img src={IMAGE_REF + id + ".png"} alt="stomach" />
                      </SwiperSlide>
                    );
                  })}
                </Swiper>
              </div>
            ) : (
              <div className="imgContainer">
                {imageFirstData.path?.includes("dc") ? (
                  <DwvComponent
                    index={imageFirstData.path}
                    item={{ path: imageFirstData.path }}
                  />
                ) : (
                  <img src={imageSrc} alt="stomach" />
                )}
                {/* <img src={imageSrc} alt="stomach" /> */}
              </div>
            )}

            <div className="comparisonCheckBoxContainer">
              <ui.input.BasicCheckbox
                id={"comparisonCheck"}
                text={lang.getLang("정상 사진과 비교하기")}
                checked={comparisonCheck}
                onChange={(e) => {
                  setComparisonCheck(!comparisonCheck);
                }}
              />
            </div>
          </>
        );

      case 1:
        return (
          <ul className="imageListWrap">
            {imageData?.map((item, index) => {
              const imageSrc = IMAGE_SRC + item.path;
              return (
                <li className="imageList" key={"imageList" + index}>
                  <div className="imgContainer">
                    {item.path?.includes("d") ? (
                      <DwvComponent index={index} item={item} />
                    ) : (
                      <img src={imageSrc} alt={imageSrc} />
                    )}
                  </div>
                </li>
              );
            })}
          </ul>
        );

      case 2:
        return (
          <ul className="imageListWrap">
            {imageData?.map((item, index) => {
              const imageSrc = IMAGE_SRC + item.path;
              return (
                <li className="imageList" key={"imageList" + index}>
                  <div className="imgContainer">
                    <img src={imageSrc} alt={imageSrc} />
                  </div>
                </li>
              );
            })}
          </ul>
        );

      case 3:
        return (
          <ul className="imageListWrap">
            {imageData?.map((item, index) => {
              const imageSrc = IMAGE_SRC + item.path;
              return (
                <li className="imageList" key={"imageList" + index}>
                  <div className="imgContainer">
                    <img src={imageSrc} alt={imageSrc} />
                  </div>
                </li>
              );
            })}
          </ul>
        );
      default:
        return <></>;
    }
  };

  console.log("imageData", imageData);

  return (
    <Styles.Container>
      <div className="overlay" onClick={modalClose}></div>

      <div
        className={
          "inner type" +
          `${imageData.length}` +
          (comparisonCheck ? " comparisonCheck" : "")
        }
      >
        <button
          type="button"
          className="closeButton"
          onClick={() => {
            onModalClose?.();
            modalClose();
          }}
        >
          <img src={IconClose} alt="close" />
        </button>

        {/* <div className="userInfoContainer">
          <p className="userName">{patientsData.name}</p>

          <div className="dateContainer">
            <p className="birthContainer">
              <span className="dateTitle">{lang.getLang("생년월일")}</span>
              <span className="date">{patientsData.birthday}</span>
            </p>

            <p className="examinationContainer">
              <span className="dateTitle">{lang.getLang("검진일자")}</span>
              {patientsData?.studies?.map((item) => {
                return (
                  window.location.href.split("/")[6] == item.studyId &&
                  item.studyDate.split(" ")[0]
                );
              })}
            </p>
          </div>
        </div> */}

        {renderImageContainer(imageData.length - 1)}
      </div>
    </Styles.Container>
  );
}
