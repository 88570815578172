import Styles from "./styles";
import { useLanguage } from "hooks";
import PicturesTitle from "../PicturesTitle";
import { common, layout } from "components";
import { getCookie } from "service/connector";
import { useEffect, useState } from "react";
import SVG from "components/common/SVG";
import { useParams } from "react-router-dom";
import { useMutation, useQuery } from "react-query";
import { api } from "service";
import {
  formatImageWithPath,
  formatNormalImageWithLandmark,
  lesionTypeToColor,
} from "utils/common";
import { useReport } from "hooks/useReport";
import ChangeAbnormalImage from "./components/ChangeAbnormalImage";

export default function AbnormalitySection(props) {
  const lang = useLanguage("Report");
  const {studyId} = useParams();
  const [sectionType, setSectionType ] = useState("view"); // view, edit

  // 리포트 정보를 불러오는 커스텀 훅
  const reportInfo = useReport();

  // 최초 진입 후 abnormality 정보를 가져와서 변수에 저장
  const [abnormalityData, setAbnormalityData] = useState(null);
  const [lesionScore, setLesionScore] = useState(null);
  const [lesionType, setLesionType] = useState(null);
  const [notSelectedSection, setNotSelectedSection] = useState(null);
  const [landmark, setLandmark] = useState(null);

  // 리포트 정보에서 abnormality 정보를 가져옴
  const [currentImageSize, setCurrentImageSize] = useState();

  const [scope, setScope] = useState("lite");

  useEffect(() => {
    setScope(getCookie("scope"));
  }, []);
  
  const {
    // data: reportsAbnormalityData = {},
    isLoading: isLoadingAbnormalityImages,
  } = useQuery(
    [
      "reports-images",
      studyId,
      "abnormality",
      reportInfo.reportData?.abnormality?.lesionType,
    ],
    () =>
      api.reports.getReportsImages({
        medicalstudyId: studyId,
        check: "abnormality",
        lesionType: reportInfo.reportData?.abnormality?.lesionType,
      }),
    {
      onSuccess(responseAbnormalityData) {

        if (abnormalityData === null) {
          setAbnormalityData(responseAbnormalityData);
        }

      },
      refetchOnWindowFocus: false,
    }
  );


  useEffect(() => {

    setLesionScore(abnormalityData?.lesionScore);
    const lesionType = lesionTypeToColor(abnormalityData?.lesionType);
    setLesionType(lesionType);
    const notSelectedSection = [
      { landmark: abnormalityData?.landmark, type: lesionType },
    ];
    setNotSelectedSection(notSelectedSection);
    setLandmark(abnormalityData?.landmark);

    reportInfo.setReportWriteData((prev) => ({
      ...prev,
      abnormality: abnormalityData,
    }));

  }, [abnormalityData]);

  const reportsChangeAbnormalityMutation = useMutation(
    ({ studyId, imageId, landmark, lesionType }) =>
      api.reports.putReportsPriorityChangeAbnormality({
        studyId: Number(studyId),
        imageId,
        landmark,
        lesionType,
      })
  );

  if (isLoadingAbnormalityImages || !abnormalityData) return <></>;

  return (
    <>
      <PicturesTitle
        colorTitle={lang.getLang("이상부위") }
        subTitle={lang.getLang("문제가 있는 부위를 표시합니다.")}
        paddingSize={props.paddingSize}
        viewPort={props.viewPort}
      />

      { sectionType === "view" ?

      <Styles.Container
        paddingSize={props.paddingSize}
        viewPort={props.viewPort}
      >
        <div className="wrap">
          <div className="leftContainer">
          <div className="stomachWrap">
              <layout.NoHoverStomachReport
                selectedSection={landmark}
                notSelectedSection={notSelectedSection}
                score={lesionScore}
                scoreType={lesionType}
                numberType={lesionType}
                lesionType={lesionType}
                noClickEvent
                opacityBg={true}
                screen={"abnormality"}
              />
            </div>
            <layout.LesionAnimationContainer
              type={lesionType}
              noAni
              score={lesionScore}
              viewPort={props.viewPort}
              screen={"abnormality"}
            />
          </div>

          <div className="rightContainer">
            <div className="imgContainer">
              <div className="imgWrap">
                <img
                  src={formatImageWithPath(abnormalityData?.path)}
                  alt="stomach"
                />
              </div>
              <p className={"text"}>
                  Image
              </p>
            </div>
            <div className="imgContainer">
              <div className="imgWrap">
                <img
                  src={formatImageWithPath(abnormalityData?.path)}
                  alt="stomach"
                  onLoad={(event) => {
                    const { naturalWidth, naturalHeight } = event.currentTarget;
                    setCurrentImageSize(
                      (prev) => ({
                        ...prev,
                        naturalWidth,
                        naturalHeight,
                      })
                    );
                  }}
                />
                {abnormalityData?.contour && currentImageSize &&
                <SVG.Rectangle
                  data={abnormalityData?.contour}
                  width={currentImageSize?.naturalWidth}
                  height={currentImageSize?.naturalHeight}
                />
                }
              </div>
              <p className={"text"}>
                  Lesion
              </p>
            </div>
            <div className="imgContainer">
              <div className="imgWrap">
                <img
                  className={landmark === 12 ? "opacity" : ""}
                  src={formatNormalImageWithLandmark(landmark)}
                  alt="stomach"
                />
              </div>
              <p className={"text"}>
                  Normal
              </p>
            </div>
          </div>
        </div>

        <button
          type="button"
          className="changeButton"
          onClick={() => {
            setSectionType("edit");
          }}
        >
          {lang.getLang("사진 변경")}
        </button>
      </Styles.Container>
      : 
      <Styles.EditContainer>
        <div className="editContainer">
          <ChangeAbnormalImage 
            viewPort={props.viewPort}
            paddingSize={props.padding}
            studyId={studyId}
            landmark={landmark}
            score={lesionScore}
            type={lesionType}
            onChangeCancel={() => {
              setSectionType("view");
            }}
            onChangeAbnormality={({ data }) => {
              const { selected, polygon } = data;

              console.log("selected ====> ", selected);
              console.log("polygon ====> ", polygon);

              const updateData = {
                landmark: selected.landmark,
                lesionType: selected.lesion[0].type,
                lesionScore: selected.lesion[0].score,
                path: selected.path,
                contour: polygon[0],
              };

              reportInfo.setReportWriteData((prev) => ({
                ...prev,
                abnormality: updateData,
              }));

              setAbnormalityData(updateData);

              setSectionType("view");
            }}
          >
          </ChangeAbnormalImage>
        </div>
      </Styles.EditContainer>
      }
    </>
  );
}
