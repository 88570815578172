import styled, { css } from 'styled-components';


const InputBox = styled.div`
  width: 100%; height: 100%;
  & input {width: 100%; height: 100%; padding: 0 calc(100vw*(15/1920)); border: 1px solid #fff; border-radius: calc(100vw*(12/1920));}
  & input::placeholder {color: rgba(255,255,255,0.3);}
`;


export {
  InputBox
}


export default {
  InputBox
};