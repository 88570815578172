import { useLanguage } from "hooks";
import { useMediaQuery } from "react-responsive";
import Styles from "./styles";
import { getCookie } from "service/connector";

import LesionTitle from "../LesionTitle";

// swiper
import { Swiper, SwiperSlide } from "swiper/react";
import { EffectCoverflow, Navigation } from "swiper";
import "swiper/css";
import "swiper/css/effect-coverflow";
import "swiper/css/navigation";

import { IMAGE_REF, IMAGE_SRC, swiperOption } from "constants/common";
import SVG from "components/common/SVG";
import { lesionTypeToColor } from "utils/common";
import DwvComponent from "../DwvComponent";
import { useEffect, useRef, useState } from "react";

const IMCheckedSectionTemplate = ({
  studyId,
  patientsData,
  landmark,
  currentLesion,
  lesionScoreType,
  lesionsIM,
  imIndex,
  onImIndexChange,
  currentHitMapSrc,
  checkLesion,
  onChangeChecked,
  matchingLesionJsonData,
  isFetchingLesionsJson,
  onLoadImage,
  renderedImgList,
}) => {
  const [scope, setScope] = useState('lite')

  useEffect(() => {
    setScope(getCookie("scope"))
  }, [])

  const lang = useLanguage("Lesion");
  const isPro = useMediaQuery({
    query: "(max-width: 1366px)",
  });
  const isAir = useMediaQuery({
    query: "(max-width: 1180px)",
  });

  const swiperRef = useRef(null);

  useEffect(() => {
      if (swiperRef.current) swiperRef.current?.slideTo(imIndex ? imIndex : 0, 0);
  }, [renderedImgList, imIndex]);

  if (isFetchingLesionsJson) return;

  const status = patientsData.studies.find(
    (item) => item.studyId === Number(studyId)
  )?.status;

  return (
    <Styles.Container>
      <div className="left">
        <LesionTitle
          title={scope === 'lite' ? lang.getLang("히트맵") : lang.getLang("장상피화생")}
          type={lesionScoreType}
          checkTitle={"Decision Area"}
          id={"Decision Area"}
          percent={currentLesion?.lesionScore}
          checkLesion={checkLesion}
          onChangeChecked={onChangeChecked}
          screen={'heatmap'}
        />

        <div className="leftWrap">
          <div className="swiperContainer">
            {renderedImgList && renderedImgList.length > 0 ? (
              <Swiper
                direction={"vertical"}
                effect={"coverflow"}
                grabCursor={true}
                initialSlide={imIndex}
                navigation={true}
                centeredSlides={true}
                slidesPerView={"auto"}
                coverflowEffect={
                  isAir
                    ? swiperOption.AirOption
                    : isPro
                    ? swiperOption.ProOption
                    : swiperOption.option
                }
                onSlideChange={(swiper) => {
                  onImIndexChange(swiper.activeIndex);
                }}
                modules={[EffectCoverflow, Navigation]}
                className="swiper"
                onSwiper={(swiper) => {
                  if (swiper.destroyed) return;
                  swiperRef.current = swiper;
                }}
              >
                {lesionsIM?.map((item, index) => {
                  const { path, imageId } = item;
                  const jsonMap = matchingLesionJsonData[index]?.polygon;
                  const imgData = renderedImgList[index];
                  return (
                    <SwiperSlide
                      className="imgContainer slider"
                      key={"slider" + imageId}
                    >
                      {item?.imageName?.includes("d") ? (
                        <DwvComponent index={imageId} item={item} />
                      ) : (
                        <img src={IMAGE_SRC + item.path} alt="stomach" />
                      )}

                      <img
                        src={IMAGE_SRC + path}
                        alt="stomach"
                        onLoad={(event) => onLoadImage(event, imageId)}
                      />
                      { status === 4 && (
                      <div className="imgInfo">
                        <p>{path.split("/").slice(-1)[0]}</p>
                        <p className="desc">*사용자 입력데이터만 이미지명이 표시됩니다.</p>
                      </div>
                      )}
                      
                      {/* Lesion > 장상피화생 > 에서는 polygon이 나오지 말아야 한다해서 주석처리 */}
                      {/* {checkLesion &&
                        !isFetchingLesionsJson &&
                        jsonMap?.map((jsonData) => (
                          <SVG.Polygon
                            data={jsonData || []}
                            width={imgData?.naturalWidth}
                            height={imgData?.naturalHeight}
                          />
                        ))} */}
                    </SwiperSlide>
                  );
                })}
              </Swiper>
            ) : null}
          </div>

          <div className="imgContainer">
            <img src={currentHitMapSrc} alt="stomach" />
          </div>
        </div>
      </div>

      <div className="right">
        <LesionTitle title={lang.getLang("정상")} type={"green"} />

        <div className="imgContainer">
          <img src={IMAGE_REF + landmark + ".png"} alt="stomach" />
        </div>
      </div>
    </Styles.Container>
  );
};

export default IMCheckedSectionTemplate;
