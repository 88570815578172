import styled from "styled-components";

const Container = styled.div`
  position: fixed;
  top: calc(100vw * (18 / 1920));
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: calc(100vw * (1856 / 1920));
  height: calc(100vw * (82 / 1920));
  border-bottom: calc(100vw * (3 / 1920)) solid #ffffff;
  z-index: 2;

  img {
    width: 100%;
    height: 100%;
    object-fit: fill;
  }

  & button {
    width: calc(100vw * (50 / 1920));
    height: calc(100vw * (50 / 1920));
  }
  & button.setting {
    opacity: 0.3;
  }

  & h2 {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-size: calc(100vw * (32 / 1920));
    font-weight: bold;
  }
`;

export { Container };

export default { Container };
