import { useEffect } from "react";
import Styles from "./styles";

// img
import IconDot from "resources/image/icon/icon_dot.svg";


export default function Pagination({list,page,setPage,maxPage,setPageList,pageSize,setFilterInfo,}) {
  useEffect(() => {
    setFilterInfo((f) => {
      f.page = page;
      return { ...f };
    });
  }, [page]);

  const onClickPage = (value) => {
    setPage(value);
  };

  const setPageListFunc = (start, end) => {
    let list = [];
    while (start <= end) list.push(start++);
    setPageList(list);
  };

  const onClickPrev = () => {
    if (page == 1) return;
    if (page % pageSize == 1) {
      let start = page - pageSize;
      let end = page - 1;

      setPageListFunc(start, end);
    }

    setPage(page - 1);
  };

  const onClickNext = () => {
    console.log(page)
    console.log(maxPage)
    if (page == maxPage) return;
    if (page % pageSize == 0) {
      let start = page + 1;
      let end = page + pageSize;

      if (end >= maxPage) end = maxPage;

      setPageListFunc(start, end);
    }

    setPage(page + 1);
  };

  const onClickStart = () => {
    let start = 1;
    let end = pageSize;
    if (maxPage <= pageSize) end = maxPage;

    setPageListFunc(start, end);
    setPage(1);
  };

  const onClickEnd = () => {
    let end = maxPage;
    let start = Math.floor(end / pageSize) * pageSize + 1;

    setPageListFunc(start, end);
    setPage(maxPage);
  };

  return (
    <Styles.Container>
      <button
        type="button"
        className="prev_btn"
        onClick={(e) => { onClickPrev(); }}
      >PREV</button>
      <div className="paginationList">
        {!list.includes(1) &&
          <>
            <button
              type="button"
              className={page == 1 ? "on" : ""}
              onClick={() => { onClickStart(); onClickPage(1); }}
            >{1}</button>
            <img src={IconDot} alt="more" />
          </>
        }
        {list.map((item, index) => {
          return (
            <button
              type="button"
              value={item}
              className={page == item ? "on" : ""}
              onClick={() => {
                onClickPage(item);
              }}
              key={index}
            >
              {item}
            </button>
          );
        })}
        {!list.includes(maxPage) &&
          <>
            <img src={IconDot} alt="more" />
            <button
              type="button"
              className={page == maxPage ? "on" : ""}
              onClick={() => { onClickEnd(); onClickPage(maxPage); }}
            >{maxPage}</button>
          </>
        }
      </div>
        <button
          type="button"
          className="next_btn"
          onClick={(e) => { onClickNext(); }}
        >NEXT</button>
    </Styles.Container>
  );
}
