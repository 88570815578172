import { useState, useEffect } from "react";
import { layout, common } from "components";
import Styles from "./styles";
import { useLanguage } from "hooks";
import useIsTimeoutAfterAction from "hooks/isTimeoutAfterAction";

export default function MiniStomachContainer({
  landmark,
  onClickStomachSection,
  isHoverOutSectionClick,
}) {
  const lang = useLanguage("History");

  const [isStomachHover, setIsStomachHover] = useState(false);
  const [hoverSection, setHoverSection] = useState(false);
  const [selectedSection, setSelectedSection] = useState(`${landmark}`);
  const [notSelectedSection, setNotSelectedSection] = useState(false);

  const { isAbleAction, onTimeoutAction } = useIsTimeoutAfterAction();

  const stomachListData = [
    lang.getLang("식도"),
    lang.getLang("위식도 접합부"),
    lang.getLang("십이지장 2부"),
    lang.getLang("십이지장 구부"),
    lang.getLang("위 전정부"),
    lang.getLang("위 각부"),
    lang.getLang("위체부(하-중부)"),
    lang.getLang("위체부(중-상부)"),
    lang.getLang("위저부(반전)"),
    lang.getLang("위 분문부(반전)"),
    lang.getLang("위체부(반전)"),
  ];

  useEffect(() => {
    if (isStomachHover && hoverSection !== "") {
      setSelectedSection("");
    } else {
      setSelectedSection(`${landmark}`);
    }
  }, [isStomachHover, hoverSection]);

  return (
    <Styles.Container className={isStomachHover ? "on" : ""}>
      <div className="numberIconContainer">
        <common.NumberIcon
          number={
            isStomachHover && hoverSection !== "" ? hoverSection : landmark
          }
        />
        <p className="landmarkName">
          {isStomachHover && hoverSection !== ""
            ? stomachListData[hoverSection - 1]
            : stomachListData[landmark - 1]}
        </p>
      </div>

      <div className="miniStomachWrap">
        <div
          className="stomachContainer"
          onMouseOver={() => {
            if (!isAbleAction) return;
            setIsStomachHover(true);
          }}
          onMouseLeave={() => {
            setIsStomachHover(false);
          }}
          onClick={() => {
            if (isHoverOutSectionClick) {
              setIsStomachHover(false);
              onTimeoutAction();
            }
          }}
        >
          <layout.Stomach
            settingData={{
              startLandmark: landmark,
            }}
            hoverSection={hoverSection}
            setHoverSection={setHoverSection}
            selectedSection={selectedSection}
            setSelectedSection={setSelectedSection}
            notSelectedSection={notSelectedSection}
            setNotSelectedSection={setNotSelectedSection}
            onClickStomachSection={onClickStomachSection}
          />
        </div>
      </div>

      <div
        className="overlay"
        onMouseOver={() => {
          setIsStomachHover(false);
        }}
      ></div>
    </Styles.Container>
  );
}
