import styled from "styled-components";

const Container = styled.div`
  position: relative;
  padding: calc(100vw * (30 / 1920)) calc(100vw * (22 / 1920))
    calc(100vw * (30 / 1920));

  & img {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: fill;
  }

  & .wrap {
    display: flex;
    justify-content: flex-end;
    align-items: center;

    & .leftContainer {
      position: absolute;
      top: calc(100vw * (45 / 1920));
      left: calc(100vw * (32 / 1920));
    }

    & .rightContainer {
      display: flex;
      gap: calc(100vw * (26 / 1920));

      & .imgContainer {
        padding: calc(100vw * (15 / 1920)) calc(100vw * (21 / 1920))
          calc(100vw * (26 / 1920));
        background-color: #ffffff;
        border-radius: calc(100vw * (9 / 1920));
        background: #A1A1A1;

        & .imgWrap {
          width: calc(100vw * (430 / 1920));
          height: calc(100vw * (430 / 1920));
          margin-bottom: calc(100vw * (46 / 1920));
          filter: drop-shadow(
            calc(100vw * (5 / 1920)) calc(100vw * (5 / 1920))
              calc(100vw * (4 / 1920)) rgba(0, 0, 0, 0.5)
          );
          border-radius: calc(100vw * (9 / 1920));
          overflow: hidden;
        }
      }
    }
  }

  & .noImg {
    display: flex;
    justify-content: center;
    align-items: center;
    width: calc(100vw * (430 / 1920));
    height: calc(100vw * (430 / 1920));
    font-size: calc(100vw * (36 / 1920));
    font-weight: 700;
    color: #000;
    margin-bottom: calc(100vw * (46 / 1920));
    background-color: #d9d9d9;
    border-radius: calc(100vw * (9 / 1920));
    background: #d9d9d9;

  }
`;

export { Container };

export default { Container };
