import styled from "styled-components";

// img
import Logo from "resources/image/icon/logo.svg";

const Container = styled.div`
  width: 100%;
  min-height: 100vh;
  padding: calc(100vw * (100 / 1920)) calc(100vw * (32 / 1920)) 0;
  background: url(${Logo}) no-repeat left calc(100vw * (30 / 1920)) bottom
    calc(100vw * (30 / 1920)) / contain;
  background-size: calc(100vw * (204 / 1920)) calc(100vw * (36 / 1920));
  background-color: #000;

  & .formContainer {
    display: flex;
    flex-direction: column;
    height: calc(100vh - calc(100vw * (167 / 1920)));
    padding-top: calc(100vw * (71 / 1920));

    & input,
    textarea {
      font-size: calc(100vw * (24 / 1920));
    }

    & .inputListWrap {
      display: flex;
      flex-direction: column;
      align-items: center;
      width: calc(100vw * (588 / 1920));
      margin: 0 auto;

      & .inputList {
        width: 100%;
        margin-bottom: calc(100vw * (32 / 1920));

        &.gender {
          margin-top: calc(100vw * (16 / 1920));
        }

        &.textarea {
          & .inputContainer {
            align-items: flex-start;
          }

          & .inputTitle {
            padding-top: calc(100vw * (16 / 1920));
          }

          & .inputWrap {
            height: calc(100vw * (193 / 1920));
          }
        }

        & .inputTitle {
          font-size: calc(100vw * (24 / 1920));
          font-weight: 500;
        }

        & .inputContainer {
          display: flex;
          align-items: center;
          justify-content: space-between;
        }

        & .inputWrap {
          position: relative;
          width: calc(100vw * (394 / 1920));
          height: calc(100vw * (60 / 1920));
        }

        & .errorMessage {
          width: calc(100vw * (394 / 1920));
          margin: calc(100vw * (8 / 1920)) 0 0 auto;
          font-size: calc(100vw * (15 / 1920));
          color: #ff0000;
        }

        & .radioListWrap {
          display: flex;
          align-items: center;
          width: calc(100vw * (394 / 1920));
          gap: calc(100vw * (49 / 1920));
        }

        & .calendarButtonComponent {
          position: absolute;
          top: 50%;
          right: calc(100vw * (14 / 1920));
          transform: translateY(-50%);
          z-index: 1;
        }
      }
    }

    & .buttonContainer {
      display: flex;
      justify-content: flex-end;
      margin-top: auto;
      gap: calc(100vw * (14 / 1920));

      & button {
        display: inline-flex;
        justify-content: center;
        align-items: center;
        height: calc(100vw * (48 / 1920));
        font-size: calc(100vw * (26 / 1920));
        font-weight: 500;
        border-radius: calc(100vw * (11 / 1920));
      }

      & .cancelButton {
        width: calc(100vw * (144 / 1920));
        background-color: #6a6a6a;
      }

      & .completeButton {
        width: calc(100vw * (200 / 1920));
        background-color: #008ad1;
      }
    }
  }
`;

export { Container };

export default { Container };
