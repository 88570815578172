import { useParams } from "react-router-dom";
import Styles from "./styles";
import { useLanguage } from "hooks";
import { useQuery } from "react-query";
import { api } from "service";
import moment from "moment";
import { useReport } from "hooks/useReport";

export default function PatientInfoTable() {
  const lang = useLanguage("Report");

  const { id, studyId } = useParams();

  const reportInfo = useReport();

  const reportsData = reportInfo.reportData.patient;

  return (
    <Styles.Container>
      <table>
        <colgroup>
          <col span={2} />
        </colgroup>
        <tbody>
          <tr>
            <th>{lang.getLang("성명")}</th>
            <td>{reportsData.patientName}</td>
            <th>{lang.getLang("검진기관")}</th>
            <td>{reportsData.hospital}</td>
          </tr>

          <tr>
            <th>{lang.getLang("생년월일")}</th>
            <td>
              {moment(reportsData.patientBirthdate)?.format("YYYY-MM-DD")}
            </td>
            <th>{lang.getLang("검진의")}</th>
            <td>{reportsData.doctorName}</td>
          </tr>

          <tr>
            <th>{lang.getLang("성별")}</th>
            <td>{String(reportsData.patientSex)?.toUpperCase()}</td>
            <th>{lang.getLang("검진일")}</th>
            <td>
              {reportsData.studyDate
                ? moment(reportsData.studyDate)?.format("YYYY-MM-DD")
                : reportsData.studyDate
                ? moment(reportsData.studyDate)?.format("YYYY-MM-DD")
                : "-"}
            </td>
          </tr>

          <tr>
            <th>{lang.getLang("환자번호")}</th>
            <td>{id}</td>
            <th>{lang.getLang("이전검진일")}</th>
            <td>
              {reportsData.prevstudyDate
                ? moment(reportsData.prevstudyDate)?.format("YYYY-MM-DD")
                : reportsData.prevStudyDate
                ? moment(reportsData.prevStudyDate)?.format("YYYY-MM-DD")
                : "-"}
            </td>
          </tr>
        </tbody>
      </table>
    </Styles.Container>
  );
}
