import { useParams } from "react-router-dom";
import { useState, useCallback, useRef, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { common, layout, modal } from "components";
import { useMutation, useQuery } from "react-query";
import { api } from "service";
import Styles from "./styles";
import { useLanguage, useModals } from "hooks";
import {
  ImageContainer,
  StomachContainer,
  ImgCheckContainer,
  FilterContainer,
} from "./components";

import { debounce } from "lodash";
import useImageFilterInfo from "hooks/useImageFilterInfo";

// 환자 검색해서 환자 검진결과 하나 눌렀을때 나오는 환자의 검진 디테일 이미지 목록을 감싸는 컨테이터를 불러오는 페이지

export default function ImagePage() {
  const navigate = useNavigate();
  const lang = useLanguage("Checkup");
  const { type, id, studyId } = useParams();

  const modalOption = useModals();
  const imageContainerRef = useRef(null);

  const { imageFilterInfo, setWithAutoPhotoCheck } = useImageFilterInfo();

  const [sensitivity, setSensitivity] = useState({
    abnormalSensitivity: 50,
    imSensitivity: 50,
  });

  const [stomachIconCheck, setStomachIconCheck] = useState(type === "stomach");

  useEffect(() => {
    setWithAutoPhotoCheck((prev) => false);
  }, []);
  useEffect(() => {
    setStomachIconCheck(type === "stomach");
  }, [window.location.pathname]);

  const { data: patientsData = {} } = useQuery(
    ["patients_info", id],
    () => api.patient.getInfo(id),
    {
      refetchOnWindowFocus: false,
    }
  );

  const {
    data: imageData = [],
    isLoading: imageLoading,
    isError,
    isLoadingError,
  } = useQuery(
    ["image_list", imageFilterInfo],
    () => api.image.getList(imageFilterInfo),
    {
      refetchOnWindowFocus: false,
    }
  );

  const { isLoading: isLoadingSettings } = useQuery(
    ["settings"],
    api.setting.getSetting,
    {
      onSuccess({ abnormalSensitivity, imSensitivity }) {
        setSensitivity({ abnormalSensitivity, imSensitivity });
      },
      refetchOnWindowFocus: false,
    }
  );

  const { mutate: updateSensitivityMutate } = useMutation((requestValues) =>
    api.setting.updateSensitivity(requestValues)
  );

  const handleUpdateSettingSensitivity = useCallback(
    debounce((requestValues) => {
      updateSensitivityMutate(requestValues);
    }, 200),
    []
  );

  const imageContainer = document.getElementById("imageContainer");

  useEffect(() => {
    if (
      imageData.length > 0 &&
      (modalOption.loadingModal.show || modalOption.defaultModal.show)
    ) {
      modalOption.clearModals();
    } else if (
      imageData.length == 0 &&
      imageLoading == false &&
      modalOption.defaultModal.show == false
    ) {
      modalOption.setDefaultModal((e) => {
        e.show = true;
        e.lang = lang.country;
        e.title = (
          <>
            {lang.getLangString("내시경 검진 결과가 존재하지 않습니다.")}
            <br />
            {lang.getLangString(
              "결과를 받아오지 못했거나, 당시 검진을 실시하지 않았습니다."
            )}
          </>
        );
        e.button = <>{lang.getLangString("확인")}</>;
        e.buttonClickEvent = () => {
          navigate("/checkup/new");
        };
        return { ...e };
      });
    } else if (
      !!imageData?.error == false &&
      imageLoading &&
      (modalOption.loadingModal.show == false ||
        modalOption.loadingModal.lang != lang.country) &&
      !modalOption.loadingModal.isClose
    ) {
      modalOption.setLoadingModal((e) => {
        e.show = true;
        e.isClose = false;
        e.lang = lang.country;
        e.title = (
          <>
            {lang.getLangString("현재 AI가 내시경 검진 결과를 분석중입니다.")}
            <br />
            {lang.getLangString("추론이 완료될 때까지 기다려주세요.")}
          </>
        );
        e.button = <>{lang.getLangString("취소")}</>;
        e.buttonClickEvent = () => {
          modalOption.clearModals();
          navigate("/checkup/new");
        };
        return { ...e };
      });
    } else if (
      imageData &&
      imageData?.error &&
      (modalOption.defaultModal.show == false ||
        modalOption.defaultModal.lang != lang.country)
    ) {
      modalOption.clearModals();
      modalOption.setDefaultModal((e) => {
        e.show = true;
        e.title = (
          <>
            {lang.getLangString("내시경 검진 결과가 존재하지 않습니다.")}
            <br />
            {lang.getLangString(
              "결과를 받아오지 못했거나, 당시 검진을 실시하지 않았습니다."
            )}
          </>
        );
        e.button = <>{lang.getLangString("취소")}</>;
        e.buttonClickEvent = () => {
          navigate("/checkup/new");
        };
        return { ...e };
      });
    }
  }, [imageLoading, imageData, lang]);

  if (isLoadingSettings) return <></>;

  return (
    <>
      {/* 내시경 결과가 없는 환자 일 시 아래 모달을 띄워 주세요 */}

      <common.SubHeader />
      <Styles.Container className={type === "stomach" ? "on" : ""}>
        {type === "detail" && (
          <>
            <FilterContainer
              patientsData={patientsData}
              studyId={studyId}
              id={id}
              abnormality={sensitivity.abnormalSensitivity}
              IMValue={sensitivity.imSensitivity}
              onChangeValues={(values) => {
                setSensitivity(values);
                handleUpdateSettingSensitivity(values);
              }}
              navigate={navigate}
              type={type}
            />

            <div
              ref={imageContainerRef}
              className="imgContainer"
              id="imageContainer"
            >
              {imageFilterInfo.is_apt ? (
                <layout.APTContainer
                  listData={imageData && imageData.map ? imageData : []}
                  filterInfo={imageFilterInfo}
                  abnormality={sensitivity.abnormalSensitivity}
                  IMValue={sensitivity.imSensitivity}
                  onContextMenu
                  imageContainerRef={imageContainerRef}
                />
              ) : (
                <ImageContainer
                  id={id}
                  studyId={studyId}
                  listData={imageData && imageData.map ? imageData : []}
                  abnormality={sensitivity.abnormalSensitivity}
                  IMValue={sensitivity.imSensitivity}
                />
              )}
            </div>
          </>
        )}

        {type === "stomach" && (
          <StomachContainer
            listData={imageData && imageData.map ? imageData : []}
            filterInfo={imageFilterInfo}
            patientsData={patientsData}
            IMValue={sensitivity.imSensitivity}
          />
        )}

        <div
          className={"imgCheckContainer" + (type === "stomach" ? " on" : "")}
        >
          <ImgCheckContainer
            withAutoPhotoCheck={imageFilterInfo.is_apt}
            stomachIconCheck={stomachIconCheck}
            onChangeCheckRadio={({ checkId, checked }) => {
              switch (type) {
                case "detail":
                  if (checkId === "withAutoPhoto") {
                    // withAutoPhoto
                    setStomachIconCheck(false);
                    setWithAutoPhotoCheck((prev) => !prev);
                  } else {
                    //  stomachCheck
                    setStomachIconCheck(true);
                    navigate(`/image/stomach/${id}/${studyId}`);
                  }
                  return;
                case "stomach":
                  if (checkId === "withAutoPhoto") {
                    // withAutoPhoto
                    setStomachIconCheck(true);
                    setWithAutoPhotoCheck((prev) => !prev);
                    // navigate(`/image/detail/${id}/${studyId}`);
                  } else {
                    // stomachCheck
                    if (!checked) {
                      navigate(`/image/detail/${id}/${studyId}`);
                      setStomachIconCheck(false);
                      return;
                    }
                  }
                  return;
                default:
                  return;
              }
            }}
          />
        </div>
      </Styles.Container>
    </>
  );
}
