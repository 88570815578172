import styled from "styled-components";

// img
import Logo from "resources/image/icon/logo.svg";

const Container = styled.div`
  position: relative;
  width: 100%;
  min-height: 100vh;
  padding: calc(100vw * (100 / 1920)) calc(100vw * (32 / 1920)) 0;
  background: url(${Logo}) no-repeat left calc(100vw * (30 / 1920)) bottom
    calc(100vw * (30 / 1920)) / contain;
  background-size: calc(100vw * (204 / 1920)) calc(100vw * (36 / 1920));
  background-color: #000;

  & .wrap {
    display: flex;
    width: 100%;
    height: 100%;
    padding-top: calc(100vw * (74 / 1920));

    & .leftContainer {
      flex-shrink: 0;
      width: calc(100vw * (604 / 1920));
      margin-right: calc(100vw * (117 / 1920));

      & .patientName {
        margin-bottom: calc(100vw * (17 / 1920));
        font-size: calc(100vw * (70 / 1920));
        font-weight: bold;
      }

      & .infoListWrap {
        padding-left: calc(100vw * (30 / 1920));

        & .infoList {
          display: flex;
          font-size: calc(100vw * (24 / 1920));

          &:not(:last-child) {
            margin-bottom: calc(100vw * (26 / 1920));
          }

          & .infoTitle {
            width: calc(100vw * (160 / 1920));
            font-weight: 700;
          }

          & .info {
            flex-grow: 1;
          }

          & textarea {
            width: 100%;
            height: calc(100vw * (195 / 1920));
            padding: calc(100vw * (9 / 1920)) calc(100vw * (15 / 1920));
            border: 1px solid #ffffff;
            border-radius: calc(100vw * (11 / 1920));
          }
        }
      }
    }

    & .rightContainer {
      position: relative;
      display: flex;
      flex-direction: column;
      width: calc(100% - calc(100vw * (721 / 1920)));

      & .resultTitle {
        margin-bottom: calc(100vw * (57 / 1920));
        font-size: calc(100vw * (32 / 1920));
        font-weight: 700;
      }

      & .listWrap {
        padding-right: calc(100vw * (50 / 1920));
      }

      & .list {
        display: flex;
        align-items: center;
        height: calc(100vw * (70 / 1920));
        font-size: calc(100vw * (24 / 1920));
        font-weight: 700;
        border-bottom: 1px solid #424447;
      }

      & .titleBox {
        height: auto;
        padding-bottom: calc(100vw * (20 / 1920));
        border-bottom: calc(100vw * (3 / 1920)) solid #424447;
      }

      & .box {
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
      }

      & .number,
      .date {
        width: calc(100vw * (240 / 1920));
      }

      & .result {
        flex-grow: 1;
        display: flex;
        align-items: baseline;
        max-width: calc(100% - calc(100vw * (494 / 1920)));

        & span {
          text-overflow: ellipsis;
          overflow: hidden;
          white-space: nowrap;
        }

        & .moreButton {
          margin-left: calc(100vw * (5 / 1920));
          font-size: calc(100vw * (26 / 1920));
          font-weight: 700;
          color: #008ad1;
          text-decoration: underline;
        }
      }
    }

    & .bottomButtonContainer {
      position: fixed;
      right: calc(100vw * (32 / 1920));
      bottom: calc(100vw * (40 / 1920));
      display: flex;
      justify-content: center;
      gap: calc(100vw * (24 / 1920));

      & button {
        display: flex;
        justify-content: center;
        align-items: center;
        height: calc(100vw * (48 / 1920));
        font-size: calc(100vw * (26 / 1920));
        font-weight: 700;
        border-radius: calc(100vw * (11 / 1920));
      }

      & .cancelButton {
        width: calc(100vw * (144 / 1920));
        background: #6a6a6a;
      }

      & .submitButton {
        width: calc(100vw * (200 / 1920));
        background: #008ad1;
      }
    }
  }
`;

export { Container };

export default { Container };
