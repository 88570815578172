import Styles from "./styles";
import { useLanguage } from "hooks";
import { common, layout } from "components";
import { useEffect, useState } from "react";

// img
import { useParams } from "react-router-dom";
import { useQuery } from "react-query";
import { api } from "service";
import {
  formatImageWithPath,
  formatNormalImageWithLandmark,
  lesionTypeToColor,
} from "utils/common";
import { useReport } from "hooks/useReport";
import SVG from "components/common/SVG";

export default function AbnormalitySection() {
  const lang = useLanguage("Report");

  const reportInfo = useReport();

  // 최초 진입 후 abnormality 정보를 가져와서 변수에 저장
  const [abnormalityData, setAbnormalityData] = useState(null);
  const [lesionScore, setLesionScore] = useState(null);
  const [lesionType, setLesionType] = useState(null);
  const [notSelectedSection, setNotSelectedSection] = useState(null);
  const [landmark, setLandmark] = useState(null);

  // 리포트 정보에서 abnormality 정보를 가져옴
  const [currentImageSize, setCurrentImageSize] = useState();

  const [scope, setScope] = useState("lite");

  // const {
  //   data: reportsAbnormalityData = {},
  //   isLoading: isLoadingReportsAbnormality,
  // } = useQuery(
  //   [
  //     "reports-images",
  //     studyId,
  //     "abnormality",
  //     reportsData?.abnormality?.lesionType,
  //   ],
  //   () =>
  //     api.reports.getReportsImages({
  //       medicalstudyId: studyId,
  //       check: "abnormality",
  //       lesionType: reportsData?.abnormality?.lesionType,
  //     }),
  //   {
  //     refetchOnWindowFocus: false,
  //   }
  // );

  useEffect(() => {
    if (reportInfo.reportData?.abnormality) {

      setAbnormalityData(reportInfo.reportData?.abnormality);
      const { lesionScore, landmark } = reportInfo.reportData?.abnormality;
      const lesionType = lesionTypeToColor(reportInfo.reportData?.abnormality.lesionType);

      console.log ("lesionType: ", lesionType);
      setLesionType(lesionType);
      setLesionScore(lesionScore);
      setLandmark(landmark);

      const notSelectedSection = [
        { landmark: Number(landmark), type: lesionType },
      ];

      setNotSelectedSection(notSelectedSection);
    }
  }
  , [reportInfo.reportData?.abnormality]);


  return (
    <Styles.Container
    viewPort={100}
  >
    <div className="wrap">
      <div className="leftContainer">
      <div className="stomachWrap">
          <layout.NoHoverStomachReport
            selectedSection={landmark}
            notSelectedSection={notSelectedSection}
            score={lesionScore}
            scoreType={lesionType}
            numberType={lesionType}
            lesionType={lesionType}
            noClickEvent
            opacityBg={true}
            screen={"abnormality"}
          />
        </div>
        <layout.LesionAnimationContainer
          type={lesionType}
          noAni
          score={lesionScore}
          viewPort={100}
          screen={"abnormality"}
        />
      </div>

      <div className="rightContainer">
        <div className="imgContainer">
          <div className="imgWrap">
            <img
              src={formatImageWithPath(abnormalityData?.path)}
              alt="stomach"
            />
          </div>
          <p className={"text"}>
              Image
          </p>
        </div>
        <div className="imgContainer">
          <div className="imgWrap">
            <img
              src={formatImageWithPath(abnormalityData?.path)}
              alt="stomach"
              onLoad={(event) => {
                const { naturalWidth, naturalHeight } = event.currentTarget;
                setCurrentImageSize(
                  (prev) => ({
                    ...prev,
                    naturalWidth,
                    naturalHeight,
                  })
                );
              }}
            />
            {abnormalityData?.contour && currentImageSize &&
            <SVG.Rectangle
              data={abnormalityData?.contour}
              width={currentImageSize?.naturalWidth}
              height={currentImageSize?.naturalHeight}
            />
            }
          </div>
          <p className={"text"}>
              Lesion
          </p>
        </div>
        <div className="imgContainer">
          <div className="imgWrap">
            <img
              className={landmark === 12 ? "opacity" : ""}
              src={formatNormalImageWithLandmark(landmark)}
              alt="stomach"
            />
          </div>
          <p className={"text"}>
              Normal
          </p>
        </div>
      </div>
    </div>
  </Styles.Container>
  );
}
