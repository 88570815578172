import BasicInput from "./BasicInput";
import BasicRadio from "./BasicRadio";
import BasicTextarea from "./BasicTextarea";
import BasicCheckbox from "./BasicCheckbox";

export { BasicInput, BasicRadio, BasicTextarea, BasicCheckbox };

export default {
  BasicInput,
  BasicRadio,
  BasicTextarea,
  BasicCheckbox,
};
