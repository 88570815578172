import styled from "styled-components";

const Container = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: calc(100vw * (153 / 1920));
  padding: 0 calc(100vw * (44 / 1920)) 0 calc(100vw * (73 / 1920));
  background-color: #131519;

  & img {
    display: block;
    width: 100%;
    height: 100%;
  }

  & .backButton {
    display: flex;
    align-items: center;
    font-size: calc(100vw * (32 / 1920));

    & .iconWrap {
      width: calc(100vw * (42 / 1920));
      height: calc(100vw * (42 / 1920));
      margin-right: calc(100vw * (10 / 1920));
    }
  }

  & .title {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-size: calc(100vw * (32 / 1920));
    font-weight: bold;
  }

  & .logoWrap {
    width: calc(100vw * (204 / 1920));
    height: calc(100vw * (36 / 1920));
  }
`;

export { Container };

export default { Container };
