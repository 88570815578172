import styled from "styled-components";

// img
import IconTagBox from "resources/image/icon/icon_tag_box.svg";
import IconTagBoxEmpty from "resources/image/icon/icon_tag_box_empty.svg";

const Container = styled.div`
  position: relative;
  padding: calc(100vw * (22 / 1920));
  cursor: pointer;

  & .tagListWrap {
    position: absolute;
    top: calc(100vw * (2 / 1920));
    right: calc(100vw * (24 / 1920));
    display: flex;
    justify-content: flex-end;

    & .tagList {
      width: calc(100vw * (35 / 1920));
      height: calc(100vw * (21 / 1920));

      &:not(:last-child) {
        // margin-right: calc(100vw * (-2 / 1920));
      }

      &.landmark {
        padding-left: calc(100vw * (5 / 1920));
        font-size: calc(100vw * (15 / 1920));
        text-align: center;
        line-height: 1.4;
        text-shadow: -0.5px -0.5px 0 #3c3c3c, 0.5px -0.5px 0 #3c3c3c,
          -0.5px 0.5px 0 #3c3c3c, 0.5px 0.5px 0 #3c3c3c;
        background: url(${IconTagBox}) no-repeat center / cover;
      }

      &.landmarkEmpty {
        padding-left: calc(100vw * (5 / 1920));
        font-size: calc(100vw * (15 / 1920));
        text-align: center;
        line-height: 1.4;
        text-shadow: -0.5px -0.5px 0 #3c3c3c, 0.5px -0.5px 0 #3c3c3c,
          -0.5px 0.5px 0 #3c3c3c, 0.5px 0.5px 0 #3c3c3c;
        background: url(${IconTagBoxEmpty}) no-repeat center / cover;
      }
    }
  }

  & .imgWrap {
    width: calc(100vw * (200 / 1920));
    height: calc(100vw * (200 / 1920));
    background-color: #d9d9d9;
    box-shadow: calc(100vw * (12 / 1920)) calc(100vw * (18 / 1920))
      calc(100vw * (28 / 1920)) rgba(0, 0, 0, 0.63);
    border-radius: calc(100vw * (9 / 1920));
    overflow: hidden;
  }
`;

export { Container };

export default { Container };
