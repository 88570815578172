import connector from "service/connector";
function getFilterStr(parameter) {
  let filter = parameter;
  return new URLSearchParams(filter).toString();
}



const getSwInfomation = async () => {
  return await connector.authConnector(`sw-information`, "GET");
};

const getSetting = async () => {
  return await connector.authConnector(`settings`, "GET");
};
const updateSetting = async (parameter) => {
  return await connector.authConnector(`settings`, "PUT", parameter);
};

const updateSensitivity = async (parameter) => {
  return await connector.authConnector(
    `settings/sensitivity`,
    "PUT",
    parameter
  );
};

const getSettingsReportImages = async () => {
  return await connector.authConnector(`settings/report-images`, "GET");
};
const putSettingsReportImages = async ({ path }) => {
  return await connector.authConnector(`settings/report-images`, "PUT", {
    path,
  });
};
const deleteSettingsReportImages = async ({ path }) => {
  return await connector.authConnector(`settings/report-images`, "DELETE", {
    path,
  });
};

export {
  getSwInfomation,
  updateSetting,
  getSetting,
  updateSensitivity,
  getSettingsReportImages,
  putSettingsReportImages,
  deleteSettingsReportImages,
};

export default {
  getSwInfomation,
  updateSetting,
  getSetting,
  updateSensitivity,
  getSettingsReportImages,
  putSettingsReportImages,
  deleteSettingsReportImages,
};
