import Styles from "./styles";
import layout from "components/layout";
import { useState } from "react";
import { useLanguage } from "hooks";

import { useQuery } from "react-query";
import { api } from "service";

export default function ChangeImageModal({ modalClose, modalOption }) {
  const { id, studyId, isReport, onSelectImage, presetSelectedImagePath } =
    modalOption;
  const lang = useLanguage("Report");

  const [abnormality, setAbnormality] = useState(50);
  const [IMValue, setIMValue] = useState(50);

  const [activeReportSelectImage, setActiveReportSelectImage] = useState(
    presetSelectedImagePath
  );

  const imageFilterInfo = {
    id,
    studyId,
    is_apt: true,
  };
  const { data: imageData = [], isLoading: isLoadingImageData } = useQuery(
    ["image_list", imageFilterInfo],
    () => api.image.getList(imageFilterInfo),
    {
      refetchOnWindowFocus: false,
    }
  );

  if (isLoadingImageData) return <></>;

  console.log(imageData);
  return (
    <Styles.Container>
      <div
        className="overlay"
        onClick={() => {
          modalClose();
        }}
      ></div>

      <div className="inner">
        <div className="aptContainer">
          <layout.APTContainer
            listData={imageData}
            abnormality={abnormality}
            IMValue={IMValue}
            notUseDoubleClickModal
            notUseContextModal
            isReport={isReport}
            onSelectImage={onSelectImage}
            activeReportSelectImage={activeReportSelectImage}
            setActiveReportSelectImage={setActiveReportSelectImage}
          />
        </div>
        <div className="buttonContainer">
          <button
            type="button"
            className="submitButton"
            onClick={() => {
              onSelectImage?.(activeReportSelectImage);
              modalClose();
            }}
          >
            {lang.getLang("사진 변경")}
          </button>
          <button type="button" className="cancelButton" onClick={modalClose}>
            {lang.getLang("취소")}
          </button>
        </div>
      </div>
    </Styles.Container>
  );
}
