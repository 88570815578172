import { common, layout } from "components";
import { getCookie } from "service/connector";
import Styles from "./styles";
import { useEffect, useCallback, useState } from "react";
import { useLanguage, useModals } from "hooks";
import { useNavigate, useParams, useLocation } from "react-router-dom";
import { useConfig } from "hooks/useConfig";
import { useReport } from "hooks/useReport";
import { useTempReport } from "hooks/useTempReport";

import {
  PatientInfoTable,
  CommentsSection,
  LandmarkSection,
  IMSection,
  AbnormalitySection,
  HistorySection,
  SelectSection,
} from "./components";
import { api } from "service";
import { useMutation } from "react-query";
import { sortHistoryObjectToArray } from "utils/common";

const buttonStyle = {
  backgroundColor: "#4CAF50", // 배경 색상
  color: "white", // 글자 색상
  border: "none", // 테두리 없음
  padding: "10px 20px", // 안쪽 여백
  textAlign: "center", // 텍스트 중앙 정렬
  textDecoration: "none", // 텍스트 장식 없음
  display: "inline-block", // 인라인-블록 디스플레이
  fontSize: "16px", // 글자 크기
  margin: "4px 2px", // 외부 여백
  cursor: "pointer", // 커서 스타일
  borderRadius: "5px", // 테두리 둥근 정도
};

const buttonContainerStyle = {
  position: "fixed", // 고정된 위치
  right: "20px", // 우측 여백 20px
  bottom: "80px", // 하단 여백 20px
  zIndex: 99, // z-index 값
};

export default function ReportPage() {
  const navigate = useNavigate();
  const lang = useLanguage("Report");
  const modalOption = useModals();
  const configInfo = useConfig();
  const reportInfo = useReport();
  
  const {id, studyId} = useParams();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const type = queryParams.get("type");

  const [zoomLevel, setZoomLevel] = useState(1); // 3.최대. 2.중간, 1.최소
  const [reportPaddingInline, setReportPaddingInline] = useState("large"); // zoom3일때 large, zoom2일때 midium, zoom3일때 small
  const [reportViewPort, setReportViewPort] = useState(60); // zoom3일때 100, zoom2일때 80, zoom3일때 60
  const [scope, setScope] = useState("lite");

  useEffect(() => {
    setScope(getCookie("scope"));
  }, []);

  const postReportsMutation = useMutation(({ medicalstudyId, ...parameters }) =>
    api.reports.postReports({
      medicalstudyId,
      ...parameters,
    })
  );

  const handleWheel = (event) => {
    // 쉬프트 키가 눌려있지 않으면 함수 종료
    if (!event.shiftKey) return;

    setZoomLevel((prevZoom) => {
      // 휠을 올릴 때 (zoom in)
      if (event.deltaY < 0) {
        // console.log("Shift + Scrolling up - Zooming in");
        return Math.min(prevZoom + 1, 3); // zoomLevel을 1 증가시키지만 최대값은 3
      }
      // 휠을 내릴 때 (zoom out)
      else if (event.deltaY > 0) {
        // console.log("Shift + Scrolling down - Zooming out");
        return Math.max(prevZoom - 1, 1); // zoomLevel을 1 감소시키지만 최소값은 1
      }
      return prevZoom; // 그 외의 경우에는 변경 없음
    });
  };

  useEffect(() => {
    window.addEventListener("wheel", handleWheel);

    // 컴포넌트가 언마운트될 때 이벤트 리스너 정리
    return () => {
      window.removeEventListener("wheel", handleWheel);
    };
  }, []);

  useEffect(() => {
    if (zoomLevel === 3) {
      setReportPaddingInline("large");
      setReportViewPort(100);
    } else if (zoomLevel === 2) {
      setReportPaddingInline("midium");
      setReportViewPort(80);
    } else if (zoomLevel === 1) {
      setReportPaddingInline("small");
      setReportViewPort(60);
    }
  }, [zoomLevel]);

  const checkListData = [
    {
      id: "isLandmark",
      text: "Landmark",
    },
    {
      id: "isIM",
      text: scope == "lite" ? "히트맵" : "장상피화생",
    },
    {
      id: "isAbnormality",
      text: "이상병변",
    },
    {
      id: "isHistory",
      text: "History",
    },
    {
      id: "isSelectImage",
      text: "추가 선택",
    },
  ];

  const handleSetCheckList = (checked, id) => {
    reportInfo.setReportWriteData((prev) => ({
      ...prev,
      [id]: checked,
      im : checked ? prev.im : null,
      landmark : checked ? prev.landmark : null,
      abnormality : checked ? prev.abnormality : null,
      history : checked ? prev.history : null,
      selectImage : checked ? prev.selectImage : null,
    }));
  };

  const handleUpdateSelection = (selection) => {
    const newSelection = selection.reduce((acc, cur, idx) => {
      acc[`imagePath${idx + 1}`] = cur;
      return acc;
    }, {});

    reportInfo.setReportWriteData((prev) => ({
      ...prev,
      selectImage: newSelection,
    }));
  }

  return (
    <>
      <common.ReportHeader title={`REPORT ${zoomLevel}`} />
      <Styles.Container
        paddingSize={reportPaddingInline}
        viewPort={reportViewPort}
      >
        <div
          style={{
            backgroundColor: "white",
            padding: 10,
            boxShadow: "5px 5px 5px 5px lightgray",
          }}
        >
          <div style={buttonContainerStyle}>
            <button
              style={buttonStyle}
              onClick={() => {
                return setZoomLevel(Math.max(zoomLevel - 1, 1));
              }}
            >
              -
            </button>{" "}
            {/* 빼기 버튼 */}
            <span style={{ color: "#000" }}>{reportPaddingInline}</span>{" "}
            {/* 현재 카운트 값을 표시 */}
            <button
              style={buttonStyle}
              onClick={() => {
                return setZoomLevel(Math.min(zoomLevel + 1, 3));
              }}
            >
              +
            </button>{" "}
            {/* 더하기 버튼 */}
          </div>
          <PatientInfoTable viewPort={reportViewPort} type={type} />

          <CommentsSection viewPort={reportViewPort} />

          <div className="picturesSection">
            <div className="titleContainer">
              <layout.ReportTitle
                viewPort={reportViewPort}
                title={"Pictures"}
                subTitle={lang.getLang("활성화 된 박스가 환자에게 보여집니다")}
                line={true}
              />
            </div>

            <ul className="checkListWrap">
              {checkListData
                .filter((el) =>
                  configInfo.setting.isSimpleMode
                    ? el.id !== "isIM" && el.id !== "isAbnormality"
                    : true
                )
                ?.map((item, index) => {
                  const checked = reportInfo.reportData[item.id];
                  return (
                    <li className="checkList" key={"checkList" + index}>
                      <input
                        type="checkbox"
                        id={item.id}
                        onChange={(e) =>
                          handleSetCheckList(e.target.checked, e.target.id)
                        }
                        checked={checked}
                      />
                      <label htmlFor={item.id}>
                        <span className="checkContainer"></span>

                        <span className="checkListtext">
                          {lang.getLang(`${item.text}`)}
                        </span>
                      </label>
                    </li>
                  );
                })}
            </ul>

            {reportInfo.reportData.isLandmark && (
              <div className="section">
                <LandmarkSection
                  paddingSize={reportPaddingInline}
                  viewPort={reportViewPort}
                />
              </div>
            )}

            {/* {!configInfo.setting.isSimpleMode && ( */}
              <>
                {reportInfo.reportData.isIM && (
                  <div className="section">
                    <IMSection
                      paddingSize={reportPaddingInline}
                      viewPort={reportViewPort}
                    />
                  </div>
                )}
                {reportInfo.reportData.isAbnormality && (
                  <div className="section">
                    <AbnormalitySection
                      paddingSize={reportPaddingInline}
                      viewPort={reportViewPort}
                    />
                  </div>
                )}
              </>
            {/* )} */}

            {reportInfo.reportData.isHistory && (
              <div className="section">
                <HistorySection
                  paddingSize={reportPaddingInline}
                  viewPort={reportViewPort}
                  // studyId={studyId}
                />
              </div>
            )}

            {reportInfo.reportData.isSelectImage && (
              <div className="section">
                <SelectSection
                  paddingSize={reportPaddingInline}
                  viewPort={reportViewPort}
                  onUpdateSelection={handleUpdateSelection}
                />
              </div>
            )}
          </div>

          <ul className="buttonListWrap">
            <li className="buttonList">
              <button
                type="button"
                className="cancelButton"
                onClick={() => {
                  navigate(-1);
                }}
              >
                {lang.getLang("취소")}
              </button>
            </li>

            <li className="buttonList">
              <button
                type="button"
                className="saveButton"
                onClick={() => {

                  console.log("reportInfo.reportData: ", reportInfo.reportData);
                  const {
                    isComment,
                    isLandmark,
                    isIM,
                    isAbnormality,
                    isHistory,
                    isSelectImage,
                    selectImage,
                    history,
                    ...request
                  } = reportInfo.reportData;

                  console.log("request: ", request);
                  
                  // const [recentlyImage, pastImage] =
                  //   sortHistoryObjectToArray(history);

                  // const settingReportWrite = {
                  //   landmark: recentlyImage.content.landmark,
                  //   recentstudyDate: recentlyImage.date,
                  //   prevstudyDate: pastImage?.date || null,
                  //   recentPath: recentlyImage.content.path,
                  //   prevPath: pastImage?.content?.path || null,
                  // };

                  console.log("history: ", history);

                  postReportsMutation.mutate(
                    {
                      medicalstudyId: studyId,
                      isComment: true,
                      isLandmark,
                      isIM,
                      isAbnormality,
                      isHistory,
                      isSelectImage,
                      ...(isSelectImage && { 
                        selectImage: selectImage,
                      }),
                      ...(isHistory && {
                        history: history,
                      }),
                      ...(isComment && {
                        comment: {
                          comment1: reportInfo.reportData.comment_new.comment1,
                          comment2: reportInfo.reportData.comment_new.comment2,
                          comment3: reportInfo.reportData.comment_new.comment3,
                          comment4: reportInfo.reportData.comment_new.comment4,
                          commentId1: reportInfo.reportData.comment_new.commentId1.subId,
                          commentId2: reportInfo.reportData.comment_new.commentId2?.subId,
                          commentId3: reportInfo.reportData.comment_new.commentId3?.subId,
                        }
                      }),
                      ...(isLandmark && {
                        landmark: reportInfo.reportData.landmark,
                      }),
                      ...(isIM && {
                        im: {
                          landmark: reportInfo.reportData.im.landmark,
                          lesionScore: reportInfo.reportData.im.lesionScore,
                          lesionType: reportInfo.reportData.im.lesionType,
                          path: reportInfo.reportData.im.path,
                        }
                      }),
                      ...(isAbnormality && {
                        abnormality: {
                          landmark: reportInfo.reportData.abnormality.landmark,
                          lesionScore: reportInfo.reportData.abnormality.lesionScore,
                          lesionType: reportInfo.reportData.abnormality.lesionType,
                          path: reportInfo.reportData.abnormality.path,
                          contour: reportInfo.reportData.abnormality.contour
                        }
                      }),
                    },
                    {
                      onSuccess(response) {
                        if ("error" in response) {
                          throw new Error("저장 실패");
                        }
                        modalOption.setDefaultModal((e) => {
                          e.show = true;
                          e.title = (
                            <>
                              {lang.getLang("리포트가 저장되었습니다.")}
                              <br />
                              {lang.getLang("미리보기 화면으로 이동합니다.")}
                            </>
                          );
                          e.button = <>{lang.getLang("확인")}</>;
                          e.buttonClickEvent = () => {
                            navigate(`/report/preview/${id}/${studyId}`);
                          };
                          return { ...e };
                        });
                      },
                      onError() {
                        alert("저장 실패");
                      },
                    }
                  );
                }}
              >
                {lang.getLang("저장")}
              </button>
            </li>

            <li className="buttonList">
              <button
                type="button"
                className="viewButton"
                onClick={() => {
                  // const {
                  //   isComment,
                  //   // isLandmark,
                  //   isIm,
                  //   isAbnormality,
                  //   isHistory,
                  //   isSelectImage,
                  //   selectImage,
                  //   history,
                  //   ...request
                  // } = reportInfo.reportData;

                  // // navigate(`/report/preview/${id}/${studyId}`);

                  // const isEmptySelectImage =
                  //   selectImage &&
                  //   Object.values(selectImage)?.every((item) => item === null);
                  
                  //   const [recentlyImage, pastImage] =
                  //   sortHistoryObjectToArray(history);

                  // const settingReportWrite = {
                  //   landmark: recentlyImage.content.landmark,
                  //   recentstudyDate: recentlyImage.date,
                  //   prevstudyDate: pastImage?.date || null,
                  //   recentPath: recentlyImage.content.path,
                  //   prevPath: pastImage?.content?.path || null,
                  // };

                  navigate(`/report/preview/${id}/${studyId}`);
                }}
              >
                {lang.getLang("미리보기")}
              </button>
            </li>
          </ul>
        </div>
      </Styles.Container>
    </>
  );
}
