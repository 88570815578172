import styled from "styled-components";

const Container = styled.div`
  position: relative;
  width: 100%;
  height: 100%;

  & img {
    width: 100%;
    height: 100%;
  }

  & .stomachSection {
    position: relative;
  }

  & .stomachParts {
    & .stomachSectionDiv {
      position: absolute;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }

  & .stomachBaseImg {
    filter: brightness(1);
    transition: 0.2s;

    &.on {
      filter: brightness(0.4);
    }
  }
`;

const StomachSectionImg = styled.img`
  width: 100%;
  height: 100%;
  clip-path: polygon(${(props) => props.polygon});
  transform-origin: ${(props) => props.transformorigin};
  transition: 0.2s;
  pointer-events: ${(props) =>
    props.isSelected || props.isNotSelected ? "auto" : "none"};
  opacity: ${(props) =>
    props.isSelected ? "1" : props.isNotSelected ? "0.3" : 0};
  z-index: ${(props) => (props.isSelected ? "2" : "1")};
  cursor: pointer;

  &.on {
    pointer-events: none;
  }
`;

export { Container, StomachSectionImg };

export default { Container, StomachSectionImg };
