import ModalPage from "./ModalPage";
import PatientsPage from "./PatientsPage";
import AdminPage from "./AdminPage";
import LoginPage from "./LoginPage";
import CreateUserPage from "./CreateUserPage";
import CreatePatientPage from "./CreatePatientPage";
import PatientDetailPage from "./PatientDetailPage";
import ImagePage from "./ImagePage";
import HistoryPage from "./HistoryPage";
import LesionPage from "./LesionPage";
import CheckupNewPage from "./CheckupNewPage";
import SettingsPage from "./SettingsPage";
import ReportPage from "./ReportPage";
import ReportPreviewPage from "./ReportPreviewPage";
import ImAnalysisPage from "./ImAnalysisPage";

export default {
  ModalPage,
  PatientsPage,
  AdminPage,
  LoginPage,
  CreateUserPage,
  CreatePatientPage,
  PatientDetailPage,
  ImagePage,
  HistoryPage,
  LesionPage,
  CheckupNewPage,
  SettingsPage,
  ReportPage,
  ReportPreviewPage,
  ImAnalysisPage,
};

export {
  ModalPage,
  PatientsPage,
  AdminPage,
  LoginPage,
  CreateUserPage,
  CreatePatientPage,
  PatientDetailPage,
  ImagePage,
  HistoryPage,
  LesionPage,
  CheckupNewPage,
  SettingsPage,
  ReportPage,
  ReportPreviewPage,
  ImAnalysisPage,
};
