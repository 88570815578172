const lang = {
  페이지: {
    _en: "Page",
  },

  "Image 페이지 시작 시 첫 화면을 지정할 수 있습니다.": {
    _en: "Select the default screen to display in the Image page.",
  },
  "이미지만 보기": {
    _en: "View images only",
  },
  "위 부위별 이미지 분류": {
    _en: "View with stomach icon",
  },
  "History 페이지 시작 시 첫 화면의 랜드마크를 지정할 수 있습니다.": {
    _en: "Select the default landmark to display in the History page.",
  },
  식도: {
    _en: "Esophagus",
  },
  "위식도 접합부": {
    _en: "Esophagogastric junction",
  },
  "십이지장 2부": {
    _en: "Descending duodenum",
  },
  "십이지장 구부": {
    _en: "Duodenum bulb",
  },
  "위 전정부": {
    _en: "Antrum",
  },
  "위 각부": {
    _en: "Angle",
  },
  "위체부(하-중부)": {
    _en: "Body, LB-MB",
  },
  "위체부(중-상부)": {
    _en: "Body, MB-HB",
  },
  "위저부(반전)": {
    _en: "Retroflexion fundus",
  },
  "위 분문부(반전)": {
    _en: "Retroflexion cardia",
  },
  "위체부(반전)": {
    _en: "Retroflexion body",
  },
  "Lesion 페이지에서 병변의 위치 표시 여부를 지정할 수 있습니다.": {
    _en: "Show or hide the lesion location in the Lesion page.",
  },
  "병변 위치 및 Decision Area 표시": {
    _en: "Show lesion location and AI decision area",
  },
  감추기: {
    _en: "Hide",
  },
  "Image 크게보기": {
    _en: "Full Image",
  },
  "이미지 크게보기 화면에서 정상 이미지와 함께 보기 여부를 지정할 수 있습니다.":
    {
      _en: "Select the default screen to display in the Full Image page.",
    },
  "클릭한 이미지만 보기": {
    _en: "View clicked image only",
  },
  "정상 이미지와 함께 보기": {
    _en: "View with reference image",
  },
  "아이콘 애니메이션 효과": {
    _en: "Icon Animation",
  },
  "Lesion 페이지에서 병변 이름과 확률이 표시되는 아이콘의 애니메이션을 여부를 지정할 수 있습니다.":
    {
      _en: "Select the default icon animation in the Lesion page.",
    },
  "GIF로 보기(애니메이션 연출)": {
    _en: "View as GIF(Display animation)",
  },
  "정지된 이미지로 보기기": {
    _en: "View as still image",
  },
  취소: {
    _en: "Cancel",
  },
  적용: {
    _en: "Apply",
  },
};

export default lang;
