import React from "react";
import { Route, Routes } from "react-router-dom";
import { CustomRoute } from "routes";
import { ModalPage } from "pages";

export default function App() {
  return (
    <>
      <Routes>
        <Route path="*" element={<CustomRoute></CustomRoute>} />
      </Routes>
      <ModalPage />
    </>
  );
}
