const lang = {
  장상피화생: {
    _en: "Intestinal Metaplasia",
  },
  "Intestinal Metaplasia": {
    _en: " ",
  },
  히트맵: {
    _en: "Heatmap",
  },
  "이상 병변": {
    _en: "Abnormality",
  },
  Abnormality: {
    _en: " ",
  },
  정상: {
    _en: "Normal",
  },
  병변: {
    _en: "Abnormality",
  },
  "병변 위치 보기": {
    _en: "view lesion location",
  },
  돌아가기: {
    _en: "Back",
  },
};

export default lang;
