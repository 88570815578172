import styled from "styled-components";

const Container = styled.div`
  & .imgListWrap {
    display: flex;
    flex-wrap: wrap;
  }

  & .imgList {
    &:hover {
      background-color: #4d4d4d;
    }

    &.on {
      background-color: #777777;
    }
  }

  & img {
    width: 100%;
    height: 100%;
    object-fit: fill;
  }

  & .layerGroup > div:last-child {
    // display: none;
  }
`;

export { Container };

export default { Container };
