import { useState, useEffect } from "react";
import Styles from "./styles";
import ui from "components/ui";
import { TableCheckup } from "pages/CheckupNewPage/components";
import { useLanguage } from "hooks";

import { api } from "service";
// img
import IconClose from "resources/image/icon/icon_close.svg";
import IconSearch from "resources/image/icon/icon_search.svg";
import utils from "utils";

export default function SearchCheckupModal({ modalOption, modalClose }) {
  const lang = useLanguage("Patient");

  const [birth, setBirth] = useState("");
  const [date, setDate] = useState("");
  const [filterInfo, setFilterInfo] = useState({
    page: 1,
    idOrName: "",
    endDate: "",
    startDate: "",
  });
  const [tableData, setTableData] = useState([]);

  useEffect(() => {
    if (modalOption) {
      setFilterInfo((f) => {
        f.idOrName = modalOption?.searchWord;
        return { ...f };
      });
      getPatientList(true);
    }
  }, [modalOption]);

  const getPatientList = async (isInit) => {
    let res;
    try {
      if (isInit) {
        let obj = {
          page: 1,
          idOrName: modalOption?.searchWord,
        };
        res = await api.doctor.getList(obj, 1);
      } else {
        let filter = filterInfo;
        if (!filter.idOrName) {
          delete filter.idOrName;
        }

        if (filter.startDate)
          filter.startDate = filter.startDate.replace(/-/gi, "");
        if (filter.endDate) filter.endDate = filter.endDate.replace(/-/gi, "");
        console.log(filter);

        res = await api.doctor.getList(filterInfo, 1);
      }

      if (res) {
        setTableData(JSON.parse(JSON.stringify(res)));
      } else {
        setTableData([]);
      }
    } catch (e) {
      setTableData([]);
    }
  };

  const onKeyUpEnter = (e) => {
    if (e.key == "Enter") {
      getPatientList();
    }
  };

  const onBdayChange = () => {
    let num = "20230108";
    let year = num.slice(0, 4);
    let month = num.slice(4, 6);
    let day = num.slice(6, 8);
    let resultText = year + (month ? "." + month : "") + (day ? "." + day : "");
    console.log(resultText);
  };

  onBdayChange();

  return (
    <Styles.Container>
      <div
        className="overlay"
        onClick={() => {
          modalClose();
        }}
      ></div>

      <div className="inner">
        <div className="topContainer">
          <form>
            <ul className="inputListWrap">
              <li className="inputList">
                <p className="inputTitle">{lang.getLang("이름 / 환자 ID")}</p>
                <div className="inputContainer name">
                  <ui.input.BasicInput
                    placeholder={lang.getLangString("이름 또는 ID")}
                    isEnter={true}
                    value={filterInfo.name}
                    setValue={(e) => {
                      setFilterInfo((f) => {
                        f.idOrName = e;
                        return { ...f };
                      });
                    }}
                    keyUp={(e) => {
                      onKeyUpEnter(e);
                    }}
                  />
                </div>
              </li>

              <li className="inputList">
                <p className="inputTitle">{lang.getLang("검진일")}</p>
                <ui.button.CalendarButton
                  dateChange={filterInfo.startDate}
                  setDateChange={(e) => {
                    setFilterInfo((f) => {
                      f.startDate = e;
                      return { ...f };
                    });
                  }}
                />
                <div className="inputContainer">
                  <ui.input.BasicInput
                    value={
                      utils.etc.numberToDate(filterInfo.startDate).resultText
                    }
                    setValue={(e) => {
                      setFilterInfo((f) => {
                        f.startDate = e;
                        return { ...f };
                      });
                    }}
                    placeholder={"2023-01-01"}
                  />
                </div>
              </li>

              <li className="inputList">
                <p className="inputTitle">~</p>
                <ui.button.CalendarButton
                  dateChange={filterInfo.endDate}
                  setDateChange={(e) => {
                    setFilterInfo((f) => {
                      f.endDate = e;
                      return { ...f };
                    });
                  }}
                />
                <div className="inputContainer">
                  <ui.input.BasicInput
                    value={
                      utils.etc.numberToDate(filterInfo.endDate).resultText
                    }
                    setValue={(e) => {
                      setFilterInfo((f) => {
                        f.endDate = e;
                        return { ...f };
                      });
                    }}
                    placeholder={"2023-12-31"}
                  />
                </div>
                <button
                  type="button"
                  className="searchButton"
                  onClick={() => {
                    if (
                      !filterInfo.startDate ||
                      !filterInfo.endDate ||
                      filterInfo.startDate <= filterInfo.endDate
                    ) {
                      getPatientList();
                    } else {
                      alert("종료일이 시작일보다 빠를수 없습니다.");
                    }
                  }}
                >
                  <span className="iconWrap">
                    <img src={IconSearch} alt="search" />
                  </span>
                </button>
              </li>
            </ul>
          </form>
        </div>

        <TableCheckup
          list={tableData}
          // url={"/image/detail/1/1"}
          modalClose={modalClose}
          searchCheckupModal
          modal
        />

        <button
          type="button"
          className="closeButton"
          onClick={() => {
            modalClose();
          }}
        >
          <img src={IconClose} alt="close" />
        </button>
      </div>
    </Styles.Container>
  );
}