import styled from "styled-components";

const Container = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: 15;

  & .inner {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: calc(100vw * (1820 / 1920));
    height: calc(100vw * (820 / 1920));
    padding: calc(100vw * (25 / 1920)) 0 calc(100vw * (14 / 1920));
    background: #292929;

    & .imContainer {
      display: flex;
      gap: calc(100vw * (32 / 1920));

      & .imgContainer {
        width: calc(100vw * (420 / 1920));
        height: calc(100vw * (420 / 1920));
      }

      & .swiper-button-prev {
        top: calc(100vw * (20 / 1920));
      }

      & .swiper-button-next {
        top: auto;
        bottom: calc(100vw * (0 / 1920));
      }
    }

    & .buttonContainer {
      display: flex;
      justify-content: flex-end;
      gap: calc(100vw * (11 / 1920));
      margin: 0 calc(100vw * (12 / 1920)) 0 auto;

      & button {
        width: calc(100vw * (200 / 1920));
        height: calc(100vw * (48 / 1920));
        font-size: calc(100vw * (26 / 1920));
        font-weight: 700;
        border-radius: calc(100vw * (11 / 1920));
      }

      & .submitButton {
        background: #0075ff;
      }

      & .cancelButton {
        background: #848b99;
      }
    }
  }

  & .overlay {
    width: 100%;
    height: 100%;
    background: rgba(34, 34, 34, 0.6);
  }

  @media (max-width: 1366px) {
    & .inner .imContainer {
      & .swiper-button-prev {
        top: calc(100vw * (45 / 1920));
      }

      & .swiper-button-next {
        bottom: calc(100vw * (15 / 1920));
      }
    }
  }
`;

export { Container };

export default { Container };
