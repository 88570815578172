import styled from "styled-components";

// img
import Logo from "resources/image/icon/logo.svg";
import ChkOn from "resources/image/icon/icon_checked_circle.svg";
import ChkOff from "resources/image/icon/icon_check_circle.svg";

const Container = styled.div`
  width: 100%;
  min-height: 100vh;
  min-height: 100dvh;
  padding: calc(100vw * (100 / 1920)) calc(100vw * (32 / 1920)) 0;
  background: url(${Logo}) no-repeat left calc(100vw * (30 / 1920)) bottom
    calc(100vw * (30 / 1920)) / contain;
  background-size: calc(100vw * (204 / 1920)) calc(100vw * (36 / 1920));
  background-color: #000;

  & .setting_box {
    position: relative;
    width: calc(100vw * (1200 / 1920));
    height: calc(100vh - calc(100vw * (170 / 1920)));
    height: calc(100dvh - calc(100vw * (170 / 1920)));
    padding: calc(100vw * (52 / 1920)) calc(100vw * (24 / 1920))
      calc(100vw * (100 / 1920)) calc(100vw * (98 / 1920));
    background: #292929;
    margin: calc(100vw * (20 / 1920)) auto 0;
    border-radius: calc(100vw * (20 / 1920));
  }

  & .inner {
    height: 100%;
    overflow-y: auto;
    padding: 0 calc(100vw * (50 / 1920)) calc(100vw * (146 / 1920)) 0;
  }
  & .inner::-webkit-scrollbar {
    width: calc(100vw * (16 / 1920));
    height: calc(100vw * (16 / 1920));
    background-color: #1c1c1c;
    border-radius: calc(100vw * (20 / 1920));
  }
  & .inner::-webkit-scrollbar-track {
    background: transparent;
  }
  & .inner::-webkit-scrollbar-thumb {
    background: #333333;
    border-radius: 999px;
    background-clip: padding-box;
    border-top: calc(100vw * (4 / 1920)) solid transparent;
    border-bottom: calc(100vw * (4 / 1920)) solid transparent;
    border-right: calc(100vw * (4 / 1920)) solid transparent;
    border-left: calc(100vw * (4 / 1920)) solid transparent;
  }
  & .row:not(:last-of-type) {
    margin-bottom: calc(100vw * (80 / 1920));
  }
  & .title_box {
    width: 100%;
  }
  & .title_box p {
    display: flex;
    align-items: center;
    gap: calc(100vw * (6 / 1920));
    font-size: calc(100vw * (30 / 1920));
    font-weight: 600;
  }
  & .title_box p span {
    color: #008ad1;
  }
  & .title_box > span {
    display: block;
    font-size: calc(100vw * (20 / 1920));
    font-weight: 600;
    margin-top: calc(100vw * (10 / 1920));
  }

  & .inner_box {
    display: flex;
    gap: calc(100vw * (240 / 1920));
    padding-top: calc(100vw * (20 / 1920));
  }
  /* radio */
  & .radiobox {
    position: relative;
    margin-top: calc(100vw * (14 / 1920));
    margin-left: calc(100vw * (34 / 1920));
  }
  & .radiobox input {
    position: absolute;
    left: 0;
    top: 0;
    width: 1px;
    height: 1px;
  }
  & .radiobox input:checked + label::after {
    content: "";
    position: absolute;
    top: calc(100vw * (-16 / 1920));
    left: 0;
    display: block;
    width: calc(100vw * (35 / 1920));
    height: calc(100vw * (34 / 1920));
    background: url(${ChkOn}) no-repeat center/100%;
    margin-right: calc(100vw * (10 / 1920));
  }
  & .radiobox label {
    display: flex;
    align-items: center;
    font-size: calc(100vw * (20 / 1920));
    font-weight: 500;
    cursor: pointer;
  }
  & .radiobox label::before {
    content: "";
    display: block;
    width: calc(100vw * (23 / 1920));
    height: calc(100vw * (23 / 1920));
    background: url(${ChkOff}) no-repeat center/100%;
    margin-right: calc(100vw * (10 / 1920));
  }

  /* 위장 */
  & .stomach_box {
    display: flex;
    width: 100%;
    padding-top: calc(100vw * (30 / 1920));

    & .stomachContainer {
      flex-shrink: 0;
      width: calc(100vw * (288 / 1920));
      height: calc(100vw * (253 / 1920));
      margin-right: calc(100vw * (56 / 1920));
    }

    & .stomachListWrap,
    .stomachList {
      display: flex;
      align-items: center;
    }

    & .stomachListWrap {
      flex-wrap: wrap;
      gap: calc(100vw * (15 / 1920));

      & .stomachList {
        position: relative;
        gap: calc(100vw * (25 / 1920));
        width: calc(100vw * (319 / 1920));
        padding: 0 calc(100vw * (9 / 1920));
        font-size: calc(100vw * (20 / 1920));
        font-weight: bold;
        cursor: pointer;

        &.on::after,
        &:hover::after {
          position: absolute;
          top: 50%;
          left: 0;
          transform: translateY(-50%);
          width: 100%;
          height: calc(100vw * (45 / 1920));
          background: #d9d9d9;
          opacity: 0.2;
          border-radius: calc(100vw * (18 / 1920));
          content: "";
        }
      }
    }
  }

  /* 버튼 */
  & .button_box {
    position: absolute;
    right: calc(100vw * (20 / 1920));
    bottom: calc(100vw * (20 / 1920));
    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: calc(100vw * (12 / 1920));
  }
  & .button_box button {
    width: calc(100vw * (140 / 1920));
    height: calc(100vw * (44 / 1920));
    font-size: calc(100vw * (24 / 1920));
    font-weight: bold;
    border-radius: calc(100vw * (10 / 1920));
  }
  & .button_box .cancel {
    background-color: #6a6a6a;
  }
  & .button_box .save {
    background-color: #008ad1;
  }
`;

export { Container };

export default { Container };
