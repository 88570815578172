import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import ui from "components/ui";
import Styles from "./styles";

// img
import IconBack from "resources/image/icon/icon_back.svg";


export default function Layout() {

  return (
    <Styles.Container>
    </Styles.Container>
  );
}

