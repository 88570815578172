import Styles from "./styles";
import { useLanguage } from "hooks";

export default function  StateButton({ type, callback }) {
  const lang = useLanguage("Common");
  const btnlistMall = () => {
    switch (type) {
      case 4:
        return (
          <Styles.State>
            <p className="bl">{lang.getLang("Uploaded")}</p>
            <p type="button" className="overlay" onClick={callback}></p>
          </Styles.State>
        );
      case 3:
        return (
          <Styles.State>
            <p>{lang.getLang("대기 중")}</p>
          </Styles.State>
        );
      case 2:
        return (
          <Styles.State>
            <p>{lang.getLang("저장 중")}</p>
          </Styles.State>
        );
      case 1:
        return (
          <Styles.State>
            <p className="gr">{lang.getLang("검진완료")}</p>
          </Styles.State>
        );
      case 0:
        return (
          <Styles.State>
            <p className="gr">{lang.getLang("전송완료")}</p>
          </Styles.State>
        );

      default:
        return <p>-</p>;
    }
  };

  return btnlistMall();
}
