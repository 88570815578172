import styled from "styled-components";

const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: calc(100vw * (35 / 1920));
  height: calc(100vw * (35 / 1920));
  font-size: calc(100vw * (24 / 1920));
  font-weight: bold;
  color: #fff;
  text-shadow: 0px calc(100vw * (3 / 1920)) calc(100vw * (4 / 1920))
    rgba(0, 0, 0, 0.5);
  border-radius: 50%;
  background-color: #008ad1;
  box-shadow: 0px calc(100vw * (3 / 1920)) calc(100vw * (2 / 1920))
    rgba(0, 0, 0, 0.3);
  cursor: pointer;

  &.cancer {
    background-color: #cc004c;
  }

  &.adenoma {
    background-color: #f37021;
  }

  &.polyp {
    background-color: #04B24A;
  }

  &.IM {
    background-color: #fcb711;
  }

  &.green {
    background-color: #04b24a;
  }

  &.none {
    background-color: #000;
  }
`;

export { Container };

export default { Container };
