import Styles from "./styles";

export default function BasicRadio({
  id,
  checked,
  onChange,
  name,
  text,
  onBlur,
}) {
  return (
    <Styles.InputBox>
      <input
        type="radio"
        id={id}
        name={name}
        checked={checked}
        onChange={onChange}
        onBlur={onBlur}
      />

      <label htmlFor={id}>
        <span className="dotContainer"></span>

        <span className="labelText">{text}</span>
      </label>
    </Styles.InputBox>
  );
}
