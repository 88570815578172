import { useAtom } from "jotai";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { useNavigate } from "react-router-dom";
import { useState } from "react";
import { api } from "service";
import { authAtom, configAtom } from "store/atoms";
import utils from "utils";

export const useConfig = () => {
  const [configData, setConfigData] = useAtom(configAtom);

  //간소화 여부 TRUE / FALSE
  /*
    const configInfo = useConfig();
    아래 형태로 사용필요 
    configInfo.isSimpleMode ? "class" : "class"
  */
  const [setting, setSetting] = useState({
    isSimpleMode: false,
  });

  const navigate = useNavigate();
  const queryClient = useQueryClient();

  const configQuery = useQuery(["settings"], () => api.setting.getSetting(), {
    refetchOnWindowFocus: false,
    onSuccess(data) {
      setConfigData(data);
    },
  });

  const { mutate: updateSettingMutate } = useMutation(
    (settings) => api.setting.updateSetting(settings),
    {
      onSuccess() {
        queryClient.invalidateQueries(["settings"]);
        navigate(-1);
        alert("update setting success");
      },
      onError() {
        console.log("update fail");
      },
    }
  );

  const onUpdateSetting = (settingData) => {
    const { stomachView, startLandmark, lesionMark, withNormal, isGIF } =
      settingData;

    updateSettingMutate({
      stomachView: utils.etc.booleanToNum(stomachView),
      startLandmark,
      lesionMark: utils.etc.booleanToNum(lesionMark),
      withNormal: utils.etc.booleanToNum(withNormal),
      isGIF: utils.etc.booleanToNum(isGIF),
    });
  };

  return { configQuery, configData, setConfigData, onUpdateSetting, setting };
};
