import React, { useState } from "react";
import Styles from "./styles";
import _stomach from "_stomach";
import { common, layout } from "components";

//img
import LineStomach01 from "resources/image/stomach_line/stomach_line_01.png";
import LineStomach02 from "resources/image/stomach_line/stomach_line_02.png";
import LineStomach03 from "resources/image/stomach_line/stomach_line_03.png";
import LineStomach04 from "resources/image/stomach_line/stomach_line_04.png";
import LineStomach05 from "resources/image/stomach_line/stomach_line_05.png";
import LineStomach06 from "resources/image/stomach_line/stomach_line_06.png";
import LineStomach07 from "resources/image/stomach_line/stomach_line_07.png";
import LineStomach08 from "resources/image/stomach_line/stomach_line_08.png";
import LineStomach09 from "resources/image/stomach_line/stomach_line_09.png";
import LineStomach10 from "resources/image/stomach_line/stomach_line_10.png";
import LineStomach11 from "resources/image/stomach_line/stomach_line_11.png";

export default function StomachSection({
  hoverSection,
  setHoverSection,
  notSelectedSection,
  setNotSelectedSection,
  landmarkTypeList,
  onClickStomachSection,
}) {
  const lineListData = [
    LineStomach01,
    LineStomach02,
    LineStomach03,
    LineStomach04,
    LineStomach05,
    LineStomach06,
    LineStomach07,
    LineStomach08,
    LineStomach09,
    LineStomach10,
    LineStomach11,
  ];

  return (
    <Styles.Container>
      {_stomach.stomachImageList.map((item, index) => {
        return (
          <div
            className="numberIconContainer"
            key={"numberIconContainer" + index}
          >
            <Styles.NumberIconWrap
              className={"numberIconWrap" + (notSelectedSection ? " on" : "")}
              isHover={
                hoverSection === `${item.id}` || hoverSection === item.id
              }
            >
              <common.NumberIcon
                type={landmarkTypeList?.[index]}
                number={item.id}
              />
            </Styles.NumberIconWrap>

            <Styles.Line
              className={"line" + (notSelectedSection ? " on" : "")}
              isHover={
                hoverSection === `${item.id}` || hoverSection === item.id
              }
            >
              <img src={lineListData[item.id - 1]} alt="line" />
            </Styles.Line>
          </div>
        );
      })}

      <layout.Stomach
        hoverSection={hoverSection}
        setHoverSection={setHoverSection}
        setNotSelectedSection={setNotSelectedSection}
        onClickStomachSection={(landmark) => {
          onClickStomachSection?.(landmark);
        }}
      />
    </Styles.Container>
  );
}
