import { common, layout } from "components";
import { getCookie } from "service/connector";
import Styles from "./styles";
import { useLanguage } from "hooks";
import { useRef, useState, useEffect, useMemo } from "react";
import { useParams, useNavigate, useLocation } from "react-router-dom";
import { useQuery } from "react-query";
import { useConfig } from "hooks/useConfig";
import { useReport } from "hooks/useReport";
import { api } from "service";

export default function LesionPage() {
  const lang = useLanguage("Lesion");
  const navigate = useNavigate();
  const { id, studyId, landmark } = useParams();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const fromImageId = queryParams.get("imageId");
  const [selectCheckList, setSelectCheckList] = useState("장상피화생");
  const onceSetHasLesions = useRef(false);
  const configInfo = useConfig();
  const reportInfo = useReport();

  const [scope, setScope] = useState("lite");
  const [checkLesion, setCheckLesion] = useState(
    configInfo.configData.lesionMark
  );

  // imageId useMemo 추가
  const imageId = useMemo(() => {
    if (fromImageId && fromImageId > 0) return fromImageId;
  }, [fromImageId]);

  useEffect(() => {
    setScope(getCookie("scope"));
  }, []);

  const { data: patientsData = {} } = useQuery(
    ["patients_info", id],
    () => api.patient.getInfo(id),
    {
      refetchOnWindowFocus: false,
    }
  );

  const { data: isLesionsData, isLoading: isLoadingIsLesions } = useQuery(
    ["is-lesions", studyId],
    () => api.image.getIsLesions({ medicalstudyId: studyId }),
    {
      refetchOnWindowFocus: false,
      onSuccess(response) {
        if (onceSetHasLesions.current) return;
        if (response.abnormality) {
          setSelectCheckList("이상 병변");
          onceSetHasLesions.current = true;
        }
      },
    }
  );

  const hasAbnormality = isLesionsData?.abnormality;

  const [lesionIndex, setLesionIndex] = useState(0);
  const [imIndex, setImIndex] = useState(0);
  const [imLandmark, setImLandmark] = useState(landmark);

  const handleLesionIndexChange = (lesionIdx) => {
    setLesionIndex(lesionIdx);
  };

  const handleImIndexChange = (imIdx) => {
    setImIndex(imIdx);
  }

  const handleImLandmarkChange = (landmark) => {
    setImLandmark(landmark);
    handleImIndexChange(0);
    navigate(`/lesion/${id}/${studyId}/${landmark}`);
  }

  const checkListData = [
    {
      id: "장상피화생",
      text: (
        <>
          {scope === "lite" ? (
            <>
              {lang.getLang("히트맵")} <span>{lang.getLang("Heatmap")}</span>
            </>
          ) : (
            <>
              {lang.getLang("장상피화생")}{" "}
              <span>{lang.getLang("Intestinal Metaplasia")}</span>
            </>
          )}
        </>
      ),
    },
    {
      id: "이상 병변",
      text: (
        <>
          {lang.getLang("이상 부위")} <span>{lang.getLang("Abnormality")}</span>
        </>
      ),
      disabled: !hasAbnormality,
    },
  ];

  console.log("PatientsData", patientsData);

  const commonProps = {
    patientsData,
    checkListData,
    selectCheckList,
    setSelectCheckList,
    imageId,
    imIndex,
    imLandmark,
    lesionIndex,
  };

  if (isLoadingIsLesions) return <></>;

  return (
    <>
      <common.SubHeader />
      <Styles.Container>
        {selectCheckList === "장상피화생" && (
          <>
            <layout.IMCheckedSection {...commonProps} onImIndexChange={handleImIndexChange} onLandmarkChange={handleImLandmarkChange} />
            {/* {scope !== "lite" && (
              <button
                type="button"
                className="imAnalysisButton"
                onClick={() => {
                  navigate(`/lesion/im-analysis/${id}/${studyId}`);
                }}
              >
                IM Analysis
              </button>
            )} */}
          </>
        )}

        {selectCheckList === "이상 병변" && (
          <layout.AbnormalityCheckedSection {...commonProps} onLesionIndexChange={handleLesionIndexChange}/>
        )}
      </Styles.Container>
    </>
  );
}
