import Styles from "./styles";
import layout from "components/layout";
import ReportChangeAbnormalityCheckedSection from "components/layout/ReportChangeAbnormalityCheckedSection";
import { useLanguage } from "hooks";
import { useMemo, useState } from "react";
import { useQuery } from "react-query";
import { api } from "service";
import { lesionTypeToColor } from "utils/common";

export default function ChangeAbnormalityModal({ modalClose, modalOption }) {
  const { studyId, score, type, onClickChangeAbnormality } = modalOption;
  const lang = useLanguage("Report");

  const [selectedAbnormalityData, setSelectedAbnormalityData] = useState(null);

  const [lesionIndex, setLesionIndex] = useState(0);

  const {
    data: lesionsPathData = [],
    isSuccess: isSuccessLesionsPath,
    isLoading: isLoadingLesions,
  } = useQuery(
    ["lesions-abnormality-path", studyId],
    () => api.image.getLesionsAbnormalityPath({ studyId: studyId }),
    {
      refetchOnWindowFocus: false,
    }
  );

  const currentLesionPathData = lesionsPathData?.[lesionIndex];
  const landmark = currentLesionPathData?.landmark;

  const lesionType = lesionTypeToColor(selectedAbnormalityData?.lesion?.type);

  const currentImage = useMemo(() => {
    return lesionsPathData[lesionIndex] || null;
  }, [lesionsPathData, lesionIndex]);

  const currentLesion = currentImage?.lesion?.[0];
  const lesionColorType = lesionTypeToColor(currentLesion?.type);

  const notSelectedSection = [
    {
      landmark: Number(landmark),
      type: lesionColorType,
    },
  ];

  return (
    <Styles.Container>
      <div
        className="overlay"
        onClick={() => {
          modalClose();
        }}
      ></div>

      <div className="inner">
        <div className="abnormailtyContainer">
          <layout.ScoreSection
            noAni
            landmark={landmark}
            score={currentLesion?.score}
            scoreType={lesionColorType}
            notSelectedSection={notSelectedSection}
            numberType={lesionColorType}
            screen={"abnormality"}
          />
          <ReportChangeAbnormalityCheckedSection
            landmark={landmark}
            studyId={studyId}
            lesionIndex={lesionIndex}
            setLesionIndex={setLesionIndex}
            onChangeAbnormality={({ selected }) => {
              setSelectedAbnormalityData(selected);
            }}
          />
        </div>

        <div className="buttonContainer">
          <button
            type="button"
            className="submitButton"
            onClick={() => {
              onClickChangeAbnormality({ selected: selectedAbnormalityData });
              modalClose();
            }}
          >
            {lang.getLang("사진 변경")}
          </button>
          <button type="button" className="cancelButton" onClick={modalClose}>
            {lang.getLang("취소")}
          </button>
        </div>
      </div>
    </Styles.Container>
  );
}
