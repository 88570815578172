import { useQuery } from "react-query";
import Styles from "./styles";
import { useLanguage } from "hooks";
import { useParams } from "react-router-dom";
import { api } from "service";
import moment from "moment";
import { useReport } from "hooks/useReport";

export default function PatientInfoTable(props) {
  const lang = useLanguage("Report");
  const { id, studyId } = useParams();
  const reportInfo = useReport();

  const { data: reportsData = {}, isLoading: isLoadingReports } = useQuery(
    ["reports", studyId],
    () => api.reports.getReports({ medicalstudyId: studyId }),
    {
      enabled: reportInfo.reportData.status === false,
      refetchOnWindowFocus: false,
      onSuccess(responseReportsData) {

        console.log("PatientInfoTable responseReportsData: ", responseReportsData);
        const { 
          isComment,
          isLandmark,
          isIM,
          isAbnormality,
          isHistory,
          isSelectImage,
          comment,
          landmark,
          im,
          abnormality,
          history,
          selectImage
        } = responseReportsData;

        if (reportInfo.reportData.status === false) {

          console.log("Update reportInfo.reportData: ", responseReportsData);
          const initialSelectImage = selectImage || {
            imagePath1: null,
            imagePath2: null,
            imagePath3: null,
          };
  
          const initialHistory = {
            [history?.recentstudyDate]: {
              landmark: history?.landmark,
              path: history?.recentPath,
            },
            [history?.prevstudyDate]: {
              landmark: history?.landmark,
              path: history?.prevPath,
            },
          };

          reportInfo.setReportWriteData((prev) => ({
            patient: {
              patientName: reportsData.patientName,
              patientBirthdate: reportsData.patientBirthdate,
              patientSex: reportsData.patientSex,
              patientId: reportsData.patientId,
              prevStudyDate: reportsData.prevStudyDate,
              studyDate: reportsData.studyDate,
            },
            status: true,
            backFromPreview: false,
            isComment,
            isLandmark,
            isIM: isIM,
            isAbnormality,
            isHistory,
            isSelectImage,
            comment,
            landmark,
            ...(im && { im }),
            ...(abnormality && { abnormality }),
            ...(history && {
              history: initialHistory,
            }),
            selectImage: initialSelectImage,
          }));
        }

        console.log("PatientInfoTable reportInfo.reportData: ", reportInfo.reportData);
      },
    }
  );

  return (
    <Styles.Container viewPort={props.viewPort}>
      <table>
        <colgroup>
          <col span={2} />
        </colgroup>
        <tbody>
          <tr>
            <th>{lang.getLang("성명")}</th>
            <td>{reportsData.patientName}</td>
            <th>{lang.getLang("검진기관")}</th>
            <td>{reportsData.hospital}</td>
          </tr>

          <tr>
            <th>{lang.getLang("생년월일")}</th>
            <td>
              {moment(
                reportsData.birthday
                  ? reportsData.birthday
                  : reportsData.patientBirthdate
              )?.format("YYYY-MM-DD")}
            </td>
            <th>{lang.getLang("검진의")}</th>
            <td>{reportsData.doctorName}</td>
          </tr>

          <tr>
            <th>{lang.getLang("성별")}</th>
            <td>
              {String(reportsData.sex || reportsData.patientSex)?.toUpperCase()}
            </td>
            <th>{lang.getLang("검진일")}</th>
            <td>
              {reportsData.studyDate
                ? moment(reportsData.studyDate)?.format("YYYY-MM-DD")
                : reportsData.studyDate
                ? moment(reportsData.studyDate)?.format("YYYY-MM-DD")
                : "-"}
            </td>
          </tr>

          <tr>
            <th>{lang.getLang("환자번호")}</th>
            <td>{id}</td>
            <th>{lang.getLang("이전검진일")}</th>
            <td>
              {reportsData.prevstudyDate
                ? moment(reportsData.prevstudyDate)?.format("YYYY-MM-DD")
                : reportsData.prevStudyDate
                ? moment(reportsData.prevStudyDate)?.format("YYYY-MM-DD")
                : "-"}
            </td>
          </tr>
        </tbody>
      </table>
    </Styles.Container>
  );
}
