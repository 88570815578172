import Styles from "./styles";
import { useLanguage } from "hooks";
import { useMediaQuery } from "react-responsive";

// swiper
import { Swiper, SwiperSlide } from "swiper/react";
import { EffectCoverflow, Navigation, Keyboard } from "swiper";
import "swiper/css";
import "swiper/css/effect-coverflow";
import "swiper/css/navigation";
import { IMAGE_SRC } from "constants/common";
import { swiperOption } from "constants/common";
import { useEffect, useRef, useState } from "react";
import moment from "moment";
import { useLocation } from "react-router-dom";

export default function PastHistory({
  viewPort,
  data,
  landmark,
  onChangeHistorySelected,
  historyPage,
}) {
  const {
    recentlyImageData,
    pastImageData,
    imageId,
    normalImage,
    pastDateList,
  } = data;

  const location = useLocation();
  const currentLandmarkSwiperRef = useRef([]);
  const insideLandmarkSwiperRef = useRef([]);
  const dateSwiperListRef = useRef([]);

  const [recentlyIndex, setRecentlyIndex] = useState(
    imageId && recentlyImageData
      ? recentlyImageData.list.findIndex((image) => image.imageId === imageId)
      : 0
  );

  const [insideLandmarkIndex, setInsideLandmarkIndex] = useState(0);
  const [dateIndex, setDateIndex] = useState(0);

  const currentResentlyImageData = recentlyImageData?.list?.[recentlyIndex];
  console.log("그럼 현재 이미지 정보는?", currentResentlyImageData);

  const currentPastImageData = pastImageData[insideLandmarkIndex]
    ? pastImageData[insideLandmarkIndex][dateIndex]
    : [];

  useEffect(() => {
    onChangeHistorySelected?.({
      recentlySelected: currentResentlyImageData,
      pastSelected: currentPastImageData,
    });
  }, [currentResentlyImageData, currentPastImageData]);

  useEffect(() => {
    const insideLandmarkSwiper = insideLandmarkSwiperRef.current;

    const moveToImageData = pastImageData[insideLandmarkIndex]
      ? pastImageData[insideLandmarkIndex][dateIndex]
      : [];
    if (!moveToImageData) {
      insideLandmarkSwiper?.slideTo(insideLandmarkIndex - 1);
    }

    dateSwiperListRef.current.forEach((dateSwiper) => {
      if (dateSwiper.destroyed) return;
      dateSwiper?.slideTo(dateIndex);
    });
  }, [pastDateList, insideLandmarkIndex, dateIndex]);

  const lang = useLanguage("History");

  const isPro = useMediaQuery({
    query: "(max-width: 1366px)",
  });
  const isAir = useMediaQuery({
    query: "(max-width: 1180px)",
  });

  const formatPastDateList = pastDateList.map((item) =>
    moment(item).format("YYYY.MM.DD")
  );

  const timeLineListData = {
    nextDate: dateIndex - 1 > -1 ? formatPastDateList[dateIndex - 1] : null,
    nowDate: formatPastDateList[dateIndex],
    prevDate:
      formatPastDateList.length - 1 !== dateIndex
        ? formatPastDateList[dateIndex + 1]
        : null,
  };

  useEffect(() => {
    if (insideLandmarkSwiperRef.current)
      insideLandmarkSwiperRef.current?.slideTo(0);
    if (currentLandmarkSwiperRef.current)
      currentLandmarkSwiperRef.current?.slideTo(recentlyIndex);
  }, [location]);

  return (
    <Styles.Container
      viewPort={viewPort}
    >
      <div className={"sliderContainer" + (historyPage ? " historyPage" : "")}>
        <div className="swiperContainer">
          <p className="title">
            {moment(recentlyImageData?.date).format("YYYY.MM.DD")}(
            {lang.getLang("최근 촬영본")})
          </p>
          <Swiper
            direction={"vertical"}
            effect={"coverflow"}
            grabCursor={true}
            initialSlide={0}
            centeredSlides={true}
            slidesPerView={"auto"}
            coverflowEffect={
              isAir
                ? swiperOption.AirOption
                : isPro
                ? swiperOption.ProOption
                : swiperOption.option
            }
            modules={[EffectCoverflow, Navigation, Keyboard]}
            navigation={true}
            keyboard={{
              onlyInViewport: true,
            }}
            onSwiper={(swiper) => {
              if (swiper.destroyed) return;
              currentLandmarkSwiperRef.current = swiper;
            }}
            onSlideChange={(swiper) => {
              setRecentlyIndex(swiper.activeIndex);
            }}
            className="recentSwiper"
          >
            {recentlyImageData?.list?.length > 0 ? (
              recentlyImageData?.list?.map((item, index) => {
                return (
                  <SwiperSlide
                    className="imgContainer slider"
                    key={"recentSwiper" + landmark + index}
                  >
                    <img src={IMAGE_SRC + item.path} alt="stomach" />
                  </SwiperSlide>
                );
              })
            ) : (
              <SwiperSlide className="imgContainer slider emptySlider">
                {lang.getLangString("해당 위 부위의 최근 촬영본이 없습니다.")}
              </SwiperSlide>
            )}
          </Swiper>
        </div>

        <div className="swiperContainer">
          <p className="title">
            {moment(pastDateList[dateIndex]).format("YYYY.MM.DD")}
          </p>

          <Swiper
            direction={"vertical"}
            effect={"coverflow"}
            grabCursor={true}
            initialSlide={0}
            centeredSlides={true}
            slidesPerView={"auto"}
            keyboard={{
              onlyInViewport: true,
            }}
            onSwiper={(swiper) => {
              if (swiper.destroyed) return;
              insideLandmarkSwiperRef.current = swiper;
            }}
            coverflowEffect={
              isAir
                ? swiperOption.AirOption
                : isPro
                ? swiperOption.ProOption
                : swiperOption.option
            }
            modules={[EffectCoverflow, Navigation, Keyboard]}
            navigation={true}
            className="pastSwiper"
            onSlideChange={({ activeIndex }) => {
              // 상하
              setInsideLandmarkIndex(activeIndex);
            }}
          >
            {pastImageData?.map((item, index) => {
              return (
                <SwiperSlide
                  className="imgContainer slider"
                  key={"pastSwiper" + index}
                >
                  <div className="sub swiperContainer">
                    <Swiper
                      onSwiper={(swiper) => {
                        if (swiper.destroyed) return;
                        dateSwiperListRef.current[index] = swiper;
                      }}
                      effect={"coverflow"}
                      grabCursor={true}
                      initialSlide={0}
                      centeredSlides={true}
                      slidesPerView={"auto"}
                      coverflowEffect={
                        isAir
                          ? swiperOption.AirOption
                          : isPro
                          ? swiperOption.ProOption
                          : swiperOption.option
                      }
                      modules={[EffectCoverflow, Navigation, Keyboard]}
                      navigation={true}
                      keyboard={{
                        onlyInViewport: true,
                      }}
                      className="subSwiper"
                      onSlideChange={({ activeIndex }) => {
                        // 좌우 (날짜)
                        setDateIndex(activeIndex);
                        insideLandmarkSwiperRef.current?.slideTo(0);
                      }}
                    >
                      {item && item.map ? (
                        item.map((current, index) => {
                          if (
                            current == null &&
                            pastImageData[0][index] == null
                          ) {
                            return (
                              <SwiperSlide className="imgContainer slider emptySlider">
                                {lang.getLangString(
                                  "해당 위 부위의 과거 촬영본이 없습니다."
                                )}
                              </SwiperSlide>
                            );
                          }
                          return (
                            <SwiperSlide
                              className="imgContainer slider"
                              key={"subSwiper" + landmark + index}
                            >
                              {current && (
                                <img
                                  src={IMAGE_SRC + current.path}
                                  alt="stomach"
                                />
                              )}
                            </SwiperSlide>
                          );
                        })
                      ) : (
                        <SwiperSlide className="imgContainer slider emptySlider">
                          {lang.getLangString(
                            "해당 위 부위의 과거 촬영본이 없습니다."
                          )}
                        </SwiperSlide>
                      )}
                    </Swiper>
                  </div>
                  {/* <img src={imageData} alt="stomach" /> */}
                </SwiperSlide>
              );
            })}
            {/* <SwiperSlide className="imgContainer slider emptySlider">
              {lang.getLangString("해당 위 부위의 과거 촬영본이 없습니다.")}111
            </SwiperSlide> */}
          </Swiper>
        </div>

        <div className="normalContainer">
          <p className="title">{lang.getLang("정상 이미지")}</p>
          <div className="imgContainer">
            <img src={normalImage} alt="stomach" />
          </div>
        </div>
      </div>

      <div
        className={
          "timeLineContainer" +
          (timeLineListData.nextDate ? "" : " noNextDate") +
          (timeLineListData.prevDate ? "" : " noPrevDate")
        }
      >
        {timeLineListData.nextDate && (
          <div
            onClick={() => {
              setDateIndex((prev) => prev - 1);
              if (insideLandmarkSwiperRef.current)
                insideLandmarkSwiperRef.current?.slideTo(0);
              if (currentLandmarkSwiperRef.current)
                currentLandmarkSwiperRef.current?.slideTo(0);
            }}
            className="timeLine nextDate"
          >
            <span>{timeLineListData.nextDate}</span>
          </div>
        )}
        <div className="timeLine nowDate">
          <span>{timeLineListData.nowDate}</span>
        </div>
        {timeLineListData.prevDate && (
          <div
            onClick={() => {
              setDateIndex((prev) => prev + 1);
              if (insideLandmarkSwiperRef.current)
                insideLandmarkSwiperRef.current?.slideTo(0);
              if (currentLandmarkSwiperRef.current)
                currentLandmarkSwiperRef.current?.slideTo(0);
            }}
            className="timeLine prevDate"
          >
            <span>{timeLineListData.prevDate}</span>
          </div>
        )}
      </div>
    </Styles.Container>
  );
}
