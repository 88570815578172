import styled from "styled-components";

const Container = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: 15;

  & .inner {
    position: absolute;
    top: 50%;
    right: calc(100vw * (30 / 1920));
    transform: translateY(-50%);
    display: flex;
    flex-direction: column;
    align-items: center;
    width: calc(100% - calc(100vw * (530 / 1920)));
    height: 81vh;
    background: #292929;
    border-radius: calc(100vw * (20 / 1920));
    color: #ffffff;
    overflow: hidden;

    & .topContainer {
      width: 100%;
      padding: calc(100vw * (57 / 1920)) calc(100vw * (30 / 1920))
        calc(100vw * (49 / 1920)) calc(100vw * (30 / 1920));
      border-bottom: calc(100vw * (3 / 1920)) solid #fff;

      & .inputListWrap {
        display: flex;
        align-items: center;

        & .inputList {
          display: flex;
          align-items: center;

          &:first-child {
            margin-right: calc(100vw * (81 / 1920));
          }

          &:nth-of-type(2) {
            margin-right: calc(100vw * (17 / 1920));
          }

          & .inputTitle {
            margin-right: calc(100vw * (17 / 1920));
            font-size: calc(100vw * (24 / 1920));
            font-weight: 700;
          }

          & .inputContainer {
            width: calc(100vw * (230 / 1920));
            height: calc(100vw * (32 / 1920));
            margin-left: calc(100vw * (7 / 1920));

            & input {
              font-size: calc(100vw * (20 / 1920));
            }
          }
          & .inputContainer.name {
            width: calc(100vw * (250 / 1920));
            margin-right: 0;
            margin-left: 0;
          }

          & .searchButton {
            display: flex;
            justify-content: center;
            align-items: center;
            width: calc(100vw * (41 / 1920));
            height: calc(100vw * (33 / 1920));
            margin-left: calc(100vw * (18 / 1920));
            background: #ffffff;
            border-radius: calc(100vw * (7 / 1920));

            & .iconWrap {
              width: calc(100vw * (21 / 1920));
              height: calc(100vw * (21 / 1920));

              & img {
                display: block;
                width: 100%;
              }
            }
          }
        }
      }
    }

    & .list_box {
      height: calc(80vh - calc(100vw * (202 / 1920)));
    }

    & .closeButton {
      position: absolute;
      top: calc(100vw * (18 / 1920));
      right: calc(100vw * (22 / 1920));
      width: calc(100vw * (35 / 1920));
      height: calc(100vw * (35 / 1920));

      & img {
        display: block;
        width: 100%;
      }
    }
  }

  & .overlay {
    width: 100%;
    height: 100%;
    background: rgba(34, 34, 34, 0.6);
  }

  // datepicker
  /* & .react-datepicker__input-container input {
    display: none;
  } */
`;

export { Container };

export default { Container };
