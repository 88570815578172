import React from "react";
import Styles from "./styles";
import stomachData from "_stomach";

//img
import ImgStomachBg02 from "resources/image/stomach_bg_02.png";
import ImgStomach01 from "resources/image/stomach_01.png";
import ImgStomach02 from "resources/image/stomach_02.png";
import ImgStomach03 from "resources/image/stomach_03.png";
import ImgStomach04 from "resources/image/stomach_04.png";
import ImgStomach05 from "resources/image/stomach_05.png";
import ImgStomach06 from "resources/image/stomach_06.png";
import ImgStomach07 from "resources/image/stomach_07.png";
import ImgStomach08 from "resources/image/stomach_08.png";
import ImgStomach09 from "resources/image/stomach_09.png";
import ImgStomach10 from "resources/image/stomach_10.png";
import ImgStomach11 from "resources/image/stomach_11.png";

export default function Stomach({
  settingData,
  setSettingData,
  setNotSelectedSection,
  hoverSection,
  setHoverSection,
  onClickStomachSection = () => {},
}) {
  const imageListData = [
    ImgStomach01,
    ImgStomach02,
    ImgStomach03,
    ImgStomach04,
    ImgStomach05,
    ImgStomach06,
    ImgStomach07,
    ImgStomach08,
    ImgStomach09,
    ImgStomach10,
    ImgStomach11,
  ];

  function handleSectionIn(e) {
    e.preventDefault();
    e.stopPropagation();
    setHoverSection(e.target.id);
    setNotSelectedSection(true);
  }

  function handleSectionOut(e) {
    e.preventDefault();
    e.stopPropagation();
    setHoverSection("");
    setNotSelectedSection(false);
  }

  return (
    <Styles.Container>
      <div className="stomachSection">
        {stomachData.stomachImageList.map((item, index) => {
          return (
            <div className="stomachParts" key={"stomachParts" + index}>
              <div
                className="stomachSectionDiv"
                onMouseEnter={(e) => handleSectionIn(e)}
                onMouseLeave={(e) => handleSectionOut(e)}
                onClick={(e) => {
                  setSettingData?.((f) => {
                    f.startLandmark = e.target.id;
                    return { ...f };
                  });
                }}
              >
                <Styles.StomachSectionImg
                  onClick={() => {
                    const landmark = index + 1;
                    onClickStomachSection({ landmark });
                  }}
                  alt="section"
                  src={imageListData[item.id - 1]}
                  id={item.id}
                  polygon={item.polygon}
                  transformorigin={item.transformorigin}
                  isHover={
                    hoverSection === `${item.id}` || hoverSection === item.id
                  }
                  isSelected={
                    settingData?.startLandmark === `${item.id}` ||
                    settingData?.startLandmark === item.id
                  }
                />
              </div>
            </div>
          );
        })}
      </div>
      <img
        className={
          "stomachBaseImg" +
          (hoverSection || settingData?.startLandmark ? " on" : "")
        }
        src={ImgStomachBg02}
        alt="stomach"
      />
    </Styles.Container>
  );
}
