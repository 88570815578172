import { useState, useEffect } from "react";
import Stomach from "../Stomach";
import Styles from "./styles";

export default function MiniStomach({
  selectedSection,
  setSelectedSection,
  hoverSection,
  setHoverSection,
  onClickStomachSection,
}) {
  const [notSelectedSection, setNotSelectedSection] = useState(false);

  return (
    <Styles.Container>
      <div className="stomachContainer">
        <Stomach
          selectedSection={selectedSection}
          setSelectedSection={setSelectedSection}
          hoverSection={hoverSection}
          setHoverSection={setHoverSection}
          setNotSelectedSection={setNotSelectedSection}
          onClickStomachSection={onClickStomachSection}
        />
      </div>
    </Styles.Container>
  );
}
