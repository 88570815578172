import { common, ui } from "components";
import { useState } from "react";
import Styles from "./styles";
import { useNavigate } from "react-router-dom";
import { useLanguage } from "hooks";
import { api } from "service";

function CreateUserPage() {
  const lang = useLanguage("Admin");
  const navigate = useNavigate();

  const [idValue, setIdValue] = useState("");
  const [passwordValue, setPasswordValue] = useState("");
  const [confirmPassowrdValue, setConfirmPassowrdValue] = useState("");
  const [nameValue, setNameValue] = useState("");
  const [phoneValue, setPhoneValue] = useState("");
  const [typeCheck, setTypeCheck] = useState(1);

  const [idCheckState, setIdCheckState] = useState(-1);
  const [pwCheckState, setPwCheckState] = useState(-1);
  const [rePwCheckState, setRePwCheckState] = useState(true);
  const [nameCheckState, setNameCheckState] = useState(false);
  const [phoneCheckState, setPhoneCheckState] = useState(false);

  const [isDisabled, setIsDisabled] = useState(true);

  // map
  const typeListData = [
    {
      id: "의사",
      text: <>{lang.getLang("의사")}</>,
    },
    {
      id: "간호사",
      text: <>{lang.getLang("간호사")}</>,
    },
  ];

  // 아이디 validation
  function isIdCheck(id) {
    // const idExp = /^[a-zA-Z0-9_]{6,20}$/;
    const idExp = /^[a-zA-Z0-9]{2,20}$/
    return idExp.test(id);
  }
  // 아이디 중복검사
  const idCheck = async () => {
    if (idValue.length == 0) {
      setIdCheckState(3);
      return false;
    }
    if (!isIdCheck(idValue)) {
      setIdCheckState(2);
      return false;
    }

    let result = await api.admin.getChecksId(idValue);
    if (result.message == "this id is available") {
      setIdCheckState(1);
      return true;
    } else {
      setIdCheckState(0);
      return false;
    }
  };

  // 비밀번호 validation
  function passwordCheck() {
    // const pwRegEx = /^(?=.*[a-zA-Z])(?=.*[!@#$%^*+=-])(?=.*[0-9]).{8,15}$/;
    const pwRegEx = /^(?=.*[a-zA-Z])(?=.*\d)(?=.*[-_\.?!@#\$%\^&\*])[a-zA-Z\d\-_\.?!@#\$%\^&\*]{4,20}$/
    let result = pwRegEx.test(passwordValue);
    let isMatch = passwordValue == confirmPassowrdValue;
    setPwCheckState(result ? 1 : 2);
    setRePwCheckState(isMatch);

    if (passwordValue.length == 0) {
      setPwCheckState(0);
      return false;
    }

    return result && isMatch;
  }

  // 이름 validation
  function nameCheck() {
    if (!nameValue) {
      setNameCheckState("NAME_EMPTY");
      return false;
    }
    // const nameEx = /[\{\}\[\]\/?.,;:|\)*~`!^\-_+<>@\#$%&\\\=\(\'\"]/g;  // 원래 있던 정규식
    // const nameEx = /^(?:[가-힣]+|[a-zA-Z\s]+)$/  // 창현님이 보내준 정규식
    const nameEx = /^(?:[가-힣]+|([a-zA-Z]+(\s?)))+$/  // \s(공백)에 ? 또는 {0,1}을 추가 하여 연속된 공백이 1개 이하만 통과 되도록 설정
    // const test = nameEx.test(nameValue) ? "NAME_FORMAT" : false;  // 기존 로직은 특수문자가 있을때 불합격 로직이었음
    const test = nameEx.test(nameValue) ? true : "NAME_FORMAT";  // 정규식이 통과일때 합격로직으로 변경
    // setNameCheckState(nameEx.test(nameValue) ? "NAME_FORMAT" : false);
    // return !nameEx.test(nameValue);  // 기존로직 : 그래서 정규식이 잡히면 "NameFormat으로 바꾸고 안잡히면 false인데 !를 줘서 true로 리턴해준듯"
    setNameCheckState(test);  // 이거를 정규식에 잡히면 true로 변경
    return nameEx.test(nameValue);  // 정규식에 잡히면 true를 return하도록 변경
  }

  function phoneCheck() {
    if (!phoneValue) {
      return setPhoneCheckState("PHONE_EMPTY");
    }

    // const phoneNumberRegex = /^(\d{3}-\d{3,4}-\d{4})$/;
    const phoneNumberRegex = /^01([0|1|6|7|8|9])-?([0-9]{3,4})-?([0-9]{4})$/;
    // const test = !phoneNumberRegex.test(phoneValue) ? "PHONE_FORMAT" : false;
    const test = phoneNumberRegex.test(phoneValue) ? true : "PHONE_FORMAT";
    setPhoneCheckState(test);
    return phoneNumberRegex.test(phoneValue);
  }

  // 유저생성
  const createUserClick = async () => {
    if (await validate()) {
        let obj = {
          id: idValue,
          password: confirmPassowrdValue,
          name: nameValue,
          phone_number: phoneValue,
          auth: typeCheck,
        };

        let result = await api.admin.postSignupUser(obj);
        if (result.message == "signup is complete") {
          navigate("/admin");
        }
    }
  };

  async function validate() {
    let result = await idCheck();
    let phoneResult = phoneCheck();
    let passwordResult = passwordCheck();
    let nameResult = nameCheck();

    // console.log("id check => ", result);
    // console.log("phoneResult check => ", phoneResult);
    // console.log("passwordResult check => ", passwordResult);
    // console.log("nameResult check => ", nameResult);
    if (result && phoneResult && passwordResult && nameResult) return true;

    return false;
  }

  function disableCheck() {
    return !(
      phoneValue.length > 0 &&
      idValue.length > 0 &&
      nameValue.length > 0 &&
      passwordValue.length > 0 &&
      confirmPassowrdValue.length > 0
    );
  }

  return (
    <>
      <common.Header
        title={lang.getLang("신규유저 생성")}
        home
        url={"/admin"}
      />
      <Styles.Container>
        <form>
          <div className="formContainer">
            <ul className="inputListWrap">
              <li className="inputList">
                <div className="inputContainer">
                  <p className="inputTitle">ID*</p>

                  <div className="inputWrap">
                    <ui.input.BasicInput
                      value={idValue}
                      setValue={setIdValue}
                      placeholder={"* 띄어쓰기 없이 영/숫자 2~20자"}
                    />
                  </div>
                </div>
                {/* 입력값 없을때 */}
                {idCheckState == 3 && (
                  <p className="errorMessage">
                    {lang.getLang("ID를 입력해주세요.")}
                  </p>
                )}
                {/* ID 중복될 때 */}
                {idCheckState == 0 && (
                  <p className="errorMessage">
                    {lang.getLang("이미 사용중인 ID 입니다.")}
                  </p>
                )}
                {/* ID 기준 안맞을 때 */}
                {idCheckState == 2 && (
                  <p className="errorMessage">
                    {lang.getLang(
                      "띄어쓰기 없이 영/숫자로 2~20글자만 가능합니다."
                    )}
                  </p>
                )}
              </li>

              <li className="inputList">
                <div className="inputContainer">
                  <p className="inputTitle">{lang.getLang("비밀번호*")}</p>

                  <div className="inputWrap">
                    <ui.input.BasicInput
                      type={"password"}
                      value={passwordValue}
                      setValue={setPasswordValue}
                      placeholder={
                        "* 영문 대문자, 소문자, 숫자, 특수문자를 적어도 1개씩 포함한 4-20자"
                      }
                      onBlur={() => passwordCheck(passwordValue)}
                    />
                  </div>
                </div>
                {/* PW 기준 안맞을 때 */}
                {pwCheckState == 2 && (
                  <p className="errorMessage">
                    {lang.getLang(
                      "* 영문 대문자, 소문자, 숫자, 특수문자를 적어도 1개씩 포함한 4-20자"
                    )}
                  </p>
                )}
                {/* 입력값 없을때 */}
                {pwCheckState == 0 && (
                  <p className="errorMessage">
                    {lang.getLang("비밀번호를 입력해주세요.")}
                  </p>
                )}
              </li>

              <li className="inputList">
                <div className="inputContainer">
                  <p className="inputTitle">{lang.getLang("비밀번호 확인*")}</p>

                  <div className="inputWrap">
                    <ui.input.BasicInput
                      type={"password"}
                      value={confirmPassowrdValue}
                      setValue={setConfirmPassowrdValue}
                      placeholder={"* 위의 비밀번호를 다시 입력해주세요."}
                      onBlur={() => {
                        setRePwCheckState(
                          passwordValue == confirmPassowrdValue
                        );
                      }}
                    />
                  </div>
                </div>
                {/* 일치하지 않을 때 */}
                {!rePwCheckState && (
                  <p className="errorMessage">
                    {lang.getLang("비밀번호가 일치하지 않습니다.")}
                  </p>
                )}
              </li>

              <li className="inputList">
                <div className="inputContainer">
                  <p className="inputTitle">{lang.getLang("이름")}*</p>

                  <div className="inputWrap">
                    <ui.input.BasicInput
                      value={nameValue}
                      setValue={setNameValue}
                      placeholder={"* 성함을 입력해주세요."}
                      onBlur={() => {
                        nameCheck(nameValue)
                      }}
                    />
                  </div>
                </div>
                {/* 이름란에 특수문자 있을 때 */}
                {nameCheckState === "NAME_FORMAT" && (
                  <p className="errorMessage">
                    {lang.getLang("한글/영문으로 입력해주세요.")}
                  </p>
                )}
                {nameCheckState === "NAME_EMPTY" && (
                  <p className="errorMessage">
                    {lang.getLang("성명을 입력해주세요.")}
                  </p>
                )}
              </li>

              <li className="inputList">
                <div className="inputContainer">
                  <p className="inputTitle">{lang.getLang("휴대폰 번호")}*</p>

                  <div className="inputWrap">
                    <ui.input.BasicInput
                      type={"tel"}
                      value={phoneValue}
                      setValue={setPhoneValue}
                      placeholder={"* 휴대폰 번호를 입력해주세요."}
                      maxLength={"13"}
                      onBlur={() => {
                        phoneCheck(phoneValue)
                      }}
                    />
                  </div>
                </div>
                {phoneCheckState === "PHONE_FORMAT" && (
                  <p className="errorMessage">
                    {lang.getLang("올바른 휴대폰 번호를 입력해주세요.")}
                  </p>
                )}
                {phoneCheckState === "PHONE_EMPTY" && (
                  <p className="errorMessage">
                    {lang.getLang("휴대폰 번호를 입력해주세요.")}
                  </p>
                )}
              </li>

              <li className="inputList type">
                <div className="inputContainer">
                  <p className="inputTitle">{lang.getLang("구분")}*</p>

                  <ul className="radioListWrap">
                    {typeListData.map((item, index) => {
                      return (
                        <li className="radioList" key={"radioList" + index}>
                          <ui.input.BasicRadio
                            name={"type"}
                            id={item.id}
                            text={item.text}
                            checked={typeCheck === index + 1}
                            onChange={(e) => {
                              setTypeCheck(index + 1);
                            }}
                          />
                        </li>
                      );
                    })}
                  </ul>
                </div>
              </li>
            </ul>

            <div className="buttonContainer">
              <button
                type="button"
                className="cancelButton"
                onClick={() => {
                  navigate("/admin");
                }}
              >
                {lang.getLang("취소")}
              </button>
              <button
                type="button"
                className="completeButton"
                disabled={disableCheck()}
                onClick={() => {
                  createUserClick();
                }}
              >
                {lang.getLang("완료")}
              </button>
            </div>
          </div>
        </form>
      </Styles.Container>
    </>
  );
}

export default CreateUserPage;
