import styled from "styled-components";

// const Container = styled.div`
//   position: relative;

//   & .scoreContainer {
//     position: absolute;
//     top: 50%;
//     left: 50%;
//     transform: translate(-50%, -50%);
//     text-align: center;
//     z-index: 1;

//     & .gifTitle {
//       margin-bottom: calc(100vw * (19 / 1920));
//       font-size: calc(100vw * (28 / 1920));
//       font-weight: 700;
//       line-height: 1.17;
//       text-transform: uppercase;
//     }

//     & .score {
//       font-size: calc(100vw * (75 / 1920));

//       & span {
//         font-size: calc(100vw * (39 / 1920));
//         vertical-align: super;
//       }
//     }
//   }

//   & .gifWrap {
//     /* width: calc(100vw * (275 / 1920));
//     height: calc(100vw * (275 / 1920)); */
//     /* width: calc(100vw * (230 / 1920));
//     height: calc(100vw * (230 / 1920)); */
//     width: calc(100vw * (${(props) => {
//       if (props.paddingSize === "small") {
//         return 230;
//       } else if (props.paddingSize === "midium") {
//         return 253;
//       } else {
//         return 275
//       }
//     }} / 1920));
//     height: calc(100vw * (${(props) => {
//       if (props.paddingSize === "small") {
//         return 230;
//       } else if (props.paddingSize === "midium") {
//         return 253;
//       } else {
//         return 275
//       }
//     }} / 1920));
    
//   }

//   &.small {
//     & .scoreContainer {
//       & .gifTitle {
//         margin-bottom: calc(100vw * (-5 / 1920));
//         font-size: calc(100vw * (20 / 1920));
//       }

//       & .score {
//         font-size: calc(100vw * (50 / 1920));

//         & span {
//           font-size: calc(100vw * (39 / 1920));
//           vertical-align: middle;
//         }
//       }
//     }

//     & .gifWrap {
//       width: calc(100vw * (120 / 1920));
//       height: calc(100vw * (120 / 1920));
//     }
//   }

//   &.big {
//     & .scoreContainer {
//       & .gifTitle {
//         margin-bottom: calc(100vw * (22 / 1920));
//         font-size: calc(100vw * (32 / 1920));
//       }

//       & .score {
//         font-size: calc(100vw * (100 / 1920));

//         & span {
//           font-size: calc(100vw * (39 / 1920));
//         }
//       }
//     }

//     & .gifWrap {
//       width: calc(100vw * (356 / 1920));
//       height: calc(100vw * (356 / 1920));
//     }
//   }
// `;

const Container = styled.div`
  position: relative;

  & .scoreContainer {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
    z-index: 1;

    & .gifTitle {
      align-items: center;
      font-size: calc(${(props)=>props.viewPort}vw * (28 / 1920));
      font-weight: 700;
      line-height: 1.17;
      text-transform: uppercase;
    }

    & .score {
      font-size: calc(${(props)=>{return (props.viewPort?props.viewPort:100)}}vw * (75 / 1920));

      & span {
        font-size: calc(${(props)=>{return (props.viewPort?props.viewPort:100)}}vw * (39 / 1920));
        vertical-align: super;
      }
    }
  }

  &.small {
    & .scoreContainer {
      & .gifTitle {
        align-items: center;
        font-size: calc(${(props)=>{return (props.viewPort?props.viewPort:100)}}vw * (20 / 1920));
      }

      & .score {
        font-size: calc(${(props)=>{return (props.viewPort?props.viewPort:100)}}vw * (50 / 1920));

        & span {
          font-size: calc(${(props)=>{return (props.viewPort?props.viewPort:100)}}vw * (39 / 1920));
          vertical-align: middle;
        }
      }
    }

    & .gifWrap {
      width: calc(${(props)=>{return (props.viewPort?props.viewPort:100)}}vw * (120 / 1920));
      height: calc(${(props) => { return (props.viewPort ? props.viewPort : 100) }}vw * (120 / 1920));
      & img {
        border-radius: 50%;
      }
    }
  }

  &.big {
    & .scoreContainer {
      & .gifTitle {
        margin-left: calc(${(props)=>{return (props.viewPort?props.viewPort:100)}}vw * (10 / 1920));
        font-size: calc(${(props)=>{return (props.viewPort?props.viewPort:100)}}vw * (32 / 1920));
      }
    }

    & .gifWrap {
      margin-left: calc(${(props)=>{return (props.viewPort?props.viewPort:100)}}vw * (10 / 1920));
      width: calc(${(props)=>{return (props.viewPort?props.viewPort:100)}}vw * (250 / 1920));
      height: calc(${(props)=>{return (props.viewPort?props.viewPort:100)}}vw * (250 / 1920));
    }

    & .abnormalBar {
      position: absolute;
      margin-left: calc(${(props)=>{return (props.viewPort?props.viewPort:100)}}vw * (-40 / 1920));
      width: calc(${(props)=>{return (props.viewPort?props.viewPort:100)}}vw * (330 / 1920));

      & .abnormalBarBase {
          & .normal {
            border-radius: calc(${(props)=>{return (props.viewPort?props.viewPort:100)}}vw * (5 / 1920));
            background: linear-gradient(to right, #04B24A calc(0% + ${props => props.score}% * 33 / 100), #000000 calc(0% + ${props => props.score}% * 33 / 100));
          }

          & .adenoma {
            border-radius: calc(${(props)=>{return (props.viewPort?props.viewPort:100)}}vw * (5 / 1920));
            background: linear-gradient(to right, #F37021 33%, #F37021 33%, #F37021 calc(33% + ${props => props.score}% * 33 / 100), #000000 calc(33% + ${props => props.score}% * 33 / 100));
          }

          & .cancer {
            border-radius: calc(${(props)=>{return (props.viewPort?props.viewPort:100)}}vw * (5 / 1920));
            background: linear-gradient(to right, #CC004C 67%, #CC004C 67%, #CC004C calc(67% + ${props => props.score}% * 33 / 100), #000000 calc(67% + ${props => props.score}% * 33 / 100));
          }
      }
    }

    & .abnormalText {
      display: flex;
      margin-top: calc(${(props)=>{return (props.viewPort?props.viewPort:100)}}vw * (20 / 1920));
      margin-bottom: calc(${(props)=>{return (props.viewPort?props.viewPort:100)}}vw * (3 / 1920));
      font-size: calc(${(props)=>{return (props.viewPort?props.viewPort:100)}}vw * (12 / 1920));

      & .normal {
        margin-left: calc(${(props)=>{return (props.viewPort?props.viewPort:100)}}vw * (10 / 1920));
      }

      & .adenoma {
        margin-left: calc(${(props)=>{return (props.viewPort?props.viewPort:100)}}vw * (33 / 1920));
      }

      & .cancer {
        margin-left: calc(${(props)=>{return (props.viewPort?props.viewPort:100)}}vw * (55 / 1920));
      }

      & .off {
        opacity: 0.4;
      }

      & .on {
        opacity: 1;
        font-weight: 400;
      }
    }


    
    & .imBar {
      position: absolute;
      margin-left: calc(${(props)=>{return (props.viewPort?props.viewPort:100)}}vw * (-40 / 1920));
      width: calc(${(props)=>{return (props.viewPort?props.viewPort:100)}}vw * (330 / 1920));

      & .imBarBase {
          & .normal {
            border-radius: calc(${(props)=>{return (props.viewPort?props.viewPort:100)}}vw * (5 / 1920));
            background: linear-gradient(to right, #04B24A ${props => props.score}%, #000000 ${props => props.score}%);
          }

          & .im {
            border-radius: calc(${(props)=>{return (props.viewPort?props.viewPort:100)}}vw * (5 / 1920));
            background: linear-gradient(to right, #FF9900 0%, #FF9900 ${props => props.score}%, #000000 ${props => props.score}%);
          }
      }
    }


    & .imText {
      display: flex;
      margin-top: calc(${(props)=>{return (props.viewPort?props.viewPort:100)}}vw * (20 / 1920));
      margin-bottom: calc(${(props)=>{return (props.viewPort?props.viewPort:100)}}vw * (3 / 1920));
      font-size: calc(${(props)=>{return (props.viewPort?props.viewPort:100)}}vw * (12 / 1920));

      & .normal {
        margin-left: calc(${(props)=>{return (props.viewPort?props.viewPort:100)}}vw * (5 / 1920));
      }

      & .im {
        margin-left: calc(${(props)=>{return (props.viewPort?props.viewPort:100)}}vw * (260 / 1920));
      }

      & .off {
        opacity: 0.4;
      }

      & .on {
        opacity: 1;
        font-weight: 400;
      }
    }
    
  }
`;

export { Container };

export default { Container };
