import Styles from "./styles";

export default function PicturesTitle({ colorTitle, subTitle, paddingSize, viewPort }) {
  return (
    <Styles.Container viewPort={viewPort}>
      <span className="colorTitle">{colorTitle}</span>{" "}
      <span className="subTitle">{subTitle}</span>
    </Styles.Container>
  );
}
