// img
import { useNavigate, useParams } from "react-router-dom";
import { useQuery } from "react-query";
import { api } from "service";
import { useEffect, useMemo, useRef, useState } from "react";
import { getCookie } from "service/connector";
import UserDataList from "../UserDataList";
import ScoreSection from "../ScoreSection";
import LesionCheckList from "../LesionCheckList";
import IMCheckedSectionTemplate from "./template";
import { formatHeatmapWithPath, isNumeric } from "utils/common";
import { useConfig } from "hooks/useConfig";
import { set } from "lodash";
import { UserDataListAbnoramlity } from "..";

export default function IMCheckedSection(props) {
  // const {
  //   patientsData,
  //   checkListData,
  //   selectCheckList,
  //   setSelectCheckList,
  //   imIndex,
  // } = props;

  const navigate = useNavigate();
  const { type, id, studyId } = useParams();

  const [landmark, setLandmark] = useState(props.imLandmark > 0 ? props.imLandmark : 5);

  const [imIndex, setImIndex] = useState(props.imIndex);

  const configInfo = useConfig();
  const [checkLesion, setCheckLesion] = useState(
    configInfo.configData.lesionMark
  );
  const [renderedImgList, setRenderedImgList] = useState([]);

  useEffect(() => {
    setCheckLesion(configInfo.configData.lesionMark);
  }, [configInfo.configData]);

  const [scope, setScope] = useState("lite");

  useEffect(() => {
    setScope(getCookie("scope"));
  }, []);

  useEffect(() => {
    if (props.imLandmark > 0) setLandmark(props.imLandmark);
  }, [props.imLandmark]);

  const {
    data: IMData,
    isLoading: isLoadingIM,
    isSuccess: isSuccessIM,
  } = useQuery(
    ["lesions-im-path", studyId],
    () => api.image.getLesionsIMPath({ medicalstudyId: studyId }),
    {
      onSuccess(responseLesionPath) {
        const landmarkList = Object.keys(responseLesionPath)
          .filter((key) => isNumeric(key))
          .sort((a, b) => a - b);
        const initialRenderedImgList = {};
        landmarkList.forEach((landmarkStr) => {
          const result = responseLesionPath[landmarkStr].map((item) => ({
            imageId: item.imageId,
            naturalWidth: 0,
            naturalHeight: 0,
          }));
          initialRenderedImgList[landmarkStr] = result;
        });
        setRenderedImgList(initialRenderedImgList); // initialRenderedImgList로 랜드마크들의 첫번째 이미지 경로를 저장
        navigate(`/lesion/${id}/${studyId}/${landmarkList[0]}`);
      },
    },
  );

  const { data: settingsData, isLoadingSettings } = useQuery(
    ["settings"],
    () => api.setting.getSetting(),
    {
      refetchOnWindowFocus: false,
    }
  );

  const [notSelectedSection, setNotSelectedSection] = useState([]);
  const [lesionsIM, setLesionsIM] = useState([]);
  const [currentLesion, setCurrentLesion] = useState();
  const [currentHitMapSrc, setCurrentHitMapSrc] = useState();
  const [IMScore, setIMScore] = useState();
  const [lesionScoreType, setLesionScoreType] = useState();
  const [imGifLesionType, setImGifLesionType] = useState();

  useEffect(() => {
    if (IMData && landmark && imIndex >= 0 && settingsData) {
      // const canSelectStomach = [5, 6, 7, 8, 11];
      const canSelectStomach = Object.keys(IMData)
        .filter((key) => isNumeric(key))
        .map(Number)
        .sort((a, b) => a - b);

      const notSelectedSection = Object.keys(IMData)
        .map((keyword) => {
          console.log("keyword", keyword);
          const preProperty = "scoreLandmark";
          if (canSelectStomach.some((item) => preProperty + item === keyword)) {
            const score = IMData[keyword];
            console.log("IMData score", score);
            // const colorType = score > IMData.IMScore ? "yellow" : "green";
            const colorType =
              score >= 100 - settingsData?.imSensitivity ? "yellow" : "green";

            console.log("colorType", colorType);

            // const imGifLesionType = settingsData?.imSensitivity > IMData.IMScore ? "yellow" : "green";
            return {
              landmark: Number(keyword.split(preProperty)[1]),
              type: colorType,
            };
          }
          return null;
        })
        .filter((item) => item);

      setNotSelectedSection(notSelectedSection);

      const lesionsIM = IMData[landmark];
      setLesionsIM(lesionsIM);

      const currentLesion = lesionsIM[imIndex];
      setCurrentLesion(currentLesion);

      if (currentLesion) {
        const path = currentLesion.path;
        const hitMapSrc = formatHeatmapWithPath(path);
        setCurrentHitMapSrc(hitMapSrc);
      }

      // 현재 선택된 랜드마크의 IM 점수
      const ImScore = IMData[`scoreLandmark${landmark}`];
      setIMScore(ImScore);

      // 현재 선택된 랜드마크의 병변 점수 타입
      const lesionScoreType = notSelectedSection?.find(
        (item) => item.landmark === Number(landmark)
      )?.type;

      setLesionScoreType(lesionScoreType);

      console.log("settingsData?.imSensitivity", settingsData?.imSensitivity);
      console.log("IMData.IMScore", IMData.IMScore);

      // 현재 선택된 랜드마크의 IM 점수 타입
      const imGifLesionType =
        settingsData?.imSensitivity <= IMData.IMScore ? "yellow" : "green";
      setImGifLesionType(imGifLesionType);
    }
  }, [IMData, landmark, imIndex]);

  useEffect(() => {
    if (props.imIndex >= 0) {
      setImIndex(props.imIndex);
    }
  }, [props.imIndex]);

  const { data: lesionJsonData, isFetching: isFetchingLesionsJson } = useQuery(
    ["lesions-path-json", "IM", landmark, studyId],
    async () => {
      const promises = lesionsIM?.map(async ({ path }) => {
        return await api.image.getLesionJson({ path });
      });

      return await Promise.all(promises);
    },
    {
      enabled: lesionsIM && lesionsIM.length > 0 && checkLesion,
      refetchOnWindowFocus: false,
    }
  );

  const matchingLesionJsonData = useMemo(() => {
    if (!lesionJsonData) return [];
    // return lesionJsonData?.map((item, index) => {
    //   const findLesionJsonData = item?.filter(
    //     (item) => item.lesion === "im"
    //   )[0];

    //   return findLesionJsonData;
    // });
    return lesionJsonData?.map((item) => {
      const findLesionJsonData = item.lesion_metaplasia.filter(
        (metaplasia) => metaplasia.metaplasia_type === "im"
      )[0];

      return findLesionJsonData;
    });
  }, [lesionJsonData]);

  const handleImIndexChange = (imIdx) => {
    setImIndex(imIdx);
    props.onImIndexChange(imIdx);
  }

  const handleLandmarkChange = (landmark) => {
    props.onLandmarkChange(landmark);
  }

  console.log("IMData", IMData);

  if (isLoadingIM || isLoadingSettings) return <></>;

  return (
    <>
      <div className="leftContainer">
        <div className="userDataContainer">
          <UserDataListAbnoramlity
            select
            patientsData={props.patientsData}
            id={id}
            studyId={studyId}
            type={type}
            navigate={navigate}
          />
        </div>
        <ScoreSection
          landmark={landmark}
          onChangeSelectedSection={(landmark) => {
            handleLandmarkChange(landmark);
          }}
          score={IMScore}
          scoreType={imGifLesionType}
          numberType={lesionScoreType}
          notSelectedSection={notSelectedSection}
          noClickEvent={props.selectCheckList === "이상 병변"}
          screen={"heatmap"}
        />
      </div>
      <div className="rightContainer">
        <LesionCheckList
          checkListData={props.checkListData}
          selectCheckList={props.selectCheckList}
          setSelectCheckList={props.setSelectCheckList}
        />
        <IMCheckedSectionTemplate
          studyId={studyId}
          patientsData={props.patientsData}
          currentLesion={currentLesion}
          lesionScoreType={lesionScoreType}
          lesionsIM={lesionsIM}
          landmark={landmark}
          imIndex={imIndex}
          onImIndexChange={handleImIndexChange}
          currentHitMapSrc={currentHitMapSrc}
          checkLesion={checkLesion}
          onChangeChecked={(check) => {
            setCheckLesion(check);
          }}
          matchingLesionJsonData={matchingLesionJsonData}
          isFetchingLesionsJson={isFetchingLesionsJson}
          renderedImgList={renderedImgList[landmark] || []}
          onLoadImage={(event, imageId) => {
            const { naturalWidth, naturalHeight } = event.currentTarget;
            setRenderedImgList((prev) => {
              return {
                ...prev,
                [landmark]: prev[landmark]?.map((item) => {
                  if (imageId === item.imageId) {
                    return {
                      ...item,
                      naturalWidth,
                      naturalHeight,
                    };
                  }

                  return item;
                }),
              };
            });
          }}
        />
        {scope !== "lite" && (
          <button
            type="button"
            className="imAnalysisButton"
            onClick={() => {
              navigate(`/lesion/im-analysis/${id}/${studyId}`);
            }}
          >
            IM Analysis
          </button>
        )}
      </div>
    </>
  );
}
