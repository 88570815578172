const lang = {
  "오늘 미검진 현황": {
    _en: "STATUS BOARD",
  },
  "총 ": {
    _en: " ",
  },
  "성명을 기입해 주세요.": {
    _en: "Name",
  },
  검색: {
    _en: "Search",
  },
  필터: {
    _en: "Filter",
  },
  "이름 / 환자 ID": {
    _en: "Name/ID",
  },
  "이름 또는 ID": {
    _en: "Name or ID",
  },
  리셋: {
    _en: "Reset",
  },
  "환자 ID": {
    _en: "Patient ID",
  },
  이름: {
    _en: "Name",
  },
  명: {
    _en: " ",
  },
  성별: {
    _en: "Sex",
  },
  생년월일: {
    _en: "Date of birth",
  },
  검진일: {
    _en: "Study date",
  },
  "최근 검진일": {
    _en: "Recent date",
  },
  상태: {
    _en: "Status",
  },
  "내시경 검진 결과가 존재하지 않습니다.": {
    _en: "The endoscopic examination results do not exist.",
  },
  "결과를 받아오지 못했거나, 당시 검진을 실시하지 않았습니다.": {
    _en: "Or the examination was not performed at that time.",
  },
  "내시경 검진 대기 중인 환자입니다.": {
    _en: "Patient is awaiting endoscopy examination.",
  },
  "내시경 검진을 진행 후 클릭해 주세요.": {
    _en: "Please click after the endoscopy examination is conducted.",
  },
  "현재 AI가 내시경 검진 결과를 분석중입니다.": {
    _en: "Currently, AI is analyzing endoscopy results.",
  },
  "추론이 완료될 때까지 기다려주세요.": {
    _en: "Please wait for inference completion.",
  },
  "잠시만 기다려 주세요.": {
    _en: "Please wait a moment.",
  },
  "검색 결과가 존재하지 않습니다.": {
    _en: "No search results found.",
  },
  "종료일이 시작일보다 빠를수 없습니다.": {
    _en: "The end date cannot be earlier than the start date.",
  },
  "숫자만 또는 한글영문 2~20자까지 검색 가능합니다.": {
    _en: "2 to 20 of only numbers or Korean and English characters can be searched.",
  },
  월요일: {
    _en: "Monday",
  },
  화요일: {
    _en: "Tuesday",
  },
  수요일: {
    _en: "Wednesday",
  },
  목요일: {
    _en: "Thursday",
  },
  금요일: {
    _en: "Friday",
  },
  토요일: {
    _en: "Saturday",
  },
  일요일: {
    _en: "Sunday",
  },
  취소: {
    _en: "Cancel",
  },
};

export default lang;
