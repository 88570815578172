import Styles from "./styles";
import { useLanguage } from "hooks";
import { useMediaQuery } from "react-responsive";
import LesionTitle from "../LesionTitle";
import { getCookie } from "service/connector";

// swiper
import { Swiper, SwiperSlide } from "swiper/react";
import { EffectCoverflow, Navigation } from "swiper";
import "swiper/css";
import "swiper/css/effect-coverflow";
import "swiper/css/navigation";

// img
import { useQuery } from "react-query";
import { useNavigate, useParams, useLocation } from "react-router-dom";
import { api } from "service";
import { IMAGE_REF, IMAGE_SRC, swiperOption } from "constants/common";
import { useEffect, useMemo, useRef, useState } from "react";
import SVG from "components/common/SVG";
import LesionCheckList from "../LesionCheckList";
import ScoreSection from "../ScoreSection";
import UserDataList from "../UserDataList";
import { lesionTypeForJsonFirstLetter, lesionTypeToColor } from "utils/common";
import { useConfig } from "hooks/useConfig";
import { image } from "service/api";
import { LesionPage } from "pages";
import { UserDataListAbnoramlity } from "..";
import { trim, trimEnd } from "lodash";

export default function AbnormalityCheckedSection( props) {

  // const { 
  //   patientsData, 
  //   checkListData, 
  //   selectCheckList, 
  //   setSelectCheckList,
  //   onLesionIndexChange,
  // } = props;

  const lang = useLanguage("Lesion");
  const isPro = useMediaQuery({
    query: "(max-width: 1366px)",
  });
  const isAir = useMediaQuery({
    query: "(max-width: 1180px)",
  });


  const navigate = useNavigate();
  const { type, id, studyId } = useParams();

  const configInfo = useConfig();

  const normalSwiperRef = useRef(null);
  const [imageId, setImageId] = useState(props.imageId);
  const [lesionIndex, setLesionIndex] = useState(props.lesionIndex);
  const [checkLesion, setCheckLesion] = useState(
    configInfo.configData.lesionMark
  );

  const [lesionsPathData, setLesionsPathData] = useState([]);
  const [lesionsJsonData, setLesionsJsonData] = useState([]);
  const [matchingLesionJsonData, setMatchingLesionJsonData] = useState([]);
  const [currentLesionPathData, setCurrentLesionPathData] = useState();
  const [currentLandmark, setCurrentLandmark] = useState();
  const [renderedImgList, setRenderedImgList] = useState([]);
  const [isLoad, setIsLoad] = useState(false);

  const swiperRef = useRef(null);

  const [scope, setScope] = useState("lite");

  useEffect(() => {
    setScope(getCookie("scope"));
  }, []);

  useEffect(() => {
    setCheckLesion(configInfo.configData.lesionMark);
  }, [configInfo.configData]);

  useEffect(() => {
    if (renderedImgList?.length != 0) setIsLoad(true);
    else setIsLoad(false);
  }, [renderedImgList]);

  useEffect(() => {
    if (currentLesionPathData) {
      const currentLandmark = currentLesionPathData?.landmark;
      const moveToNormalIndex =
        currentLandmark !== 12 ? currentLandmark - 1 : 0;
      normalSwiperRef.current?.slideTo(moveToNormalIndex);
      navigate(`/lesion/${id}/${studyId}/${currentLandmark}`);
    }
  }, [currentLesionPathData]);


  useEffect(() => {}, [checkLesion]);

  const {
    data,
    isSuccess: isSuccessLesionsPath,
    isLoading: isLoadingLesions,
  } =useQuery(
    ["lesions-abnormality-path", studyId],
    () => api.image.getLesionsAbnormalityPath({ medicalstudyId: studyId }),
    {
      refetchOnWindowFocus: false,
      onSuccess(responseLesionPath) {

        const lesionsPathData = responseLesionPath;
  
        const initialRenderedImgList = lesionsPathData.map((item) => {
          return { imageId: item.imageId, naturalWidth: 0, naturalHeight: 0 };
        });
  
        setLesionsPathData(lesionsPathData);
        setRenderedImgList(initialRenderedImgList);
        setCurrentLesionPathData(lesionsPathData[props.lesionIndex]);
        setCurrentLandmark(lesionsPathData[props.lesionIndex].landmark);

        setLesionsPathData(responseLesionPath);
      },
    }
  );

  useQuery(
    ["lesions-path-json", "abnormality", studyId],
    async () => {
      const promises = lesionsPathData?.map(async ({ path }) => {
        return await api.image.getLesionJson({ path });
      });
      return await Promise.all(promises);
    },
    {
      enabled: isSuccessLesionsPath && lesionsPathData.length > 0 && checkLesion,
      refetchOnWindowFocus: true,

      onSuccess(responseLesionJson) {
        
        setLesionsJsonData(responseLesionJson);

        const matchingLesionJsonData = responseLesionJson?.map((item, index) => {
          const lesionType = lesionsPathData[index].lesion[0].type;
          const findLesionJsonData = item.lesion_tumor.filter(
            (tumor) => tumor.tumor_type === lesionType
          )[0];
          return findLesionJsonData;
        });

        setMatchingLesionJsonData(matchingLesionJsonData);
      }
    }
  );
  
  const [currentImage, setCurrentImage] = useState();
  const [currentLesion, setCurrentLesion] = useState();
  const [currentLesionColorType, setCurrentLesionColorType] = useState();


  useEffect(() => {
    if (lesionsPathData && lesionsPathData.length > 0) {
      const currentImage = lesionsPathData[lesionIndex];
      setCurrentImage(currentImage);
      setCurrentLesion(currentImage?.lesion?.[0]);
      setCurrentLesionColorType(lesionTypeToColor(currentImage?.lesion?.[0]?.type));
      setCurrentLandmark(lesionsPathData[props.lesionIndex].landmark);
    }
  }, [lesionIndex, lesionsPathData]);

  const handleChangeLesionIdx = (lesionIdx) => {
    setLesionIndex(lesionIdx);
    props.onLesionIndexChange(lesionIdx);
  };

  // const { data: _imageNormalData = {} } = useQuery(
  //   ["image-normal"],
  //   () => api.image.getNormal(),
  //   {
  //     refetchOnWindowFocus: false,
  //   }
  // );
  // const imageNormalData = Object.values(_imageNormalData);

  const notSelectedSection = [
    {
      landmark: Number(currentLandmark),
      type: currentLesionColorType,
    },
  ];

  console.log("imageId", imageId);
  console.log("patientsData", props.patientsData);

  const status = props.patientsData.studies.find(
    (item) => item.studyId === Number(studyId)
  )?.status;

  if (isLoadingLesions) return <></>;
  if (renderedImgList && renderedImgList.length === 0) return <></>;

  return (
    <>
      <div className="leftContainer">
        <div className="userDataContainer">
          <UserDataListAbnoramlity
            select
            patientsData={props.patientsData}
            id={id}
            studyId={studyId}
            type={type}
            navigate={navigate}
          />
        </div>
        <ScoreSection
          landmark={currentLandmark}
          onChangeSelectedSection={(landmark) => {
            navigate(`/lesion/86/135/${landmark}`);
          }}
          score={currentLesion?.score}
          scoreType={currentLesionColorType}
          numberType={currentLesionColorType}
          notSelectedSection={notSelectedSection}
          noClickEvent={props.selectCheckList === "이상 병변"}
          screen={"abnormality"}
        />
      </div>
      <div className="rightContainer">
        <LesionCheckList
          checkListData={props.checkListData}
          selectCheckList={props.selectCheckList}
          setSelectCheckList={props.setSelectCheckList}
        />
        <Styles.Container>
          <div className="left">
            <LesionTitle
              title={lang.getLang("병변")}
              type={currentLesionColorType}
              checkTitle={lang.getLang("위치 보기")}
              id={"병변 위치 보기"}
              checkLesion={checkLesion}
              onChangeChecked={(check) => {
                setCheckLesion(check);
              }}
              screen={"abnormality"}
            />
            <div className="swiperContainer">
              {renderedImgList &&
              renderedImgList.length > 0 &&
              lesionsPathData &&
              lesionsPathData.length > 0 ? (
                <Swiper
                  direction={"vertical"}
                  effect={"coverflow"}
                  grabCursor={true}
                  initialSlide={lesionIndex}
                  centeredSlides={true}
                  slidesPerView={"auto"}
                  coverflowEffect={
                    isAir
                      ? swiperOption.AirOption
                      : isPro
                      ? swiperOption.ProOption
                      : swiperOption.option
                  }
                  onSlideChange={(swiper) => {
                    handleChangeLesionIdx(swiper.activeIndex);
                  }}
                  onSwiper={(swiper) => {
                    if (swiper.destroyed) return;
                    swiperRef.current = swiper;
                  }}
                  modules={[EffectCoverflow, Navigation]}
                  navigation={true}
                  className="IMSwiper swiper"
                >
                  {lesionsPathData?.map(({ imageId, path }, index) => {
                    const imagePathSrc = IMAGE_SRC + path;
                    const jsonMap = matchingLesionJsonData[index]?.polygon;
                    const imgData = renderedImgList[index];

                    console.log("jsonMap", jsonMap);

                    return (
                      // console.log("renderedImgList", imageId),
                      isLoad && (
                        <SwiperSlide
                          className="imgContainer slider"
                          key={"slider" + imageId}
                        >
                          <img
                            src={imagePathSrc}
                            placeholder="stomach"

                            alt="stomach"
                            onLoad={(event) => {
                              const { naturalWidth, naturalHeight } =
                                event.currentTarget;
                              setRenderedImgList((prev) => {
                                return prev?.map((item) => {
                                  if (imageId === item.imageId) {
                                    return {
                                      ...item,
                                      naturalWidth,
                                      naturalHeight,
                                    };
                                  }
                                  return item;
                                });
                              });
                            }}
                          />
                          { status === 4 && (
                          <div className="imgInfo">
                            <p>{path.split("/").slice(-1)[0]}</p>
                            <p className="desc">*사용자 입력데이터만 이미지명이 표시됩니다.</p>
                          </div>
                          )}
                          { lesionsJsonData.length > 0 &&
                            checkLesion &&
                            jsonMap?.map((jsonData) => {
                              return (
                                // <SVG.Polygon
                                //   data={jsonData || []}
                                //   width={imgData?.naturalWidth}
                                //   height={imgData?.naturalHeight}
                                // />
                                <SVG.Rectangle
                                  data={jsonData || []}
                                  width={imgData?.naturalWidth}
                                  height={imgData?.naturalHeight}
                                />
                              );
                            })}
                        </SwiperSlide>
                      )
                    );
                  })}
                </Swiper>
              ) : null}
            </div>
          </div>

          <div className="right">
            <LesionTitle title={lang.getLang("정상")} type={"green"} />

            <div className="swiperContainer">
              {[11].length > 0 ? (
                <Swiper
                  direction={"vertical"}
                  effect={"coverflow"}
                  grabCursor={true}
                  initialSlide={currentLandmark - 1}
                  centeredSlides={true}
                  slidesPerView={"auto"}
                  navigation={true}
                  coverflowEffect={
                    isAir
                      ? swiperOption.AirOption
                      : isPro
                      ? swiperOption.ProOption
                      : swiperOption.option
                  }
                  onSwiper={(swiper) => {
                    if (!swiper.destroyed) {
                      normalSwiperRef.current = swiper;
                    }
                  }}
                  modules={[EffectCoverflow, Navigation]}
                  className="normalSwiper swiper"
                >
                  {[currentLandmark]?.map((path) => {
                    let resultPath = path == 12 ? 1 : currentLandmark;

                    return (
                      <SwiperSlide
                        className="imgContainer slider"
                        key={"slider" + resultPath}
                      >
                        <img
                          className={currentLandmark === 12 ? "opacity" : ""}
                          src={IMAGE_REF + resultPath + ".png"}
                          alt="stomach"
                        />
                      </SwiperSlide>
                    );
                  })}
                </Swiper>
              ) : null}
            </div>
          </div>
        </Styles.Container>
      </div>
    </>
  );
}
