import Styles from "./styles";

export default function PicturesTitle({ colorTitle, subTitle }) {
  return (
    <Styles.Container>
      <span className="colorTitle">{colorTitle}</span>{" "}
      <span className="subTitle">{subTitle}</span>
    </Styles.Container>
  );
}
