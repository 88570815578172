import { common, ui } from "components";
import { useEffect, useState } from "react";
import Styles from "./styles";
import { useModals, useLanguage } from "hooks";
import { useNavigate } from "react-router-dom";
import { api } from "service";
import { checkKoreanLang } from "utils/etc";
import { useQuery } from "react-query";

// img

function CreatePatientPage() {
  const modalOption = useModals();
  const navigate = useNavigate();
  const lang = useLanguage("Patient");

  const [nameValue, setNameValue] = useState("");
  const [dateChange, setDateChange] = useState("");
  const [genderCheck, setGenderCheck] = useState("m");
  const [phoneValue, setPhoneValue] = useState("");
  const [commentValue, setCommentValue] = useState("");

  const [validate, setValidate] = useState({
    name: "",
    date: "",
    phone: "",
    comment: "",
  });

  const handleValidate = (key, value) => {
    setValidate((prev) => ({
      ...prev,
      [key]: value,
    }));
  };

  const checkKoreanLang = (ch) => {
    let c = ch.charCodeAt(0);
    if (0x1100 <= c && c <= 0x11ff) return true;
    if (0x3130 <= c && c <= 0x318f) return true;
    if (0xac00 <= c && c <= 0xd7a3) return true;
    return false;
  };

  useEffect(() => {
    handleValidate("name", "");
  }, [nameValue]);
  useEffect(() => {
    handleValidate("date", "");
  }, [dateChange]);
  useEffect(() => {
    handleValidate("phone", "");
  }, [phoneValue]);

  const checkName = () => {
    if (nameValue.length < 2) {
      handleValidate(
        "name",
        "이름은 한글 2~6자, 영문 2~20글자로 작성해주세요."
      );
      return false;
    }

    let isKorean = false;

    if (checkKoreanLang(nameValue[0]) == true) isKorean = true;

    for (let i = 0; i < nameValue.length; i++) {
      if (
        (isKorean && checkKoreanLang(nameValue[i]) == false) ||
        (!isKorean && checkKoreanLang(nameValue[i]) == true)
      ) {
        handleValidate("name", "하나의 언어로만 이름을 작성해주세요.");
        return false;
      }
      if (i > 6 && isKorean) {
        handleValidate("name", "한글 이름은 2~6자까지 입력 가능합니다.");
        return false;
      }
      if (i > 20 && !isKorean) {
        handleValidate("name", "영문 이름은 2~20자까지 입력 가능합니다.");
        return false;
      }
    }

    return true;
  };

  const checkDate = () => {
    if (dateChange == null || dateChange == "" || dateChange.length < 9) {
      handleValidate("date", "생일을 선택해주세요.");
      return false;
    }

    return true;
  };

  const checkPhone = () => {
    const patternPhone = /01[016789]-[^0][0-9]{2,3}-[0-9]{3,4}/;

    if (phoneValue.length < 8 || !patternPhone.test(phoneValue)) {
      handleValidate(
        "phone",
        "핸드폰 번호는 -를 포함하여 전체로 입력해주세요."
      );
      return false;
    }

    return true;
  };

  const onCreatePatient = async () => {
    if (!checkName() || !checkDate() || !checkPhone()) {
      return false;
    }

    let obj = {
      name: nameValue,
      birthday: dateChange,
      sex: genderCheck,
      phone: phoneValue,
      memo: commentValue,
    };

    try {
      let res = await api.patient.insertPatients(obj);
      navigate(`/patient/detail/${res?.INDEX}`);
    } catch (errorResponse) {
      const error = errorResponse?.error;
      if (error === "invalid name") {
        handleValidate("name", "올바른 이름을 작성해주세요.");
        return;
      }
      alert("환자 생성에 실패했습니다.");
    }
  };

  // map
  const genderListData = [
    {
      id: "Male",
      text: "Male",
    },
    {
      id: "Female",
      text: "Female",
    },
  ];

  return (
    <>
      <common.Header
        title={lang.getLang("신규환자 생성")}
        home
        url={"/patient"}
      />
      <Styles.Container>
        <form>
          <div className="formContainer">
            <ul className="inputListWrap">
              <li className="inputList">
                <div className="inputContainer">
                  <p className="inputTitle">{lang.getLang("성명")}</p>

                  <div className="inputWrap">
                    <ui.input.BasicInput
                      value={nameValue}
                      setValue={setNameValue}
                      placeholder={"*  성명을 입력해주세요."}
                      onBlur={checkName}
                    />
                  </div>
                </div>

                {validate.name && (
                  <p className="errorMessage">{validate.name}</p>
                )}
              </li>

              <li className="inputList">
                <div className="inputContainer">
                  <p className="inputTitle">{lang.getLang("생년월일")}</p>

                  <div className="inputWrap">
                    <ui.input.BasicInput
                      value={dateChange}
                      setValue={setDateChange}
                      placeholder={"*  생년월일을 입력해주세요."}
                      readOnly={true}
                    />
                    <ui.button.CalendarButton
                      dateChange={dateChange}
                      setDateChange={setDateChange}
                    />
                  </div>
                </div>
                {validate.date && (
                  <p className="errorMessage">{validate.date}</p>
                )}
              </li>

              <li className="inputList gender">
                <div className="inputContainer">
                  <p className="inputTitle">{lang.getLang("성별")}</p>

                  <ul className="radioListWrap">
                    {genderListData.map((item, index) => {
                      return (
                        <li className="radioList" key={"radioList" + index}>
                          <ui.input.BasicRadio
                            name={"gender"}
                            id={item.id}
                            text={item.text}
                            checked={genderCheck === (index == 0 ? "m" : "f")}
                            onChange={(e) => {
                              setGenderCheck(index == 0 ? "m" : "f");
                            }}
                          />
                        </li>
                      );
                    })}
                  </ul>
                </div>
              </li>

              <li className="inputList">
                <div className="inputContainer">
                  <p className="inputTitle">{lang.getLang("휴대폰 번호")}</p>

                  <div className="inputWrap">
                    <ui.input.BasicInput
                      type={"text"}
                      value={phoneValue}
                      setValue={setPhoneValue}
                      placeholder={"*  휴대폰 번호를 입력해주세요."}
                      onBlur={checkPhone}
                    />
                  </div>
                </div>
                {validate.phone && (
                  <p className="errorMessage">{validate.phone}</p>
                )}
              </li>

              <li className="inputList textarea">
                <div className="inputContainer">
                  <p className="inputTitle">{lang.getLang("접수 메모")}</p>

                  <div className="inputWrap">
                    <ui.input.BasicTextarea
                      value={commentValue}
                      setValue={setCommentValue}
                      placeholder={"*  메모를 입력해주세요."}
                    />
                  </div>
                </div>
              </li>
            </ul>

            <div className="buttonContainer">
              <button
                type="button"
                className="cancelButton"
                onClick={() => {
                  navigate(-1);
                }}
              >
                {lang.getLang("취소")}
              </button>
              <button
                type="button"
                className="completeButton"
                onClick={() => {
                  onCreatePatient();
                }}
              >
                {lang.getLang("완료")}
              </button>
            </div>
          </div>
        </form>
      </Styles.Container>
    </>
  );
}

export default CreatePatientPage;
