const lang = {
  // 2. Patient Table Page
  생성: {
    _en: "Create",
  },
  "환자 ID": {
    _en: "Patient ID",
  },
  성명: {
    _en: "Name",
  },
  성별: {
    _en: "Sex",
  },
  생년월일: {
    _en: "Date of birth",
  },
  "최근 검진일": {
    _en: "Recent date of checkup",
  },
  "휴대폰 번호": {
    _en: "Phone",
  },
  상태: {
    _en: "Status",
  },
  "접수 메모": {
    _en: "Memo",
  },
  "접수 메모": {
    _en: "Memo",
  },
  // 2. Patient Table Page(검색 팝업창)
  이름: {
    _en: "Name",
  },
  // 2. Patient Table Page(검색 팝업창)
  "이름 / 환자 ID": {
    _en: "Name / ID",
  },
  검진일: {
    _en: "Study",
  },
  // 2-1. Patient create Page
  "신규환자 생성": {
    _en: "Create new patient",
  },
  완료: {
    _en: "Create",
  },
  // 2-2. Patient Detail Page
  나이: {
    _en: "Age",
  },
  "검진 기록": {
    _en: "Checkup records",
  },
  "검진 번호": {
    _en: "Checkup ID",
  },
  "검진 일자": {
    _en: "Date of checkup",
  },
  "검진 결과": {
    _en: "Checkup results",
  },
  더보기: {
    _en: "more",
  },
  취소: {
    _en: "Cancel",
  },
  "검진 등록": {
    _en: "Registration",
  },
  // 2-2. Patient Detail Page(더보기 팝업창)
  "검진 기록 상세 보기": {
    _en: "Detailed Checkup Results",
  },
  "이름 또는 ID": {
    _en: "Name or ID",
  },
};

export default lang;
