import styled from "styled-components";

const Container = styled.div`
  display: flex;
  align-items: center;
  width: fit-content;
  min-width: calc(100vw * (904 / 1920));
  height: calc(100vw * (73 / 1920));
  padding: 0 calc(100vw * (62 / 1920)) 0 calc(100vw * (17 / 1920));
  border: calc(100vw * (5 / 1920)) solid #68727D;
  border-radius: calc(100vw * (20 / 1920)) calc(100vw * (100 / 1920)) 0px 0px;
  background-color: #68727D;

  & .colorTitle {
    margin-top: calc(100vw * (20 / 1920));
    font-size: calc(100vw * (40 / 1920));
    font-weight: 700;
    color: #fff;
  }

  & .subTitle {
    font-size: calc(100vw * (31 / 1920));
    color: #68727D;
  }
`;

export { Container };

export default { Container };
