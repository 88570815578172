const lang = {
  생년월일: {
    _en: "Date of birth",
  },
  검진일자: {
    _en: "Date of checkup",
  },
  "자동 촬영 이미지 같이 보기": {
    _en: "View with automatic captured images",
  },
  "위 아이콘으로 보기": {
    _en: "View with stomach icon",
  },
  "위 부위별 이미지 분류": {
    _en: "View with stomach icon",
  },
  "랜드마크 위치 변경": {
    _en: "Change landmark location",
  },
  "이미지 크게 보기": {
    _en: "Enlarge image",
  },
  "리포트용 사진으로 선택": {
    _en: "Select as report",
  },
  "선택 시 해당": {
    _en: "Add image to",
  },
  "위치에 사진 추가": {
    _en: "selected location",
  },
  "History 페이지로 보기": {
    _en: "View history page",
  },
  "Lesion 페이지로 보기": {
    _en: "View lesion page",
  },
  식도: {
    _en: "Esophagus",
  },
  "위식도 접합부": {
    _en: "Esophagogastric junction",
  },
  "십이지장 2부": {
    _en: "Duodenum 2nd portion",
  },
  "십이지장 구부": {
    _en: "Duodenum bulb",
  },
  "위 전정부": {
    _en: "Antrum",
  },
  "위 각부": {
    _en: "Angle",
  },
  "위 체부(하-중부)": {
    _en: "Body, LB-MB",
  },
  "위 체부(중-상부)": {
    _en: "Body, MB-HB",
  },
  "위 저부(반전)": {
    _en: "Retroflexion fundus",
  },
  "위 본문부(반전)": {
    _en: "Retroflexion cardia",
  },
  "위 체부(반전)": {
    _en: "Retroflexion cardia",
  },
  lesions: {
    _en: "lesions",
  },
  "식도 - Esophagus": {
    _en: "Esophagus",
  },
  "위식도 접합부 - Esophagogastric junction": {
    _en: "Esophagogastric junction",
  },
  "십이지장 2부 - Duodenum 2nd portion": {
    _en: "Duodenum 2nd portion",
  },
  "십이지장 구부 - Duodenum bulb": {
    _en: "Duodenum bulb",
  },
  "위 전정부 - Antrum": {
    _en: "Antrum",
  },
  "위 각부 - Angle": {
    _en: "Angle",
  },
  "위 체부(하-중부) - Body, LB-MB": {
    _en: "Body, LB-MB",
  },
  "위 체부(중-상부) - Body, MB-HB": {
    _en: "Body, MB-HB",
  },
  "위 저부(반전) - Retroflexion fundus": {
    _en: "Retroflexion fundus",
  },
  "위 본문부(반전) - Reftroflexion cardia": {
    _en: "Retroflexion cardia",
  },
  "위 체부(반전) - Retroflexion body": {
    _en: "Retroflexion cardia",
  },
};

export default lang;
