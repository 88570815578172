import styled from "styled-components";

// img
import IconArrowBottom from "resources/image/icon/icon_arrow_bottom_big.svg";
import IconArrowTop from "resources/image/icon/icon_arrow_top_big.svg";

const Container = styled.div`
  display: flex;
  align-items: center;
  gap: calc(100vw * (66 / 1920));

  & img {
    width: 100%;
    height: 100%;
    object-fit: fill;
  }

  & > div {
    flex-grow: 1;
    flex-shrink: 0;
  }

  & .swiperContainer {
    position: relative;
    height: calc(100vw * (705 / 1920));
    overflow: hidden;
  }

  & .swiper {
    margin: 0;
    height: 100%;

    & .slider {
      box-shadow: none;
      opacity: 0.5;
      transition: 0.3s;
    }

    & .swiper-slide-active {
      opacity: 1;
    }
  }

  & .swiper-button-prev,
  .swiper-button-next {
    position: absolute;
    left: 50%;
    width: calc(100vw * (110 / 1920));
    height: calc(100vw * (110 / 1920));
    transform: translateX(-50%);
    z-index: 1;
    cursor: pointer;

    &::after {
      content: none;
    }
  }

  & .swiper-button-prev {
    top: 0;
    background: url(${IconArrowTop}) no-repeat center / cover;
  }

  & .normalSwiper img {
    &.opacity {
      opacity: 30%;
    }
  }

  & .swiper-button-next {
    top: auto;
    bottom: calc(100vw * (-15 / 1920));
    background: url(${IconArrowBottom}) no-repeat center / cover;
  }

  & .imgContainer {
    width: calc(100vw * (520 / 1920));
    max-height: calc(100vw * (520 / 1920));
    margin: 0 auto;
    border-radius: calc(100vw * (9 / 1920));
    overflow: hidden;
  }

  & .userInfoContainer {
    position: relative;
    display: flex;
    align-items: center;
    gap: calc(50vw * (25 / 1920));
    z-index: 3;
    // top: calc(100vw * (-3 / 1920));

    & .patientName {
      font-size: calc(100vw * (40 / 1920));
      font-weight: 700;
    }

    & .dateContainer {
      font-size: calc(100vw * (20 / 1920));
      display: flex;
      position: relative;
      top: calc(100vw * (9 / 1920));
    }

    & .birthDate {
      // display: flex;
      justify-content: space-between;
      margin-bottom: calc(100vw * (2 / 1920));
    }

    & .dateTitle {
      margin-right: calc(80vw * (12 / 1920));
    }

    & .date {
      position: relative;
      padding-right: calc(100vw * (20 / 1920));

      &.on {
        background: url(${IconArrowBottom}) no-repeat center right /
          calc(100vw * (18 / 1920)) calc(100vw * (10 / 1920));
      }
    }
  }

  & .imgInfo {
    position: absolute;
    top: calc(100vw * (5 / 1920));
    right: 0;
    font-size: calc(100vw * (15 / 1920));
  }

  & .desc {
    position: relative;
    text-align: right;
    font-size: calc(100vw * (12 / 1920));
    margin-top: calc(100vw * (5 / 1920));
  }

  @media (max-width: 1366px) {
    & .swiperContainer {
      height: calc(100vw * (750 / 1920));
    }

    & .swiper-button-prev {
      top: calc(100vw * (25 / 1920));
    }

    & .swiper-button-next {
      bottom: 0;
    }
  }
`;

export { Container };

export default { Container };
