

import Styles from "./styles";
import { useLanguage } from "hooks";
import { useMemo, useState } from "react";
import { useQuery } from "react-query";
import { api } from "service";
import { common, layout } from "components";
import {
  formatHeatmapWithPath,
  formatImageWithPath,
  formatNormalImageWithLandmark,
  sortHistoryObjectToArray,
  lesionTypeToColor,
} from "utils/common";
import moment from "moment";
import { IMAGE_REF } from "constants/common";
import utils from "utils";
import PastHistory from "../PastHistory";

export default function ChangeHistory(props) {

  const { landmark: landmarkProps, studyId, viewPort, paddingSize, onClickChangeHistory, onCancel } = props;
  const lang = useLanguage("Report");

  const [landmark, setLandmark] = useState(null);
  const [path, setPath] = useState(null);
  const [date, setDate] = useState(null);
  const [selectedHistory, setSelectedHistory] = useState({
    recentlySelected: null,
    pastSelected: null,
  });

  if (!landmark) {
    setLandmark(landmarkProps);
  }

  const stomachListData = [
    lang.getLang("식도"),
    lang.getLang("위식도 접합부"),
    lang.getLang("십이지장 2부"),
    lang.getLang("십이지장 구부"),
    lang.getLang("위 전정부"),
    lang.getLang("위 각부"),
    lang.getLang("위체부(하-중부)"),
    lang.getLang("위체부(중-상부)"),
    lang.getLang("위저부(반전)"),
    lang.getLang("위 분문부(반전)"),
    lang.getLang("위체부(반전)"),
  ];

  const notSelectedSection = [
    { landmark, type: lesionTypeToColor(1) },
  ];

  
  const { data: historyData, isLoaing: isLoadingHistoryData } = useQuery(
    ["history_list", landmark],
    () => api.history.getList({ patientId: studyId, landmark }),
    { refetchOnWindowFocus: false, enabled: !!landmark }
  );

  const sortHistoryData = useMemo(() => {
    if (!historyData) return [];
    return Object.entries(historyData)
      .sort((a, b) => {
        const _a = Number(moment(a[0]).format("YYYYMMDD"));
        const _b = Number(moment(b[0]).format("YYYYMMDD"));
        return _b - _a;
      })
      ?.map(([key, value]) => ({ date: key, list: value }));
  }, [historyData]);

  const recentlyImageData = sortHistoryData[0];

  const sortPastHistoryData = sortHistoryData.slice(1);

  const pastImageData = useMemo(() => {
    if (!sortHistoryData?.length) return [];
    const getLongestPastImageDataLength = sortPastHistoryData.reduce(
      (maxLength, innerArray) => {
        const innerArrayLength = innerArray?.list?.length || 0;
        return Math.max(maxLength, innerArrayLength);
      },
      0
    );

    return utils.etc.transposeArray(
      Array(sortPastHistoryData?.length)
        .fill([])
        ?.map((_, oneIndex) =>
          Array(getLongestPastImageDataLength)
            .fill({})
            .map((_, twoIndex) => {
              const data = sortPastHistoryData[oneIndex].list[twoIndex];
              if (!data) return null;
              return {
                historyDate: sortPastHistoryData[oneIndex].date,
                ...data,
              };
            })
        )
    );
  }, [historyData]);

  // data
  const imageData = useMemo(
    () => ({
      normalImage: IMAGE_REF + landmark + ".png",
      recentlyImageData,
      pastImageData,
      pastDateList: sortPastHistoryData?.map((item) => item.date) || [],
    }),
    [landmark, historyData]
  );
  if (isLoadingHistoryData) return <></>;


  return (
    <Styles.Container
      paddingSize={props.paddingSize}
      viewPort={props.viewPort}
    >
      <div className="wrap">
      <div className="leftContainer">
         <div className="numberIconContainer">
            <common.NumberIcon number={landmark} />
            <p className="landmarkName">{stomachListData[landmark]}</p>
          </div>

        <div className="stomachWrap">
          <layout.NoHoverStomachReport
            selectedSection={landmark}
            notSelectedSection={notSelectedSection}
            opacityBg
            noClickEvent
            screen={"abnormality"}
          />
        </div>
      </div>
      <div className="rightContainer">
      {imageData.pastImageData?.length ? (
            <PastHistory
              viewPort={viewPort}
              paddingSize={paddingSize}
              data={imageData}
              landmark={landmark}
              onChangeHistorySelected={(data) => {
                setSelectedHistory(data);
              }}
            />
          ) : (
            <layout.NoPastHistory
              data={imageData}
              landmark={landmark}
              onChangeHistorySelected={(data) => {
                setSelectedHistory(data);
              }}
            />
          )}
      </div>
    </div>
    <div className="buttonContainer">
        <button
          type="button"
          className="submitButton"
          onClick={() => {
            console.log("selectedHistory ====> ", selectedHistory);
            let histories = selectedHistory;
            histories.recentDateInfo = imageData?.recentlyImageData?.date;
            // onClickChangeHistory(selectedHistory);
            onClickChangeHistory(histories);
          }}
        >
          {lang.getLang("사진 변경")}
        </button>
        <button type="button" className="cancelButton" onClick={onCancel}>
          {lang.getLang("취소")}
        </button>
    </div>
    </Styles.Container>
  );
}
