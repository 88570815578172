import Styles from './styles';

export default function BasicInput({readOnly,isEnter,type,value,setValue,placeholder,keyUp,onBlur,maxLength}) {
  return (
    <Styles.InputBox>
      {
        !isEnter?
        <input
          type={type ? type : "text"}
          value={value || ""}
          onChange={(e)=>{
            if(type == "tel"){
              let phoneValue = e.target.value.replace(/-/gi,'');
              phoneValue = phoneValue.substr(0, 3) +  (phoneValue.length > 3 ? ("-" + phoneValue.substr(3, 4) + (phoneValue.length > 7 ? "-" + phoneValue.substr(7,4) : "")) : "");
              setValue(phoneValue);
            }else{
              setValue(e.target.value)
            }
          }}
          placeholder={placeholder}
          autoComplete="off"
          onBlur={onBlur}
          readOnly={readOnly}
          maxLength={maxLength}
          onKeyUp={(e)=>{
            if(type == "tel"){
              let phoneValue = e.target.value.replace(/-/gi,'');
              phoneValue = phoneValue.substr(0, 3) +  (phoneValue.length > 3 ? ("-" + phoneValue.substr(3, 4) + (phoneValue.length > 7 ? "-" + phoneValue.substr(7,4) : "")) : "");
              setValue(phoneValue);
            }
          }}
        />
        :
        <input
          type={type ? type : "text"}
          value={value}
          onKeyUp={(e)=> keyUp(e)}
          onChange={(e)=>{setValue(e.target.value)}}
          placeholder={placeholder}
          autoComplete="off" 
          maxLength={maxLength}
        />
      }
    </Styles.InputBox>
  );
}
