import { useAtom } from "jotai";
import { imageActiveListAtom } from "store/atoms/imageAtom";

const useImageActiveList = () => {
  const [imageActiveList, setImageActiveList] = useAtom(imageActiveListAtom);

  const isActiveWithImageId = (imageId) => {
    return imageActiveList.some((activeList) => activeList.imageId === imageId);
  };

  const pushImageActiveList = (imageData) => {
    if (imageActiveList.length >= 4) {
      setImageActiveList([imageData]);
      return;
    }

    setImageActiveList((prevList) => [...prevList, imageData]);
  };

  const removeImageActiveItem = (imageId) => {
    setImageActiveList((prevImageList) =>
      prevImageList.filter((item) => item.imageId !== imageId)
    );
  };

  const resetImageActiveList = () => {
    setImageActiveList([]);
  };

  return {
    imageActiveList,
    isActiveWithImageId,
    setImageActiveList,
    pushImageActiveList,
    removeImageActiveItem,
    resetImageActiveList,
  };
};

export default useImageActiveList;
