import { useEffect, useRef } from "react";

const useForwardRef = (ref, initialValue = null) => {
  const targetRef = useRef(initialValue);

  useEffect(() => {
    if (!ref) return;

    if (typeof ref === "function") {
      ref(targetRef.current);
    } else {
      ref.current = targetRef.current;
    }
  }, [ref]);

  return targetRef;
};

export default useForwardRef;
