import styled from "styled-components";

// img
import IconReset from "resources/image/icon/icon_reset.svg";

const Container = styled.div`
  padding: calc(${(props)=>props.viewPort}vw * (45 / 1920)) calc(${(props)=>props.viewPort}vw * (22 / 1920))
  calc(${(props)=>props.viewPort}vw * (31 / 1920));
  background-color: white;
  border: calc(${(props)=>props.viewPort}vw * (5 / 1920)) solid #d9d9d9;

  & img {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: fill;
  }

  & .wrap {
    display: flex;

    & .leftContainer {
      flex-shrink: 0;
      margin-right: calc(${(props)=>props.viewPort}vw * (24 / 1920));
      margin-left: calc(${(props)=>props.viewPort}vw * (230 / 1920));
    }

    & .rightContainer {
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      grid-gap: calc(${(props)=>props.viewPort}vw * (26 / 1920));
      width: 100%;
    
      & .imgContainer {
        background-color: #A1A1A1;
        border-radius: calc(${(props)=>props.viewPort}vw * (9 / 1920));

        & .imgWrap {
          height: calc(${(props)=>props.viewPort}vw * (470 / 1920));
          margin: calc(${(props)=>props.viewPort}vw * (10 / 1920)) calc(${(props)=>props.viewPort}vw * (15 / 1920));
          margin-bottom: calc(${(props)=>props.viewPort}vw * (90 / 1920));
          filter: drop-shadow(
            calc(${(props)=>props.viewPort}vw * (5 / 1920)) calc(${(props)=>props.viewPort}vw * (5 / 1920))
            calc(${(props)=>props.viewPort}vw * (4 / 1920)) rgba(0, 0, 0, 0.5)
          );
          // 내부 버튼 중앙 정렬을 위한 flex
          display: flex;
          justify-content: center;
          align-items: center;
          border-radius: calc(${(props)=>props.viewPort}vw * (9 / 1920));
          overflow: hidden;
          cursor: pointer;

          &.imgBox:hover {
            & img {
              opacity: 0.5;
            }

            &::after {
              position: absolute;
              width: 100px;
              height: 100px;
              background: url(${IconReset}) no-repeat center / cover;
              content: "";
            }
          }

          & .resetImgWrap {
            width: calc(${(props)=>props.viewPort}vw * (205 / 1920));
            height: calc(${(props)=>props.viewPort}vw * (205 / 1920));
          }
        }
      }
    }
  }
`;

const EditContainer = styled.div`
  display: flex;
  background: #292929;

  $ .inner {
    display: flex;

  }

  & .editContainer {
    display: flex;
    height: calc(${(props)=>props.viewPort}vw * (650 / 1920));
    margin-top: calc(${(props)=>props.viewPort}vw * (10 / 1920));
  }

  & .buttonContainer {
    display: flex;
    justify-content: flex-end;
    gap: calc(${(props)=>props.viewPort}vw * (22 / 1920));
    margin-right: calc(${(props)=>props.viewPort}vw * (24 / 1920));
    margin-bottom: calc(${(props)=>props.viewPort}vw * (24 / 1920));

    & button {
      width: calc(100vw * (200 / 1920));
      height: calc(100vw * (48 / 1920));
      font-size: calc(100vw * (26 / 1920));
      font-weight: 700;
      border-radius: calc(100vw * (11 / 1920));
    }

    & .submitButton {
      background: #0075ff;
    }

    & .cancelButton {
      background: #848b99;
    }
  }


`;

export { Container, EditContainer};

export default { Container, EditContainer };
