import styled from "styled-components";

// img
import Logo from "resources/image/icon/logo.svg";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  min-height: 100vh;
  padding: calc(100vw * (106 / 1920)) calc(100vw * (32 / 1920))
    calc(100vw * (25 / 1920));
  background: url(${Logo}) no-repeat left calc(100vw * (30 / 1920)) bottom
    calc(100vw * (30 / 1920)) / contain;
  background-size: calc(100vw * (204 / 1920)) calc(100vw * (36 / 1920));
  background-color: #000;

  &.on {
    padding: calc(100vw * (100 / 1920)) 0 0;
    background: url(${Logo}) no-repeat left calc(100vw * (100 / 1920)) top
      calc(100vw * (40 / 1920)) / contain;
    background-size: calc(100vw * (204 / 1920)) calc(100vw * (36 / 1920));
    background-color: #000;
  }

  & .imgContainer {
    flex-grow: 1;
    height: 71vh;
    margin-bottom: calc(100vw * (28 / 1920));
    padding: calc(100vw * (26 / 1920)) calc(100vw * (13 / 1920));
    background: #292929;
    border-radius: calc(100vw * (10 / 1920));
    overflow: auto;

    &.hidden {
      overflow: hidden;
    }

    &::-webkit-scrollbar {
      width: calc(100vw * (16 / 1920));
    }

    &::-webkit-scrollbar-track {
      background-color: #1c1c1c;
      border-radius: 999px;
      margin: calc(100vw * (15 / 1920));
    }

    &::-webkit-scrollbar-thumb {
      background-color: #2b2c30;
      border-radius: 999px;
      border: calc(100vw * (4 / 1920)) solid transparent;
    }

    & img {
      width: 100%;
      height: 100%;
      object-fit: fill;
    }
  }

  & .imgCheckContainer.on {
    position: absolute;
    left: 50%;
    bottom: calc(100vw * (20 / 1920));
    transform: translateX(-50%);

    & .checkboxListWrap {
      flex-direction: row;
      align-items: flex-start;
      gap: calc(100vw * (20 / 1920));
    }
  }
`;

export { Container };

export default { Container };
