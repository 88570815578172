import { useMemo, useRef, useState } from "react";
import Styles from "./styles";
import ImageList from "../ImageList";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import useIsBeautifulDndMount from "hooks/useIsBeautifulDndMount";
import { useMutation, useQueryClient } from "react-query";
import { api } from "service";
import useImageDoubleClickModalFunction from "hooks/useImageDoubleClickModalFunction";
import modal from "components/modal";

export default function APTContainer({
  listData,
  filterInfo,
  abnormality,
  IMValue,
  notUseDoubleClickModal,
  notUseContextModal,
  isReport,
  activeReportSelectImage,
  setActiveReportSelectImage,
}) {
  const [activeContextImageId, setActiveContextImageId] = useState(null);

  const [isDragging, setIsDragging] = useState(false);
  const [xy, setXY] = useState({ x: 0, y: 0 });

  const handleContextMenu = (e) => {
    setXY({ x: e.clientX, y: e.clientY });
  };

  const activeContextImage = listData.find(
    (item) => item.imageId === activeContextImageId
  );

  const queryClient = useQueryClient();
  const isBeautifulDndMount = useIsBeautifulDndMount();

  const { isActiveWithImageId, hybridClickImage } =
    useImageDoubleClickModalFunction();

  const imageGridList = useMemo(() => {
    const list = Array.from({ length: 12 }, () => {
      return [];
    });
    listData?.forEach((value) => {
      list[value.landmark - 1]?.push(value);
    });

    return list;
  }, [listData]);

  const { mutate: landmarkChangeMutate } = useMutation(
    (changeProps) =>
      api.image.putLandmarkChange({
        landmark: changeProps.landmark,
        imageId: changeProps.imageId,
        priority: changeProps.priority,
        sequence: changeProps.sequence,
        imageName: changeProps.imageName,
        path: changeProps.path,
      }),
    {
      async onMutate(variable) {
        await queryClient.cancelQueries(["image_list", filterInfo]);

        const previousImageList = queryClient.getQueryData([
          "image_list",
          filterInfo,
        ]);

        let targetValue = null;
        let resultList = [];
        let list = previousImageList;

        list = list.filter((value, index) => {
          // 모든 이미지와 잡은 이미지를 비교 해서 잡은 이미지만 제거된 전체 이미지 리스트
          // variable은 바뀐 이미지 정보 (랜드마크, sequenece)
          if (value.imageId === variable.imageId) {
            console.log(variable);
            targetValue = value;
            return false;
          }
          return true;
        });
        //(결함)이미지 드래그
        console.log("클릭한 이미지만 제거된 전체 이미지 리스트", list);

        let noneLandmarkList = list.filter(
          (value) => value.landmark != variable.landmark
        );
        let landmarkList = list.filter(
          (value) => value.landmark == variable.landmark
        );

        targetValue.sequence = variable.sequence;
        targetValue.landmark = variable.landmark;
        console.log("target", targetValue);

        // 바뀐 랜드마크 이미지 리스트
        landmarkList = [
          ...landmarkList.slice(0, variable.sequence),
          targetValue,
          ...landmarkList.slice(variable.sequence),
        ];

        console.log(landmarkList);

        resultList = noneLandmarkList.concat(landmarkList);
        queryClient.setQueryData(["image_list", filterInfo], resultList);

        return () =>
          queryClient.setQueryData(["image_list", filterInfo], resultList);

        // await queryClient.cancelQueries(["image_list", filterInfo]);

        // const previousImageList = queryClient.getQueryData([
        //   "image_list",
        //   filterInfo,
        // ]);

        // queryClient.setQueryData(["image_list", filterInfo], (oldImageList) => {
        //   return oldImageList.map((imageItem) => {
        //     if (imageItem.imageId === variable.imageId) {
        //       return {
        //         ...imageItem,
        //         landmark: variable.landmark,
        //         priority: variable.priority,
        //       };
        //     }
        //     return imageItem;
        //   });
        // });

        // return () =>
        //   queryClient.setQueryData(
        //     ["image_list", filterInfo],
        //     previousImageList
        //   );
      },
      onSettled(response) {
        if (!response?.message) {
          throw new Error(response.error);
        }
      },
      onError(error, values, rollback) {
        if (rollback) rollback();
      },
    }
  );

  return (
    <>
      <Styles.Container>
        <DragDropContext
          onDragStart={() => {
            setIsDragging(true);
          }}
          onDragEnd={(props) => {
            setIsDragging(false);

            let target = listData.filter(
              (value, index) => value.imageId == props.draggableId
            )[0];

            if (target == null) {
              return;
            }

            const targetImageId = Number(props.draggableId);

            const destinationId = props.destination.droppableId;

            const [forPosition, beforLandmark] = destinationId.split("-");

            const forLandmark = Number(beforLandmark);
            const path = target.path;
            const imageName = target.imageName;

            landmarkChangeMutate({
              imageId: targetImageId,
              landmark: forLandmark,
              sequence: props.destination.index,
              priority: forPosition === "left" ? 0 : 1,
              imageName,
              path,
            });

            // if (forPosition === "left") {
            //   landmarkChangeMutate({
            //     imageId: targetImageId,
            //     landmark: forLandmark,
            //     priority: 0,
            //   });
            //   return;
            // }

            // if (forPosition === "right") {
            //   landmarkChangeMutate({
            //     imageId: targetImageId,
            //     landmark: forLandmark,
            //     priority: 1,
            //   });
            // }
          }}
        >
          <ul className="aptListWrap">
            {imageGridList.map((value, index) => {
              const landmark = index + 1;
              return (
                <li className="aptList" key={"aptList" + index}>
                  {isBeautifulDndMount && (
                    <>
                      <Droppable
                        droppableId={String("left-" + landmark)}
                        direction="horizontal"
                      >
                        {(provided) => (
                          <ul
                            ref={provided.innerRef}
                            {...provided.droppableProps}
                            className="leftContainer"
                            onWheel={(e) => {
                              e.preventDefault();
                              e.target.scrollLeft += e.deltaY;
                            }}
                          >
                            {value?.map((item, index) => {
                              const isActive = isActiveWithImageId(
                                item.imageId
                              );
                              const isReportImageActive =
                                (activeReportSelectImage?.path ??
                                  activeReportSelectImage) === item.path;
                              return (
                                <Draggable
                                  draggableId={String(item.imageId)}
                                  index={index}
                                  key={"imageList" + item.imageId}
                                  // draggableId={String(item.imageId)}
                                  // index={0}
                                  // key={item.imageId}
                                  // isDragDisabled={isReport}
                                >
                                  {(provided) => (
                                    <li
                                      ref={provided.innerRef}
                                      {...provided.draggableProps}
                                      {...provided.dragHandleProps}
                                      className={
                                        "imgList" +
                                        (!isReport
                                          ? isActive
                                            ? " on"
                                            : ""
                                          : isReportImageActive
                                          ? " on"
                                          : "")
                                      }
                                      onClick={(event) => {
                                        if (isReport) {
                                          if (!isReportImageActive) {
                                            setActiveReportSelectImage(item);
                                          } else {
                                            setActiveReportSelectImage(null);
                                          }
                                        }
                                        if (notUseDoubleClickModal) return;
                                        hybridClickImage(event, item);
                                      }}
                                    >
                                      <ImageList
                                        item={item}
                                        index={index}
                                        abnormality={abnormality}
                                        IMValue={IMValue}
                                        activeContextImageId={
                                          activeContextImageId
                                        }
                                        setActiveContextImageId={
                                          setActiveContextImageId
                                        }
                                        handleContextMenu={handleContextMenu}
                                        notUseContextModal={notUseContextModal}
                                      />
                                    </li>
                                  )}
                                </Draggable>
                              );
                            })}
                          </ul>
                        )}
                      </Droppable>

                      {/* <Droppable
                        droppableId={"right-" + landmark}
                        direction="horizontal"
                      >
                        {(provided) => (
                          <ul
                            ref={provided.innerRef}
                            {...provided.droppableProps}
                            className="rightContainer"
                          >
                            {value[1]?.map((item, index) => {
                              const isActive = isActiveWithImageId(
                                item.imageId
                              );
                              const isReportImageActive =
                                (activeReportSelectImage?.path ??
                                  activeReportSelectImage) === item.path;
                              return (
                                <Draggable
                                  draggableId={String(item.imageId)}
                                  index={"r" + index}
                                  key={"imageList" + item.imageId}
                                >
                                  {(provided) => (
                                    <li
                                      ref={provided.innerRef}
                                      {...provided.draggableProps}
                                      {...provided.dragHandleProps}
                                      className={
                                        "imgList" +
                                        (!isReport
                                          ? isActive
                                            ? " on"
                                            : ""
                                          : isReportImageActive
                                          ? " on"
                                          : "")
                                      }
                                      onClick={(event) => {
                                        if (isReport) {
                                          if (!isReportImageActive) {
                                            setActiveReportSelectImage(item);
                                          } else {
                                            setActiveReportSelectImage(null);
                                          }
                                        }
                                        if (notUseDoubleClickModal) return;
                                        hybridClickImage(event, item);
                                      }}
                                    >
                                      <ImageList
                                        item={item}
                                        index={index}
                                        abnormality={abnormality}
                                        IMValue={IMValue}
                                        activeContextImageId={
                                          activeContextImageId
                                        }
                                        setActiveContextImageId={
                                          setActiveContextImageId
                                        }
                                        handleContextMenu={handleContextMenu}
                                        notUseContextModal={notUseContextModal}
                                      />
                                    </li>
                                  )}
                                </Draggable>
                              );
                            })}
                          </ul>
                        )}
                      </Droppable> */}
                    </>
                  )}
                </li>
              );
            })}
          </ul>
        </DragDropContext>
      </Styles.Container>
      {activeContextImage && (
        <modal.ContextMenuModal
          priority={activeContextImage.priority}
          imageId={activeContextImage.imageId}
          isModalOpen={true}
          onClickOutSide={() => setActiveContextImageId(null)}
          lesion={activeContextImage.lesion}
          path={activeContextImage.path}
          landmark={activeContextImage.landmark}
          xy={xy}
          setXY={setXY}
          notUseDoubleClickModal={notUseDoubleClickModal}
        />
      )}
    </>
  );
}
