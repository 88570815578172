import { useRef, useEffect, useMemo, useState } from "react";
import { common, layout } from "components";
import Styles from "./styles";
import { useLanguage } from "hooks";
import ReactToPrint from "react-to-print";
import { getCookie } from "service/connector";

import {
  PatientInfoTable,
  LandmarkSection,
  IMSection,
  AbnormalitySection,
  PicturesTitle,
} from "./components";

// img
import IconLogo from "resources/image/icon/logo.svg";
import { useQuery } from "react-query";
import { useNavigate, useParams } from "react-router-dom";
import { api } from "service";
import { responseCommentDataToList } from "utils/common";
import HistorySection from "./components/HistorySection";
import SelectSection from "./components/SelectSection";
import { useReport } from "hooks/useReport";

export default function ReportPreviewPage(props) {
  const ref = useRef();
  const lang = useLanguage("Report");
  const { id, studyId } = useParams();
  const navigate = useNavigate();
  const [scope, setScope] = useState("lite");
  const [reportViewPort, setReportViewPort] = useState(100); // zoom3일때 100, zoom2일때 80, zoom3일때 60

  useEffect(() => {
    setScope(getCookie("scope"));
  }, []);

  useEffect(() => {
    // 배경삭제
    const html = document.getElementsByTagName("html")[0];
    const body = document.getElementsByTagName("body")[0];
    html.style.backgroundColor = "#ffffff";
    body.style.backgroundColor = "#ffffff";
  }, []);

  const reportInfo = useReport();

  const commentObject = reportInfo.reportData?.comment_new;

  const commentList = useMemo(() => {
    return responseCommentDataToList(commentObject);
  }, [commentObject]);

  console.log("ReportPreviewPage reportData: ", reportInfo.reportData);

  return (
    <Styles.Container>
      <common.ReportHeader title={"미리보기"} />

      {/* 프린트영역 */}
      <Styles.PrintWrap ref={ref}>
        {/* 페이지 구분 */}
        <div className="inner">
          <h2 className="prview_title">AI 내시경 결과 REPORT</h2>
          <PatientInfoTable />

          {/* 랜드마크 */}
          {reportInfo.reportData.isLandmark && (
            <div className="row">
              <PicturesTitle
                colorTitle={"위 부위별 대표 사진"}
              />
              <div className="box">
                <LandmarkSection />
              </div>
            </div>
          )}

          {/* 장상피화생 */}
          {reportInfo.reportData.isIM && (
            <div className="row" style={{ pageBreakInside: "avoid" }}>
              <PicturesTitle
                colorTitle={
                  scope === "lite"
                    ? lang.getLang("히트맵")
                    : lang.getLang("장상피화생")
                }
              />
              <div className="box">
                <IMSection viewPort={reportViewPort} />
              </div>
            </div>
          )}

          {/* 선종 의심 병변 */}
          {reportInfo.reportData.isAbnormality && (
            <div className="row" style={{ pageBreakInside: "avoid" }}>
              <PicturesTitle
                colorTitle={lang.getLang("이상부위")}
              />
              <div className="box">
                <AbnormalitySection />
              </div>
            </div>
          )}

          {/* 위 내시경 최근 이력 */}
          {reportInfo.reportData.isHistory && (
            <div className="row" style={{ pageBreakInside: "avoid" }}>
              <PicturesTitle
                colorTitle={lang.getLang("History")}
              />
              <div className="box">
                <HistorySection />
                {/* <IMSection img={true} text={true} num={true} /> */}
              </div>
            </div>
          )}

          {/* 주요 내시경 사진 */}
          {reportInfo.reportData.isSelectImage && (
            <div className="row" style={{ pageBreakInside: "avoid" }}>
              <PicturesTitle
                colorTitle={lang.getLang("사진 추가 선택")}
              />
              <div className="box">
                <SelectSection />
              </div>
            </div>
          )}

          {/* 의사 코멘트 */}
          {reportInfo.reportData.isComment && (
            <div className="row" style={{ pageBreakInside: "avoid" }}>
              <PicturesTitle colorTitle={"Doctor’s Comments"}/>
              <div className="box comment_box">
                {commentList.map(({ comment, id }) => {
                  return (
                    <p className="comment" key={id}>
                      {comment}
                    </p>
                  );
                })}
              </div>
            </div>
          )}
        </div>
      </Styles.PrintWrap>

      {/* 프린트 버튼 */}
      <div className="btn_box">
        <img src={IconLogo} alt="prevenotics" />
        <div className="btn_list">
          <button
            className="cancel_btn"
            onClick={() => {
              reportInfo.setReportWriteData((prev) => (
                {
                  ...prev,
                  comment: prev.comment_new,
                }
              ));
              navigate(-1);
            }}
          >
            {lang.getLang("취소")}
          </button>
          <ReactToPrint
            trigger={() => (
              <button className="print_btn">{lang.getLang("출력")}</button>
            )}
            content={() => ref.current}
          />
        </div>
      </div>
    </Styles.Container>
  );
}
