import { useState, useEffect } from "react";
import { useModals, useLanguage } from "hooks";
import Styles from "./styles";

export default function TableAdmin({ list, setFilterInfo, refetch }) {
  const [filter, setFilter] = useState({ sortby: false, order: false });
  const modalOption = useModals();
  const lang = useLanguage("Admin");

  return (
    <Styles.Container>
      <div className={`title_box ${list.length > 12 ? "scroll" : ""}`}>
        <div className="box title">
          <p>INDEX</p>
        </div>
        <div className="box title">
          <p>ID</p>
        </div>
        <div className="box title center">
          <p>{lang.getLang("이름")}</p>
          {/* <button
            type="button"
            className={`${filter.sortby ? "down" : "up"}`}
            onClick={() => {
              setFilterInfo((e) => {
                e.sortby = !filter.sortby ? "auth" : "name";
                e.order = filter.order ? "ASC" : "DESC";
                return { ...e };
              });
              setFilter((e) => {
                e.sortby = !filter.sortby;
                return { ...e };
              });
            }}
          ></button> */}
        </div>
        <div className="box title">
          <p>{lang.getLang("휴대폰 번호")}</p>
        </div>
        <div className="box title">
          <p>{lang.getLang("권한")}</p>
          {/* <button
            type="button"
            className={`${filter.order ? "down" : "up"}`}
            onClick={() => {
              setFilterInfo((e) => {
                e.order = !filter.order ? "DESC" : "ASC";
                e.sortby = filter.sortby ? "name" : "auth";
                return { ...e };
              });
              setFilter((e) => {
                e.order = !filter.order;
                return { ...e };
              });
            }}
          ></button> */}
        </div>
        <div className="box title center">
          <p>{lang.getLang("생성일자")}</p>
        </div>
        <div className="box title">
          <p></p>
        </div>
      </div>

      <div className="list_box">
        {list.map((value, index) => {
          return (
            <div className="list_row" key={index + "admin list"}>
              <div className="box list">
                <p>{value.idx? value.idx : value.index}</p>
              </div>
              <div className="box list">
                <p>{value.userId}</p>
              </div>
              <div className="box list center">
                <p>{value.userName}</p>
              </div>
              <div className="box list">
                <p>{value.phone}</p>
              </div>
              <div className="box list">
                <p>
                  {(value.auth === 0 && "관리자") ||
                    (value.auth === 1 && "의사") ||
                    (value.auth === 2 && "간호사")}
                </p>
              </div>
              <div className="box list center">
                <p>{value.creationDate?.split(" ")[0]}</p>
              </div>
              <div className="box list button">
                <button
                  type="button"
                  className="changePwButton"
                  onClick={() => {
                    modalOption.setChangePassowrdModal((e) => {
                      return {
                        ...e,
                        show: true,
                        id: value.idx,
                        displayID: value.userId,
                        name: value.userName,
                      };
                    });
                  }}
                >
                  {lang.getLang("비밀번호 변경")}
                </button>
                <button
                  type="button"
                  className="userDeleteButton"
                  onClick={() => {
                    modalOption.setUserDeleteModal((e) => {
                      return {
                        ...e,
                        show: true,
                        id: value.idx,
                        displayID: value.userId,
                        name: value.userName,
                        refetch: refetch,
                        auth: value.auth,
                      };
                    });
                  }}
                >
                  {lang.getLang("유저 삭제")}
                </button>
              </div>
            </div>
          );
        })}
      </div>
    </Styles.Container>
  );
}
