import styled, { css } from "styled-components";

// img
import IconFilter from "resources/image/icon/icon_filter.svg";

const Container = styled.div`
  width: 100%;

  & .title_box {
    border-bottom: calc(100vw * (3 / 1920)) solid #424447;
  }
  & .title_box,
  .list_row {
    display: flex;
    align-items: center;
    gap: calc(100vw * (20 / 1920));
    width: 100%;
    height: calc(100vw * (65 / 1920));
    padding: 0 calc(100vw * (45 / 1920));
  }
  /* & .title_box.scroll {padding: 0 calc(100vw*(61/1920)) 0 calc(100vw*(45/1920));} */
  & .title {
    display: flex;
    align-items: center;
    gap: calc(100vw * (10 / 1920));
  }
  & .box {
    overflow: hidden;
  }
  & .box.center {
    justify-content: center;
  }
  & .box.center p {
    text-align: center;
  }
  & .box p {
    font-size: calc(100vw * (24 / 1920));
  }
  & .box p.text {
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }
  & .box button {
    width: calc(100vw * (16 / 1920)); height: calc(100vw * (16 / 1920));
    background: url(${IconFilter}) no-repeat center/contain;
    cursor: pointer;
  }
  & .box button.up {transform: rotate(180deg);}

  & .list_box {
    width: 100%;
    /* height: calc(100vh - calc(100vw * (280 / 1920))); */
    height: calc(90vh - calc(100vw * (280 / 1920)));
    overflow-y: auto;
    overflow: overlay;
  }
  & .list_row {
    border-bottom: 1px solid #424447;
  }

  & .box:nth-child(1) {
    width: 10%;
  }
  & .box:nth-child(2) {
    width: 12%;
  }
  & .box:nth-child(3) {
    width: 12%;
  }
  & .box:nth-child(4) {
    width: 15%;
  }
  & .box:nth-child(5) {
    width: 12%;
  }
  & .box:nth-child(6) {
    width: 12%;
  }
  & .box:nth-child(7) {
    width: 26%;
  }

  & .button {
    display: flex;
    align-items: center;
    gap: calc(100vw * (15 / 1920));

    & button {
      min-width: calc(100vw * (163 / 1920));
      height: calc(100vw * (38 / 1920));
      padding: calc(100vw * (5 / 1920));
      font-size: calc(100vw * (20 / 1920));
      font-weight: 700;
      color: #fff;
      border-radius: calc(100vw * (7 / 1920));
    }

    & .changePwButton {
      background: #008ad1;
    }

    & .userDeleteButton {
      background: #6a6a6a;
    }
  }
`;

export { Container };

export default {
  Container,
};
