import connector from "service/connector";

const getList = async ({ patientId, landmark = 1 }) => {
  return await connector.authConnector(
    `images/history/path/${patientId}/${landmark}`,
    "GET"
  );
};

export { getList };

export default { getList };
