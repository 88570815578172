import styled from "styled-components";

// img
import Logo from "resources/image/icon/logo.svg";
import IconChecked from "resources/image/icon/icon_checked_line.svg";

const Container = styled.div`
  position: relative;
  width: 100%;
  min-height: 100vh;
  padding: calc(100vw * (37 / 1920)) calc(100vw * (50 / 1920));
  background-color: #000;

  & img {
    width: 100%;
    height: 100%;
    object-fit: fill;
  }

  & .title {
    margin-bottom: calc(100vw * (24 / 1920));
    font-size: calc(100vw * (50 / 1920));
    font-weight: 600;
  }

  & .wrap {
    display: flex;
    justify-content: space-between;

    & .leftContainer {
      flex-shrink: 0;
      margin-right: calc(100vw * (65 / 1920));

      & .userDataContainer {
        margin-bottom: calc(100vw * (100 / 1920));
      }

      & .scoreWrap {
        & .gifTitle {
          font-size: calc(100vw * (40 / 1920));
        }

        & .score {
          font-size: calc(100vw * (170 / 1920));

          & span {
            font-size: calc(100vw * (80 / 1920));
          }
        }

        & .gifWrap {
          width: calc(100vw * (510 / 1920));
          height: calc(100vw * (510 / 1920));
        }
      }
    }

    & .rightContainer {
      display: flex;
      gap: calc(100vw * (43 / 1920));
    }
  }
  & .prevButton {
    /* position: absolute; */
    /* bottom: calc(100vw * (73 / 1920));
    right: calc(100vw * (49 / 1920)); */
    float: right;
    margin-top: 30px;
    width: calc(100vw * (213 / 1920));
    height: calc(100vw * (44 / 1920));
    font-size: calc(100vw * (24 / 1920));
    font-weight: bold;
    background-color: #008ad1;
    border-radius: calc(100vw * (10 / 1920));
  }
`;
// (결함) 버튼 위치 조정

export { Container };

export default { Container };
