import styled from "styled-components";
const Container = styled.div`
  & .inner {
    position: relative;
    height: 100%;
    padding-bottom: calc(100vw * (14 / 1920));
    background: #292929;

    & .aptContainer {
      width: 100%;
      height: 100%;
      overflow: auto;
    }    
  }
`;

export { Container};

export default { Container };
