import connector from "service/connector";

/**
 * @description 리포트 번호 검색 및 생성
 */
const getReports = async ({ medicalstudyId }) => {
  return await connector.authConnector(`reports/${medicalstudyId}`, "GET");
};

const getReportsMainCategory = async () => {
  return await connector.authConnector(`reports/category`, "GET");
};

const getReportsMiddleCategory = async ({ majorId }) => {
  return await connector.authConnector(`reports/category?id=${majorId}`, "GET");
};

const getReportsAllCategory = async () => {

  const mainCategorys = await connector.authConnector(`reports/category`, "GET");
  
  let middleCategoryData = [];

  await Promise.all(
    mainCategorys.map(async (item) => {
      const middleCategorys = await connector.authConnector(
        `reports/category?id=${item.majorId}`,
        "GET"
      );

      middleCategoryData.push(middleCategorys);
    }
  ));

  middleCategoryData = middleCategoryData.flat().sort((a, b) => a.subId - b.subId);

  return middleCategoryData;
};

const getReportsImages = async ({
  medicalstudyId,
  check,
  landmark,
  lesionType,
}) => {
  if (check === "im") {
    return await connector.authConnector(
      `reports/images/${medicalstudyId}?check=${check}${
        landmark ? `&landmark=${landmark}` : ""
      }`,
      "GET"
    );
  }

  if (check === "history") {
    return await connector.authConnector(
      `reports/images/${medicalstudyId}?check=${check}${
        landmark ? `&landmark=${landmark}` : ""
      }`,
      "GET"
    );
  }

  if (check === "abnormality") {
    return await connector.authConnector(
      `reports/images/${medicalstudyId}?check=${check}${
        lesionType ? `&lesionType=${lesionType}` : ""
      }`,
      "GET"
    );
  }

  return await connector.authConnector(
    `reports/images/${medicalstudyId}?check=${check}`,
    "GET"
  );
};

const putReportsPriorityChangeIM = async ({
  studyId,
  imageId,
  landmark,
}) => {
  return await connector.authConnector(`reports/images/im`, "PUT", {
    studyId,
    imageId,
    landmark,
  });
};
const putReportsPriorityChangeAbnormality = async ({
  studyId,
  imageId,
  landmark,
  lesionType,
}) => {
  return await connector.authConnector(`reports/images/abnormality`, "PUT", {
    studyId,
    imageId,
    landmark,
    lesionType,
  });
};
const putReportsPriorityChangeHistory = async ({
  studyId,
  recentStudy,
  recentImageId,
  prevImageId,
}) => {
  // console.log("final ======= ");
  // console.log(studyId);
  return await connector.authConnector(`reports/images/history`, "PUT", {
    studyId,
    recentStudy,
    recentImageId,
    prevImageId,
  });
};

const postReports = async ({ medicalstudyId, ...parameters }) => {
  console.log("postReports parameters : ", parameters);
  return await connector.authConnector(`reports/${medicalstudyId}`, "POST", {
    ...parameters,
  });
};

export {
  getReports,
  getReportsImages,
  getReportsMainCategory,
  getReportsMiddleCategory,
  getReportsAllCategory,
  putReportsPriorityChangeIM,
  putReportsPriorityChangeAbnormality,
  putReportsPriorityChangeHistory,
  postReports,
};

export default {
  getReports,
  getReportsImages,
  getReportsMainCategory,
  getReportsMiddleCategory,
  getReportsAllCategory,
  putReportsPriorityChangeIM,
  putReportsPriorityChangeAbnormality,
  putReportsPriorityChangeHistory,
  postReports,
};
