import Styles from "./styles";

export default function ReportTitle({ title, subTitle, line, viewPort }) {
  return (
    <Styles.Container line={line} viewPort={viewPort}>
      <span className="colorTitle">{title}</span>{" "}
      <span className="subTitle">{subTitle}</span>
    </Styles.Container>
  );
}
