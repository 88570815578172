import Styles from "./styles";
import { useEffect, useRef, useState, useMemo } from "react";
import { layout } from "components";
import { useLanguage } from "hooks";

// img
import IconCategory from "resources/image/icon/icon_category.svg";
import IconPlusMini from "resources/image/icon/icon_plus_mini.svg";
import IconPlusMini02 from "resources/image/icon/icon_plus_mini_02.svg";
import IconMinusMini from "resources/image/icon/icon_minus_mini.svg";
import IconSearch from "resources/image/icon/icon_search_report.svg";
import IconCheck from "resources/image/icon/icon_check_01.svg";
import IconClose from "resources/image/icon/icon_close.svg";
import { useQuery } from "react-query";
import { api } from "service";
import { v4 } from "uuid";
import { CommentBox } from "./components";
import { useParams } from "react-router-dom";
import { responseCommentDataToList } from "utils/common";
import { useReport } from "hooks/useReport";
import { set } from "lodash";

export default function CommentsSection(props) {
  const lang = useLanguage("Report");

  const reportInfo = useReport();
  
  const { id, studyId } = useParams();

  const initialData = useMemo(() => {
    return reportInfo.reportData.comment;
  }, [reportInfo.reportData.comment]);

  const [activeMainCategoryId, setActiveMainCategoryId] = useState(null);
  const [selectCommentList, setSelectCommentList] = useState([]);
  const [commentList, setCommentList] = useState([]);
  const keyupRef = useRef();

  const updateReporInfo = () => {
    let newCommentList = {};

    selectCommentList.forEach((item, index) => {
      const { recommendations, subId, subTitle } = item;

      const itemName = "commentId" + (index + 1);
      const newItem = {
        [itemName]: {
          subId,
          recommendations,
          subTitle,
        }
      };

      newCommentList = {
        ...newCommentList,
        ...newItem,
      };
    });

    commentList.forEach((item, index) => {
      const itemName = "comment" + (index + 1);
      const newItem = {
        [itemName]: item.comment,
      };

      newCommentList = {
        ...newCommentList,
        ...newItem,
      };
    });

    reportInfo.setReportWriteData((prev) => ({
      ...prev,
      isComment: commentList.length > 0,
      comment_new: newCommentList,
    }));
  };


  useEffect(() => {
    updateReporInfo();
  }, [commentList]);

  useEffect(() => {
    if (initialData) {

      // console.log("initialData", initialData);

      setSelectCommentList([]);
      setCommentList([]);

      let commentIdList = [];
      let commentList = [];

      // commentId숫자로 시작하는 key값을 찾아서 commentIdList에 추가
      // comment숫자로 시작하는 key값을 찾아서 commentList에 추가

      for (let i = 1; i <= 3; i++) {  
        const commentId = initialData["commentId" + i];
        if (commentId && commentId.subId) {
          commentIdList.push(commentId);
          commentList.push({
            type: "CATEGORY",
            id: commentId.subId,
            comment: commentId.recommendations,
          });
        }
      }

      for (let i = 1; i <= 4; i++) {
        const comment = initialData["comment" + i];

        if (comment) {


          if ( commentList.map((item) => item.comment).includes(comment) ) {
            continue;
          }

          commentList.push({
            type: "CUSTOM",
            id: v4(),
            comment,
          });
        }
      }

      setSelectCommentList(commentIdList);
      setCommentList(commentList);

      // console.log("initialData commentIdList", commentIdList);
      // console.log("initialData commentList", commentList);

      if (reportInfo.reportData.backFromPreview) {
        reportInfo.setReportWriteData((prev) => ({
          ...prev,
          backFromPreview: false,
        }));
      }
    }
  }, [initialData]);

  const addComment = ({ type, id, comment }) => {
    if (commentList.length >= 4) return;
    const newItem = { type, id, comment };
    setCommentList((prevList) => {
      const categoryList = [];
      const customList = [];

      prevList.forEach((item) => {
        if (item.type === "CATEGORY") {
          categoryList.push(item);
        }
        if (item.type === "CUSTOM") {
          customList.push(item);
        }
      });
      let newList = [];
      if (type === "CATEGORY") {
        newList = [...categoryList, newItem, ...customList];
      }

      if (type === "CUSTOM") {
        newList = [...categoryList, ...customList, newItem];
      }

      return newList;
    });
  };

  const removeComment = (id) => {
    setCommentList((prevList) => prevList.filter((item) => item.id !== id));
  };

  const changeComment = ({ id, comment }) => {
    // if (selectCommentList.some((item) => item.subId === id))  return;
    setCommentList((prevList) =>
      prevList.map((item) => {
        if (item.id === id) {
          return {
            ...item,
            comment,
          };
        }
        return item;
      })
    );
  };

  const isSelectCommentList = (subId) => {
    return selectCommentList.some((item) => item.subId === subId);
  };

  const addSelectCommentList = (item) => {
    if (selectCommentList.length >= 3 || isSelectCommentList(item.subId)) return;
    if (commentList.length >= 4) return;

    setSelectCommentList((prevList) => [...prevList, item]);

    addComment({
      type: "CATEGORY",
      id: item.subId,
      comment: item.recommendations,
    });

  };

  const removeSelectComment = (subId) => {
    setSelectCommentList((prevList) =>
      prevList.filter((item) => item.subId !== subId)
    );
    removeComment(subId);
  };

  // const { data: allCategoryData = [], isLoading: isLoadingAllCategory } = useQuery(
  //   ["reports_all_category"],
  //   () => api.reports.getReportsAllCategory(),
  //   {
  //     refetchOnWindowFocus: false,
  //     onSuccess(responseData) {

  //       if (props.initialData) {

  //         let commentIdList = [];
  //         let commentList = [];
  //         for (let i = 1; i <= 3; i++) {
  //           const commentId = props.initialData["commentId" + i];
  //           if (commentId) {
  //             commentIdList.push(commentId);
  //           }
  //         }
    
  //         for (let i = 1; i <= 4; i++) {
  //           const comment = props.initialData["comment" + i];
  //           if (comment) {
  //             commentList.push(comment);
  //           }
  //         }

  //         console.log("commentIdList", commentIdList);
  //         console.log("commentList", commentList);



          
  //       }
  //     }
  //   }
  // );

  const { data: reportsMainCategoryData = [], isLoading: isLoadingMainCategory } = useQuery(
    ["reports_main_category"],
    () => api.reports.getReportsMainCategory(),
    {
      refetchOnWindowFocus: false,
    }
  );

  const { data: reportsMiddleCategoryData = [], isLoading: isLoadingMiddleCategory } = useQuery(
    ["reports_middle_category", activeMainCategoryId],
    () =>
      api.reports.getReportsMiddleCategory({ majorId: activeMainCategoryId }),
    {
      refetchOnWindowFocus: false,
      enabled: !!activeMainCategoryId,
      staleTime: 5 * 1000 * 60,
    }
  );

  const reportsCategoryList =
    reportsMainCategoryData && reportsMainCategoryData.map
      ? reportsMainCategoryData.map((mainCategoryData) => {
          return {
            ...mainCategoryData,
            middleCategoryList:
              activeMainCategoryId === mainCategoryData.majorId
                ? reportsMiddleCategoryData
                : [],
          };
        })
      : [];

  if (isLoadingMainCategory || isLoadingMiddleCategory) return <></>

  return (
    <Styles.Container viewPort={props.viewPort}>
      <div className="titleContainer">
        <layout.ReportTitle
          title={"Doctor’s Comments"}
          subTitle={lang.getLang("의사 소견을 클릭해주세요.")}
          line={true}
          viewPort={props.viewPort}
        />
      </div>

      <div className="commentsListContainer">
        <div className="leftContainer">
          <div className="listTitle">
            <div className="iconWrap">
              <img src={IconCategory} alt="category" />
            </div>
            목표 질환
          </div>

          <ul className="commentsListWrap">
            {reportsCategoryList?.map(
              ({ majorId, majorTitle, middleCategoryList }, index) => {
                return (
                  <li
                    className={
                      "commentsList" +
                      (activeMainCategoryId === majorId ? " on" : "")
                    }
                    key={"commentsList" + majorId}
                  >
                    <p
                      className="commentsTitle"
                      onClick={() => {
                        setActiveMainCategoryId((prevMajorId) => {
                          if (prevMajorId === majorId) return null;
                          return majorId;
                        });
                      }}
                    >
                      <span className="iconWrap">
                        <img
                          src={
                            activeMainCategoryId === majorId
                              ? IconMinusMini
                              : IconPlusMini
                          }
                          alt="icon_plus"
                        />
                      </span>
                      {majorTitle}
                    </p>

                    {activeMainCategoryId === majorId && (
                      <ul className="subCommentListWrap">
                        {middleCategoryList?.map((item, idx) => {
                          const { recommendations, subId, subTitle } = item;
                          return (
                            <li
                              className={
                                "subCommentList" +
                                (isSelectCommentList(subId) ? " on" : "")
                              }
                              key={"subCommentList" + subId}
                              onClick={() => {
                                addSelectCommentList(item);
                              }}
                            >
                              {subTitle}
                            </li>
                          );
                        })}
                      </ul>
                    )}
                  </li>
                );
              }
            )}
          </ul>
        </div>

        {!!selectCommentList?.length && (
          <div className="rightContainer">
            <div className="listTitle">
              <div className="leftTitle">
                <div className="iconWrap">
                  <img src={IconSearch} alt="search" />
                </div>
                판정
              </div>

              <div className="rightTitle">
                <div className="iconWrap">
                  <img src={IconCheck} alt="check" />
                </div>
                권고사항
              </div>
            </div>

            {selectCommentList?.map((item) => {
              const { recommendations, subId, subTitle } = item;

              return (
                <>
                  <p className="commentsTitle">
                    <span className="iconWrap">
                      <img src={IconSearch} alt="search" />
                    </span>
                    {subTitle}
                    <button
                      type="button"
                      className="closeButton"
                      onClick={() => {
                        removeSelectComment(subId);
                      }}
                    >
                      <img src={IconClose} alt="close" />
                    </button>
                  </p>

                  <p className="comment">{recommendations}</p>
                </>
              );
            })}
          </div>
        )}
      </div>

      <div className="commentsContainer">
        <div className="commentTitleContainer">
          <p className="commentTitle">
            {lang.getLang("글자를 클릭하여 수정할 수 있습니다.")}
          </p>

          <button
            type="button"
            className="addCommentButton"
            onClick={() => {
              addComment({
                type: "CUSTOM",
                id: v4(),
                comment: "",
              });
            }}
          >
            <span className="iconWrap">
              <img src={IconPlusMini02} alt="plus" />
            </span>
            Add comment
          </button>
        </div>
        <ul className="commentListWrap">
          {commentList.map((item) => {
            return (
              <li className="commentList" key={item.id}>
                <CommentBox
                  onBlur={(event) => {
                    const value = event.target.value;
                    if (value === "") {
                      removeSelectComment(item.id);
                    }
                  }}
                  value={item.comment}
                  onChange={(event) => {
                    const value = event.target.value;
                    changeComment({
                      id: item.id,
                      comment: value,
                    });
                  }}
                  onKeyUp={(event) => {
                    const value = event.target.value;
                    if (
                      value == "" &&
                      item.comment == "" &&
                      event.key === "Backspace"
                    ) {
                      keyupRef.current =
                        keyupRef.current == null
                          ? 0
                          : keyupRef.current > 2
                          ? 0
                          : keyupRef.current + 1;
                      if (keyupRef.current == 2) {
                        removeSelectComment(item.id);
                      }
                    } else {
                      keyupRef.current = 0;
                    }
                  }}
                />
              </li>
            );
          })}
        </ul>
      </div>
    </Styles.Container>
  );
}
