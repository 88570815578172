import connector from "service/connector";

function getFilterStr(parameter){
  let filter = parameter;
  return new URLSearchParams(filter).toString();
}

// Files Upload
const uploadFiles = async (data) => {

  let formData = new FormData();
  for (let i = 0; i < data.length; i++) {
    formData.append(`images`, data[i]);
  }

  return await connector.uploadConnector(`studies/upload`,"POST", formData);

}

// 업로드된 파일 삭제
const deleteUploadedFile = async (studyId) => {
  return await connector.authConnector(`studies/${studyId}`,"DELETE");
}

export {
  uploadFiles,
  deleteUploadedFile
};


export default {
  uploadFiles,
  deleteUploadedFile
}