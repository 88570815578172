import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { QueryClient, QueryClientProvider } from "react-query";
import { ReactQueryDevtools } from "react-query/devtools";
import { BrowserRouter } from "react-router-dom";
import {  useAtomsSnapshot,
  useGotoAtomsSnapshot,
  useAtomsDebugValue,
  // Redux devtool integration hooks
  useAtomDevtools,
  useAtomsDevtools } from 'jotai-devtools'
import "jotai-devtools/styles.css";

const queryClient = new QueryClient();

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
    <BrowserRouter>
      <React.StrictMode>
        <QueryClientProvider client={queryClient}>
          <App />
          {/* <ReactQueryDevtools /> */}
        </QueryClientProvider>
      </React.StrictMode>
    </BrowserRouter>
  // <BrowserRouter>
  //   <React.StrictMode>
  //     <QueryClientProvider client={queryClient}>
  //       {/* devtools */}
  //       <App />
  //     </QueryClientProvider>
  //   </React.StrictMode>
  // </BrowserRouter>
);

reportWebVitals();
