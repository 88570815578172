import Styles from "./styles";
import { useEffect, useImperativeHandle, useRef, useState } from "react";
import modal from "components/modal";
import { useModals } from "hooks";
import { useConfig } from "hooks/useConfig";
import { getCookie } from "service/connector";

// img
import IconTagRed from "resources/image/icon/icon_tag_red.svg";
import IconTagRedEmpty from "resources/image/icon/icon_tag_red_empty.svg";
import IconTagOrange from "resources/image/icon/icon_tag_orange.svg";
import IconTagOrangeEmpty from "resources/image/icon/icon_tag_orange_empty.svg";
import IconTagGreen from "resources/image/icon/icon_tag_green.svg";
// import IconTagPurple from "resources/image/icon/icon_tag_purple.svg";
// import IconTagPurpleEmpty from "resources/image/icon/icon_tag_purple_empty.svg";
import IconTagYellow from "resources/image/icon/icon_tag_yellow.svg";
import IconTagYellowEmpty from "resources/image/icon/icon_tag_yellow_empty.svg";
import { v4 } from "uuid";
import { IMAGE_SRC } from "constants/common";
import DwvComponent from "../DwvComponent";

// 환자 검색해서 환자 검진결과 하나 눌렀을때 나오는 환자의 검진 디테일 이미지 목록에 쓰이는중

export default function ImageList({
  id,
  studyId,
  item,
  abnormality,
  IMValue,
  setActiveContextImageId,
  handleContextMenu,
  notUseContextModal,
}) {
  const { lesion, landmark, path, imageId, priority } = item;
  const configInfo = useConfig();
  const [scope, setScope] = useState("lite");

  useEffect(() => {
    setScope(getCookie("scope"));
  }, []);

  const scopeLesion = (lesion) => {
    if (scope === "lite") {
      let boolCount = false;
      return lesion?.reduce((acc, current) => {
        if (current.type === 1 || current.type === 2 || current.type === 3) {
          if (!boolCount) {
            boolCount = true;
            return [...acc, { type: 1, score: current.score }];
          }
        } else {
          return [...acc, current];
        }
        return acc;
      }, []);
    } else if (scope === "plus") {
      return lesion;
    }
    return lesion;
  };

  const renderTag = (current, idx) => {
    switch (current.type) {
      case 1:
        return <img src={IconTagRed} alt="tag" />;
      case 2:
        return <img src={IconTagOrange} alt="tag" />;
      case 3:
        return <img src={IconTagGreen} alt="tag" />;
      case 4:
        return current.score > 100 - IMValue && <img src={IconTagYellow} alt="tag" />;

      default:
        return <></>;
    }
  };

  return (
    <Styles.Container
      onContextMenu={(e) => {
        if (notUseContextModal) return;
        e.preventDefault();
        setActiveContextImageId?.(imageId);
        handleContextMenu(e);
      }}
      onBlur={() => setActiveContextImageId?.(null)}
    >
      <ul className="tagListWrap">
        {!configInfo.setting.isSimpleMode &&
          scopeLesion(lesion).map((current, idx) => {
            // console.log("idx => ", idx);
            // console.log("current => ", current);
            return (
              <li className="tagList" key={v4()}>
                {renderTag(current, idx)}
              </li>
            );
          })}
        {landmark < 12 ? (
          priority === 0 ? (
            <li className="tagList landmark">{landmark}</li>
          ) : (
            <li className="tagList landmarkEmpty">{landmark}</li>
          )
        ) : null}
      </ul>

      <div className="imgWrap">
        {item?.imageName?.includes("d") ? (
          <DwvComponent index={item.imageId} item={item} />
        ) : (
          <img src={`${IMAGE_SRC}${path}`} alt="stomach" />
        )}
      </div>
    </Styles.Container>
  );
}
