const lang = {
  성명: {
    _en: "Name",
  },
  검진기관: {
    _en: "Hosptial",
  },
  생년월일: {
    _en: "Date of birth",
  },
  검진의: {
    _en: "Doctor",
  },
  성별: {
    _en: "Sex",
  },
  검진일: {
    _en: "Medical checkup",
  },
  환자번호: {
    _en: "Patient ID",
  },
  이전검진일: {
    _en: "Previous date of checkup",
  },
  "의사 소견을 클릭해주세요.": {
    _en: "Click on the Doctor’s Comments",
  },
  "글자를 클릭하여 수정할 수 있습니다.": {
    _en: "You can click on the text to edit it.",
  },
  "활성화 된 박스가 환자에게 보여집니다": {
    _en: "An activated box is displayed to the patient.",
  },
  장상피화생: {
    _en: "Intestinal Metaplasia",
  },
  히트맵: {
    _en: "Heatmap"
  },
  "암/선종 의심": {
    _en: "Suspicion of Cancer/Adenoma",
  },
  "이상병변": {
    _en: "Suspicion of Cancer/Adenoma",
  },
  "추가 선택": {
    _en: "Select additional",
  },
  취소: {
    _en: "Cancel",
  },
  저장: {
    _en: "Save",
  },
  미리보기: {
    _en: "Preview",
  },
  "위 부위별 장상피화생의 정도를 표시합니다.": {
    _en: "Displays the degree of IM by part of the stomach.",
  },
  "위 부위별 히트맵 정도를 표시합니다.": {
    _en: "Displays the degree of heatmap by part of the stomach.",
  },
  "사진 변경": {
    _en: "Change",
  },
  "암/선종": {
    _en: "Suspicion of Cancer/Adenoma",
  },
  "병변이 있는 부위를 표시합니다.": {
    _en: "Marks the area with lesions.",
  },
  " 님의": {
    _en: "’s",
  },
  "위 내시경 사진": {
    _en: "stomach image",
  },
  "정상 위": {
    _en: "Normal",
  },
  "모델 사진": {
    _en: "stomach image",
  },
  정상: {
    _en: "Normal",
  },
  식도: {
    _en: "Esophagus",
  },
  "위식도 접합부": {
    _en: "Esophagogastric junction",
  },
  "십이지장 2부": {
    _en: "Descending duodenum",
  },
  "십이지장 구부": {
    _en: "Duodenum bulb",
  },
  "위 전정부": {
    _en: "Antrum",
  },
  "위 각부": {
    _en: "Angle",
  },
  "위체부(하-중부)": {
    _en: "Body, LB-MB",
  },
  "위체부(중-상부)": {
    _en: "Body, MB-HB",
  },
  "위저부(반전)": {
    _en: "Retroflexion fundus",
  },
  "위 분문부(반전)": {
    _en: "Retroflexion cardia",
  },
  "위체부(반전)": {
    _en: "Retroflexion body",
  },
  "같은 위치를 시간순으로 추적/관찰합니다.": {
    _en: "Tracking/Observing the same location over time.",
  },
  "최근 촬영본": {
    _en: "Recent",
  },
  "(참고용)정상 이미지": {
    _en: "Normal",
  },
  "사진 추가 선택": {
    _en: "Select additional",
  },
  "마우스 우클릭으로 선택한 리포트용 사진을 출력합니다.": {
    _en: "Prints the selected report image using right-click.",
  },
  "위 부위 별 대표 사진": {
    _en: "Landmark",
  },
  "위 내시경 최근 이력": {
    _en: "History",
  },
  "선종 의심 병변": {
    _en: "Suspicion of Adenoma",
  },
  "주요 내시경 사진": {
    _en: "Select additional",
  },
  출력: {
    _en: "Print",
  },
  "리포트가 저장되었습니다.": {
    _en: "Report has been saved. ",
  },
  "미리보기 화면으로 이동합니다.": {
    _en: "Moving to the preview screen.",
  },
  확인: {
    _en: "OK",
  },
  "해당 부위의 촬영본이 없습니다.": {
    _en: (
      <>
        No past images available <br /> for the stomach areas.
      </>
    ),
  },
};

export default lang;
