import styled from "styled-components";

const Container = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: 30;

  & .inner {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: calc(100vw * (750 / 1920));
    padding: calc(100vw * (43 / 1920)) calc(100vw * (68 / 1920));
    font-size: calc(100vw * (24 / 1920));
    background: #000000;

    & .title {
      margin-bottom: calc(100vw * (33 / 1920));
      font-size: calc(100vw * (32 / 1920));
      font-weight: 700;
    }

    & .listTitle {
      margin-right: calc(100vw * (10 / 1920));
      font-weight: 700;
    }

    & .infoContainer {
      display: flex;
      align-items: center;
      width: 100%;
      margin-bottom: calc(100vw * (47 / 1920));

      & .profileImgContainer {
        flex-shrink: 0;
        width: calc(100vw * (71 / 1920));
        height: calc(100vw * (70 / 1920));
        margin-right: calc(100vw * (23 / 1920));

        & img {
          width: 100%;
        }
      }

      & .infoList {
        &:not(:last-child) {
          margin-bottom: calc(100vw * (11 / 1920));
        }

        & span:last-child {
          margin-left: calc(100vw * (10 / 1920));
        }
      }
    }

    & .commentContainer {
      width: 100%;
      height: calc(100vw * (145 / 1920));
      margin-bottom: calc(100vw * (29 / 1920));
      padding: calc(100vw * (23 / 1920));
      font-size: calc(100vw * (20 / 1920));
      line-height: 1.2;
      background-color: #424447;
      overflow: auto;
    }

    & .buttonContainer {
      display: flex;
      justify-content: center;
      gap: calc(100vw * (25 / 1920));

      & button {
        display: inline-flex;
        justify-content: center;
        align-items: center;
        width: calc(100vw * (163 / 1920));
        height: calc(100vw * (38 / 1920));
        font-size: calc(100vw * (20 / 1920));
        font-weight: 700;
        border-radius: calc(100vw * (7 / 1920));
      }

      & .deleteButton {
        background-color: #008ad1;
      }

      & .cancelButton {
        background-color: #6a6a6a;
      }
    }
  }

  & .inner * {
    color: #ffffff;
  }

  & .overlay {
    width: 100%;
    height: 100%;
    background: rgba(34, 34, 34, 0.6);
  }
`;

export { Container };

export default { Container };
