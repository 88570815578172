import styled from "styled-components";

// const Container = styled.div`
//   display: flex;
//   align-items: center;
//   width: fit-content;
//   min-width: calc(100vw * (904 / 1920));
//   height: calc(100vw * (73 / 1920));
//   padding: 0 calc(100vw * (62 / 1920)) 0 calc(100vw * (17 / 1920));
//   border: calc(100vw * (5 / 1920)) solid #d9d9d9;
//   border-radius: calc(100vw * (20 / 1920)) calc(100vw * (100 / 1920)) 0px 0px;

//   & .colorTitle {
//     font-size: calc(100vw * (40 / 1920));
//     font-weight: 700;
//     color: #0075ff;
//   }

//   & .subTitle {
//     margin-left: calc(100vw * (20 / 1920));
//     font-size: calc(100vw * (31 / 1920));
//     color: #68727d;
//   }
// `;
const Container = styled.div`
  display: flex;
  align-items: center;
  width: fit-content;
  min-width: calc(${(props)=>props.viewPort}vw * (904 / 1920));
  height: calc(${(props)=>props.viewPort}vw * (73 / 1920));
  padding: 0 calc(${(props)=>props.viewPort}vw * (62 / 1920)) 0 calc(${(props)=>props.viewPort}vw * (17 / 1920));
  border: calc(${(props)=>props.viewPort}vw * (5 / 1920)) solid #d9d9d9;
  border-radius: calc(${(props)=>props.viewPort}vw * (20 / 1920)) calc(${(props)=>props.viewPort}vw * (100 / 1920)) 0px 0px;
  background-color: #D9D9D9;

  & .colorTitle {
    font-size: calc(${(props)=>props.viewPort}vw * (40 / 1920));
    font-weight: 700;
    color: #0075ff;
  }

  & .subTitle {
    margin-left: calc(${(props)=>props.viewPort}vw * (100 / 1920));
    font-size: calc(${(props)=>props.viewPort}vw * (31 / 1920));
    color: #68727D;
  }
`;

export { Container };

export default { Container };
