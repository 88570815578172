import styled from "styled-components";

const Container = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: 16;

  & .inner {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: calc(100vw * (750 / 1920));
    background: #292929;
    border-radius: calc(100vw * (12 / 1920));
    overflow-y: auto;
    padding: calc(100vw * (70 / 1920)) calc(100vw * (92 / 1920))
      calc(100vw * (30 / 1920));
  }
  & .inner * {
    color: #ffffff;
  }
  & .inner p {
    font-size: calc(100vw * (32 / 1920));
    font-weight: 500;
    text-align: center;
    line-height: 1.3;
  }
  & .inner button {
    width: calc(100vw * (162 / 1920));
    height: calc(100vw * (38 / 1920));
    margin-top: calc(100vw * (40 / 1920));
    font-size: calc(100vw * (20 / 1920));
    background: #008ad1;
    border-radius: calc(100vw * (12 / 1920));
    font-weight: 500;
  }

  & .overlay {
    width: 100%;
    height: 100%;
    background: rgba(34, 34, 34, 0.6);
  }
`;

export { Container };

export default { Container };
