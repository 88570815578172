import Common from "./Common";
import Login from "./Login";
import Admin from "./Admin";
import Patient from "./Patient";
import Checkup from "./Checkup";
import Image from "./Image";
import History from "./History";
import Settings from "./Settings";
import Report from "./Report";
import Lesion from "./Lesion";

export default {
  Common,
  Login,
  Admin,
  Patient,
  Checkup,
  Image,
  History,
  Settings,
  Report,
  Lesion,
};

export {
  Common,
  Login,
  Admin,
  Patient,
  Checkup,
  Image,
  History,
  Settings,
  Report,
  Lesion,
};
