import styled from "styled-components";

// img
import Logo from "resources/image/icon/logo.svg";

const Container = styled.div`
  // margin-top: calc(100vh * (5 / 1920));
  display: flex;
  flex-direction: column;
  width: 100%;
  // height: 100vh;
  min-height: 100vh;
  padding: calc(100vw * (106 / 1920)) calc(100vw * (32 / 1920))
    calc(100vw * (25 / 1920));
  background: url(${Logo}) no-repeat left calc(100vw * (30 / 1920)) bottom
    calc(100vw * (40 / 1920)) / contain;
  background-size: calc(100vw * (204 / 1920)) calc(100vw * (36 / 1920));
  background-color: #000;

  & .infoContainer {
    margin: calc(100vh * (20 / 1920)) 0 calc(100vw * (20 / 1920));
  }

  & .sliderContainer {
  }

  &.on {
    padding: calc(100vw * (100 / 1920)) 0 0;
    background: url(${Logo}) no-repeat left calc(100vw * (100 / 1920)) top
      calc(100vw * (40 / 1920)) / contain;
    background-size: calc(100vw * (204 / 1920)) calc(100vw * (36 / 1920));
    background-color: #000;
  }

  & img {
    width: 100%;
    height: 100%;
    object-fit: fill;
  }

  & .miniStomachContainer {
    margin: calc(100vh * (20 / 1920)) 0 calc(100vw * (20 / 1920));
    position: absolute;
    top: calc(100vw * (110 / 1920));
    right: calc(100vw * (49 / 1920));
    cursor: pointer;
    z-index: 3;
  }
`;

export { Container };

export default { Container };
