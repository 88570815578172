import Styles from "./styles";
import { useLanguage } from "hooks";
import { useMediaQuery } from "react-responsive";

// swiper
import { Swiper, SwiperSlide } from "swiper/react";
import { EffectCoverflow, Navigation, Keyboard } from "swiper";
import "swiper/css";
import "swiper/css/effect-coverflow";
import "swiper/css/navigation";
import { IMAGE_SRC } from "constants/common";
import { useEffect, useRef, useState } from "react";
// import { swiperOption } from "constants/common";
import moment from "moment";
import DwvComponent from "../DwvComponent";
import { useLocation } from "react-router-dom";

export default function NoPastHistory({
  data,
  landmark,
  onChangeHistorySelected,
  historyPage,
}) {
  const { recentlyImageData, imageId, normalImage } = data;

  console.log(data);
  const location = useLocation();
  const currentLandmarkSwiperRef = useRef([]);
  // const insideLandmarkSwiperRef = useRef([]);
  // const dateSwiperListRef = useRef([]);
  console.log("왜 안나와", recentlyImageData);
  const [recentlyIndex, setRecentlyIndex] = useState(
    imageId && recentlyImageData
      ? recentlyImageData.list.findIndex((image) => image.imageId === imageId)
      : 0
  );
  const currentResentlyImageData = recentlyImageData?.list?.[recentlyIndex];

  useEffect(() => {
    onChangeHistorySelected?.({
      recentlySelected: currentResentlyImageData,
    });
  }, [currentResentlyImageData]);

  const lang = useLanguage("History");

  const option = {
    rotate: -65,
    stretch: 120,
    depth: 400,
    modifier: 1,
    slideShadows: true,
  };
  const ProOption = {
    rotate: -65,
    stretch: 85,
    depth: 280,
    modifier: 1,
    slideShadows: true,
  };
  const AirOption = {
    rotate: -65,
    stretch: 75,
    depth: 230,
    modifier: 1,
    slideShadows: true,
  };
  const isPro = useMediaQuery({
    query: "(max-width: 1366px)",
  });
  const isAir = useMediaQuery({
    query: "(max-width: 1180px)",
  });

  useEffect(() => {
    if (currentLandmarkSwiperRef.current)
      currentLandmarkSwiperRef.current?.slideTo(recentlyIndex);
  }, [location]);

  return (
    <Styles.Container>
      <div className="swiperContainer">
        <p className="title">
          {moment(recentlyImageData?.date).format("YYYY.MM.DD")}(
          {lang.getLang("최근 촬영본")})
        </p>
        <Swiper
          direction={"vertical"}
          effect={"coverflow"}
          grabCursor={true}
          initialSlide={1}
          centeredSlides={true}
          slidesPerView={"auto"}
          coverflowEffect={isAir ? AirOption : isPro ? ProOption : option}
          modules={[EffectCoverflow, Navigation, Keyboard]}
          navigation={true}
          className="mySwiper recentSwiper"
          onSwiper={(swiper) => {
            if (swiper.destroyed) return;
            currentLandmarkSwiperRef.current = swiper;
          }}
          onSlideChange={(swiper) => {
            setRecentlyIndex(swiper.activeIndex);
          }}
        >
          {recentlyImageData && recentlyImageData.list.length > 0 ? (
            recentlyImageData?.list?.map((item, index) => {
              return (
                <SwiperSlide
                  className="imgContainer slider"
                  key={"slider" + index}
                >
                  {item?.imageName?.includes("d") ? (
                    <DwvComponent index={item.imageId} item={item} />
                  ) : (
                    <img src={IMAGE_SRC + item.path} alt="stomach" />
                  )}
                </SwiperSlide>
              );
            })
          ) : (
            <SwiperSlide className="imgContainer slider emptySlider">
              {/* {lang.getLang("해당 위 부위의 최근 촬영본이 없습니다.")} */}
              {lang.getLang("해당 검진일의 위 부위 촬영본이 없습니다.")}
            </SwiperSlide>
          )}
        </Swiper>
      </div>

      <div className="normalContainer">
        <p className="title">{lang.getLang("정상 이미지")}</p>
        <div className="imgContainer">
          <img src={normalImage} alt="stomach" />
        </div>
      </div>
    </Styles.Container>
  );
}
