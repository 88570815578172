import styled, { css } from "styled-components";

const TextareaBox = styled.div`
  width: 100%;
  height: 100%;

  & textarea {
    width: 100%;
    height: 100%;
    padding: calc(100vw * (16 / 1920)) calc(100vw * (25 / 1920));
    border: 1px solid #fff;
    border-radius: calc(100vw * (12 / 1920));
  }
  & textarea::placeholder {
    color: rgba(255, 255, 255, 0.3);
  }
`;

export { TextareaBox };

export default {
  TextareaBox,
};
