import styled, { css } from "styled-components";

const InputBox = styled.div`
  width: 100%;
  height: 100%;

  & input {
    display: none;

    &:checked + label .dotContainer::after {
      display: block;
    }
  }

  & label {
    display: flex;
    font-size: calc(100vw * (24 / 1920));
    cursor: pointer;

    & .dotContainer {
      position: relative;
      display: inline-block;
      width: calc(100vw * (26 / 1920));
      height: calc(100vw * (26 / 1920));
      margin-right: calc(100vw * (16 / 1920));
      border-radius: 100%;
      background-color: #fff;

      &::after {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        display: none;
        width: calc(100vw * (14 / 1920));
        height: calc(100vw * (14 / 1920));
        border-radius: 50%;
        background-color: #008ad1;
        content: "";
      }
    }

    & .labelText {
      font-size: calc(100vw * (24 / 1920));
    }
  }
`;

export { InputBox };

export default {
  InputBox,
};
