import Styles from "./styles";
import PicturesTitle from "../PicturesTitle";
import { layout } from "components";
import { useLanguage, useModals } from "hooks";
import { getCookie } from "service/connector";

// img
import { useParams } from "react-router-dom";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { api } from "service";
import {
  formatHeatmapWithPath,
  formatImageWithPath,
  formatNormalImageWithLandmark,
  lesionTypeToColor,
} from "utils/common";
import { useReport } from "hooks/useReport";
import { useEffect, useState } from "react";
import { set } from "lodash";
import { image } from "service/api";
import ChangeImImage from "./components/ChangeImImage";

export default function IMSection(props) {
  const lang = useLanguage("Report");
  const modalOption = useModals();
  const { studyId } = useParams();
  const [sectionType, setSectionType ] = useState("view"); // view, edit

  const reportInfo = useReport();

  const [scope, setScope] = useState("lite");
  const [imData, setImData] = useState(null);
  const [lesionScoreType, setLesionScoreType] = useState(null);
  const [landmark, setLandmark] = useState(null);
  const [imageListData, setImageListData] = useState(null);
  const [imSensitivity, setImSensitivity] = useState(null);
  const [notSelectedSection, setNotSelectedSection] = useState(null);

  useEffect(() => {
    setScope(getCookie("scope"));
  }, []);

  useEffect(() => {
    if (reportInfo.reportData?.im) {
      setLandmark(reportInfo.reportData.im.landmark);
    }
  }, [reportInfo.reportData]);

  const { data: settingsData, isLoadingSettings } = useQuery(
    ["settings"],
    () => api.setting.getSetting(),
    {
      onSuccess(responseData) {
        setImSensitivity(responseData.imSensitivity);
      },
      refetchOnWindowFocus: false,
    }
  );


  const {
    isLoading: isLoadingImages,
    isSuccess: isSuccessImages,
   } = useQuery(
    ["reports-images", studyId, "im", landmark],
    () =>
      api.reports.getReportsImages({
        medicalstudyId: studyId,
        check: "im",
        landmark: landmark,
      }),
    {
      onSuccess(responseIMData) {

          setImData(responseIMData);
          setLandmark(responseIMData.landmark);

          const lesionScoreType = responseIMData.lesionScore > settingsData.imSensitivity ? "yellow" : "green";

          setLesionScoreType(lesionScoreType);

          const imageListData = [
            {
              img: formatImageWithPath(responseIMData.path),
              text: "Image",
            },
            {
              img: formatHeatmapWithPath(responseIMData.path),
              text: "Heatmap",
            },
            {
              img: formatNormalImageWithLandmark(responseIMData.landmark),
              text: "Normal",
              normal: true,
            },
          ];

          setImageListData(imageListData);

          reportInfo.setReportWriteData((prev) => ({
            ...prev,
            im: {
              ...responseIMData,
              imageListData : imageListData,
            },
          }));
      },
      refetchOnWindowFocus: false,
    }
  )

  
  const {
    data: IMData = {},
    isLoading: isLoadingIM,
    isSuccess: isSuccessIM,
  } = useQuery(
    ["lesions-im-path", studyId],
    () => api.image.getLesionsIMPath({ medicalstudyId: studyId }),
    {
      enabled: isSuccessImages && !isLoadingImages,
      refetchOnWindowFocus: false,
      onSuccess(responseData) {
        const canSelectStomach = [5, 6, 7, 8, 11];
        const notSelectedSection = Object.keys(responseData)
          .map((keyword) => {
            const preProperty = "scoreLandmark";
            if (canSelectStomach.some((item) => preProperty + item === keyword)) {
              const score = responseData[keyword];
              const colorType = score >= 100 - imSensitivity ? "yellow" : "green";
              return {
                landmark: Number(keyword.split(preProperty)[1]),
                type: colorType,
              };
            }
            return null;
          })
          .filter((item) => item);
          setNotSelectedSection(notSelectedSection);

        
         reportInfo.setReportWriteData((prev) => ({
          ...prev,
          im: {
            ...prev.im,
            notSelectedSection: notSelectedSection,
          },
        }));
      }
    }
  );

  useEffect(() => {
    if (imageListData) {
      reportInfo.setReportWriteData((prev) => ({
        ...prev,
        im: {
          ...imData,
          imageListData : imageListData,
          notSelectedSection: notSelectedSection,
        },
      }));
    }
  }, [imageListData]);

  const reportsChangeIMMutation = useMutation(
    ({ studyId, imageId, landmark }) =>
      api.reports.putReportsPriorityChangeIM({
        studyId: Number(studyId),
        imageId,
        landmark,
      })
  );

  const imGifLesionType =
    settingsData.imSensitivity > imData?.lesionScore ? "yellow" : "green";

  if (isLoadingSettings || !imageListData || !imData) return <></>;

  return (
    <>
      <PicturesTitle
        colorTitle={
          scope == "lite" ? lang.getLang("히트맵") : lang.getLang("장상피화생")
        }
        subTitle={
          scope == "lite"
            ? lang.getLang("위 부위별 히트맵 정도를 표시합니다.")
            : lang.getLang("위 부위별 장상피화생의 정도를 표시합니다.")
        }
        paddingSize={props.paddingSize}
        viewPort={props.viewPort}
      />

      { sectionType === "view" ?  

      <Styles.Container
        paddingSize={props.paddingSize}
        viewPort={props.viewPort}
      >
        <div className="wrap">
          <div className="leftContainer">
          <div className="stomachWrap">
            <layout.NoHoverStomachReport
              selectedSection={landmark}
              notSelectedSection={notSelectedSection}
              noClickEvent
              opacityBg
              numberType={lesionScoreType}
              score={imData?.lesionScore}
              scoreType={imGifLesionType}
              lesionType={imData?.lesionType}
              screen={"heatmap"}
              />
          </div>
            <layout.LesionAnimationContainer
              noAni={true}
              score={imData?.lesionScore}
              type={lesionScoreType}
              viewPort={props.viewPort}
              lite_type={"im_section"}
              screen={"heatmap"}
            />
          </div>

          <div className="rightContainer">
            {imageListData?.map((item, index) => {
              return (
                <div className="imgContainer" key={"imgContainer" + index}>
                  <div className="imgWrap">
                    <img src={item.img} alt="stomach" />
                  </div>

                  <p className={"text" + (item.normal ? " normal" : "")}>
                    {item.text}
                  </p>
                </div>
              );
            })}
          </div>
        </div>

        <button
          type="button"
          className="changeButton"
          onClick={() => {
            setSectionType("edit");
          }}
        >
          {lang.getLang("사진 변경")}
        </button>
      </Styles.Container>
      : 
      <Styles.EditContainer 
        paddingSize={props.paddingSize}
        viewPort={props.viewPort}
      >
        <div className="editContainer">
          <ChangeImImage 
            paddingSize={props.paddingSize}
            viewPort={props.viewPort}
            studyId={studyId}
            landmark={landmark}
            lesionScore={imData?.lesionScore}
            lesionType={lesionScoreType}
            onChangeCancel={() => {
              setSectionType("view");
            }}
            onChangeLesion={(selected) => {
              const { imageId, landmark } = selected;
              reportsChangeIMMutation.mutate(
                {
                  imageId,
                  landmark,
                  studyId,
                },
                {
                  onSuccess(responseData) {
                    setImData(responseData);
                    setLandmark(responseData.landmark);
                    const imageListData = [
                      {
                        img: formatImageWithPath(responseData.path),
                        text: "Image",
                      },
                      {
                        img: formatHeatmapWithPath(responseData.path),
                        text: "Heatmap",
                      },
                      {
                        img: formatNormalImageWithLandmark(responseData.landmark),
                        text: "Normal",
                        normal: true,
                      },
                    ];
          
                    setImageListData(imageListData);
                  },
                }
              );

              setSectionType("view");
            }
          }
          />
        </div>
      </Styles.EditContainer> 
      }
    </>
  );
}
