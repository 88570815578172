import Styles from "./styles";
import { useLanguage } from "hooks";
import { useMediaQuery } from "react-responsive";
import LesionTitle from "../LesionTitle";

// swiper
import { Swiper, SwiperSlide } from "swiper/react";
import { EffectCoverflow, Navigation } from "swiper";
import "swiper/css";
import "swiper/css/effect-coverflow";
import "swiper/css/navigation";

// img
import { useQuery } from "react-query";
import { useNavigate } from "react-router-dom";
import { api } from "service";
import { IMAGE_REF, IMAGE_SRC, swiperOption } from "constants/common";
import { useEffect, useMemo, useRef, useState } from "react";
import SVG from "components/common/SVG";
import { lesionTypeForJsonFirstLetter, lesionTypeToColor } from "utils/common";
import { useConfig } from "hooks/useConfig";

export default function ReportChangeAbnormalityCheckedSection({
  studyId,
  lesionIndex,
  setLesionIndex,
  onChangeAbnormality,
}) {
  const lang = useLanguage("Lesion");
  const isPro = useMediaQuery({
    query: "(max-width: 1366px)",
  });
  const isAir = useMediaQuery({
    query: "(max-width: 1180px)",
  });
  const navigate = useNavigate();

  const normalSwiperRef = useRef(null);

  const configInfo = useConfig();
  const [checkLesion, setCheckLesion] = useState(
    configInfo.configData.lesionMark
  );
  const [renderedImgList, setRenderedImgList] = useState([]);

  useEffect(() => {
    setCheckLesion(configInfo.configData.lesionMark);
  }, [configInfo.configData]);

  const {
    data: lesionsPathData = [],
    isSuccess: isSuccessLesionsPath,
    isLoading: isLoadingLesions,
  } = useQuery(
    ["lesions-abnormality-path", studyId],
    () => api.image.getLesionsAbnormalityPath({ medicalstudyId: studyId }),
    {
      refetchOnWindowFocus: false,
      onSuccess(responseLesionPath) {
        const initialRenderedImgList = responseLesionPath.map((item) => ({
          imageId: item.imageId,
          naturalWidth: 0,
          naturalHeight: 0,
        }));
        setRenderedImgList(initialRenderedImgList);
      },
    }
  );

  const currentLesionPathData = lesionsPathData?.[lesionIndex];
  const landmark = currentLesionPathData?.landmark;

  useEffect(() => {
    if (currentLesionPathData) {
      const currentLandmark = currentLesionPathData?.landmark;
      const moveToNormalIndex =
        currentLandmark !== 12 ? currentLandmark - 1 : 0;
      normalSwiperRef.current?.slideTo(moveToNormalIndex);
    }
  }, [currentLesionPathData]);

  const { data: lesionJsonData, isFetching: isFetchingLesionsJson } = useQuery(
    ["lesions-path-json", "abnormality", studyId],
    async () => {
      const promises = lesionsPathData?.map(async ({ path }) => {
        return await api.image.getLesionJson({ path });
      });

      return await Promise.all(promises);
    },
    {
      enabled: isSuccessLesionsPath && checkLesion,
      refetchOnWindowFocus: false,
    }
  );
  const matchingLesionJsonData = useMemo(() => {
    if (!lesionJsonData) return [];
    // return lesionJsonData?.map((item, index) => {
    //   const lesionType = lesionsPathData[index].lesion[0].type;
    //   const lessionFirstLetter = lesionTypeForJsonFirstLetter(lesionType);

    //   const findLesionJsonData = item.filter(
    //     (item) => item.lesion === lessionFirstLetter
    //   )[0];

    //   return findLesionJsonData;
    // });
    return lesionJsonData?.map((item, index) => {
      const lesionType = lesionsPathData[index].lesion[0].type;
      const lessionFirstLetter = lesionTypeForJsonFirstLetter(lesionType);
      const findLesionJsonData = item.lesion_tumor.filter(
        (tumor) => tumor.tumor_type === lessionFirstLetter
      )[0];
      return findLesionJsonData;
    });
  }, [lesionJsonData, lesionsPathData]);

  const currentImage = useMemo(() => {
    return lesionsPathData[lesionIndex] || null;
  }, [lesionsPathData, lesionIndex]);

  const currentLesion = currentImage?.lesion?.[0];
  const lesionColorType = lesionTypeToColor(currentLesion?.type);

  const { data: _imageNormalData = {} } = useQuery(
    ["image-normal"],
    () => api.image.getNormal(),
    {
      refetchOnWindowFocus: false,
    }
  );
  const imageNormalData = Object.values(_imageNormalData);

  const notSelectedSection = [
    {
      landmark: Number(landmark),
      type: lesionColorType,
    },
  ];

  useEffect(() => {
    onChangeAbnormality?.({ selected: currentImage });
  }, [currentImage]);

  if (isLoadingLesions) return <></>;

  return (
    <Styles.Container>
      <div className="left">
        <LesionTitle
          title={lang.getLang("병변")}
          type={lesionColorType}
          checkTitle={lang.getLang("병변 위치 보기")}
          id={"병변 위치 보기"}
          checkLesion={checkLesion}
          onChangeChecked={(check) => {
            setCheckLesion(check);
          }}
          screen={"abnormality"}
        />

        <div className="swiperContainer">
          {renderedImgList && renderedImgList.length > 0 ? (
            <Swiper
              direction={"vertical"}
              effect={"coverflow"}
              grabCursor={true}
              initialSlide={0}
              centeredSlides={true}
              slidesPerView={"auto"}
              coverflowEffect={
                isAir
                  ? swiperOption.AirOption
                  : isPro
                  ? swiperOption.ProOption
                  : swiperOption.option
              }
              onSlideChange={(swiper) => {
                setLesionIndex(swiper.activeIndex);
              }}
              modules={[EffectCoverflow, Navigation]}
              navigation={true}
              className="IMSwiper swiper"
            >
              {lesionsPathData?.map(({ imageId, path }, index) => {
                const imagePathSrc = IMAGE_SRC + path;

                const jsonMap = matchingLesionJsonData[index]?.polygon;
                const imgData = renderedImgList[index];
                return (
                  <SwiperSlide
                    className="imgContainer slider"
                    key={"slider" + imageId}
                  >
                    <>
                      <img
                        src={imagePathSrc}
                        alt="stomach"
                        onLoad={(event) => {
                          const { naturalWidth, naturalHeight } =
                            event.currentTarget;
                          setRenderedImgList((prev) => {
                            return prev.map((item) => {
                              if (imageId === item.imageId) {
                                return {
                                  ...item,
                                  naturalWidth,
                                  naturalHeight,
                                };
                              }

                              return item;
                            });
                          });
                        }}
                      />
                      {!isFetchingLesionsJson &&
                        checkLesion &&
                        jsonMap?.map((jsonData) => (
                          <SVG.Polygon
                            data={jsonData || []}
                            width={imgData?.naturalWidth}
                            height={imgData?.naturalHeight}
                          />
                        ))}
                    </>
                  </SwiperSlide>
                );
              })}
            </Swiper>
          ) : null}
        </div>
      </div>

      <div className="right">
        <LesionTitle title={lang.getLang("정상")} type={"green"} />

        <div className="swiperContainer">
          <Swiper
            direction={"vertical"}
            effect={"coverflow"}
            grabCursor={true}
            initialSlide={0}
            centeredSlides={true}
            slidesPerView={"auto"}
            navigation={true}
            coverflowEffect={
              isAir
                ? swiperOption.AirOption
                : isPro
                ? swiperOption.ProOption
                : swiperOption.option
            }
            modules={[EffectCoverflow, Navigation]}
            className="normalSwiper swiper"
            onSwiper={(swiper) => {
              if (!swiper.destroyed) {
                normalSwiperRef.current = swiper;
              }
            }}
          >
            {[landmark]?.map((path) => {
              let resultPath = path == 12 ? 1 : landmark;
              return (
                <SwiperSlide
                  className="imgContainer slider"
                  key={"slider" + resultPath}
                >
                  <img
                    className={landmark === 12 ? "opacity" : ""}
                    src={IMAGE_REF + resultPath + ".png"}
                    alt="stomach"
                  />
                </SwiperSlide>
              );
            })}
          </Swiper>
        </div>
      </div>
    </Styles.Container>
  );
}
