const { useAtom } = require("jotai");
const { reportAtom } = require("store/atoms");

export const useReport = () => {
  const [reportData, setReportData] = useAtom(reportAtom);

  const setReportWriteData = (data) => {
    setReportData(data);
  };

  const clearReportData = () => {
    setReportData({
      status: false,
      isComment: false,
      isLandmark: false,
      isIM: false,
      isAbnormality: false,
      isHistory: false,
      isSelectImage: false,
    });
  };

  return { reportData, setReportWriteData, clearReportData };
};
