import React from "react";
import { Route, Routes, useLocation } from "react-router-dom";
import Pages, { ModalPage } from "pages";
import { useLanguage } from "hooks";
import { useEffect } from "react";
import IndexRoute from "routes/IndexRoute";

export default function CustomRoute() {
  let location = useLocation();
  const lang = useLanguage();

  React.useEffect(() => {
    window.scrollTo(0, 0);
    document.body.scrollTo(0, 0);
  }, [location]);

  // useEffect(() => {
  //   lang.setCountry("_en");
  // }, []);

  return (
    <>
      <Routes>
        <Route path="/" element={<IndexRoute />} />
        <Route path="/admin" element={<Pages.AdminPage />} />
        <Route path="/login" element={<Pages.LoginPage />} />
        <Route path="/user/:type" element={<Pages.CreateUserPage />} />
        <Route path="/patient" element={<Pages.PatientsPage />} />
        <Route path="/patient/:type" element={<Pages.CreatePatientPage />} />
        <Route
          path="/patient/detail/:id"
          element={<Pages.PatientDetailPage />}
        />
        {/* <Route path="/checkup/insert" element={<Pages.PatientDetailPage />} /> */}
        <Route path="/checkup/new" element={<Pages.CheckupNewPage />} />
        <Route path="/image/:type/:id/:studyId" element={<Pages.ImagePage />} />
        <Route
          path="/history/:id/:studyId/:landmark"
          element={<Pages.HistoryPage />}
        />
        <Route
          path="/lesion/:id/:studyId/:landmark"
          element={<Pages.LesionPage />}
        />
        <Route
          path="/lesion/im-analysis/:id/:studyId"
          element={<Pages.ImAnalysisPage />}
        />
        <Route path="/settings" element={<Pages.SettingsPage />} />
        <Route path="/report/:id/:studyId" element={<Pages.ReportPage />} />
        <Route
          path="/report/preview/:id/:studyId"
          element={<Pages.ReportPreviewPage />}
        />
      </Routes>
    </>
  );
}
