import styled, { css } from "styled-components";

const Container = styled.div`
  position: relative;
  width: calc(100vw * (30 / 1920));
  height: calc(100vw * (30 / 1920));
  cursor: pointer;
  & * {
    color: #4b4b4b;
    cursor: pointer;
  }
  & img {
    width: 100%;
  }

  // datepicker custom
  & .react-datepicker-wrapper {
    position: absolute;
    top: 0;
    right: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;

    & input {
      padding: 0 calc(100vw * (12 / 1920));
      color: transparent;
    }
  }

  & .react-datepicker-popper {
    inset: 0px auto auto calc(100vw * (-120 / 1920)) !important;
  }

  .react-datepicker-popper[data-placement^="top"]
    .react-datepicker__triangle::before,
  .react-datepicker-popper[data-placement^="bottom"]
    .react-datepicker__triangle::before,
  .react-datepicker-popper[data-placement^="top"]
    .react-datepicker__triangle::after,
  .react-datepicker-popper[data-placement^="bottom"]
    .react-datepicker__triangle::after {
    display: none;
  }

  .react-datepicker {
    font-family: "Pretendard", sans-serif;
    font-size: calc(100vw * (20 / 1920));
    border: 0;
    box-shadow: 0 0.125rem 0.5rem rgba(0, 0, 0, 0.1);
  }

  .react-datepicker__header {
    background-color: #fff;
    border-bottom: 0;
  }

  .react-datepicker__current-month {
    padding: calc(100vw * (10 / 1920)) 0;
    font-size: 1rem;
    font-weight: 400;
    color: #4b4b4b;
  }

  .react-datepicker__day-name,
  .react-datepicker__day,
  .react-datepicker__time-name {
    width: 1.9rem;
    margin: 0.3rem;
    font-size: 0.9rem;
    color: #4b4b4b;
  }

  .react-datepicker__day--outside-month {
    color: #ccc;
  }

  .react-datepicker__navigation {
    top: 10px;
  }

  .react-datepicker__year-read-view--down-arrow,
  .react-datepicker__month-read-view--down-arrow,
  .react-datepicker__month-year-read-view--down-arrow,
  .react-datepicker__navigation-icon::before {
    top: calc(100vw * (2 / 1920));
    width: calc(100vw * (13 / 1920));
    height: calc(100vw * (13 / 1920));
    border-width: 1px 1px 0 0;
    border-color: #4b4b4b;
  }

  .react-datepicker__month-read-view--down-arrow,
  .react-datepicker__year-read-view--down-arrow {
    top: calc(100vw * (5 / 1920));
    width: calc(100vw * (8 / 1920));
    height: calc(100vw * (8 / 1920));
  }

  .react-datepicker__month-select,
  .react-datepicker__year-select,
  .react-datepicker__month-read-view--selected-month,
  .react-datepicker__year-read-view--selected-year {
    font-size: 1rem;
  }

  .react-datepicker__navigation.react-datepicker__navigation--years.react-datepicker__navigation--years-upcoming,
  .react-datepicker__navigation.react-datepicker__navigation--years.react-datepicker__navigation--years-previous {
    top: 0;
    text-indent: unset;
  }

  .react-datepicker__navigation.react-datepicker__navigation--years.react-datepicker__navigation--years-upcoming::after,
  .react-datepicker__navigation.react-datepicker__navigation--years.react-datepicker__navigation--years-previous::after {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    display: inline-flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    font-size: 0.7rem;
  }

  .react-datepicker__navigation.react-datepicker__navigation--years.react-datepicker__navigation--years-upcoming::after {
    content: "▲";
  }

  .react-datepicker__navigation.react-datepicker__navigation--years.react-datepicker__navigation--years-previous::after {
    content: "▼";
  }

  .react-datepicker__day--selected,
  .react-datepicker__day--in-selecting-range,
  .react-datepicker__day--in-range,
  .react-datepicker__month-text--selected,
  .react-datepicker__month-text--in-selecting-range,
  .react-datepicker__month-text--in-range,
  .react-datepicker__quarter-text--selected,
  .react-datepicker__quarter-text--in-selecting-range,
  .react-datepicker__quarter-text--in-range,
  .react-datepicker__year-text--selected,
  .react-datepicker__year-text--in-selecting-range,
  .react-datepicker__year-text--in-range {
    color: #fff;
    background-color: #008ad1;
  }

  .react-datepicker__day--today,
  .react-datepicker__month-text--today,
  .react-datepicker__quarter-text--today,
  .react-datepicker__year-text--today {
    color: #008ad1;
  }

  .react-datepicker__day--selected.react-datepicker__day--today,
  .react-datepicker__day--in-selecting-range.react-datepicker__day--today,
  .react-datepicker__day--in-range.react-datepicker__day--today,
  .react-datepicker__month-text--selected.react-datepicker__day--today,
  .react-datepicker__month-text--in-selecting-range.react-datepicker__day--today,
  .react-datepicker__month-text--in-range.react-datepicker__day--today,
  .react-datepicker__quarter-text--selected.react-datepicker__day--today,
  .react-datepicker__quarter-text--in-selecting-range.react-datepicker__day--today,
  .react-datepicker__quarter-text--in-range.react-datepicker__day--today,
  .react-datepicker__year-text--selected.react-datepicker__day--today,
  .react-datepicker__year-text--in-selecting-range.react-datepicker__day--today,
  .react-datepicker__year-text--in-range.react-datepicker__day--today {
    color: #fff;
  }

  .react-datepicker__input-container {
    width: 100%;
    height: 100%;
  }

  .react-datepicker__input-container input {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
`;

export { Container };

export default {
  Container,
};
