import ImgCheckContainer from "./ImgCheckContainer";
import ImageContainer from "./ImageContainer";
import StomachContainer from "./StomachContainer";
import FilterContainer from "./FilterContainer";

export { ImgCheckContainer, ImageContainer, StomachContainer, FilterContainer };

export default {
  ImgCheckContainer,
  ImageContainer,
  StomachContainer,
  FilterContainer,
};
