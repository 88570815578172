import Styles from "./styles";
import AnalysisStomach from "../AnalysisStomach";
import ImResultScoreBg from "resources/image/score_bar/im_score_bg.png";

export default function AnalysisContainer({ data, type, imSensitivity }) {
  const { analysisTitle, selectedSection, stomachListData, totalPercent } =
    data;

  console.log(123, stomachListData);

  console.log("data: ", data);
  console.log("type: ", type);
  console.log("imSensitivity: ", imSensitivity);
  console.log("totalPercent: ", totalPercent);
  const im_on = imSensitivity < totalPercent ? "on" : "off";
  const normal_on = imSensitivity >= totalPercent ? "on" : "off";
  return (
    // (결함) 페이지 출력 Default 오류
    <Styles.Container
      imSensitivity={imSensitivity}
      totalPercent={totalPercent}
    >
      <div className="analysisTitle">{analysisTitle}</div>
      <div className="stomachWrap">
        <AnalysisStomach
          selectedSection={data}
          type={type}
          score={totalPercent}
          imSensitivity={imSensitivity}
        />
      </div>
{/* 
      <ul className="stomachListWrap">
        {stomachListData?.map((item, index) => {
          return (
            <div>
            </div>
            // <li className="stomachList" key={"stomachList" + index}>
            //   <div className="stomachTitle">
            //     {item.number}. {item.stomach}
            //   </div>
            //   <div className="percent">- &nbsp;&nbsp;{item.percent}%</div>
            // </li>
          );
        })}
      </ul> */}

      <div className="imResultBar">
        <div className="imText">
          <div className={"normal " + normal_on}>NORMAL</div>
          <div className={"im " + im_on}>IM</div>
        </div>


        <div className="imResultBarBase">
          <div className="normal">
            <img src={ImResultScoreBg} alt="score" />
          </div>
        </div>
      </div>

      {/* <div className={"totalContainer " + analysisTitle}>
        {analysisTitle} Total : {totalPercent}%
      </div> */}
    </Styles.Container>
  );
}
