import styled from "styled-components";

// img
import IconArrowBottom from "resources/image/icon/icon_arrow_bottom_big.svg";
import IconArrowTop from "resources/image/icon/icon_arrow_top_big.svg";

const Container = styled.div`
  display: flex;
  align-items: center;
  gap: calc(100vw * (66 / 1920));

  & img {
    width: 100%;
    height: 100%;
    object-fit: fill;
  }

  & > div {
    flex-grow: 1;
    flex-shrink: 0;
  }

  & .swiperContainer {
    position: relative;
    height: calc(100vw * (705 / 1920));
    overflow: hidden;
  }

  & .swiper {
    margin: 0;
    height: 100%;

    & .slider {
      box-shadow: none;
      opacity: 0.5;
      transition: 0.3s;
    }

    & .swiper-slide-active {
      opacity: 1;
    }
    
  }


  & .normalSwiper img {
    &.opacity {
      opacity: 30%;
    }
  }
  
  & .swiper-button-prev,
  .swiper-button-next {
    position: absolute;
    left: 50%;
    width: calc(100vw * (110 / 1920));
    height: calc(100vw * (110 / 1920));
    transform: translateX(-50%);
    z-index: 1;
    cursor: pointer;

    &::after {
      content: none;
    }
  }

  & .swiper-button-prev {
    top: 0;
    background: url(${IconArrowTop}) no-repeat center / cover;
  }

  & .swiper-button-next {
    top: auto;
    bottom: calc(100vw * (-15 / 1920));
    background: url(${IconArrowBottom}) no-repeat center / cover;
  }

  & .imgContainer {
    width: calc(100vw * (520 / 1920));
    max-height: calc(100vw * (520 / 1920));
    margin: 0 auto;
    border-radius: calc(100vw * (9 / 1920));
    overflow: hidden;
  }

  @media (max-width: 1366px) {
    & .swiperContainer {
      height: calc(100vw * (750 / 1920));
    }

    & .swiper-button-prev {
      top: calc(100vw * (25 / 1920));
    }

    & .swiper-button-next {
      bottom: 0;
    }
  }
`;

export { Container };

export default { Container };
