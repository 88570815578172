import modal, {
  DefaultModal,
  ToastModal,
  BasicModal,
  ChangePasswordModal,
  UserDeleteModal,
  SearchAdminModal,
  SearchPatientModal,
  SearchCheckupModal,
  MoreResultModal,
  ContextMenuModal,
  DoubleClickModal,
  ChangeImageModal,
  ChangeHistoryModal,
  ChangeIMModal,
  ChangeAbnormalityModal,
  LoadingModal,
  SoftwareInformationModal,
  UploadFileModal,
  UploadFileDeleteModal,
} from "components/modal";
import { useModals } from "hooks";
import { useEffect } from "react";

export default function MainPage() {
  const modalOption = useModals();

  useEffect(() => {
    const body = document.body;
    const html = document.querySelector("html");
    if (
      modalOption.defaultModal.show ||
      modalOption.basicModal.show ||
      modalOption.toastModal.show ||
      modalOption.changePassowrdModal.show ||
      modalOption.userDeleteModal.show ||
      modalOption.searchAdminModal.show ||
      modalOption.searchPatientModal.show ||
      modalOption.searchCheckupModal.show ||
      modalOption.changeImageModal.show ||
      modalOption.changeHistoryModal.show ||
      modalOption.changeIMModal.show ||
      modalOption.changeAbnormalityModal.show ||
      modalOption.loadingModal.show ||
      modalOption.softwareInformationModal.show ||
      modalOption.uploadFileModal.show ||
      modalOption.uploadFileDeleteModal.show
    ) {
      body.className = "hidden";
      html.className = "hidden";
    } else {
      body.classList.remove("hidden");
      html.classList.remove("hidden");
    }
  }, [modalOption]);

  return (
    <>
      {
        //기본 모달
        modalOption.defaultModal.show ? (
          <DefaultModal
            modalOption={modalOption.defaultModal}
            modalClose={() => {
              modalOption.setDefaultModal((e) => {
                e.show = false;
                return { ...e };
              });
            }}
          />
        ) : null
      }
      {
        //토스트 메세지 모달
        modalOption.toastModal.show ? (
          <ToastModal
            modalOption={modalOption.toastModal}
            modalClose={() => {
              modalOption.setToastModal((e) => {
                e.show = false;
                return { ...e };
              });
            }}
          />
        ) : null
      }
      {
        //기본 모달
        modalOption.basicModal.show ? (
          <BasicModal
            modalOption={modalOption.basicModal}
            modalClose={() => {
              modalOption.setBasicModal((e) => {
                e.show = false;
                return { ...e };
              });
            }}
          />
        ) : null
      }
      {
        // 비밀번호 변경 모달
        modalOption.changePassowrdModal.show ? (
          <ChangePasswordModal
            modalOption={modalOption.changePassowrdModal}
            modalClose={() => {
              modalOption.setChangePassowrdModal((e) => {
                e.show = false;
                return { ...e };
              });
            }}
          />
        ) : null
      }
      {
        // 유저 삭제 모달
        modalOption.userDeleteModal.show ? (
          <UserDeleteModal
            modalOption={modalOption.userDeleteModal}
            modalClose={() => {
              modalOption.setUserDeleteModal((e) => {
                e.show = false;
                return { ...e };
              });
            }}
          />
        ) : null
      }
      {
        // 유저 검색 모달
        modalOption.searchAdminModal.show ? (
          <SearchAdminModal
            modalOption={modalOption.searchAdminModal}
            modalClose={() => {
              modalOption.setSearchAdminModal((e) => {
                e.show = false;
                return { ...e };
              });
            }}
          />
        ) : null
      }
      {
        // 환자 검색 모달
        modalOption.searchPatientModal.show ? (
          <SearchPatientModal
            modalOption={modalOption.searchPatientModal}
            modalClose={() => {
              modalOption.setSearchPatientModal((e) => {
                e.show = false;
                return { ...e };
              });
            }}
          />
        ) : null
      }
      {
        // 검진 환자 검색 모달
        modalOption.searchCheckupModal.show ? (
          <SearchCheckupModal
            modalOption={modalOption.searchCheckupModal}
            modalClose={() => {
              modalOption.setSearchCheckupModal((e) => {
                e.show = false;
                return { ...e };
              });
            }}
          />
        ) : null
      }
      {
        // 검진 결과 더보기 모달
        modalOption.moreResultModal.show ? (
          <MoreResultModal
            modalOption={modalOption.moreResultModal}
            modalClose={() => {
              modalOption.setMoreResultModal((e) => {
                e.show = false;
                return { ...e };
              });
            }}
          />
        ) : null
      }
      {
        // 이미지 우클릭 모달
        modalOption.contextMenuModal.show ? (
          <ContextMenuModal
            modalOption={modalOption.contextMenuModal}
            modalClose={() => {
              modalOption.setContextMenuModal((e) => {
                e.show = false;
                return { ...e };
              });
            }}
          />
        ) : null
      }
      {
        // 더블 클릭 모달
        modalOption.doubleClickModal.show ? (
          <DoubleClickModal
            modalOption={modalOption.doubleClickModal}
            modalClose={() => {
              modalOption.setDoubleClickModal((e) => ({
                ...e,
                show: false,
              }));
            }}
          />
        ) : null
      }
      {
        // report - 이미지 변경 모달
        modalOption.changeImageModal.show ? (
          <ChangeImageModal
            modalOption={modalOption.changeImageModal}
            modalClose={() => {
              modalOption.setChangeImageModal((e) => {
                e.show = false;
                return { ...e };
              });
            }}
          />
        ) : null
      }
      {
        // report - history 변경 모달
        modalOption.changeHistoryModal.show ? (
          <ChangeHistoryModal
            modalOption={modalOption.changeHistoryModal}
            modalClose={() => {
              modalOption.setChangeHistoryModal((e) => {
                return { ...e, show: false };
              });
            }}
          />
        ) : null
      }
      {
        // report - 장상피화생 변경 모달
        modalOption.changeIMModal.show ? (
          <ChangeIMModal
            modalOption={modalOption.changeIMModal}
            modalClose={() => {
              modalOption.setChangeIMModal((e) => {
                return { ...e, show: false };
              });
            }}
          />
        ) : null
      }
      {
        // report - 암/선종 변경 모달
        modalOption.changeAbnormalityModal.show ? (
          <ChangeAbnormalityModal
            modalOption={modalOption.changeAbnormalityModal}
            modalClose={() => {
              modalOption.setChangeAbnormalityModal((e) => {
                return { ...e, show: false };
              });
            }}
          />
        ) : null
      }
      {
        // 로딩 모달
        modalOption.loadingModal.show ? (
          <LoadingModal
            modalOption={modalOption.loadingModal}
            modalClose={() => {
              modalOption.setLoadingModal((e) => {
                return { ...e, show: false, isClose : true };
              });
            }}
          />
        ) : null
      }
      {
        // software information 모달
        modalOption.softwareInformationModal.show ? (
          <SoftwareInformationModal
            modalOption={modalOption.softwareInformationModal}
            modalClose={() => {
              modalOption.setSoftwareInformationModal((e) => {
                return { ...e, show: false };
              });
            }}
          />
        ) : null
      }
      {
        // upload file 모달
        modalOption.uploadFileModal.show ? (
          <UploadFileModal
            modalOption={modalOption.uploadFileModal}
            modalClose={() => {
              modalOption.setUploadFileModal((e) => {
                e.show = false;
                return { ...e};
              });
            }}
          />
        ) : null
      }
      {
        // upload file 삭제 모달
        modalOption.uploadFileDeleteModal.show ? (
          <UploadFileDeleteModal
            modalOption={modalOption.uploadFileDeleteModal}
            modalClose={() => {
              modalOption.setUploadFileDeleteModal((e) => {
                return { ...e, show: false };
              });
            }}
          />
        ) : null
      }
    </>
  );
}
