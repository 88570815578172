import styled from "styled-components";
const Container = styled.div`
  position: relative;
  width: calc(100vw * (650 / 1920));
  justify-content: center;
  align-items: center;

  & .gaugeWrap {

    & .gaugeTitle {
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: calc(100vw * (60 / 1920));
      font-weight: 600;
      margin-bottom: calc(100vw * (10 / 1920));
    }
  }


  & .gauge {
    position: relative;
    margin-top: calc(100vw * (120 / 1920));
    margin-left: calc(100vw * (80 / 1920));
    width: calc(100vw * (500 / 1920));

      
    & .gaugeCenter {
      position: absolute;
      top: 100%;
      left: 50%;
      transform: translate(-50%, -50%);
      display: flex;
      justify-content: center;
      align-items: center;
      width: calc(100vw * (24 / 1920));
      height: calc(100vw * (24 / 1920));
      border-radius: 50%;
      background-color: #fff;
      font-size: calc(100vw * (80 / 1920));
      font-weight: 600;

      & .gaugePointer {
        // position: absolute;
        // top: 50%;
        // left: 50%;
        // width: calc(100vw * (190 / 1920));
        // height: calc(100vw * (3 / 1920));
        // background-color: #fff;
        // transform-origin: 0 0;
        // transform: rotate(${(props) => 180 + (props.score * 90 / 50)}deg);

          position: absolute;
          top: 50%;
          left: 50%;
          width: 0;
          height: 0;
          border-left: calc(100vw * (12 / 1920)) solid transparent;
          border-right: calc(100vw * (12 / 1920)) solid transparent;
          border-bottom: calc(100vw * (200 / 1920)) solid #fff;
          transform-origin: 50% 100%;
          transform: translate(-50%, calc(-100% - (100vw * (0 / 1920)))) 
             rotate(${(props) => -90 + (props.score * 180 / 100)}deg);
      }
  }


`;

export { Container };

export default { Container };
