import styled from "styled-components";

const Wrap = styled.div`
  width: 100%;
  min-height: 100vh;
  padding: calc(100vw * (100 / 1920)) calc(100vw * (32 / 1920)) 0;
  background-color: #000;

  & .table_bar {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: calc(100vw * (30 / 1920));
  }
  & .table_bar > button {
    position: absolute;
    height: calc(100vw * (48 / 1920));
    font-size: calc(100vw * (26 / 1920));
    font-weight: 500;
    border-radius: calc(100vw * (10 / 1920));
  }
  & .connect {
    left: 0;
    width: calc(100vw * (265 / 1920));
    background-color: #6a6a6a;
  }
  & .add {
    right: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    width: calc(100vw * (200 / 1920));
    height: calc(100vw * (48 / 1920));
    background-color: #008ad1;

    & .iconWrap {
      display: inline-block;
      width: calc(100vw * (20 / 1920));
      height: calc(100vw * (20 / 1920));
      margin-right: calc(100vw * (8 / 1920));
    }
  }
`;

export { Wrap };

export default { Wrap };
