import styled from "styled-components";

// const Container = styled.div`
//   padding: calc(100vw * (116 / 1920)) calc(100vw * (22 / 1920))
//     calc(100vw * (31 / 1920));
//   background-color: #d9d9d9;

//   & img {
//     display: block;
//     width: 100%;
//     height: 100%;
//     object-fit: fill;
//   }

//   & .wrap {
//     display: flex;
//     justify-content: space-between;

//     & .leftContainer {
//       flex-shrink: 0;
//       margin-right: calc(100vw * (44 / 1920));
//       padding-top: calc(100vw * (13 / 1920));

//       & .numberIconContainer {
//         display: flex;
//         justify-content: center;
//         align-items: center;
//         margin-bottom: calc(100vw * (25 / 1920));

//         & .landmarkName {
//           margin-left: calc(100vw * (10 / 1920));
//           font-size: calc(100vw * (30 / 1920));
//           font-weight: 700;
//           color: #000000;
//         }
//       }

//       & .stomachWrap {
//         width: calc(100vw * (230 / 1920));
//         height: calc(100vw * (210 / 1920));
//         margin-bottom: calc(100vw * (50 / 1920));

//         & img {
//           width: calc(100vw * (230 / 1920));
//           height: calc(100vw * (210 / 1920));
//         }
//       }
//     }

//     & .rightContainer {
//       display: flex;
//       gap: calc(100vw * (26 / 1920));

//       & .imgContainer {
//         padding: calc(100vw * (15 / 1920)) calc(100vw * (21 / 1920))
//           calc(100vw * (26 / 1920));
//         background-color: #ffffff;
//         border-radius: calc(100vw * (9 / 1920));

//         & .imgWrap {
//           width: calc(100vw * (440 / 1920));
//           height: calc(100vw * (440 / 1920));
//           margin-bottom: calc(100vw * (26 / 1920));
//           border-radius: calc(100vw * (9 / 1920));
//           overflow: hidden;
//         }

//         & .text {
//           font-size: calc(100vw * (35 / 1920));
//           color: #000;
//           text-align: right;

//           &.normal {
//             color: #009b6d;
//           }
//         }
//       }
//     }
//   }

//   & .changeButton {
//     display: flex;
//     justify-content: center;
//     align-items: center;
//     width: calc(100vw * (200 / 1920));
//     height: calc(100vw * (48 / 1920));
//     margin: calc(100vw * (25 / 1920)) 0 0 auto;
//     font-size: calc(100vw * (26 / 1920));
//     font-weight: 700;
//     background-color: #0075ff;
//     border-radius: calc(100vw * (11 / 1920));
//   }
// `;
const Container = styled.div`
  padding: calc(${(props)=>props.viewPort}vw * (45 / 1920)) calc(${(props)=>props.viewPort}vw * (22 / 1920))
  calc(${(props)=>props.viewPort}vw * (31 / 1920));
  background-color: white;
  border: calc(${(props)=>props.viewPort}vw * (5 / 1920)) solid #d9d9d9;

  & img {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: fill;
  }

  & .wrap {
    display: flex;

    & .leftContainer {
      flex-shrink: 0;
      margin-right: calc(${(props)=>props.viewPort}vw * (24 / 1920));

      & .numberIconContainer {
        display: flex;
        justify-content: center;
        align-items: center;
        margin-bottom: calc(${(props)=>props.viewPort}vw * (25 / 1920));

        & .landmarkName {
          margin-left: calc(${(props)=>props.viewPort}vw * (10 / 1920));
          font-size: calc(${(props)=>props.viewPort}vw * (30 / 1920));
          font-weight: 700;
          color: #000000;
        }
      }

      & .stomachWrap {
        width: calc(${(props)=>props.viewPort}vw * (230 / 1920));
        height: calc(${(props)=>props.viewPort}vw * (240 / 1920));
        margin-bottom: calc(${(props)=>props.viewPort}vw * (250 / 1920));

        & img {
          width: calc(${(props)=>props.viewPort}vw * (230 / 1920));
          height: calc(${(props)=>props.viewPort}vw * (220 / 1920));
        }
      }
    }

    & .rightContainer {
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      grid-gap: calc(${(props)=>props.viewPort}vw * (26 / 1920));
      width: 100%;

      & .imgContainer {
        background-color: #A1A1A1;
        border-radius: calc(${(props)=>props.viewPort}vw * (9 / 1920));

        & .imgWrap {
          height: calc(${(props)=>props.viewPort}vw * (470 / 1920));
          margin: calc(${(props)=>props.viewPort}vw * (10 / 1920)) calc(${(props)=>props.viewPort}vw * (15 / 1920));
          margin-bottom: calc(${(props)=>props.viewPort}vw * (30 / 1920));
          filter: drop-shadow(
            calc(${(props)=>props.viewPort}vw * (5 / 1920)) calc(${(props)=>props.viewPort}vw * (5 / 1920))
              calc(${(props)=>props.viewPort}vw * (4 / 1920)) rgba(0, 0, 0, 0.5)
          );
          border-radius: calc(${(props)=>props.viewPort}vw * (9 / 1920));
          overflow: hidden;
        }

        & .text {
          margin-bottom: calc(${(props)=>props.viewPort}vw * (20 / 1920));
          margin-left: calc(${(props)=>props.viewPort}vw * (15 / 1920));
          font-size: calc(${(props)=>props.viewPort}vw * (36 / 1920));
          font-weight: 700;
          color: #000;
        }
      }
    }
  }

  & .changeButton {
    display: flex;
    justify-content: center;
    align-items: center;
    width: calc(100vw * (200 / 1920));
    height: calc(100vw * (48 / 1920));
    margin: calc(100vw * (25 / 1920)) 0 0 auto;
    font-size: calc(100vw * (26 / 1920));
    font-weight: 700;
    background-color: #0075ff;
    border-radius: calc(100vw * (11 / 1920));
  }

  & .noImageBox {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #C4C4C4;
    height: 100%;
    padding: 20px;
    text-align: center;
  }

  & .noImageBoxTitle {
    font-size: calc(${(props)=>props.viewPort}vw * (40 / 1920));
    font-weight: 700;
    color: #000;
  }
`;

const EditContainer = styled.div`
  padding: calc(${(props)=>props.viewPort}vw * (45 / 1920)) calc(${(props)=>props.viewPort}vw * (22 / 1920))
  calc(${(props)=>props.viewPort}vw * (31 / 1920));
  background-color: #292929;
  border: calc(${(props)=>props.viewPort}vw * (5 / 1920)) solid #d9d9d9;

  & .editContainer {
    width: 100%;
    height: calc(${(props)=>props.viewPort}vw * ${props=>props.viewPort == 60 || props.viewPort == 80 ? 780 : 760} / 1920);
`;

export { Container, EditContainer };

export default { Container, EditContainer };
