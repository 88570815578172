import styled from "styled-components";

const Container = styled.div`
  display: flex;
  align-items: center;
  z-index: 8;

  & .miniStomachWrap {
    position: relative;
    display: flex;
    align-items: center;
    transform: scale(1);
    transition: 0.7s;
    transform-origin: top right;
    z-index: 2;
  }

  & .numberIconContainer {
    display: flex;
    align-items: center;
    margin-right: calc(100vw * (18 / 1920));
    font-size: calc(100vw * (30 / 1920));
    font-weight: 700;
    transition: 0.7s;
    z-index: 2;

    & .landmarkName {
      margin-left: calc(100vw * (13 / 1920));
    }
  }

  & .stomachContainer {
    width: calc(100vw * (89 / 1920));
    height: calc(100vw * (72 / 1920));
    z-index: 2;

    & img {
      width: calc(100vw * (89 / 1920));
      height: calc(100vw * (72 / 1920));
    }
  }

  & .overlay {
    position: fixed;
    top: calc(100vw * (100 / 1920));
    right: 0;
    display: none;
    width: 100vw;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.8);
    opacity: 0;
    z-index: 1;
  }

  &.on {
    & .numberIconContainer {
      transform: translate(
        calc(100vw * (-300 / 1920)),
        calc(100vw * (100 / 1920))
      );
    }

    & .miniStomachWrap {
      transform: scale(5);
    }

    & .overlay {
      display: block;
      opacity: 1;
    }
  }
`;

export { Container };

export default { Container };
