import { useAtom } from "jotai";
import { set } from "lodash";
import { modalAtom } from "store/atoms";

export default function useModals() {
  const [defaultModal, setDefaultModal] = useAtom(modalAtom.defaultModalAtom);
  const [basicModal, setBasicModal] = useAtom(modalAtom.basicModalAtom);
  const [toastModal, setToastModal] = useAtom(modalAtom.toastModalAtom);
  const [changePassowrdModal, setChangePassowrdModal] = useAtom(
    modalAtom.changePassowrdModalAtom
  );
  const [userDeleteModal, setUserDeleteModal] = useAtom(
    modalAtom.userDeleteModalAtom
  );
  const [searchAdminModal, setSearchAdminModal] = useAtom(
    modalAtom.searchAdminModalAtom
  );
  const [searchPatientModal, setSearchPatientModal] = useAtom(
    modalAtom.searchPatientModalAtom
  );
  const [searchCheckupModal, setSearchCheckupModal] = useAtom(
    modalAtom.searchCheckupModalAtom
  );
  const [moreResultModal, setMoreResultModal] = useAtom(
    modalAtom.moreResultModalAtom
  );
  const [contextMenuModal, setContextMenuModal] = useAtom(
    modalAtom.contextMenuModalAtom
  );
  const [doubleClickModal, setDoubleClickModal] = useAtom(
    modalAtom.doubleClickModalAtom
  );
  const [changeImageModal, setChangeImageModal] = useAtom(
    modalAtom.changeImageModalAtom
  );
  const [changeHistoryModal, setChangeHistoryModal] = useAtom(
    modalAtom.changeHistoryModalAtom
  );
  const [changeIMModal, setChangeIMModal] = useAtom(
    modalAtom.changeIMModalAtom
  );
  const [changeAbnormalityModal, setChangeAbnormalityModal] = useAtom(
    modalAtom.changeAbnormalityModalAtom
  );
  const [loadingModal, setLoadingModal] = useAtom(modalAtom.loadingModalAtom);
  const [softwareInformationModal, setSoftwareInformationModal] = useAtom(
    modalAtom.softwareInformationModalAtom
  );
  const [uploadFileModal, setUploadFileModal] = useAtom(
    modalAtom.uploadFileModalAtom
  );
  const [uploadFileDeleteModal, setUploadFileDeleteModal] = useAtom(
    modalAtom.uploadFileDeleteModalAtom
  );

  const clearModals = () => {
    setDefaultModal({ show: false });
    setBasicModal({ show: false });
    setToastModal({ show: false });
    setChangePassowrdModal({ show: false });
    setUserDeleteModal({ show: false });
    setSearchAdminModal({ show: false });
    setSearchPatientModal({ show: false });
    setSearchCheckupModal({ show: false });
    setMoreResultModal({ show: false });
    setContextMenuModal({ show: false });
    setDoubleClickModal({ show: false });
    setChangeImageModal({ show: false });
    setChangeHistoryModal({ show: false });
    setChangeIMModal({ show: false });
    setChangeAbnormalityModal({ show: false });
    setLoadingModal({ show: false });
    setSoftwareInformationModal({ show: false });
    setUploadFileModal({ show: false });
    setUploadFileDeleteModal({ show: false });
  };

  return {
    clearModals,
    defaultModal,
    setDefaultModal,
    basicModal,
    setBasicModal,
    toastModal,
    setToastModal,
    changePassowrdModal,
    setChangePassowrdModal,
    userDeleteModal,
    setUserDeleteModal,
    searchAdminModal,
    setSearchAdminModal,
    searchPatientModal,
    setSearchPatientModal,
    searchCheckupModal,
    setSearchCheckupModal,
    moreResultModal,
    setMoreResultModal,
    contextMenuModal,
    setContextMenuModal,
    doubleClickModal,
    setDoubleClickModal,
    changeImageModal,
    setChangeImageModal,
    changeHistoryModal,
    setChangeHistoryModal,
    changeIMModal,
    setChangeIMModal,
    changeAbnormalityModal,
    setChangeAbnormalityModal,
    loadingModal,
    setLoadingModal,
    softwareInformationModal,
    setSoftwareInformationModal,
    uploadFileModal,
    setUploadFileModal,
    uploadFileDeleteModal,
    setUploadFileDeleteModal,
  };
}
