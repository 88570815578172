import product from "./product";
import login from "./login";
import patient from "./patient";
import doctor from "./doctor";
import image from "./image";
import stomach from "./stomach";
import setting from "./setting";
import history from "./history";
import admin from "./admin";
import reports from "./reports";
import studies from "./studies";

export default {
  product,
  login,
  patient,
  doctor,
  image,
  stomach,
  setting,
  history,
  admin,
  reports,
  studies,
};

export {
  product,
  login,
  patient,
  doctor,
  image,
  stomach,
  setting,
  history,
  admin,
  reports,
  studies,
};
