import { common, layout } from "components";
import Styles from "./styles";
import { MiniStomachContainer } from "./components";
import { useParams, useNavigate, useLocation } from "react-router-dom";
import { useMemo } from "react";

// img
import { useQuery, useQueryClient } from "react-query";
import { api } from "service";
import moment from "moment";
import { IMAGE_REF } from "constants/common";
import utils from "utils";

export default function HistoryPage() {
  const { type, id, studyId, landmark } = useParams();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const imageId = queryParams.get("imageId");

  const navigate = useNavigate();
  const queryClient = useQueryClient();
  console.log("page에서 imageId", imageId);

  const { data: patientsData = {}, isLoading: isLoadingPatientsData } =
    useQuery(["patients_info", id], () => api.patient.getInfo(id), {
      refetchOnWindowFocus: false,
    });

  // history loading modal 추가 해야 하는 api
  const { data: historyData, isLoaing: isLoadingHistoryData } = useQuery(
    ["history_list", landmark],
    () => api.history.getList({ patientId: studyId, landmark }),
    { refetchOnWindowFocus: false, enabled: !!landmark }
  );

  const sortHistoryData = useMemo(() => {
    if (!historyData) return [];
    return Object.entries(historyData)
      .sort((a, b) => {
        const _a = Number(moment(a[0]).format("YYYYMMDD"));
        const _b = Number(moment(b[0]).format("YYYYMMDD"));
        return _b - _a;
      })
      ?.map(([key, value]) => ({ date: key, list: value }));
  }, [historyData]);

  const recentlyImageData = sortHistoryData[0];

  const sortPastHistoryData = sortHistoryData.slice(1);

  const pastImageData = useMemo(() => {
    if (!sortHistoryData?.length) return [];
    const getLongestPastImageDataLength = sortPastHistoryData;

    let list = Array(sortPastHistoryData?.length).fill([]);
    getLongestPastImageDataLength.map((value, index) => {
      if (value.list.length > 0) {
        console.log(value);
        let item = value.list.map((v, i) => {
          const data = getLongestPastImageDataLength[index].list[i];
          if (!data) return null;
          return {
            historyDate: "",
            ...data,
          };
        });
        list[index] = item;
      }
    });

    return utils.etc.transposeNewArray(list);
  }, [historyData]);

  // data
  const imageData = useMemo(
    () => ({
      normalImage: IMAGE_REF + landmark + ".png",
      recentlyImageData,
      pastImageData,
      imageId: imageId && Number(imageId),
      pastDateList: sortPastHistoryData?.map((item) => item.date) || [],
    }),
    [landmark, historyData]
  );

  if (isLoadingHistoryData) return <></>;
  if (!imageData.recentlyImageData) return <></>;
  else
    return (
      <>
        <common.SubHeader />

        <Styles.Container>
          <div className="infoContainer">
            <layout.UserDataList
              select
              patientsData={patientsData}
              id={id}
              studyId={studyId}
              type={"detail"}
              navigate={navigate}
            />
          </div>

          <div className="miniStomachContainer">
            <MiniStomachContainer
              isHoverOutSectionClick
              landmark={landmark}
              onClickStomachSection={({ landmark }) => {
                navigate(`/history/${id}/${studyId}/${landmark}`);
                queryClient.invalidateQueries(["history_list", landmark]);
              }}
            />
          </div>

          {imageData?.pastImageData?.length || sortPastHistoryData[0] ? (
            <layout.PastHistory
              data={imageData}
              landmark={landmark}
              historyPage
            />
          ) : (
            // <layout.PastHistory
            //   data={imageData}
            //   landmark={landmark}
            //   historyPage
            // />
            <layout.NoPastHistory
              data={imageData}
              landmark={landmark}
              historyPage
            />
          )}
        </Styles.Container>
      </>
    );
}
