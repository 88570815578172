import Styles from "./styles";

export default function CommentBox({ value, onChange, onBlur, onKeyUp }) {
  return (
    <Styles.Container>
      <textarea autoFocus value={value} onChange={onChange} onBlur={onBlur} onKeyUp={onKeyUp}>
        {value}
      </textarea>
    </Styles.Container>
  );
}
