import Styles from "./styles";
import { layout, common } from "components";
import { useLanguage } from "hooks";
import { useQuery } from "react-query";
import { useParams } from "react-router-dom";
import { useReport } from "hooks/useReport";
import { useEffect, useState } from "react";
import PicturesTitle from "../PicturesTitle";
import { getCookie } from "service/connector";

// img
import { api } from "service";
import {
  lesionTypeToColor,
} from "utils/common";

export default function IMSection(props) {
  const lang = useLanguage("Report");
  const [scope, setScope] = useState("lite");
  const { studyId } = useParams();
  const [imScore, setImScore] = useState(null);
  const [lesionType, setLesionType] = useState(null);
  const [landmark, setLandmark] = useState(null);
  const [imageListData, setImageListData] = useState(null);
  const [imSensitivity, setImSensitivity] = useState(null);
  const [notSelectedSection, setNotSelectedSection] = useState(null);
  useEffect(() => {
    setScope(getCookie("scope"));
  }, []);
  const reportInfo = useReport();

  useEffect(() => {
    if (reportInfo.reportData?.im) {
      setImageListData(reportInfo.reportData.im.imageListData);
      setImScore(reportInfo.reportData.im.lesionScore);
      setLesionType(reportInfo.reportData.im.lesionType);
      setLandmark(reportInfo.reportData.im.landmark);
      setImSensitivity(reportInfo.reportData.im.imSensitivity);
      setNotSelectedSection(reportInfo.reportData.im.notSelectedSection);
    }
  }, [reportInfo.reportData]);

  const { data: settingsData, isLoadingSettings } = useQuery(
    ["settings"],
    () => api.setting.getSetting(),
    {
      refetchOnWindowFocus: false,
    }
  );

  const lesionScoreType =
  imScore > imSensitivity ? "yellow" : "green";


  if (!imageListData) return <></>;

  return (
    <>
      <Styles.Container
        paddingSize={props.paddingSize}
        viewPort={props.viewPort}
      >
        <div className="wrap">
          <div className="leftContainer">
            <div className="stomachWrap">
              <layout.NoHoverStomachReport
                selectedSection={landmark}
                notSelectedSection={notSelectedSection}
                noClickEvent
                opacityBg
                numberType={lesionScoreType}
                screen={"report_heatmap"}
              />
            </div>
            <layout.LesionAnimationContainer
              noAni={true}
              score={imScore}
              type={lesionScoreType}
              viewPort={props.viewPort}
              lite_type={"im_section"}
              screen={"heatmap"}
            />
          </div>

          <div className="rightContainer">
            {imageListData?.map((item, index) => {
              return (
                <div className="imgContainer" key={"imgContainer" + index}>
                  <div className="imgWrap">
                    <img src={item.img} alt="stomach" />
                  </div>

                  <p className={"text" + (item.normal ? " normal" : "")}>
                    {item.text}
                  </p>
                </div>
              );
            })}
          </div>
        </div>
      </Styles.Container>
    </>
  );
}
