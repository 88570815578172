import connector from "service/connector";

function getFilterStr(parameter) {
  let filter = parameter;
  return new URLSearchParams(filter).toString();
}

const getList = async (parameter) => {
  return await connector.authConnector(
    `images/pacs/path/${parameter.studyId}?apt=${
      parameter.is_apt ? "true" : "false"
    }`,
    "GET"
  );
};

const putLandmarkChange = async ({
  landmark,
  imageId,
  priority,
  sequence,
  imageName,
  path,
}) => {
  // console.log("landmark => ", landmark);
  // console.log("imageId => ", imageId);
  // console.log("path => ", path);
  // console.log("imageName => ", imageName);

  // sequence 받은거 +1해서 query param (targetSequence)으로 붙여 보내야함
  // priorty는 없어도 됨
  // 아래는 변경된 바디
  // {
  //     "imageId": 417,
  //     "imageName": "231106-005-1-5.jpg",
  //     "path": "1001/231106-005/jpg/231106-005-1-5.jpg"
  // }

  return await connector.authConnector(
    `images/landmark-change/${landmark}?targetSequence=${sequence + 1}`,
    "PUT",
    {
      imageId,
      imageName,
      path,
    }
  );
};

const getIsLesions = async ({ medicalstudyId }) => {
  return await connector.authConnector(
    `images/is-lesions/${medicalstudyId}`,
    "GET"
  );
};
const getLesionsAbnormalityPath = async ({ medicalstudyId }) => {
  return await connector.authConnector(
    `images/lesions/path/${medicalstudyId}?isIM=false`,
    "GET"
  );
};
const getLesionsIMPath = async ({ medicalstudyId }) => {
  return await connector.authConnector(
    `images/lesions/path/${medicalstudyId}?isIM=true`,
    "GET"
  );
};
const getImAnalysis = async (id) => {
  console.log(id);
  return await connector.authConnector(`images/im-analysis/${id}`, "GET");
};

const getNormal = async () => {
  return await connector.authConnector(`images/normal`, "GET");
};

const getLesionJson = async ({ path }) => {
  // const imageJsonSrc = path.split(".")[0] + ".json";
  const imageJsonSrc = path.replace(/jpg/g, "json");
  // console.log("original path => ", path);
  // console.log("image path => ", imageJsonSrc);
  return await connector.authConnector(`src/${imageJsonSrc}`, "GET");
};

// image.jpeg
// image + .json
// 기존응답 : 123/2323/2323-1.jpg
// 신규응답 : 123/2323/jpg/2323-1.jpg
// 전달 : 123/2323/json/2323-1.json

export {
  getList,
  putLandmarkChange,
  getIsLesions,
  getLesionsAbnormalityPath,
  getLesionsIMPath,
  getNormal,
  getLesionJson,
  getImAnalysis,
};

export default {
  getList,
  putLandmarkChange,
  getIsLesions,
  getLesionsAbnormalityPath,
  getLesionsIMPath,
  getNormal,
  getLesionJson,
  getImAnalysis,
};
