import styled from "styled-components";

// img
import IconArrowBottom from "resources/image/icon/icon_arrow_bottom_big.svg";
import IconArrowTop from "resources/image/icon/icon_arrow_top_big.svg";

const Container = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  height: 100dvh;
  z-index: 15;

  & img {
    width: 100%;
    height: 100%;
    object-fit: fill;
  }

  & .inner {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    display: flex;
    width: calc(100vw * (1835 / 1920));
    height: 90vh;
    height: 90dvh;
    padding: calc(100vw * (56 / 1920)) calc(100vw * (70 / 1920))
      calc(100vw * (31 / 1920));
    color: #ffffff;
    background: #292929;
    border-radius: calc(100vw * (20 / 1920));
    overflow: auto;

    &.type1 {
      & .comparisonContainer {
        position: absolute;
        top: 6%;
        left: 50%;
        transform: translateX(-50%);
        display: flex;
        align-items: center;
        gap: calc(100vw * (60 / 1920));
        height: calc(100vw * (820 / 1920));
        overflow: hidden;

        & .imgContainer {
          width: calc(100vw * (650 / 1920));
          height: calc(100vw * (650 / 1920));
          margin-left: 0;

          &.slider {
            box-shadow: none;
            opacity: 0.5;
            transition: 0.3s;
          }

          &.swiper-slide-active {
            opacity: 1;
          }
        }

        & .swiper {
          height: calc(100vw * (820 / 1920));
        }

        & .swiper-button-prev,
        .swiper-button-next {
          position: absolute;
          left: 50%;
          width: calc(100vw * (110 / 1920));
          height: calc(100vw * (110 / 1920));
          transform: translateX(-50%);
          filter: blur(0);
          z-index: 1;
          cursor: pointer;

          &::after {
            content: "";
          }
        }

        & .swiper-button-prev {
          top: calc(100vw * (-4 / 1920));
          background: url(${IconArrowTop}) no-repeat center / cover;
        }

        & .swiper-button-next {
          top: auto;
          bottom: calc(100vw * (-26 / 1920));
          background: url(${IconArrowBottom}) no-repeat center / cover;
        }
      }

      & .imgContainer {
        position: relative;
        width: calc(100vw * (970 / 1920));
        height: calc(100vw * (897 / 1920));
        margin-left: calc(50% - calc(100vw * (485 / 1920)));
        margin-bottom: calc(100vw * (20 / 1920));
      }
    }

    &.type2 {
      & .imageListWrap {
        position: absolute;
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        grid-auto-rows: calc(100vw * (770 / 1920));
        gap: calc(100vw * (30 / 1920));
        top: calc(100vw * (100 / 1920));
        left: calc(100vw * (50 / 1920));
      }

      & .imgContainer {
        height: calc(100vw * (770 / 1920));
      }
    }

    &.type3 {
      & .imageListWrap {
        position: absolute;
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        gap: calc(100vw * (30 / 1920));
        top: calc(100vw * (180 / 1920));
        left: calc(100vw * (50 / 1920));
      }

      & .imgContainer {
        width: calc(100vw * (560 / 1920));
        height: calc(100vw * (560 / 1920));
      }
    }

    &.type4 {
    
      & .imageListWrap {
        position: absolute;
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        grid-auto-rows: calc(100vw * (450 / 1920));
        gap: calc(100vw * (30 / 1920));
        bottom: calc(100vw * (20 / 1920));
        left: 25%;
      }

      & .imgContainer {
        width: calc(100vw * (450 / 1920));
        height: calc(100vw * (450 / 1920));
      }
    }

    & .closeButton {
      position: absolute;
      top: calc(100vw * (11 / 1920));
      right: calc(100vw * (16 / 1920));
      width: calc(100vw * (69 / 1920));
      height: calc(100vw * (69 / 1920));
    }

    & .userInfoContainer {
      flex-shrink: 0;
      font-size: calc(100vw * (20 / 1920));

      & .userName {
        margin-bottom: calc(100vw * (15 / 1920));
        font-size: calc(100vw * (40 / 1920));
        font-weight: bold;
      }

      & .dateContainer {
        display: inline-block;
      }
      & .dateContainer p {
        display: flex;
        align-items: center;
        justify-content: space-between;
      }

      & .dateTitle {
        margin-right: calc(100vw * (13 / 1920));
      }

      & .birthContainer {
        margin-bottom: calc(100vw * (3 / 1920));
      }
    }

    & .imgContainer {
      width: 100%;
      box-shadow: calc(100vw * (12 / 1920)) calc(100vw * (18 / 1920))
        calc(100vw * (28 / 1920)) rgba(0, 0, 0, 0.63);
      border-radius: calc(100vw * (9 / 1920));
      overflow: hidden;
    }

    /* & img {
      object-fit: contain;
      max-height: 100%;
      max-width: 100%;
      width: 100%;
      height: auto;
    } */

    & .comparisonCheckBoxContainer {
      position: absolute;
      bottom: calc(100vw * (28 / 1920));
      right: calc(100vw * (28 / 1920));
      font-size: calc(100vw * (22 / 1920));
      font-weight: bold;
    }

    & .imageListWrap {
      display: flex;
    }
  }

  & .overlay {
    width: 100%;
    height: 100%;
    background: #000;
  }

  @media (max-width: 1366px) {
    & .inner.type1 {
      & .comparisonContainer {
        height: calc(100vw * (820 / 1920));

        & .swiper {
          height: calc(100vw * (820 / 1920));
        }

        & .swiper-button-prev {
          top: calc(100vw * (22 / 1920));
        }

        & .swiper-button-next {
          bottom: calc(100vw * (-6 / 1920));
        }
      }
    }
  }

  @media (max-width: 1180px) {
    & .inner.type1 {
      & .comparisonContainer {
        height: calc(100vw * (850 / 1920));

        & .swiper {
          height: calc(100vw * (850 / 1920));
        }

        & .swiper-button-prev {
          top: calc(100vw * (33 / 1920));
        }

        & .swiper-button-next {
          bottom: calc(100vw * (-3 / 1920));
        }
      }
    }
  }
`;

export { Container };

export default { Container };
