import styled from "styled-components";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: calc(100vw * (530 / 1920));
  height: calc(100vw * (779 / 1920));
  padding: calc(100vw * (30 / 1920)) calc(100vw * (40 / 1920));
  border-radius: calc(100vw * (20 / 1920));
  background-color: #292929;

  & img {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: fill;
  }

  & .analysisTitle {
    margin-bottom: calc(100vw * (80 / 1920));
    font-size: calc(100vw * (60 / 1920));
    font-weight: 600;
  }

  & .stomachWrap {
    width: calc(100vw * (439 / 1920));
    height: calc(100vw * (370 / 1920));

    & img {
      width: calc(100vw * (439 / 1920));
      height: calc(100vw * (370 / 1920));
    }
  }

  & .stomachListWrap {
    width: calc(100vw * (447 / 1920));
    margin-top: auto;

    & .stomachList {
      display: flex;
      justify-content: space-between;
      width: 100%;
      font-size: calc(100vw * (30 / 1920));
      font-weight: 600;
    }
  }

  & .guageContainer {
    position: relative;
    display: flex;
    width: calc(100vw * (447 / 1920));
    height: calc(100vw * (5 / 1920));
    margin: calc(100vw * (25 / 1920)) 0 calc(100vw * (15 / 1920));

    & .orange,
    & .green {
      height: 100%;
    }

    & .orange {
      background-color: #ff9900;
    }

    & .green {
      background-color: #009b6d;
    }
  }

  & .imResultBar {
    position: relative;
    margin-top: calc(100vw * (100 / 1920));
    width: calc(${(props)=>{return (props.viewPort?props.viewPort:100)}}vw * (447 / 1920));

    & .imText {
      display: flex;
      margin-top: calc(${(props)=>{return (props.viewPort?props.viewPort:100)}}vw * (20 / 1920));
      margin-bottom: calc(${(props)=>{return (props.viewPort?props.viewPort:100)}}vw * (3 / 1920));
      font-size: calc(${(props)=>{return (props.viewPort?props.viewPort:100)}}vw * (18 / 1920));

      & .normal {
        margin-left: calc(${(props)=>{return (props.viewPort?props.viewPort:100)}}vw * (10 / 1920));
      }

      & .im {
        margin-left: calc(${(props)=>{return (props.viewPort?props.viewPort:100)}}vw * (340 / 1920));
      }

      & .off {
        opacity: 0.4;
      }

      & .on {
        opacity: 1;
        font-weight: 400;
      }
    }

    & .imResultBarBase {
      & .normal {
        border-radius: calc(${(props)=>{return (props.viewPort?props.viewPort:100)}}vw * (5 / 1920));
        background: linear-gradient(to right, ${ props => props.totalPercent >= props.imSensitivity ? '#FF9900' : '#04B24A' } calc(0% + ${props => props.totalPercent}%), #292929 calc(0% + ${props => props.totalPercent}%));
      }
    }
  }



  & .totalContainer {
    font-size: calc(100vw * (50 / 1920));
    font-weight: 600;

    &.Antrum {
      color: #009b6d;
    }

    &.Corpus {
      color: #ff9900;
    }
  }


`;

export { Container };

export default { Container };
