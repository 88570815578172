import { useState } from "react";

const useIsTimeoutAfterAction = () => {
  const [isAbleAction, setIsAbleAction] = useState(true);

  const onTimeoutAction = (timeout = 250) => {
    setIsAbleAction(false);

    setTimeout(() => {
      setIsAbleAction(true);
    }, timeout);
  };

  return {
    isAbleAction,
    onTimeoutAction,
  };
};

export default useIsTimeoutAfterAction;
