import Styles from "./styles";

export default function BasicCheckbox({
  checked,
  onChange,
  text,
  id,
  onClick,
}) {
  return (
    <Styles.Container>
      <input
        type="checkbox"
        id={id}
        checked={checked}
        onChange={onChange}
        onClick={onClick}
      />
      <label htmlFor={id}>
        <span className="checkboxContainer"></span>
        <span className="checkboxName">{text}</span>
      </label>
    </Styles.Container>
  );
}
