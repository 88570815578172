import StateButton from "components/ui/button/StateButton";
import Styles from "./styles";
import { useNavigate } from "react-router-dom";
import { useLanguage } from "hooks";
import { useEffect } from "react";

export default function TablePatients({ item, modalClose }) {
  const navigate = useNavigate();
  const lang = useLanguage("Patient");

  console.log(item);
  return (
    <Styles.Container>
      <div className={`title_box ${item?.length > 12 ? "scroll" : ""}`}>
        <div className="box title">
          <p>{lang.getLang("환자 ID")}</p>
          <button type="button"></button>
        </div>
        <div className="box title">
          <p>{lang.getLang("성명")}</p>
          <button type="button"></button>
        </div>
        <div className="box title center">
          <p>{lang.getLang("성별")}</p>
        </div>
        <div className="box title center">
          <p>{lang.getLang("생년월일")}</p>
          <button type="button"></button>
        </div>
        <div className="box title center">
          <p>{lang.getLang("검진 일자")}</p>
          <button type="button"></button>
        </div>
        <div className="box title center">
          <p>{lang.getLang("휴대폰 번호")}</p>
        </div>
        <div className="box title center">
          <p>{lang.getLang("상태")}</p>
        </div>
        <div className="box title">
          <p>{lang.getLang("접수 메모")}</p>
        </div>
      </div>

      <div className="list_box">
        {item?.map((value, index) => {
          return (
            <div
              key={"list_row" + index}
              className="list_row"
              onClick={() => {
                navigate(`/patient/detail/${value?.INDEX}`);
                modalClose?.();
              }}
            >
              <div className="box list">
                <p>{value?.INDEX}</p>
              </div>
              <div className="box list">
                <p>{value?.patientName ? value?.patientName : value?.name}</p>
              </div>
              <div className="box list center">
                <p>
                  {value?.patientSex
                    ? value?.patientSex?.toUpperCase()
                    : value?.sex?.toUpperCase()}
                </p>
              </div>
              <div className="box list center">
                <p>
                  {value?.patientBirthdate
                    ? value?.patientBirthdate
                    : value?.birthday}
                </p>
              </div>
              <div className="box list center">
                <p>{value?.prevStudyDate ? value?.prevStudyDate : "-"}</p>
              </div>
              <div className="box list center">
                <p>{value?.phone}</p>
              </div>
              <div className="box list center">
                <StateButton
                  type={value?.status ? value?.status : value?.todayStatus}
                />
              </div>
              <div className="box list">
                <p className="text">{value?.memo}</p>
              </div>
            </div>
          );
        })}
      </div>
    </Styles.Container>
  );
}
