import React, { useEffect, useRef } from "react";
import Styles from "./styles";
import PropTypes from "prop-types";

import { App, getDwvVersion, decoderScripts } from "dwv";
import { IMAGE_SRC } from "constants/common";

export default function DwvComponent({ item, index }) {
  const dwvApp = useRef();

  useEffect(() => {
    const app = new App();
    // initialise app
    app.init({
      dataViewConfigs: {
        "*": [
          {
            divId: `layerGroup${index}`,
          },
        ],
      },
      tools: {
        Scroll: {},
      },
    });

    // handle window resize
    window.addEventListener("resize", app.onResize);
    // dwvApp = app;
    // store

    // setup drop box
    const convertURLtoFile = async (url) => {
      const response = await fetch(url);
      const data = await response.blob();
      // const ext = url.split(".").pop(); // url 구조에 맞게 수정할 것
      const filename = url.split("/").pop(); // url 구조에 맞게 수정할 것
      const metadata = { type: `application/dicom` };

      const file = new File([data], filename, metadata);
      console.log("file");
      console.log(file);
      app.loadFiles([file]);
    };

    // possible load from location
    // app.loadFromUri(window.location.href);
    convertURLtoFile(`${IMAGE_SRC}${item.path}`);
  }, []);

  return (
    <Styles.Container>
      <div id={`layerGroup${index}`} className="layerGroup">
        <div id={`dropBox${index}`}></div>
      </div>
    </Styles.Container>
  );
}
