import styled from "styled-components";

const Container = styled.div`
  padding-top: calc(${(props)=>props.viewPort}vw * (3 / 1920));
  font-size: calc(${(props)=>props.viewPort}vw * (28 / 1920));
  border-top: 1px solid #68727d;

  & * {
    color: #303943;
  }

  & img {
    width: 100%;
    height: 100%;
    object-fit: fill;
  }

  & table {
    width: 100%;
    border-top: 1px solid #68727d;
  }

  & col {
    border-right: 1px solid #68727d;
  }

  & tr {
    border-bottom: 1px solid #68727d;
  }

  & th {
    width: calc(${(props)=>props.viewPort}vw * (230 / 1920));
    height: calc(${(props)=>props.viewPort}vw * (73 / 1920));
    padding: 0 calc(${(props)=>props.viewPort}vw * (26 / 1920));
    background-color: #f3f3f3;
    vertical-align: middle;
    border-right: 1px solid #68727d;
  }

  & td {
    padding: 0 calc(${(props)=>props.viewPort}vw * (30 / 1920));
    font-weight: bold;
    vertical-align: middle;
  }
`;

export { Container };

export default { Container };
