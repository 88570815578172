import Styles from "./styles";

export default function BasicTextarea({ type, value, setValue, placeholder }) {
  return (
    <Styles.TextareaBox>
      <textarea
        value={value}
        onChange={(e) => {
          setValue(e.target.value);
        }}
        placeholder={placeholder}
        autoComplete="off"
      ></textarea>
    </Styles.TextareaBox>
  );
}
