const lang = {
  // 0. Admin Page
  "+ 유저 생성": {
    _en: "+ create user",
  },
  이름: {
    _en: "Name",
  },
  "휴대폰 번호": {
    _en: "Phone",
  },
  권한: {
    _en: "Auth",
  },
  생성일자: {
    _en: "Creation date",
  },
  "비밀번호 변경": {
    _en: "Change password",
  },
  "유저 삭제": {
    _en: "User delete",
  },
  // 0-1. Admin Page - User Create
  "신규유저 생성": {
    _en: "CREATE USER",
  },
  "ID를 입력해주세요.": {
    _en: "Please enter your ID.",
  },
  "* 띄어쓰기 없이 영/숫자 2~20자": {
    _en: "*  Alphanumeric 2~20 characters.",
  },
  "이미 사용중인 ID 입니다.": {
    _en: "This ID already in use.",
  },
  "띄어쓰기 없이 영/숫자로 2~20만 가능합니다.": {
    _en: "Only use 2~20 characters consisting of letters and numbers without spaces.",
  },
  "비밀번호*": {
    _en: "Password*",
  },
  "영문 대소문자, 숫자, 특수문자를 포함한 8-15자를 입력해주세요.": {
    _en: "8-15 chars: letters (upper/lower), numbers, special chars.",
  },
  "비밀번호를 입력해주세요.": {
    _en: "Please enter your password.",
  },
  "비밀번호 확인*": {
    _en: "Confirm*",
  },
  "비밀번호를 다시 확인해주세요.": {
    _en: "Please check your password again.",
  },
  "비밀번호가 일치하지 않습니다.": {
    _en: "Passwords do not match.",
  },
  이름: {
    _en: "Name",
  },
  "* 성함을 입력해주세요.": {
    _en: "* Input your name.",
  },
  "한글/영문으로 입력해주세요.": {
    _en: "Input in Korean or English.",
  },
  "휴대폰 번호": {
    _en: "Phone",
  },
  구분: {
    _en: "Auth",
  },
  의사: {
    _en: "Doctor",
  },
  간호사: {
    _en: "Nurse",
  },
  취소: {
    _en: "Cancel",
  },
  완료: {
    _en: "Create User",
  },

  // 0-2. Admin Page - PW Change
  "비밀번호 변경": {
    _en: "Change password",
  },
  "새로운 비밀번호 *": {
    _en: "New Password *",
  },
  "* 영문 대문자, 소문자, 숫자, 특수문자를 적어도 1개씩 포함한 4-20자": {
    _en: "* 4-20: at least one uppercase, lowercase, number, and special character.",
  },
  "새로운 비밀번호 확인 *": {
    _en: "Confirm *",
  },
  "* 위의 비밀번호를 다시 입력해주세요.": {
    _en: "* Input your password once more.",
  },
  "성명을 입력해주세요.": {
    _en: "Please, Input your name.",
  },
  "휴대폰 번호를 입력해주세요.": {
    _en: "Please, Input your Phone number.",
  },
  "올바른 휴대폰 번호를 입력해주세요.": {
    _en: "Please, Check your Phone number.",
  },
  업데이트: {
    _en: "Update",
  },
  "취 소": {
    _en: "Cancel",
  },
  // 0-3. Admin Page - User Delete
  "유저 삭제": {
    _en: "User delete",
  },
  "계정 삭제": {
    _en: "Delete",
  },
  "이름 또는 ID": {
    _en: "Name or PateintID",
  },
};

export default lang;
