import DefaultModal from "./DefaultModal";
import BasicModal from "./BasicModal";
import ToastModal from "./ToastModal";
import ChangePasswordModal from "./ChangePasswordModal";
import UserDeleteModal from "./UserDeleteModal";
import SearchAdminModal from "./SearchAdminModal";
import SearchPatientModal from "./SearchPatientModal";
import SearchCheckupModal from "./SearchCheckupModal";
import MoreResultModal from "./MoreResultModal";
import ContextMenuModal from "./ContextMenuModal";
import DoubleClickModal from "./DoubleClickModal";
import ChangeImageModal from "./ChangeImageModal";
import ChangeHistoryModal from "./ChangeHistoryModal";
import ChangeIMModal from "./ChangeIMModal";
import ChangeAbnormalityModal from "./ChangeAbnormalityModal";
import LoadingModal from "./LoadingModal";
import SoftwareInformationModal from "./SoftwareInformationModal";
import UploadFileModal from "./UploadFileModal";
import UploadFileDeleteModal from "./UploadFileDeleteModal";

export {
  DefaultModal,
  BasicModal,
  ToastModal,
  ChangePasswordModal,
  UserDeleteModal,
  SearchAdminModal,
  SearchPatientModal,
  SearchCheckupModal,
  MoreResultModal,
  ContextMenuModal,
  DoubleClickModal,
  ChangeImageModal,
  ChangeHistoryModal,
  ChangeIMModal,
  ChangeAbnormalityModal,
  LoadingModal,
  SoftwareInformationModal,
  UploadFileModal,
  UploadFileDeleteModal,
};

export default {
  DefaultModal,
  BasicModal,
  ToastModal,
  ChangePasswordModal,
  UserDeleteModal,
  SearchAdminModal,
  SearchPatientModal,
  SearchCheckupModal,
  MoreResultModal,
  ContextMenuModal,
  DoubleClickModal,
  ChangeImageModal,
  ChangeHistoryModal,
  ChangeIMModal,
  ChangeAbnormalityModal,
  LoadingModal,
  SoftwareInformationModal,
  UploadFileModal,
  UploadFileDeleteModal,
};
