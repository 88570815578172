import styled from "styled-components";

const Container = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: 30;

  & .inner {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: calc(100vw * (750 / 1920));
    padding: calc(100vw * (43 / 1920)) calc(100vw * (68 / 1920));
    font-size: calc(100vw * (24 / 1920));
    background: #292929;

    & .title {
      margin-bottom: calc(100vw * (38 / 1920));
      font-size: calc(100vw * (32 / 1920));
      font-weight: 700;
    }

    & .listTitle {
      font-weight: 700;
    }

    & .infoListWrap {
      display: flex;
      width: 100%;
      margin-bottom: calc(100vw * (39 / 1920));

      & .infoList {
        width: 50%;

        & span:first-child {
          margin-right: calc(100vw * (10 / 1920));
        }

        & span:last-child {
          margin-left: calc(100vw * (10 / 1920));
        }
      }
    }

    & .inputListWrap {
      width: 100%;
      margin-bottom: calc(100vw * (33 / 1920));

      & .inputList {
        &:not(:last-child) {
          margin-bottom: calc(100vw * (18 / 1920));
        }

        & .inputContainer {
          display: flex;
          align-items: center;
          justify-content: space-between;

          & .inputWrap {
            flex-shrink: 0;
            width: calc(100vw * (382 / 1920));
            height: calc(100vw * (37 / 1920));
            margin-left: calc(100vw * (12 / 1920));

            & input {
              font-size: calc(100vw * (17 / 1920));
              background-color: rgba(0, 0, 0, 0.5);
              border: 0;
            }
          }
        }
      }
    }

    & .errorMessage {
      width: calc(100vw * (382 / 1920));
      margin: 0 0 calc(100vw * (-18 / 1920)) auto;
      font-size: calc(100vw * (14 / 1920));
      color: #ff0000;
    }

    & .buttonContainer {
      display: flex;
      justify-content: center;
      gap: calc(100vw * (25 / 1920));

      & button {
        display: inline-flex;
        justify-content: center;
        align-items: center;
        width: calc(100vw * (163 / 1920));
        height: calc(100vw * (38 / 1920));
        font-size: calc(100vw * (20 / 1920));
        font-weight: 700;
        border-radius: calc(100vw * (7 / 1920));
      }

      & .updateButton {
        background-color: #008ad1;

        &:disabled {
          opacity: 0.5;
        }
      }

      & .cancelButton {
        background-color: #6a6a6a;
      }
    }
  }

  & .inner * {
    color: #ffffff;
  }

  & .overlay {
    width: 100%;
    height: 100%;
    background: rgba(34, 34, 34, 0.6);
  }
`;

export { Container };

export default { Container };
