import { useMemo, useRef, useState } from "react";
import Styles from "./styles";
import ImageList from "../ImageList";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import useIsBeautifulDndMount from "hooks/useIsBeautifulDndMount";
import { useMutation, useQueryClient } from "react-query";
import { api } from "service";
import useImageDoubleClickModalFunction from "hooks/useImageDoubleClickModalFunction";
import modal from "components/modal";
import { useLanguage } from "hooks";

export default function APTContainer({
  listData,
  filterInfo,
  abnormality,
  IMValue,
  notUseDoubleClickModal,
  notUseContextModal,
  isReport,
  activeReportSelectImage,
  setActiveReportSelectImage,
  onActiveImage,
}) {
  const [activeImageId, setActiveImageId] = useState(null);

  const lang = useLanguage("Report");

  const queryClient = useQueryClient();

  const { mutate: landmarkChangeMutate } = useMutation(
    (changeProps) =>
      api.image.putLandmarkChange({
        landmark: changeProps.landmark,
        imageId: changeProps.imageId,
        priority: changeProps.priority,
        sequence: changeProps.sequence,
        imageName: changeProps.imageName,
        path: changeProps.path,
      }),
    {
      async onMutate(variable) {
        await queryClient.cancelQueries(["image_list", filterInfo]);

        const previousImageList = queryClient.getQueryData([
          "image_list",
          filterInfo,
        ]);

        let targetValue = null;
        let resultList = [];
        let list = previousImageList;

        list = list.filter((value, index) => {
          // 모든 이미지와 잡은 이미지를 비교 해서 잡은 이미지만 제거된 전체 이미지 리스트
          // variable은 바뀐 이미지 정보 (랜드마크, sequenece)
          if (value.imageId === variable.imageId) {
            console.log(variable);
            targetValue = value;
            return false;
          }
          return true;
        });
        //(결함)이미지 드래그
        console.log("클릭한 이미지만 제거된 전체 이미지 리스트", list);

        let noneLandmarkList = list.filter(
          (value) => value.landmark != variable.landmark
        );
        let landmarkList = list.filter(
          (value) => value.landmark == variable.landmark
        );

        targetValue.sequence = variable.sequence;
        targetValue.landmark = variable.landmark;
        console.log("target", targetValue);

        // 바뀐 랜드마크 이미지 리스트
        landmarkList = [
          ...landmarkList.slice(0, variable.sequence),
          targetValue,
          ...landmarkList.slice(variable.sequence),
        ];

        console.log(landmarkList);

        resultList = noneLandmarkList.concat(landmarkList);
        queryClient.setQueryData(["image_list", filterInfo], resultList);

        return () =>
          queryClient.setQueryData(["image_list", filterInfo], resultList);
      },
      onSettled(response) {
        if (!response?.message) {
          throw new Error(response.error);
        }
      },
      onError(error, values, rollback) {
        if (rollback) rollback();
      },
    }
  );

  const handleSelectImage = (item) => {
    console.log("handleSelectImage", item);
    setActiveImageId(item.imageId);
    onActiveImage(item);
  }
  
  return (
    <>
      <Styles.Container>
        <div className="aptContainer">
          <div className="imgContainer">
            {listData.map((item, index) => {
              return (
                <div className={"imgList" + (item.imageId === activeImageId ? " on" : "")} key={item.imageId}>
                  <ImageList 
                    item={item} 
                    index={index} 
                    abnormality={abnormality} 
                    IMValue={IMValue} 
                    onSelectedImage={handleSelectImage} 
                  />
                </div>
                );
              })}
          </div>
        </div>
      </Styles.Container>
    </>
  );
}
