import { useEffect, useState } from "react";
import { common, layout } from "components";
import { TablePatients } from "./components";
import Styles from "./styles";
import { useNavigate } from "react-router-dom";
import { useModals, useLanguage } from "hooks";
import { useQuery } from "react-query";
import { api } from "service";

// img
import IconPlus from "resources/image/icon/icon_plus.svg";

export default function PatientsPage() {
  const modalOption = useModals();
  const navigate = useNavigate();
  const lang = useLanguage("Patient");

  const [pageList, setPageList] = useState([1, 2, 3, 4, 5]);
  const [page, setPage] = useState(1);
  const [maxPage, setMaxPage] = useState(1);
  const [maxCount, setMaxCount] = useState(0);
  const [tableData, setTableData] = useState([]);
  const pageSize = 10;

  const [filterInfo, setFilterInfo] = useState({
    page: 1,
    // pageSize: pageSize,
    // recordSize: 6,
    // type: "0",
    name: "",
  });

  const { isLoading, isError, data, error, refetch } = useQuery(
    ["patients_list", filterInfo],
    () => api.patient.getList(filterInfo),
    {
      onSuccess: (res) => {
        let e = JSON.parse(JSON.stringify(res));
        if (e.length < 1) {
          setPageList([1]);
          setPage(1);
          setMaxPage(1);
        } else {
          let start = 1;
          let end = e.totalPageCount;

          let list = [];

          for (let i = start; i <= end; i++) list.push(i);

          setPageList(list);
          setMaxPage(e.totalPageCount);
          setTableData(e.list);
        }
      },
      refetchOnWindowFocus: false,
    }
  );

  return (
    <Styles.Wrap>
      <common.Header
        title={"PATIENTS"}
        logout={true}
        search={true}
        modalOption={modalOption}
        type={"patients"}
      />
      <TablePatients item={tableData} />
      <div className="table_bar">
        <button type="button" className="connect">
          EMR connect
        </button>
        <layout.Pagination
          list={pageList}
          page={page}
          setPage={setPage}
          maxPage={maxPage}
          setPageList={setPageList}
          pageSize={pageSize}
          setFilterInfo={setFilterInfo}
        />
        <button
          type="button"
          className="add"
          onClick={() => {
            navigate("/patient/new");
          }}
        >
          <span className="iconWrap">
            <img src={IconPlus} alt="plus" />
          </span>
          {lang.getLang("생성")}
        </button>
      </div>
    </Styles.Wrap>
  );
}
