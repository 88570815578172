import { atom } from "jotai";

const configAtom = atom({
    "stomachView": true,
    "startLandmark": 4,
    "lesionMark": false,
    "withNormal": false,
    "isGIF": false,
    "abnormalSensitivity": 100,
    "imSensitivity": 36
});

configAtom.debugLabel = "configAtom";

export default configAtom;
