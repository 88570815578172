import { useState } from "react";
import { useNavigate } from "react-router-dom";
import ui from "components/ui";
import Styles from "./styles";
import { useLanguage } from "hooks";
import { useAuth } from "hooks/useAuth";

// img
import IconSearch from "resources/image/icon/icon_search.svg";
import IconHome from "resources/image/icon/icon_home.svg";

export default function Header({
  title,
  home,
  url,
  logout,
  search,
  modalOption,
  type,
  setFilterInfo,
}) {
  const navigate = useNavigate();
  const lang = useLanguage("Common");
  const authInfo = useAuth();
  const [searchWord, setSearchWord] = useState("");

  const onKeyUpEnter = (e) => {
    if (e.key == "Enter") {
      if (type == "admin") {
        setFilterInfo((e) => {
          e.name = searchWord;
          return { ...e };
        });
      } else {
        modalOption.setSearchPatientModal((e) => {
          e.show = true;
          e.searchWord = searchWord;
          return { ...e };
        });
      }
    }
  };

  return (
    <Styles.Container>
      {logout ? (
        <button
          type="button"
          className="logout"
          onClick={() => {
            authInfo.logout();
          }}
        >
          Logout
        </button>
      ) : home ? (
        <button
          type="button"
          className="home"
          onClick={() => {
            navigate(url);
          }}
        >
          <img src={IconHome} alt="home" />
        </button>
      ) : (
        <button
          type="button"
          className="logout"
          onClick={() => {
            // navigate(-1);
            navigate(url);
          }}
        >
          이전
        </button>
      )}

      <h2>{title}</h2>

      {search && (
        <div className="search">
          <ui.input.BasicInput
            isEnter={true}
            type={"text"}
            value={searchWord}
            setValue={setSearchWord}
            placeholder={lang.getLangString(
              window.location.pathname.includes("admin")
                ? "성명 또는 ID"
                : "성명을 기입해 주세요."
            )}
            keyUp={(e) => {
              onKeyUpEnter(e);
            }}
          />
          <button
            type="button"
            className="search_btn"
            onClick={() => {
              if (type == "admin") {
                modalOption.setSearchAdminModal((e) => {
                  e.show = true;
                  e.searchWord = searchWord;
                  return { ...e };
                });
              } else {
                modalOption.setSearchPatientModal((e) => {
                  e.show = true;
                  e.searchWord = searchWord;
                  return { ...e };
                });
              }
            }}
          >
            <span className="iconWrap">
              <img src={IconSearch} alt="search" />
            </span>

            {lang.getLang("검색")}
          </button>
        </div>
      )}
    </Styles.Container>
  );
}
