import Styles from "./styles";
import ui from "components/ui";
import { useState, useEffect } from "react";
import { TablePatients } from "pages/PatientsPage/components";
import { useLanguage } from "hooks";
import { useQuery } from "react-query";
import { api } from "service";

// img
import IconClose from "resources/image/icon/icon_close.svg";
import IconSearch from "resources/image/icon/icon_search.svg";
import utils from "utils";

export default function SearchPatientModal({ modalOption, modalClose }) {
  const lang = useLanguage("Patient");

  const [nameValue, setNameValue] = useState("");
  const [dateChange, setDateChange] = useState("");
  const [phoneValue, setPhoneValue] = useState("");
  const [tableData, setTableData] = useState([]);
  const [filterInfo, setFilterInfo] = useState({
    page: 1,
    // pageSize: pageSize,
    // recordSize: 6,
    // type: "0",
    name: "",
    phone: "",
    birthday: "",
  });

  useEffect(() => {
    if (modalOption) {
      setFilterInfo((f) => {
        f.name = modalOption?.searchWord;
        return { ...f };
      });
      getPatientList(true);
    }
  }, [modalOption]);

  const getPatientList = async (isInit) => {
    let res;

    // res = await api.patient.getList(filterInfo);

    if (isInit) {
      let obj = {
        page: 1,
        name: modalOption?.searchWord,
      };
      res = await api.patient.getList(obj);
    } else {
      res = await api.patient.getList(filterInfo);
    }

    if (res) {
      setTableData(JSON.parse(JSON.stringify(res.list)));
    } else {
      setTableData([]);
    }
  };

  const onKeyUpEnter = (e) => {
    if (e.key == "Enter") {
      getPatientList();
    }
  };

  return (
    <Styles.Container>
      <div
        className="overlay"
        onClick={() => {
          modalClose();
        }}
      ></div>

      <div className="inner">
        <div className="topContainer">
          <form>
            <ul className="inputListWrap">
              <li className="inputList">
                <p className="inputTitle">{lang.getLang("이름")}</p>
                <div className="inputContainer">
                  <ui.input.BasicInput
                    isEnter={true}
                    value={filterInfo.name}
                    setValue={(e) => {
                      setFilterInfo((f) => {
                        f.name = e;
                        return { ...f };
                      });
                    }}
                    placeholder={"성명을 기입해 주세요."}
                    keyUp={(e) => {
                      onKeyUpEnter(e);
                    }}
                  />
                </div>
              </li>

              <li className="inputList">
                <p className="inputTitle">{lang.getLang("생년월일")}</p>
                <div className="inputContainer birth">
                  <div className="calendarButtonContainer">
                    <ui.button.CalendarButton
                      dateChange={filterInfo.birthday}
                      setDateChange={(e) => {
                        setFilterInfo((f) => {
                          f.birthday = e;
                          return { ...f };
                        });
                      }}
                    />
                  </div>
                  <ui.input.BasicInput
                    value={utils.etc.numberToDate(filterInfo.birthday).resultText}
                    setValue={(e) => {
                      setFilterInfo((f) => {
                        f.birthday = e;
                        return { ...f };
                      });
                    }}
                    placeholder={"생년월일을 선택해 주세요."}
                  />
                </div>
              </li>

              <li className="inputList">
                <p className="inputTitle">{lang.getLang("휴대폰 번호")}</p>
                <div className="inputContainer">
                  <ui.input.BasicInput
                    isEnter={true}
                    value={filterInfo.phone}
                    setValue={(e) => {
                      setFilterInfo((f) => {
                        f.phone = e;
                        return { ...f };
                      });
                    }}
                    placeholder={"휴대폰 번호를 기입해 주세요."}
                    keyUp={(e) => {
                      onKeyUpEnter(e);
                    }}
                  />
                </div>

                <button
                  type="button"
                  className="searchButton"
                  onClick={() => {
                    getPatientList();
                  }}
                >
                  <span className="iconWrap">
                    <img src={IconSearch} alt="search" />
                  </span>
                </button>
              </li>
            </ul>
          </form>
        </div>

        <TablePatients item={tableData} modalClose={modalClose} />

        <button
          type="button"
          className="closeButton"
          onClick={() => {
            modalClose();
          }}
        >
          <img src={IconClose} alt="close" />
        </button>
      </div>
    </Styles.Container>
  );
}
