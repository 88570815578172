import { useAtom } from "jotai";
import { useParams } from "react-router-dom";
import { imageWithAutoPhotoCheckAtom } from "store/atoms";

const useImageFilterInfo = () => {
  const { id, studyId } = useParams();

  const [withAutoPhotoCheck, setWithAutoPhotoCheck] = useAtom(
    imageWithAutoPhotoCheckAtom
  );

  return {
    imageFilterInfo: {
      id: id ? id : 0,
      studyId: studyId ? studyId : 0,
      is_apt: withAutoPhotoCheck,
    },
    setWithAutoPhotoCheck,
  };
};

export default useImageFilterInfo;
