import styled from "styled-components";

const Container = styled.div`
  position: relative;
  width: 100%;
  height: 100%;

  & img {
    width: 100%;
    height: 100%;
  }

  & .stomachSection {
    position: relative;
    z-index:9;
  }

  & .stomachParts {
    & .stomachSectionDiv {
      position: absolute;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }

  & .stomachBaseImg {
    opacity: 1;
    transition: 0.2s;
    z-index:9;
    &.on {
      opacity: 1;
    }
  }
`;

const StomachSectionImg = styled.img`
  width: 100%;
  height: 100%;
  clip-path: polygon(${(props) => props.polygon});
  transform-origin: ${(props) => props.transformorigin};
  transition: 0.2s;
  opacity: ${(props) => (props.isSelected ? "1" : 0)};
  pointer-events: none;
`;

export { Container, StomachSectionImg };

export default { Container, StomachSectionImg };
