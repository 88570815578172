import styled from "styled-components";

const Container = styled.div`
  margin: calc(${(props)=>props.viewPort}vw * (28 / 1920)) 0 calc(${(props)=>props.viewPort}vw * (388 / 1920));

  & .titleContainer {
    margin-bottom: calc(${(props)=>props.viewPort}vw * (22 / 1920));
  }

  & .commentsListContainer {
    display: flex;
    margin-bottom: calc(${(props)=>props.viewPort}vw * (20 / 1920));
    background-color: #68727d;

    & .leftContainer {
      flex-shrink: 0;
      width: calc(${(props)=>props.viewPort}vw * (593 / 1920));

      & .listTitle {
        display: flex;
        align-items: center;
        padding: calc(${(props)=>props.viewPort}vw * (20 / 1920)) 0;
        margin: 0 calc(${(props)=>props.viewPort}vw * (147 / 1920)) calc(${(props)=>props.viewPort}vw * (13 / 1920))
          calc(${(props)=>props.viewPort}vw * (30 / 1920));
        font-size: calc(${(props)=>props.viewPort}vw * (40 / 1920));
        border-bottom: 1px solid #ffffff;

        & .iconWrap {
          width: calc(${(props)=>props.viewPort}vw * (31 / 1920));
          height: calc(${(props)=>props.viewPort}vw * (28 / 1920));
          margin-right: calc(${(props)=>props.viewPort}vw * (17 / 1920));
        }
      }

      & .commentsList {
        cursor: pointer;

        &.on .commentsTitle {
          background-color: #8b9aaa;
        }

        & .commentsTitle {
          display: flex;
          align-items: center;
          width: 100%;
          height: calc(${(props)=>props.viewPort}vw * (80 / 1920));
          padding: 0 calc(${(props)=>props.viewPort}vw * (28 / 1920));
          font-size: calc(${(props)=>props.viewPort}vw * (35 / 1920));

          & .iconWrap {
            display: inline-block;
            width: calc(${(props)=>props.viewPort}vw * (22 / 1920));
            height: calc(${(props)=>props.viewPort}vw * (22 / 1920));
            margin-right: calc(${(props)=>props.viewPort}vw * (28 / 1920));
          }
        }

        & .subCommentListWrap {
          max-height: calc(${(props)=>props.viewPort}vw * (543 / 1920));
          margin-left: calc(${(props)=>props.viewPort}vw * (40 / 1920));
          border-left: 1px solid #ffffff;
          overflow: auto;

          & .subCommentList {
            display: flex;
            align-items: center;
            height: calc(${(props)=>props.viewPort}vw * (74 / 1920));
            padding: 0 calc(${(props)=>props.viewPort}vw * (23 / 1920));
            font-size: calc(${(props)=>props.viewPort}vw * (30 / 1920));

            &.on {
              background-color: #8b9aaa;
            }
          }
        }
      }
    }

    & .rightContainer {
      flex-grow: 1;
      max-height: calc(${(props)=>props.viewPort}vw * (1047 / 1920));
      border-left: 1px solid #8b9aaa;
      overflow: auto;

      & .listTitle {
        display: flex;
        align-items: flex-end;
        width: calc(${(props)=>props.viewPort}vw * (438 / 1920));
        margin-bottom: calc(${(props)=>props.viewPort}vw * (43 / 1920));
        padding: calc(${(props)=>props.viewPort}vw * (36 / 1920)) 0 calc(${(props)=>props.viewPort}vw * (15 / 1920))
          calc(${(props)=>props.viewPort}vw * (17 / 1920));
        border-bottom: 1px solid #fff;

        & > div {
          display: flex;
          align-items: center;
        }

        & .leftTitle {
          position: relative;
          margin-right: calc(${(props)=>props.viewPort}vw * (20 / 1920));
          padding-right: calc(${(props)=>props.viewPort}vw * (20 / 1920));
          font-size: calc(${(props)=>props.viewPort}vw * (40 / 1920));

          &::after {
            position: absolute;
            top: 50%;
            right: 0;
            transform: translateY(-50%);
            width: calc(${(props)=>props.viewPort}vw * (2 / 1920));
            height: calc(${(props)=>props.viewPort}vw * (38 / 1920));
            background-color: #fff;
            content: "";
          }

          & .iconWrap {
            width: calc(${(props)=>props.viewPort}vw * (44 / 1920));
            height: calc(${(props)=>props.viewPort}vw * (39 / 1920));
            margin-right: calc(${(props)=>props.viewPort}vw * (10 / 1920));
          }
        }

        & .rightTitle {
          font-size: calc(${(props)=>props.viewPort}vw * (30 / 1920));

          & .iconWrap {
            width: calc(${(props)=>props.viewPort}vw * (28 / 1920));
            height: calc(${(props)=>props.viewPort}vw * (28 / 1920));
            margin-right: calc(${(props)=>props.viewPort}vw * (5 / 1920));
          }
        }
      }

      & .commentsTitle {
        display: flex;
        align-items: center;
        margin-bottom: calc(${(props)=>props.viewPort}vw * (30 / 1920));
        padding: 0 calc(${(props)=>props.viewPort}vw * (50 / 1920)) 0 calc(${(props)=>props.viewPort}vw * (17 / 1920));
        font-size: calc(${(props)=>props.viewPort}vw * (30 / 1920));
        font-weight: 700;

        & .iconWrap {
          display: inline-block;
          width: calc(${(props)=>props.viewPort}vw * (44 / 1920));
          height: calc(${(props)=>props.viewPort}vw * (39 / 1920));
          margin-right: calc(${(props)=>props.viewPort}vw * (10 / 1920));
        }

        & .closeButton {
          width: calc(${(props)=>props.viewPort}vw * (46 / 1920));
          height: calc(${(props)=>props.viewPort}vw * (46 / 1920));
          margin-left: auto;
        }
      }

      & .comment {
        font-size: calc(${(props)=>props.viewPort}vw * (30 / 1920));
        padding: 0 calc(${(props)=>props.viewPort}vw * (70 / 1920)) calc(${(props)=>props.viewPort}vw * (30 / 1920));
        line-height: 1.2;
      }
    }
  }

  & .commentsContainer {
    display: flex;
    flex-direction: column;
    height: calc(${(props)=>props.viewPort}vw * (717 / 1920));
    padding: calc(${(props)=>props.viewPort}vw * (17 / 1920)) calc(${(props)=>props.viewPort}vw * (27 / 1920));
    border: 1px solid #000000;
    overflow: auto;

    & .commentTitleContainer {
      display: flex;
      justify-content: space-between;
      flex-shrink: 0;
      margin-bottom: calc(${(props)=>props.viewPort}vw * (40 / 1920));
      font-size: calc(${(props)=>props.viewPort}vw * (31 / 1920));

      & .commentTitle {
        color: #68727d;
      }

      & .addCommentButton {
        display: flex;
        align-items: center;
        color: #68727d;

        & .iconWrap {
          display: inline-block;
          width: calc(${(props)=>props.viewPort}vw * (46 / 1920));
          height: calc(${(props)=>props.viewPort}vw * (46 / 1920));
          margin-right: calc(${(props)=>props.viewPort}vw * (2 / 1920));
        }
      }
    }

    & .commentListWrap {
      flex-grow: 1;
      overflow: auto;
    }
  }
`;

export { Container };

export default { Container };
