import styled from "styled-components";

const Container = styled.div`
  display: flex;
  justify-content: space-between;

  // https://velog.io/@brgndy/input-type-range-CSS-%EC%BB%A4%EC%8A%A4%ED%85%80-%ED%95%98%EA%B8%B0
  // https://velog.io/@071yoon/React-Volume-Slide-%EB%A7%8C%EB%93%A4%EA%B8%B0

  & .infoContainer {
    margin: calc(100vh * (20 / 1920)) 0 calc(100vw * (20 / 1920));
  }

  & .filterListWrap {
    display: flex;
    gap: calc(100vw * (31 / 1920));
    margin: calc(100vh * (20 / 1920)) 0 calc(100vw * (20 / 1920));

    & .filterList {
      width: calc(100vw * (173 / 1920));
    }

    & label {
      display: inline-block;
      margin-bottom: calc(100vw * (10 / 1920));
      font-size: calc(100vw * (15 / 1920));
      font-weight: 700;
      text-transform: capitalize;
    }

    & .inputContainer {
      position: relative;

      & .centerLine {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        display: inline-block;
        width: 3px;
        height: calc(100vw * (21 / 1920));
        background-color: #4682b4;
      }

      & input[type="range"] {
        // WebkitAppearance: "none", // 사용자 운영체제 테마에 기반한 플랫폼 고유 스타일
        width: 100%;
        height: calc(100vw * (6 / 1920));
        border-radius: calc(100vw * (20 / 1920));
        background-color: #848b99;

        // -webkit-은 chrome
        &::-webkit-slider-thumb {
          width: calc(100vw * (20 / 1920));
          height: calc(100vw * (20 / 1920));
          border-radius: 50%;
          background: #4682b4;
          -webkit-appearance: none;
          cursor: pointer; // grap, pointer, crosshair, e-resize
        }

        // focus 대신 active로 클릭했을 때만 강조되게 수정
        &:active {
          &::-webkit-slider-thumb {
            width: calc(150vw * (20 / 1920));
            height: calc(150vw * (20 / 1920));
            position: relative;
          }
          width: 100%;
          height: calc(100vw * (8 / 1920));
          background-color: #fff;
          // outline: solid;
          // border: solid;
          // color: #fff;
        }
        // &:focus {
        //   &::-webkit-slider-thumb {
        //     width: calc(150vw * (20 / 1920));
        //     height: calc(150vw * (20 / 1920));
        //     position: relative;
        //   }

        //   border: solid;
        //   color: #fff;
        //   outline: solid;
        // }

        &::-webkit-slider-track {
          background: #848b99;
        }
      }
    }
  }

  & img {
    width: 100%;
    height: 100%;
    object-fit: fill;
  }
`;

export { Container };

export default { Container };
