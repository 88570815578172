import styled from "styled-components";


const Container = styled.div`
  width: 100%;

  & .btn_box {display: flex; align-items: center; justify-content: space-between;
    width: 100%; margin-top: calc(100vw*(80/1920)); padding: calc(100vw*(50/1920));}
  & .btn_box img {width: calc(100vw*(204/1920));}
  & .btn_list {display: flex; align-items: center; gap: calc(100vw*(14/1920));}
  & .btn_list button {width: calc(100vw*(140/1920)); height: calc(100vw*(44/1920));
    font-size: calc(100vw*(24/1920)); color: #fff; border-radius: calc(100vw*(10/1920)); font-weight: 700;}
  & .cancel_btn {background: #999;}
  & .print_btn {background: #333;}
`;

const PrintWrap = styled.div`
  position: relative;
  min-height: 100vh;
  background-color: #fff;

  & .inner {padding: 0 calc(100vw*(50/1920)); background-color: #fff;}
  & .prview_title {font-size: calc(100vw*(70/1920)); text-align: center; color: #000;
    padding: calc(100vw*(136/1920)) 0; background-color: #fff;}
  & .row {width: 100%; padding-top: calc(100vw*(60/1920)); padding-bottom: calc(100vw*(100/1920));}
  & .box {width: 100%; border: 5px solid #68727D;}
  & .comment_box {display: flex; flex-direction: column; gap: calc(100vw*(40/1920));
    padding: calc(100vw*(68/1920)) calc(100vw*(28/1920));}
  & .comment {position: relative; font-size: calc(100vw*(35/1920)); padding-left: calc(100vw*(45/1920)); color: #000; line-height: 1.4;}
  & .comment::before {content: ''; position: absolute; top: calc(100vw*(16/1920)); left: calc(100vw*(16/1920)); display: block;
    width: calc(100vw*(7/1920)); height: calc(100vw*(7/1920)); border-radius: 50%; background: #000;}

  .inner {
    break-after: page;
  }

  .inner + .inner {
    margin-top:40px;
  }

  @media print {
    .inner + .inner {
      margin-top: 0;
    }
  }

  @page {
    size: A4;
    margin: 5mm;
  }
`;

export { Container, PrintWrap };

export default { Container, PrintWrap };
