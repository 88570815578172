import { useConfig } from "hooks/useConfig";
import Styles from "./styles";
import { api } from "service";

// img
import IconLoading from "resources/image/icon/icon_loading.svg";
import IconLoadingGif from "resources/image/gif/process_loading.gif";
import { useLanguage } from "hooks";
import { useState, useMemo, useEffect} from "react";
import { useMutation, useQuery } from "react-query";

import modal from "..";

export default function UploadFileModal({ modalOption, modalClose }) {

  const lang = useLanguage("Checkup");
  const { data } = modalOption;
  const configInfo = useConfig();

  const [modalTitle, setModalTitle] = useState("");
  const [modalSubTitle, setModalSubTitle] = useState("");
  const [iconLoading, setIconLoading] = useState(false);
  const [loading, setLoading] = useState(false);

  const { mutate: uploadMutate } = useMutation((data) => api.studies.uploadFiles(data), {

    async onMutate() {
      setModalTitle(lang.getLang("현재 AI가 내시경 검진 결과를 분석중입니다"));
      setModalSubTitle(lang.getLang("추론이 완료될 때까지 기다려주세요"));
      setLoading(true);
      setIconLoading(true);
    }
    ,
    onSuccess: (data) => {
      if (data.message) {
        modalOption.onModalClose();
        setLoading(false);
        modalClose();
      } else {
        setIconLoading(false);
        setModalTitle(lang.getLang("AI 분석 중 오류가 발생했습니다"));
        setModalSubTitle(lang.getLang(data.error));
      }
    }
  });

  useEffect(() => {
    if(modalOption.show){
      setLoading(true);
    }
  }
  ,[modalOption.show]);

  useEffect(() => {
    if(loading){
      uploadMutate(data);
    }
  }
  ,[loading]);

  // const { isLoading, isError, isSuccess } = useQuery(
  //   ["upload-file", data],
  //   () => api.studies.uploadFiles(data),
  //   {
  //     refetchOnWindowFocus: true,
  //   }
  // );

  // useEffect(() => {
  //   if(modalOption.show){
  //     setLoading(true);
  //   }
  // }
  // ,[modalOption.show]);

  // useEffect(() => {
  //   if(isError){
  //     console.log('isError', isError);
  //     modalOption.onModalClose();
  //     setLoading(false);
  //     modalClose();
  //   }
  // }
  // ,[isError]);

  // useEffect(() => {
  //   if(isSuccess){
  //     console.log('isSuccess', isSuccess);
  //     modalOption.onModalClose();
  //     setLoading(false);
  //     modalClose();
  //   }
  // }
  // ,[isSuccess]);


  return (
    <Styles.Container>
      { loading && loading === true ? ( 
        
      <div>
              <div
        className="overlay"
        onClick={() => {
          modalClose();
        }}
      ></div>

      <div className="inner">
      <p className="title">{lang.getLang(modalTitle)}</p>
      <p className="subTitle">{lang.getLang(modalSubTitle)}</p>
      <div className="loadingIconWrap">
        {iconLoading ? 
          <img src={configInfo.configData.isGIF ?  IconLoadingGif : IconLoading} alt="IconLoading" />
          : null }
      </div>
        <button
          type="button"
          onClick={() => {
            modalClose();
          }}
        >
            {lang.getLang("취소")}
        </button>
      </div>
      </div> 
      ) : null }

    </Styles.Container>
  );
}
