import { useState } from "react";
import { Container } from "./styles";
import { useLanguage } from "hooks";
import { api } from "service";
import { useQuery } from "react-query";

export default function UserDataList({
  select,
  patientsData,
  id,
  studyId,
  navigate,
  type,
}) {
  const lang = useLanguage("Image");

  const [isDateSelect, setIsDateSelect] = useState(false);
  const { data: settingsData } = useQuery(
    ["settings"],
    api.setting.getSetting,
    { refetchOnWindowFocus: false }
  );

  const onClickRecodeDate = (value) => {
    navigate(
      `/image/${
        settingsData.stomachView == true ? "stomach" : "detail"
      }/${id}/${value}`
    );
    // window.location.reload();
    setIsDateSelect(false);
  };

  return (
    <Container>
      <p className="patientName">{patientsData.name}</p>

      <div className="dateContainer">
        <p className="birthDate">
          <span className="dateTitle">{lang.getLang("생년월일")}</span>
          <span className="date">{patientsData.birthday}</span>
        </p>
        <div className={"checkUpDate" + (isDateSelect ? " on" : "")}>
          <p
            onClick={() => {
              select && setIsDateSelect(!isDateSelect);
            }}
          >
            <span className="dateTitle">{lang.getLang("검진일")}</span>
            <span className={"date" + (select ? " on" : "")}>
              {patientsData?.studies?.map((item) => {
                return studyId == item.studyId && item.studyDate.split(" ")[0];
              })}
            </span>
          </p>

          {select && isDateSelect && (
            <div className="optionListContainer">
              <ul className="optionListWrap">
                {patientsData?.studies?.map((item, index) => {
                  return (
                    <li
                      className="optionList"
                      key={"optionList" + index}
                      onClick={() => {
                        onClickRecodeDate(item.studyId);
                      }}
                    >
                      {item.studyDate.split(" ")[0]}
                    </li>
                  );
                })}
              </ul>
            </div>
          )}
        </div>
      </div>
    </Container>
  );
}
