const lang = {
  "최근 촬영본": {
    _en: "Recent",
  },
  "정상 이미지": {
    _en: "Normal",
  },
  식도: {
    _en: "Esophagus",
  },
  "위식도 접합부": {
    _en: "Esophagogastric junction",
  },
  "십이지장 2부": {
    _en: "Duodenum 2nd portion",
  },
  "십이지장 구부": {
    _en: "Duodenum bulb",
  },
  "위 전정부": {
    _en: "Antrum",
  },
  "위 각부": {
    _en: "Angle",
  },
  "위체부(하-중부)": {
    _en: "Body, LB-MB",
  },
  "위체부(중-상부)": {
    _en: "Body, MB-HB",
  },
  "위저부(반전)": {
    _en: "Retroflexion fundus",
  },
  "위 분문부(반전)": {
    _en: "Reftroflexion cardia",
  },
  "위체부(반전)": {
    _en: "Retroflexion body",
  },
  "해당 위 부위의 최근 촬영본이 없습니다.": {
    _en: (
      <>
        No recent images available <br /> for the stomach areas.
      </>
    ),
  },
  "해당 위 부위의 과거 촬영본이 없습니다.": {
    _en: (
      <>
        No past images available <br /> for the stomach areas.
      </>
    ),
  },
  "해당 검진일의 위 부위 촬영본이 없습니다.": {
    _en: (
      <>
        No images available <br /> for the stomach areas.
      </>
    ),
  },
};

export default lang;
