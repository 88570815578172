import { calculateViewBox } from "utils/svg";
import { AbsoluteSVG } from "./styles";

const Rectangle = ({
  data,
  width,
  height,
  strokeColor = "white",
  strokeWidth = 2,
}) => {

  const tl = data[0];
  const br = data[1];

  const x1 = tl[0];
  const y1 = tl[1];
  const x2 = br[0];
  const y2 = br[1];

  const rx = (x2 - x1) / 8.0;
  const ry = (y2 - y1) / 8.0;
  const d = (x2 - x1) / 8.0;

  return (
    <AbsoluteSVG
      xmlns="http://www.w3.org/2000/svg"
      viewBox={`0 0 ${width} ${height}`}
    >
      <line
        x1={x1 + rx}
        y1={y1}
        x2={x1 + rx + d}
        y2={y1}
        stroke={strokeColor}
        strokeWidth={strokeWidth}
      />

      <line
        x1={x1}
        y1={y1 + ry}
        x2={x1}
        y2={y1 + ry + d}
        stroke={strokeColor}
        strokeWidth={strokeWidth}
      />

      <line
        x1={x2 - rx}
        y1={y1}
        x2={x2 - rx - d}
        y2={y1}
        stroke={strokeColor}
        strokeWidth={strokeWidth}
      />

      <line
        x1={x2}
        y1={y1 + ry}
        x2={x2}
        y2={y1 + ry + d}
        stroke={strokeColor}
        strokeWidth={strokeWidth}
      />

      <line
        x1={x1 + rx}
        y1={y2}
        x2={x1 + rx + d}
        y2={y2}
        stroke={strokeColor}
        strokeWidth={strokeWidth}
      />

      <line
        x1={x2 - rx}
        y1={y2}
        x2={x2 - rx - d}
        y2={y2}
        stroke={strokeColor}
        strokeWidth={strokeWidth}
      />

      <line
        x1={x1}
        y1={y2 - ry}
        x2={x1}
        y2={y2 - ry - d}
        stroke={strokeColor}
        strokeWidth={strokeWidth}
      />

      <line
        x1={x2}
        y1={y2 - ry}
        x2={x2}
        y2={y2 - ry - d}
        stroke={strokeColor}
        strokeWidth={strokeWidth}
      />

      <path
        d={`M ${x1 + rx},${y1} A ${rx},${ry} 0 0 0 ${x1},${y1 + ry}`}
        fill="none"
        stroke={strokeColor}
        strokeWidth={strokeWidth}
      />

      <path
        d={`M ${x2 - rx},${y1} A ${rx},${ry} 0 0 1 ${x2},${y1 + ry}`}
        fill="none"
        stroke={strokeColor}
        strokeWidth={strokeWidth}
      />

      <path
        d={`M ${x2 - rx},${y2} A ${rx},${ry} 0 0 0 ${x2},${y2 - ry}`}
        fill="none"
        stroke={strokeColor}
        strokeWidth={strokeWidth}
      />

      <path
        d={`M ${x1 + rx},${y2} A ${rx},${ry} 0 0 1 ${x1},${y2 - ry}`}
        fill="none"
        stroke={strokeColor}
        strokeWidth={strokeWidth}
      />
    </AbsoluteSVG>
  );
}

const Polygon = ({
  data,
  strokeColor = "white",
  strokeWidth = 2,
  width,
  height,
}) => {
  const points = data.map(([x, y]) => `${x},${y}`).join(" ");
  const viewBox = calculateViewBox(data);


  return (
    <AbsoluteSVG
      xmlns="http://www.w3.org/2000/svg"
      viewBox={`0 0 ${width} ${height}`}
    >
      <polygon
        points={points}
        fill="none"
        stroke={strokeColor}
        strokeWidth={strokeWidth}
      />
    </AbsoluteSVG>
  );
};
const Path = ({ data, strokeColor = "white", strokeWidth = 2 }) => {
  const pathData = data
    .map(([x, y], index) => {
      if (index === 0) {
        // 시작점
        return `M ${x},${y}`;
      } else {
        // 직선
        return `L ${x},${y}`;
      }
    })
    .join(" ");

  const viewBox = calculateViewBox(data);

  return (
    <AbsoluteSVG xmlns="http://www.w3.org/2000/svg" viewBox={viewBox}>
      <path
        d={pathData}
        fill="none"
        stroke={strokeColor}
        strokeWidth={strokeWidth}
      />
    </AbsoluteSVG>
  );
};

const SVG = {};

SVG.Polygon = Polygon;
SVG.Rectangle = Rectangle;
SVG.Path = Path;

export default SVG;
