import styled from "styled-components";

// const Container = styled.div`
//   position: relative;

//   & .scoreContainer {
//     position: absolute;
//     top: 50%;
//     left: 50%;
//     transform: translate(-50%, -50%);
//     text-align: center;
//     z-index: 1;

//     & .gifTitle {
//       margin-bottom: calc(100vw * (19 / 1920));
//       font-size: calc(100vw * (28 / 1920));
//       font-weight: 700;
//       line-height: 1.17;
//       text-transform: uppercase;
//     }

//     & .score {
//       font-size: calc(100vw * (75 / 1920));

//       & span {
//         font-size: calc(100vw * (39 / 1920));
//         vertical-align: super;
//       }
//     }
//   }

//   & .gifWrap {
//     /* width: calc(100vw * (275 / 1920));
//     height: calc(100vw * (275 / 1920)); */
//     /* width: calc(100vw * (230 / 1920));
//     height: calc(100vw * (230 / 1920)); */
//     width: calc(100vw * (${(props) => {
//       if (props.paddingSize === "small") {
//         return 230;
//       } else if (props.paddingSize === "midium") {
//         return 253;
//       } else {
//         return 275
//       }
//     }} / 1920));
//     height: calc(100vw * (${(props) => {
//       if (props.paddingSize === "small") {
//         return 230;
//       } else if (props.paddingSize === "midium") {
//         return 253;
//       } else {
//         return 275
//       }
//     }} / 1920));
    
//   }

//   &.small {
//     & .scoreContainer {
//       & .gifTitle {
//         margin-bottom: calc(100vw * (-5 / 1920));
//         font-size: calc(100vw * (20 / 1920));
//       }

//       & .score {
//         font-size: calc(100vw * (50 / 1920));

//         & span {
//           font-size: calc(100vw * (39 / 1920));
//           vertical-align: middle;
//         }
//       }
//     }

//     & .gifWrap {
//       width: calc(100vw * (120 / 1920));
//       height: calc(100vw * (120 / 1920));
//     }
//   }

//   &.big {
//     & .scoreContainer {
//       & .gifTitle {
//         margin-bottom: calc(100vw * (22 / 1920));
//         font-size: calc(100vw * (32 / 1920));
//       }

//       & .score {
//         font-size: calc(100vw * (100 / 1920));

//         & span {
//           font-size: calc(100vw * (39 / 1920));
//         }
//       }
//     }

//     & .gifWrap {
//       width: calc(100vw * (356 / 1920));
//       height: calc(100vw * (356 / 1920));
//     }
//   }
// `;

const Container = styled.div`
  position: relative;

  & .animationContainer {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
    z-index: 1;

    & .gifTitle {
      font-size: calc(${(props)=>props.viewPort}vw * (28 / 1920));
      font-weight: 700;
      line-height: 1.17;
      text-transform: uppercase;
    }
  }

  & .gifWrap {
    width: calc(${(props)=>{return (props.viewPort?props.viewPort:100)}}vw * (230 / 1920));
    height: calc(${(props) => { return (props.viewPort ? props.viewPort : 100) }}vw * (230 / 1920));
    & img {
      border-radius: 50%;
    }
  }

  &.small {
    & .animationContainer {
      & .gifTitle {
        margin-bottom: calc(${(props)=>{return (props.viewPort?props.viewPort:100)}}vw * (-5 / 1920));
        font-size: calc(${(props)=>{return (props.viewPort?props.viewPort:100)}}vw * (20 / 1920));
      }
    }

    & .gifWrap {
      width: calc(${(props)=>{return (props.viewPort?props.viewPort:100)}}vw * (120 / 1920));
      height: calc(${(props) => { return (props.viewPort ? props.viewPort : 100) }}vw * (120 / 1920));
      & img {
        border-radius: 50%;
      }
    }
  }

  &.big {
    & .animationContainer {
      & .gifTitle {
        margin-bottom: calc(${(props)=>{return (props.viewPort?props.viewPort:100)}}vw * (22 / 1920));
        font-size: calc(${(props)=>{return (props.viewPort?props.viewPort:100)}}vw * (32 / 1920));
      }
    }

    & .gifWrap {
      width: calc(${(props)=>{return (props.viewPort?props.viewPort:100)}}vw * (356 / 1920));
      height: calc(${(props)=>{return (props.viewPort?props.viewPort:100)}}vw * (356 / 1920));
    }
  }
`;

export { Container };

export default { Container };
