import styled from "styled-components";

const Container = styled.div`
  width: 100%;
  height: 100%;

  & .layerGroup {
    position: relative;
    width: 100%;
    height: 100%;
  }

  & .layer {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

    & canvas {
      display: block;
    }
  }
`;

export { Container };

export default { Container };
