import Styles from "./styles";
import { layout } from "components";
import { getCookie } from "service/connector";
import useImageDoubleClickModalFunction from "hooks/useImageDoubleClickModalFunction";
import { useState } from "react";
import modal from "components/modal";
import { DwvComponent } from "components/layout";

// 환자 검색해서 환자 검진결과 하나 눌렀을때 나오는 환자의 검진 디테일 이미지 목록을 감싸는 컨테이터

export default function ImageContainer({
  listData,
  abnormality,
  IMValue,
  id,
  studyId,
}) {
  // console.log("imagepage img Container scope : ", getCookie("scope"));
  const { isActiveWithImageId, hybridClickImage } =
    useImageDoubleClickModalFunction();

  const [activeContextImageId, setActiveContextImageId] = useState(null);

  const [xy, setXY] = useState({ x: 0, y: 0 });

  const handleContextMenu = (e) => {
    setXY({ x: e.clientX, y: e.clientY });
  };

  const activeContextImage = listData.find(
    (item) => item.imageId === activeContextImageId
  );

  return (
    <Styles.Container>
      <ul className="imgListWrap">
        {listData?.map((item, index) => {
          if (item.priority > 0) return <></>;

          const isActive = isActiveWithImageId(item.imageId);
          return (
            <li
              key={item.imageId}
              className={"test imgList" + (isActive ? " on" : "")}
              onClick={(event) => {
                hybridClickImage(event, item, id);
              }}
            >
              <layout.ImageList
                id={id}
                studyId={studyId}
                item={item}
                index={index}
                abnormality={abnormality}
                IMValue={IMValue}
                activeContextImageId={activeContextImageId}
                setActiveContextImageId={setActiveContextImageId}
                handleContextMenu={handleContextMenu}
              />
            </li>
          );
        })}
      </ul>
      {activeContextImage && (
        <modal.ContextMenuModal
          priority={activeContextImage.priority}
          imageId={activeContextImage.imageId}
          isModalOpen={true}
          onClickOutSide={() => setActiveContextImageId(null)}
          lesion={activeContextImage.lesion}
          path={activeContextImage.path}
          landmark={activeContextImage.landmark}
          imageName={activeContextImage.imageName}
          xy={xy}
          setXY={setXY}
        />
      )}
    </Styles.Container>
  );
}
