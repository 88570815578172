import styled from "styled-components";

// img
import IconBack from "resources/image/icon/icon_back.svg";
import IconSearch from "resources/image/icon/icon_search.svg";


const Container = styled.div`
  position: fixed;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: calc(100vw * (1856 / 1920));
  height: calc(100vw * (100 / 1920));
  padding-top: calc(100vw * (18 / 1920));
  border-bottom: calc(100vw * (3 / 1920)) solid #ffffff;
  z-index: 2;
  background-color: #000000;

  & img {
    width: 100%;
    height: 100%;
    object-fit: fill;
  }

  & .logout {
    font-size: calc(100vw * (26 / 1920));
    font-weight: bold;
    text-align: center;
    background: url(${IconBack}) no-repeat center left/calc(100vw * (28/1920));
    padding-left: calc(100vw * (42 / 1920));
  }

  & .home {
    width: calc(100vw * (42 / 1920));
    height: calc(100vw * (35 / 1920));

    & img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  & h2 {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-size: calc(100vw * (32 / 1920));
    font-weight: bold;
  }
  & .search {
    position: relative;
    width: calc(100vw * (390 / 1920));
    height: calc(100vw * (60 / 1920));
  }
  & .search input {
    font-size: calc(100vw * (24 / 1920));
    padding-right: calc(100vw * (144 / 1920));
  }
  & .search_btn {
    position: absolute;
    top: calc(100vw * (6 / 1920));
    right: calc(100vw * (6 / 1920));
    display: flex;
    justify-content: center;
    align-items: center;
    width: calc(100vw * (130 / 1920));
    height: calc(100vw * (48 / 1920));
    font-size: calc(100vw * (25 / 1920));
    background-color: #ffffff;
    color: #000000;
    border-radius: calc(100vw * (6 / 1920));
    font-weight: bold;

    & .iconWrap {
      display: inline-block;
      width: calc(100vw * (30 / 1920));
      height: calc(100vw * (30 / 1920));
      margin-right: calc(100vw * (10 / 1920));
    }
  }
`;

export { Container };

export default { Container };
