import Styles from "./styles";
import PicturesTitle from "../PicturesTitle";
import { common, layout } from "components";
import { useLanguage } from "hooks";

// img
import { useParams } from "react-router-dom";
import { useQuery } from "react-query";
import { api } from "service";
import {
  formatHeatmapWithPath,
  formatImageWithPath,
  formatNormalImageWithLandmark,
  sortHistoryObjectToArray,
} from "utils/common";
import moment from "moment";

export default function HistorySection() {
  const lang = useLanguage("Report");
  const { id, studyId } = useParams();

  const { data: reportsHistoryData = {}, isLoading: isLoadingReportsHistory } =
    useQuery(
      ["reports-images", studyId, "history"],
      () =>
        api.reports.getReportsImages({
          medicalstudyId: studyId,
          check: "history",
        }),
      {
        refetchOnWindowFocus: false,
      }
    );

  const [recentlyImageData, prevImageData] =
    sortHistoryObjectToArray(reportsHistoryData);

  if (isLoadingReportsHistory) return <></>;

  const landmark = recentlyImageData?.content.landmark;
  const path = recentlyImageData?.content.path;
  const date = recentlyImageData?.date;
  // data
  const stomachListData = [
    lang.getLang("식도"),
    lang.getLang("위식도 접합부"),
    lang.getLang("십이지장 2부"),
    lang.getLang("십이지장 구부"),
    lang.getLang("위 전정부"),
    lang.getLang("위 각부"),
    lang.getLang("위체부(하-중부)"),
    lang.getLang("위체부(중-상부)"),
    lang.getLang("위저부(반전)"),
    lang.getLang("위 분문부(반전)"),
    lang.getLang("위체부(반전)"),
  ];

  const createRecentlyImageData = {
    img: formatImageWithPath(path),
    text: `${moment(date).format("YYYY.MM.DD")}`,
  };
  const presetImageListData = [createRecentlyImageData];
  if (prevImageData) {
    presetImageListData.push({
      img: formatImageWithPath(prevImageData?.content.path),
      text: moment(prevImageData?.date).isValid() ? moment(prevImageData?.date ).format("YYYY.MM.DD") : lang.getLang("X"),
    });
  }
  const imgListData = [
    ...presetImageListData,
    {
      img: formatNormalImageWithLandmark(landmark),
      text: lang.getLang("Normal"),
    },
  ];

  return (
    <Styles.Container>
      <div className="wrap">
        <div className="leftContainer">
          <div className="numberIconContainer">
            <common.NumberIcon number={landmark} />

            <p className="landmarkName">{stomachListData[landmark]}</p>
          </div>

          <div className="stomachWrap">
            <layout.NoHoverStomachReport
              selectedSection={landmark}
              notSelectedSection={[]}
              opacityBg
              noClickEvent
            />
          </div>
        </div>

        <div className="rightContainer">
          {imgListData?.map((item) => {
            return (
              <div className="imgContainer" key={"imgContainer" + item.img}>
                <div className="imgWrap">
                  <img src={item.img} alt="stomach" />
                </div>

                <p className="text">{item.text}</p>
              </div>
            );
          })}
        </div>
      </div>
    </Styles.Container>
  );
}
