import styled from "styled-components";

const Container = styled.div`
  position: relative;
  color: #000000;
  /* height: calc(100vw * (175 / 1920)); */
  margin-bottom: calc(100vw * (40 / 1920));
  padding-left: calc(100vw * (40 / 1920));

  &::before {
    position: absolute;
    top: 0;
    left: calc(100vw * (10 / 1920));
    font-size: calc(100vw * (30 / 1920));
    content: "•";
  }

  & textarea {
    width: 100%;
    height: 100%;
    /* font-size: calc(100vw * (30 / 1920)); */
    font-size: calc(${(props)=>props.viewPort}vw * (31 / 1920));
    color: #000000;
    line-height: 1.2;
  }
`;

export { Container };

export default { Container };
