import PatientInfoTable from "./PatientInfoTable";
import CommentsSection from "./CommentsSection";
import PicturesTitle from "./PicturesTitle";
import LandmarkSection from "./LandmarkSection";
import IMSection from "./IMSection";
import AbnormalitySection from "./AbnormalitySection";
import HistorySection from "./HistorySection";
import SelectSection from "./SelectSection";

export {
  PatientInfoTable,
  CommentsSection,
  PicturesTitle,
  LandmarkSection,
  IMSection,
  AbnormalitySection,
  HistorySection,
  SelectSection,
};

export default {
  PatientInfoTable,
  CommentsSection,
  PicturesTitle,
  LandmarkSection,
  IMSection,
  AbnormalitySection,
  HistorySection,
  SelectSection,
};
