import Styles from "./styles";
import { useState } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

// img
import IconCalendar from "resources/image/icon/icon_calendar.svg";
import utils from "utils";

function CalendarButton({ dateChange, setDateChange }) {
  const [startDate, setStartDate] = useState(new Date());
  // const year = startDate.getFullYear();
  // const month = startDate.getMonth() + 1;
  // const date = startDate.getDate();
  // const selectDate = ;

  return (
    <Styles.Container className="calendarButtonComponent">
      <img src={IconCalendar} alt="calendar" />
      <DatePicker
        selected={startDate}
        dateFormat="yyyy-MM-dd"
        showMonthDropdown
        showYearDropdown
        dropdownMode="select"
        scrollableYearDropdown
        onChange={(date) => {
          setStartDate(date);
          setDateChange(utils.date.getDateType(date, "-"));
        }}
      />
    </Styles.Container>
  );
}

export { CalendarButton };

export default CalendarButton;
