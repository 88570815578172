import styled from "styled-components";

const Container = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: 15;

  & .inner {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    display: flex;
    flex-direction: column;
    align-items: center;
    width: calc(100vw * (1853 / 1920));
    height: 80vh;
    background: #292929;
    border-radius: calc(100vw * (20 / 1920));

    & .topContainer {
      width: 100%;
      padding: calc(100vw * (36 / 1920)) calc(100vw * (53 / 1920))
        calc(100vw * (50 / 1920));
      border-bottom: calc(100vw * (3 / 1920)) solid #fff;

      & .inputListWrap {
        display: flex;
        align-items: center;

        & .inputList {
          display: flex;
          align-items: center;

          &:not(:last-child) {
            margin-right: calc(100vw * (35 / 1920));
          }

          & .inputTitle {
            margin-right: calc(100vw * (20 / 1920));
            font-size: calc(100vw * (26 / 1920));
            font-weight: 700;
          }

          & .inputContainer {
            width: calc(100vw * (324 / 1920));
            height: calc(100vw * (48 / 1920));

            & input {
              font-size: calc(100vw * (24 / 1920));
            }

            &.birth {
              display: flex;
              align-items: center;

              & .calendarButtonContainer {
                color: inherit;
                margin-right: calc(100vw * (14 / 1920));
              }
            }
          }

          & .searchButton {
            display: flex;
            justify-content: center;
            align-items: center;
            width: calc(100vw * (59 / 1920));
            height: calc(100vw * (48 / 1920));
            margin-left: calc(100vw * (19 / 1920));
            background: #ffffff;
            border-radius: calc(100vw * (7 / 1920));

            & .iconWrap {
              width: calc(100vw * (30 / 1920));
              height: calc(100vw * (30 / 1920));

              & img {
                display: block;
                width: 100%;
              }
            }
          }
        }
      }
    }

    & .list_box {
      height: calc(80vh - calc(100vw * (202 / 1920)));
    }

    & .closeButton {
      position: absolute;
      top: calc(100vw * (5 / 1920));
      right: calc(100vw * (8 / 1920));
      width: calc(100vw * (35 / 1920));
      height: calc(100vw * (35 / 1920));

      & img {
        display: block;
        width: 100%;
      }
    }
  }

  & .inner {
    color: #ffffff;
  }

  & .overlay {
    width: 100%;
    height: 100%;
    background: rgba(34, 34, 34, 0.6);
  }
`;

export { Container };

export default { Container };
