import styled from "styled-components";

// img
import IconArrowBottom from "resources/image/icon/icon_arrow_bottom_big.svg";
import IconArrowTop from "resources/image/icon/icon_arrow_top_big.svg";
import IconArrowLeft from "resources/image/icon/icon_arrow_left_big.svg";
import IconArrowRight from "resources/image/icon/icon_arrow_right_big.svg";

const Container = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;

  & img {
    width: 100%;
    height: 100%;
    object-fit: fill;
    // height: auto; /* 상하의 공백을 줄이기 위해 height를 auto로 설정 */
    // object-fit: cover;
    border-radius: calc(100vw * (9 / 1920)); //정상 이미지 동그랗게 만들기
  }

  & .sliderContainer {
    // background-color: white;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: calc(100vw * (130 / 1920));
    height: calc(100vw * (650 / 1920));
    overflow: hidden;

    &.historyPage {
      gap: calc(100vw * (200 / 1920));
    }

    & .title {
      position: absolute;
      left: 50%;
      width: max-content;
      font-size: calc(100vw * (25 / 1920));
      z-index: 2;
    }

    & .swiperContainer {
      position: relative;

      // 가운데 이미지
      &.sub {
        position: absolute;
        top: 0%;
        left: 50%;
        transform: translateX(-50%);
        width: calc(100vw * (670 / 1920));
        overflow: hidden;

        // 좌우이동 버튼
        & .swiper-button-prev,
        .swiper-button-next {
          top: 50%;
          transform: translateY(-50%);
          width: calc(100vw * (130 / 1920));
          height: calc(100vw * (130 / 1920));
        }

        & .swiper-button-prev {
          left: calc(100vw * (-22 / 1920));
          background: url(${IconArrowLeft}) no-repeat center / cover;
          // background: translate3d(0px, -10px, -300px) rotateX(-65deg)
          //   rotateY(0deg) scale(1);
          // z-index: 0;
        }

        & .swiper-button-next {
          left: auto;
          right: calc(100vw * (-22 / 1920));
          background: url(${IconArrowRight}) no-repeat center / cover;
        }
      }

      & .title {
        top: calc(50% - calc(100vw * (240 / 1920)));
        transform: translate(-50%, -50%);
      }
    }

    & .swiper {
      overflow: visible;

      & .slider {
        box-shadow: none;
        opacity: 0.5;
        transition: 0.3s;

        & img {
          border-radius: calc(
            100vw * (9 / 1920)
          ); // 최근촬영본, 과거 촬영본 이미지 동그랗게 만들기
        }

        &.emptySlider {
          display: flex;
          justify-content: center;
          align-items: center;
          font-size: calc(100vw * (25 / 1920));
          text-align: center;
          background-color: #292929;
        }
      }

      & .swiper-slide-active {
        opacity: 1;
      }

      // 최근 촬영본 상하 버튼
      &.recentSwiper {
        height: calc(100vw * (620 / 1920));
      }

      &.pastSwiper {
        height: calc(100vw * (620 / 1920));
      }
    }

    // 최근/ 과거 촬영본 크기 조정인듯?
    & .swiper-button-prev,
    .swiper-button-next {
      position: absolute;
      left: 50%;
      width: calc(100vw * (110 / 1920));
      height: calc(100vw * (60 / 1920));
      margin-top: 0;
      transform: translateX(-50%);
      z-index: 1;
      cursor: pointer;

      &::after {
        width: 100%;
        height: 100%;
        content: "";
      }
    }

    & .swiper-slide-next {
      transition-duration: 0ms;
      transform: translate3d(0px, -2px, -300px) rotateX(-65deg) rotateY(0deg)
        scale(1);
      z-index: 0;
    }

    & .swiper-button-prev {
      top: 0;
      background: url(${IconArrowTop}) no-repeat center / cover;
    }

    & .swiper-button-next {
      top: auto;
      bottom: 0;
      background: url(${IconArrowBottom}) no-repeat center / cover;
    }

    & .normalContainer {
      position: relative;
      // width: calc(100vm *(440/ 1920));

      & .title {
        top: calc(100vw * (-35 / 1920));
        transform: translateX(-50%);
      }
    }

    & .imgContainer {
      width: calc(100vw * (440 / 1920));
      height: calc(100vw * (440 / 1920));
    }
  }

  //(결함)시간선 위치 고정 필요
  & .timeLineContainer {
    /* position: fixed; */
    position: absolute;
    /* bottom: calc(100vw * (60 / 1920)); */
    bottom: 0;
    margin-bottom: -50px;
    /* transform: translateX(-50%); */
    /* display: flex;
    align-items: center; */
    width: calc(100vw * (635 / 1920));
    height: calc(100vw * (5 / 1920));
    padding: 0 calc(100vw * (120 / 1920));
    background-color: #fff;

    &.noNextDate {
      &::before {
        display: none;
      }

      & .nowDate {
        margin-right: auto;
      }
    }

    &.noPrevDate {
      &::after {
        display: none;
      }
    }

    &::before,
    &::after {
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      content: "";
    }

    &::before {
      left: calc(100vw * (-20 / 1920));
      border: calc(100vw * (15 / 1920)) solid transparent;
      border-right: calc(100vw * (25 / 1920)) solid #fff;
    }

    &::after {
      right: calc(100vw * (-20 / 1920));
      border: calc(100vw * (15 / 1920)) solid transparent;
      border-left: calc(100vw * (25 / 1920)) solid #fff;
    }

    & .timeLine {
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      width: calc(100vw * (26 / 1920));
      height: calc(100vw * (26 / 1920));
      border-radius: 50%;
      border: calc(100vw * (3 / 1920)) solid #fff;
      background-color: #000;
      cursor: pointer;

      &.nextDate {
        left: calc(100vw * (126 / 1920));
      }

      &.prevDate {
        right: calc(100vw * (126 / 1920));
      }

      &.nowDate {
        position: relative;
        left: 50%;
        transform: translate(-50%, -50%);
        width: calc(100vw * (42 / 1920));
        height: calc(100vw * (42 / 1920));
        /* margin: 0 calc(100vw * (150 / 1920)); */

        &::before {
          position: absolute;
          top: 50%;
          left: 50%;
          width: calc(100vw * (24 / 1920));
          height: calc(100vw * (24 / 1920));
          background-color: #fff;
          border-radius: 50%;
          transform: translate(-50%, -50%);
          content: "";
        }

        & span {
          bottom: calc(100vw * (-31 / 1920));
        }
      }

      & span {
        position: absolute;
        bottom: calc(100vw * (-40 / 1920));
        left: 50%;
        transform: translateX(-50%);
        font-size: calc(100vw * (25 / 1920));
      }
    }
  }

  @media (max-width: 1366px) {
    & .sliderContainer {
      gap: calc(100vw * (200 / 1920));

      & .swiperContainer {
        &.sub {
          width: calc(100vw * (705 / 1920));

          /* & .swiper-button-prev {
            left: calc(100vw * (0 / 1920));
          }

          & .swiper-button-next {
            right: calc(100vw * (0 / 1920));
          } */
        }
      }

      & .swiper {
        & .recentSwiper {
          height: calc(100vw * (660 / 1920));
        }

        & .pastSwiper {
          height: calc(100vw * (660 / 1920));
        }
      }
    }
  }
`;

export { Container };

export default { Container };
