import React from "react";
import Styles from "./styles";
import _stomach from "_stomach";
import { getCookie } from "service/connector";

//img
import ImgStomachBg01 from "resources/image/stomach_bg_01.png";
import ImgStomachBg02 from "resources/image/stomach_bg_02.png";
import ImgStomachBg03 from "resources/image/stomach_bg_03.png";
import ImgStomach01 from "resources/image/stomach_01.png";
import ImgStomach02 from "resources/image/stomach_02.png";
import ImgStomach03 from "resources/image/stomach_03.png";
import ImgStomach04 from "resources/image/stomach_04.png";
import ImgStomach05 from "resources/image/stomach_05.png";
import ImgStomach06 from "resources/image/stomach_06.png";
import ImgStomach07 from "resources/image/stomach_07.png";
import ImgStomach08 from "resources/image/stomach_08.png";
import ImgStomach09 from "resources/image/stomach_09.png";
import ImgStomach10 from "resources/image/stomach_10.png";
import ImgStomach11 from "resources/image/stomach_11.png";
import ImgStomachGreen01 from "resources/image/stomach_green/stomach_01.png";
import ImgStomachGreen02 from "resources/image/stomach_green/stomach_02.png";
import ImgStomachGreen03 from "resources/image/stomach_green/stomach_03.png";
import ImgStomachGreen04 from "resources/image/stomach_green/stomach_04.png";
import ImgStomachGreen05 from "resources/image/stomach_green/stomach_05.png";
import ImgStomachGreen06 from "resources/image/stomach_green/stomach_06.png";
import ImgStomachGreen07 from "resources/image/stomach_green/stomach_07.png";
import ImgStomachGreen08 from "resources/image/stomach_green/stomach_08.png";
import ImgStomachGreen09 from "resources/image/stomach_green/stomach_09.png";
import ImgStomachGreen10 from "resources/image/stomach_green/stomach_10.png";
import ImgStomachGreen11 from "resources/image/stomach_green/stomach_11.png";
import ImgStomachYellow01 from "resources/image/stomach_yellow/stomach_01.png";
import ImgStomachYellow02 from "resources/image/stomach_yellow/stomach_02.png";
import ImgStomachYellow03 from "resources/image/stomach_yellow/stomach_03.png";
import ImgStomachYellow04 from "resources/image/stomach_yellow/stomach_04.png";
import ImgStomachYellow05 from "resources/image/stomach_yellow/stomach_05.png";
import ImgStomachYellow06 from "resources/image/stomach_yellow/stomach_06.png";
import ImgStomachYellow07 from "resources/image/stomach_yellow/stomach_07.png";
import ImgStomachYellow08 from "resources/image/stomach_yellow/stomach_08.png";
import ImgStomachYellow09 from "resources/image/stomach_yellow/stomach_09.png";
import ImgStomachYellow10 from "resources/image/stomach_yellow/stomach_10.png";
import ImgStomachYellow11 from "resources/image/stomach_yellow/stomach_11.png";
import ImgStomachRed01 from "resources/image/stomach_red/stomach_01.png";
import ImgStomachRed02 from "resources/image/stomach_red/stomach_02.png";
import ImgStomachRed03 from "resources/image/stomach_red/stomach_03.png";
import ImgStomachRed04 from "resources/image/stomach_red/stomach_04.png";
import ImgStomachRed05 from "resources/image/stomach_red/stomach_05.png";
import ImgStomachRed06 from "resources/image/stomach_red/stomach_06.png";
import ImgStomachRed07 from "resources/image/stomach_red/stomach_07.png";
import ImgStomachRed08 from "resources/image/stomach_red/stomach_08.png";
import ImgStomachRed09 from "resources/image/stomach_red/stomach_09.png";
import ImgStomachRed10 from "resources/image/stomach_red/stomach_10.png";
import ImgStomachRed11 from "resources/image/stomach_red/stomach_11.png";
import ImgStomachPurple01 from "resources/image/stomach_purple/stomach_01.png";
import ImgStomachPurple02 from "resources/image/stomach_purple/stomach_02.png";
import ImgStomachPurple03 from "resources/image/stomach_purple/stomach_03.png";
import ImgStomachPurple04 from "resources/image/stomach_purple/stomach_04.png";
import ImgStomachPurple05 from "resources/image/stomach_purple/stomach_05.png";
import ImgStomachPurple06 from "resources/image/stomach_purple/stomach_06.png";
import ImgStomachPurple07 from "resources/image/stomach_purple/stomach_07.png";
import ImgStomachPurple08 from "resources/image/stomach_purple/stomach_08.png";
import ImgStomachPurple09 from "resources/image/stomach_purple/stomach_09.png";
import ImgStomachPurple10 from "resources/image/stomach_purple/stomach_10.png";
import ImgStomachPurple11 from "resources/image/stomach_purple/stomach_11.png";
import ImgStomachOrange01 from "resources/image/stomach_orange/stomach_01.png";
import ImgStomachOrange02 from "resources/image/stomach_orange/stomach_02.png";
import ImgStomachOrange03 from "resources/image/stomach_orange/stomach_03.png";
import ImgStomachOrange04 from "resources/image/stomach_orange/stomach_04.png";
import ImgStomachOrange05 from "resources/image/stomach_orange/stomach_05.png";
import ImgStomachOrange06 from "resources/image/stomach_orange/stomach_06.png";
import ImgStomachOrange07 from "resources/image/stomach_orange/stomach_07.png";
import ImgStomachOrange08 from "resources/image/stomach_orange/stomach_08.png";
import ImgStomachOrange09 from "resources/image/stomach_orange/stomach_09.png";
import ImgStomachOrange10 from "resources/image/stomach_orange/stomach_10.png";
import ImgStomachOrange11 from "resources/image/stomach_orange/stomach_11.png";

export default function NoHoverStomach({
  selectedSection,
  onChangeSelectedSection,
  notSelectedSection,
  noClickEvent,
  opacityBg,
  numberType,
  screen
}) {

  const stomachData = _stomach.stomachImageList;
  const imageListData = [
    ImgStomach01,
    ImgStomach02,
    ImgStomach03,
    ImgStomach04,
    ImgStomach05,
    ImgStomach06,
    ImgStomach07,
    ImgStomach08,
    ImgStomach09,
    ImgStomach10,
    ImgStomach11,
  ];
  const greenImageListData = [
    ImgStomachGreen01,
    ImgStomachGreen02,
    ImgStomachGreen03,
    ImgStomachGreen04,
    ImgStomachGreen05,
    ImgStomachGreen06,
    ImgStomachGreen07,
    ImgStomachGreen08,
    ImgStomachGreen09,
    ImgStomachGreen10,
    ImgStomachGreen11,
  ];
  const yellowImageListData = [
    ImgStomachYellow01,
    ImgStomachYellow02,
    ImgStomachYellow03,
    ImgStomachYellow04,
    ImgStomachYellow05,
    ImgStomachYellow06,
    ImgStomachYellow07,
    ImgStomachYellow08,
    ImgStomachYellow09,
    ImgStomachYellow10,
    ImgStomachYellow11,
  ];
  const redImageListData = [
    ImgStomachRed01,
    ImgStomachRed02,
    ImgStomachRed03,
    ImgStomachRed04,
    ImgStomachRed05,
    ImgStomachRed06,
    ImgStomachRed07,
    ImgStomachRed08,
    ImgStomachRed09,
    ImgStomachRed10,
    ImgStomachRed11,
  ];
  const orangeImageListData = [
    ImgStomachOrange01,
    ImgStomachOrange02,
    ImgStomachOrange03,
    ImgStomachOrange04,
    ImgStomachOrange05,
    ImgStomachOrange06,
    ImgStomachOrange07,
    ImgStomachOrange08,
    ImgStomachOrange09,
    ImgStomachOrange10,
    ImgStomachOrange11,
  ];
  const purpleImageListData = [
    ImgStomachPurple01,
    ImgStomachPurple02,
    ImgStomachPurple03,
    ImgStomachPurple04,
    ImgStomachPurple05,
    ImgStomachPurple06,
    ImgStomachPurple07,
    ImgStomachPurple08,
    ImgStomachPurple09,
    ImgStomachPurple10,
    ImgStomachPurple11,
  ];

  // console.log("number_type~~~~~ ", numberType);

  const renderImg = (item, type, numberType) => {
    const scope = getCookie('scope');

    if (scope === 'lite' && screen === 'abnormality' && (
      type === "red" || type === "orange" || type === "purple"
    )) {
      return redImageListData[item.id - 1];
    }

    if (scope === 'lite' && screen === 'report_heatmap') {
      type = numberType
    }
    
    switch (type) {
      case "red":
        return redImageListData[item.id - 1];
      case "orange":
        return orangeImageListData[item.id - 1];
      case "purple":
        return greenImageListData[item.id - 1];
      case "yellow":
        return yellowImageListData[item.id - 1];
      case "green":
        return greenImageListData[item.id - 1];
      default:
        return imageListData[item.id - 1];
    }
  };

  const isIncludesNotSelectedSection = (landmarkId) => {
    return notSelectedSection?.some(
      ({ landmark }) => landmark === Number(landmarkId)
    );
  };
  const typeofNotSelectedSection = (landmarkId) => {
    return (
      notSelectedSection?.find(
        ({ landmark }) => landmark === Number(landmarkId)
      )?.type || null
    );
  };

  return (
    <Styles.Container>
      <div className="stomachSection">
        {stomachData.map((item, index) => {
          const isActive = isIncludesNotSelectedSection(item.id);
          const type = typeofNotSelectedSection(item.id);
          return (
            <div className="stomachParts" key={"stomachParts" + index}>
              <div
                className="stomachSectionDiv"
                onClick={(e) => {
                  onChangeSelectedSection(e.target.id);
                }}
              >
                <Styles.StomachSectionImg
                  className={noClickEvent ? "on" : ""}
                  alt="section"
                  src={renderImg(item, type)}
                  id={item.id}
                  polygon={item.polygon}
                  transformorigin={item.transformorigin}
                  isSelected={
                    selectedSection === `${item.id}` ||
                    selectedSection === item.id
                  }
                  isNotSelected={isActive}
                />
              </div>
            </div>
          );
        })}
      </div>
      <img
        className={"stomachBaseImg" + (selectedSection ? " on" : " on")}
        src={opacityBg ? ImgStomachBg03 : ImgStomachBg02}
        alt="stomach"
      />
    </Styles.Container>
  );
}
