import styled from "styled-components";

// img
import IconArrowBottom from "resources/image/icon/icon_arrow_bottom_big.svg";
import IconArrowTop from "resources/image/icon/icon_arrow_top_big.svg";

const Container = styled.div`
  position: relative;

  & img {
    width: 100%;
    height: 100%;
    object-fit: fill;
  }

  & .swiper {
    margin: 0;
    height: 100%;

    & .slider {
      box-shadow: none;
      opacity: 0.5;
      transition: 0.3s;
    }

    & .swiper-slide-active {
      opacity: 1;
    }
  }

  & .swiper-button-prev,
  .swiper-button-next {
    position: absolute;
    left: 50%;
    width: calc(${(props) => props.viewPort}vw * (100 / 1920));
    height: calc(${(props) => props.viewPort}vw * (100 / 1920));
    transform: translateX(-50%);
    z-index: 1;
    cursor: pointer;

    &::after {
      content: none;
    }
  }

  & .swiper-button-prev {
    top: calc(${(props) => props.viewPort}vw * (20 / 1920));
    background: url(${IconArrowTop}) no-repeat center / cover;
  }

  & .swiper-button-next {
    top: auto;
    bottom: calc(${(props) => props.viewPort}vw * (-20 / 1920));
    background: url(${IconArrowBottom}) no-repeat center / cover;
  }


  & .leftWrap {
    position: relative;
    display: flex;
    gap: calc(${(props) => props.viewPort}vw * (30 / 1920));

    & .swiperContainer {
      margin-left: calc(${(props) => props.viewPort}vw * ${props => {
        if (props.viewPort === 60) {
          return 60;
        } else if (props.viewPort === 80) {
          return 40;
        }
        return 20;
      }} / 1920);
      height: calc(${(props) => props.viewPort}vw * (650 / 1920));
      overflow: hidden;
    }

    & .imgContainer {
      width: calc(${(props) => props.viewPort}vw * (440 / 1920));
      height: calc(${(props) => props.viewPort}vw * (440 / 1920));
      border-radius: calc(100vw * (9 / 1920));
    }

    & .imgSingleContainer {
      margin-top: calc(${(props) => props.viewPort}vw * (110 / 1920));
      width: calc(${(props) => props.viewPort}vw * (440 / 1920));
      height: calc(${(props) => props.viewPort}vw * (440 / 1920));
      margin-left: calc(${(props) => props.viewPort}vw * ${props => {
        if (props.viewPort === 60) {
          return 60;
        } else if (props.viewPort === 80) {
          return 40;
        }
        return 20;
      }} / 1920);
      border-radius: calc(100vw * (9 / 1920));
    }
  }
`;

export { Container };

export default { Container };
