import styled, { css } from "styled-components";

// img
import IconFilter from "resources/image/icon/icon_down_arrow.svg";

const Container = styled.div`
  width: 100%;

  & .title_box {
    border-bottom: calc(100vw * (3 / 1920)) solid #424447;
  }
  & .title_box,
  .list_row {
    display: flex;
    align-items: center;
    gap: calc(100vw * (20 / 1920));
    width: 100%;
    height: calc(100vw * (65 / 1920));
    padding: 0 calc(100vw * (45 / 1920));
  }
  /* & .title_box.scroll {padding: 0 calc(100vw*(61/1920)) 0 calc(100vw*(45/1920));} */
  & .title {
    display: flex;
    align-items: center;
    gap: calc(100vw * (10 / 1920));
  }
  & .box {
    overflow: hidden;
  }
  & .box.center {
    justify-content: center;
  }
  & .box.center p {
    text-align: center;
  }
  & .box p {
    font-size: calc(100vw * (24 / 1920));
  }
  & .box p.text {
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }

  & .list_box {
    width: 100%;
    height: calc(100vh - calc(100vw * (280 / 1920)));
    overflow-y: auto;
    overflow: overlay;
  }
  & .list_row {
    position: relative;
    border-bottom: 1px solid #424447;
    cursor: pointer;

    &:hover::after {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      opacity: 0.2;
      border-radius: calc(100vw * (15 / 1920));
      background-color: #d9d9d9;
      content: "";
    }
  }

  & .box:nth-child(1) {
    width: 11%;
  }
  & .box:nth-child(2) {
    width: 10%;
  }
  & .box:nth-child(3) {
    width: 9%;
  }
  & .box:nth-child(4) {
    width: 11%;
  }
  & .box:nth-child(5) {
    width: 11%;
  }
  & .box:nth-child(6) {
    width: 12%;
  }
  & .box:nth-child(7) {
    width: 10%;
  }
  & .box:nth-child(8) {
    width: 26%;
  }
`;

export { Container };

export default {
  Container,
};
