import Styles from "./styles";
import { useLanguage } from "hooks";
import { api } from "service";

// img
import IconDoctor from "resources/image/icon/icon_doctor.svg";
import IconNurse from "resources/image/icon/icon_nurse.svg";

export default function UserDeleteModal({ modalOption, modalClose }) {
  const lang = useLanguage("Admin");
  const { auth } = modalOption;
  const authType = () => {
    switch (auth) {
      // 의사
      case 1:
        return 0;
      // 간호사
      case 2:
        return 1;
    }
  };
  const userData = {
    // type 0 : 의사 , 1 : 간호사
    type: authType(auth),
    id: "PREVENOTICS3",
    name: "정프베",
    text: "이 사용자 계정을 삭제하면 맞춰진 병변 tHRESHOLD 및 관련 설정 데이터가 모두 제거됩니다.  경고문구 경고문구 경고문구 경고문구 경고문구 경고문구",
  };

  // 유저삭제
  const deleteUserClick = async () => {
    let result = await api.admin.deleteUser(modalOption.id);
    if (result.message == "user data has been deleted") {
      modalClose();
    }
    modalOption.refetch();
  };

  return (
    <Styles.Container>
      <div
        className="overlay"
        onClick={() => {
          modalClose();
        }}
      ></div>

      <div className="inner">
        <p className="title">{lang.getLang("유저 삭제")}</p>

        <div className="infoContainer">
          <div className="profileImgContainer">
            <img
              src={userData.type === 0 ? IconDoctor : IconNurse}
              alt="profileImg"
            />
          </div>

          <ul className="infoListWrap">
            <li className="infoList">
              <span className="listTitle">ID</span> :{" "}
              <span>{modalOption.displayID}</span>
            </li>

            <li className="infoList">
              <span className="listTitle">{lang.getLang("이름")}</span> :{" "}
              <span>{modalOption.name}</span>
            </li>
          </ul>
        </div>

        <p className="commentContainer">{userData.text}</p>

        <div className="buttonContainer">
          <button
            className="deleteButton"
            type="button"
            onClick={() => {
              deleteUserClick();
            }}
          >
            {lang.getLang("계정 삭제")}
          </button>

          <button
            className="cancelButton"
            type="button"
            onClick={() => {
              modalClose();
            }}
          >
            {lang.getLang("취 소")}
          </button>
        </div>
      </div>
    </Styles.Container>
  );
}
