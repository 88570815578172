import Styles from "./styles";
import { useLanguage } from "hooks";
import { useMediaQuery } from "react-responsive";
import LesionTitle from "../LesionTitle";

// swiper
import { Swiper, SwiperSlide } from "swiper/react";
import { EffectCoverflow, Navigation } from "swiper";
import "swiper/css";
import "swiper/css/effect-coverflow";
import "swiper/css/navigation";

// img
import { useQuery } from "react-query";
import { useNavigate } from "react-router-dom";
import { api } from "service";
import { IMAGE_REF, IMAGE_SRC, swiperOption } from "constants/common";
import { useEffect, useMemo, useRef, useState } from "react";
import SVG_R from "components/common/SVG_R";
import { lesionTypeForJsonFirstLetter, lesionTypeToColor, formatNormalImageWithLandmark } from "utils/common";
import { useConfig } from "hooks/useConfig";

export default function ReportChangeAbnormalityCheckedSection({
  studyId,
  lesionIndex,
  onLesionIndex,
  onChangeAbnormality,
  viewPort,
  paddingSize,
}) {
  const lang = useLanguage("Lesion");
  const isPro = useMediaQuery({
    query: "(max-width: 1366px)",
  });
  const isAir = useMediaQuery({
    query: "(max-width: 1180px)",
  });
  const navigate = useNavigate();

  const normalSwiperRef = useRef(null);

  const configInfo = useConfig();
  const [checkLesion, setCheckLesion] = useState(
    configInfo.configData.lesionMark
  );
  const [lesionsPathData, setLesionsPathData] = useState(null);
  const [lesionJsonData, setLesionJsonData] = useState(null);
  const [renderedImgList, setRenderedImgList] = useState([]);
  const [currentImage, setCurrentImage] = useState(null);
  const [currentImageSize, setCurrentImageSize] = useState(null);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [currentPolygon, setCurrentPolygon] = useState(null);
  const [currentLesion, setCurrentLesion] = useState(null);
  const [currentLesionColorType, setCurrentLesionColorType] = useState(null);

  useEffect(() => {
    setCheckLesion(configInfo.configData.lesionMark);
  }, [configInfo.configData]);

  useQuery(
    ["lesions-abnormality-path", studyId],
    () => api.image.getLesionsAbnormalityPath({ medicalstudyId: studyId }),
    {
      refetchOnWindowFocus: false,
      onSuccess(responseLesionPath) {
        setLesionsPathData(responseLesionPath);

        const lesionJsonData = responseLesionPath?.map(async ({ path }) => {
          return await api.image.getLesionJson({ path });
        } );

        Promise.all(lesionJsonData).then((data) => {
          setLesionJsonData(data);
        });
      },
    }
  );

  const handleChangeLesionIndex = (index) => {
    setCurrentIndex(index);
    onLesionIndex(index);
  };

  const currentLesionPathData = lesionsPathData?.[currentIndex];
  const landmark = currentLesionPathData?.landmark;

  useEffect(() => {
    if (currentLesionPathData) {
      const currentLandmark = lesionsPathData[currentIndex]?.landmark;
      const moveToNormalIndex =
        currentLandmark !== 12 ? currentLandmark - 1 : 0;
      normalSwiperRef.current?.slideTo(moveToNormalIndex);
    }
  }, [currentLesionPathData]);

  useEffect(() => {
    if (!lesionJsonData) return;

    console.log("lesionJsonData", lesionJsonData);

  }, [lesionJsonData]);

  useEffect(() => {
    if (!lesionJsonData) return;
    const matchingLesionJsonData = lesionJsonData?.map((item, index) => {
      const lesionType = lesionsPathData[index].lesion[0].type;
      const findLesionJsonData = item.lesion_tumor.filter(
        (tumor) => tumor.tumor_type === lesionType
      )[0];

      return findLesionJsonData;
    });
    const currentPolygon = matchingLesionJsonData[currentIndex]?.polygon;
    setCurrentPolygon(currentPolygon);

    onChangeAbnormality?.({ selected: currentImage , polygon: currentPolygon });

  }, [currentIndex, lesionJsonData]);

  useEffect(() => {
    if (lesionsPathData && lesionsPathData.length > 0) {
      const currentImage = lesionsPathData[currentIndex];
      const lesionColorType = lesionTypeToColor(currentImage?.lesion?.[0]?.type);

      setCurrentImage(currentImage);
      setCurrentLesion(currentImage?.lesion?.[0]);
      setCurrentLesionColorType(lesionColorType);
    }
  }, [currentIndex, lesionsPathData]);


  useEffect(() => {
    if (currentImage && currentPolygon) {
      onChangeAbnormality?.({ selected: currentImage , polygon: currentPolygon });
    }
  }, [currentImage]);

  console.log("currentPolygon", currentPolygon);

  if (!lesionsPathData ) return <></>;

  return (
    <Styles.Container
      viewPort={viewPort}
      paddingSize={paddingSize}
    >
      <div className="wrapContainer">
        <div className="swiperContainer">
          <Swiper
            direction={"vertical"}
            effect={"coverflow"}
            grabCursor={true}
            initialSlide={0}
            centeredSlides={true}
            slidesPerView={"auto"}
            coverflowEffect={
              isAir
                ? swiperOption.AirOption
                : isPro
                ? swiperOption.ProOption
                : swiperOption.option
            }
            onSlideChange={(swiper) => {
              handleChangeLesionIndex(swiper.activeIndex);
            }}
            modules={[EffectCoverflow, Navigation]}
            navigation={true}
            className="swiper"
          >
            {lesionsPathData?.map(({ imageId, path }, index) => {
              const imagePathSrc = IMAGE_SRC + path;
              return (
                <SwiperSlide
                  className="imgSwipeContainer slider"
                  key={"slider" + imageId}
                >
                  <>
                    <img
                      src={imagePathSrc}
                      alt="stomach"
                    />
                  </>
                </SwiperSlide>
              );
            })}
          </Swiper>
        </div>

        <div className="imgContainer">
          <div className="imgWrap">
          <img
            src={(currentImage && IMAGE_SRC + currentImage.path) || ""}
            alt="stomach"
            onLoad={(event) => {
              const { naturalWidth, naturalHeight } = event.currentTarget;
              setCurrentImageSize({
                naturalWidth,
                naturalHeight,
              });
            }}
          />
          { currentPolygon && currentPolygon.length > 0 &&
            <SVG_R.Rectangle
              viewPort={viewPort}
              data={currentPolygon[0]}
              width={currentImageSize?.naturalWidth}
              height={currentImageSize?.naturalHeight}
            />
          }
          </div>
        </div>

        <div className="imgContainer">
            <img
              src={formatNormalImageWithLandmark(
                currentImage?.landmark
              )}
              alt="stomach"
            />
        </div>
      </div>
    </Styles.Container>
  );
}
