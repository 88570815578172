import useUploadImage from "./useUploadImage";
import useFiles from "./useFiles";
import useCalendar from "./useCalendar";
import useModals from "./useModals";
import useLoader from "./useLoader";
import useLanguage from "./useLanguage";

export {
  useUploadImage,
  useFiles,
  useCalendar,
  useModals,
  useLoader,
  useLanguage,
};

export default {
  useUploadImage,
  useFiles,
  useCalendar,
  useModals,
  useLoader,
  useLanguage,
};
