import { layout } from "components";
import Styles from "./styles";
import { useLanguage } from "hooks";
import { useNavigate, useParams } from "react-router-dom";
import { AnalysisContainer, GaugeContainer } from "./components";
import { useQuery } from "react-query";
import { api } from "service";

export default function ImAnalysisPage() {
  const lang = useLanguage("Lesion");
  const navigate = useNavigate();
  const params = useParams();
  const { id, studyId } = params;

  const { data: settingsData, isLoadingSettings } = useQuery(
    ["settings"],
    () => api.setting.getSetting(),
    {
      refetchOnWindowFocus: false,
    }
  );

  const { data: patientsData = {} } = useQuery(
    ["patients_info", id],
    () => api.patient.getInfo(id),
    {
      refetchOnWindowFocus: false,
    }
  );

  const { data: ImAnalysisData = {} } = useQuery(
    ["analys_data", studyId],
    () => {
      return api.image.getImAnalysis(studyId);
    },
    {
      refetchOnWindowFocus: false,
    }
  );

  const antrumData = {
    analysisTitle: "Antrum",
    selectedSection: [5, 6],
    stomachListData: [
      {
        number: 5,
        stomach: "Antrum",
        percent: ImAnalysisData?.scoreLandmark5
          ? ImAnalysisData?.scoreLandmark5
          : 0,
      },
      {
        number: 6,
        stomach: "angle",
        percent: ImAnalysisData?.scoreLandmark6
          ? ImAnalysisData?.scoreLandmark6
          : 0,
      },
    ],
    totalPercent: ImAnalysisData.antrum,
  };

  const corpusData = {
    analysisTitle: "Corpus",
    selectedSection: [7, 8, 11],
    stomachListData: [
      {
        number: 7,
        stomach: "Body(LB-MB)",
        percent: ImAnalysisData?.scoreLandmark7
          ? ImAnalysisData?.scoreLandmark7
          : 0,
      },
      {
        number: 8,
        stomach: "Body(MB-HB)",
        percent: ImAnalysisData?.scoreLandmark8
          ? ImAnalysisData?.scoreLandmark8
          : 0,
      },
      {
        number: 11,
        stomach: "Retroflexion body",
        percent: ImAnalysisData?.scoreLandmark11
          ? ImAnalysisData?.scoreLandmark11
          : 0,
      },
    ],
    totalPercent: ImAnalysisData.corpus,
  };
  return (
    <Styles.Container>
      {/* <h2 className="title">Intestinal Metaplasia</h2> */}

      <div className="wrap">
        <div className="leftContainer">
          <div className="userDataContainer">
            <layout.UserDataListAbnoramlity
              select
              patientsData={patientsData}
              id={id}
              studyId={studyId}
              type={"stomach"}
              navigate={navigate}
            />
          </div>

          <div className="scoreWrap">
            <GaugeContainer
              big
              type={
                ImAnalysisData?.IMScore >= 100 - settingsData.imSensitivity
                  ? "yellow"
                  : "green"
              }
              score={ImAnalysisData?.IMScore}
            />
          </div>
        </div>

        <div className="rightContainer">
          <AnalysisContainer
            data={antrumData}
            type={"antrum"}
            imSensitivity={settingsData.imSensitivity}
          />

          <AnalysisContainer
            data={corpusData}
            type={"corpus"}
            imSensitivity={settingsData.imSensitivity}
          />
        </div>
      </div>

      <button
        type="button"
        className="prevButton"
        onClick={() => {
          navigate(-1);
        }}
      >
        {lang.getLang("돌아가기")}
      </button>
    </Styles.Container>
  );
}
