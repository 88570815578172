import styled from "styled-components";

// img
import Logo from "resources/image/icon/logo.svg";

const Container = styled.div`
  height: 100%;

  & .aptContainer {
    display: flex;
  }

  & .imgContainer {
    display: flex;
    flex-wrap: wrap;
    height: calc(100vw * (200 / 1920));
    margin-bottom: calc(100vw * (100 / 1920));
  }
  & .imgList {
    &:hover {
      background-color: #4d4d4d;
    }

    &.on {
      background-color: #777777;
    }
  }
`;

export { Container };

export default { Container };
