import { useState, useEffect } from "react";

const useIsBeautifulDndMount = () => {
  const [enabled, setEnabled] = useState(false);

  useEffect(() => {
    const animation = requestAnimationFrame(() => setEnabled(true));

    return () => {
      cancelAnimationFrame(animation);
      setEnabled(false);
    };
  }, []);

  return enabled;
};

export default useIsBeautifulDndMount;
