import styled from "styled-components";

// img
import Logo from "resources/image/icon/logo.svg";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: calc(100vw * (65 / 1920)) 0 0;

  & img {
    width: 100%;
    height: 100%;
    object-fit: fill;
  }

  & .numberIconContainer {
    display: flex;
    align-items: center;

    & .landmarkText {
      margin-left: calc(100vw * (15 / 1920));
      font-size: calc(100vw * (30 / 1920));
      font-weight: 700;
    }
  }

  & .stomachContainer {
    width: calc(100vw * (230 / 1920));
    height: calc(100vw * (210 / 1920));
    margin: calc(100vw * (18 / 1920)) 0 calc(100vw * (36 / 1920));

    & img {
      width: calc(100vw * (230 / 1920));
      height: calc(100vw * (210 / 1920));
    }
  }
`;

export { Container };

export default { Container };
