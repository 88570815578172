import styled from "styled-components";

// img
import IconArrowRight from "resources/image/icon/icon_arrow_right.svg";
import IconCheckBlue from "resources/image/icon/icon_check_blue.svg";

const Container = styled.div`
  position: fixed;
  /* bottom: calc(100vw * (-520 / 1920));
  right: calc(100vw * (-529 / 1920)); */
  width: calc(100vw * (617 / 1920));
  font-size: calc(100vw * (25 / 1920));
  font-weight: bold;
  color: #fff;
  background-color: #292929;
  border: 1px solid #000000;
  border-radius: calc(100vw * (10 / 1920));
  z-index: 1000;

  & img {
    width: 100%;
    height: 100%;
    object-fit: fill;
  }

  & li:not(:last-child) {
    margin-bottom: calc(100vw * (15 / 1920));
  }

  & .topListWrap {
    padding: calc(100vw * (28 / 1920)) calc(100vw * (21 / 1920));
    border-bottom: 1px solid #fff;

    & .topList {
      position: relative;

      & p {
        background: url(${IconArrowRight}) no-repeat center left
          calc(100% - calc(100vw * (22 / 1920))) / calc(100vw * (13 / 1920))
          calc(100vw * (17 / 1920));
      }

      & .subModal {
        position: absolute;
        top: calc(100vw * (-29 / 1920));
        left: calc(100% - calc(100vw * (10 / 1920)));
        width: max-content;
        background: #292929;
        border: 1px solid #000000;
        border-radius: calc(100vw * (10 / 1920));

        & .landmarkListWrap {
          padding: calc(100vw * (20 / 1920)) calc(100vw * (16 / 1920));

          & .landmarkList {
            padding-right: calc(100vw * (55 / 1920));

            &.on {
              background: url(${IconCheckBlue}) no-repeat center right /
                calc(100vw * (25 / 1920));
            }
          }
        }

        & li {
          position: relative;
          font-weight: 400;
        }
      }
    }
  }

  & .middleListWrap {
    padding: calc(100vw * (11 / 1920)) calc(100vw * (21 / 1920))
      calc(100vw * (30 / 1920));
    border-bottom: 1px solid #fff;

    & p {
      margin-bottom: calc(100vw * (18 / 1920));
    }

    & .reportImgListWrap {
      display: flex;
      gap: calc(100vw * (20 / 1920));
    }

    & .reportImgList {
      position: relative;
      width: calc(100vw * (163 / 1920));
      height: calc(100vw * (163 / 1920));
      border-radius: calc(100vw * (10 / 1920));
      box-shadow: calc(100vw * (12 / 1920)) calc(100vw * (18 / 1920))
        calc(100vw * (28 / 1920)) rgba(0, 0, 0, 0.63);
      overflow: hidden;
      cursor: pointer;

      & .deleteButton {
        position: absolute;
        top: calc(100vw * (11 / 1920));
        right: calc(100vw * (11 / 1920));
        width: calc(100vw * (23 / 1920));
        height: calc(100vw * (23 / 1920));
      }
    }

    & .addReportImgContainer {
      box-shadow: none;

      & .addReportText {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: max-content;
        font-size: calc(100vw * (20 / 1920));
        line-height: 1.2;
        text-align: center;
        z-index: 1;
      }

      &::after {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, 0.8);
        content: "";
      }
    }
  }

  & .bottomListWrap {
    padding: calc(100vw * (25 / 1920)) calc(100vw * (21 / 1920));

    & .bottomList.on {
      color: rgba(255, 255, 255, 0.5);
    }
  }

  & .disabled {
    color: rgba(255, 255, 255, 0.5);
  }
`;

export { Container };

export default { Container };
