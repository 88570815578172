import PatientInfoTable from "./PatientInfoTable";
import CommentsSection from "./CommentsSection";
import PicturesTitle from "./PicturesTitle";
import LandmarkSection from "./LandmarkSection";
import IMSection from "./IMSection";
import AbnormalitySection from "./AbnormalitySection";

export {
  PatientInfoTable,
  CommentsSection,
  PicturesTitle,
  LandmarkSection,
  IMSection,
  AbnormalitySection,
};

export default {
  PatientInfoTable,
  CommentsSection,
  PicturesTitle,
  LandmarkSection,
  IMSection,
  AbnormalitySection,
};
