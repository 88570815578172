import Styles from "./styles";
import PicturesTitle from "../PicturesTitle";
import { useLanguage, useModals } from "hooks";

// img
import IconReset from "resources/image/icon/icon_reset.svg";
import { useQuery } from "react-query";
import { useParams } from "react-router-dom";
import { api } from "service";
import { useEffect, useMemo, useState } from "react";
import { formatImageWithPath } from "utils/common";
import { useReport } from "hooks/useReport";
import { useUpdateEffect } from "hooks/useUpdateEffect";
import { ChangeImage } from "./components";

export default function SelectSection(props) {
  const lang = useLanguage("Report");
  const modalOption = useModals();
  const { id, studyId } = useParams();

  const reportInfo = useReport();
  const [reportImageList, setReportImageList] = useState([null, null, null]);
  const [sectionType, setSectionType ] = useState("view"); // view, edit
  // 
  const [editTargetImage, setEditTargetImage] = useState(null);
  const [updateTargetImage, setUpdateTargetImage] = useState(null);
  const [selectedImage, setSelectedImage] = useState(null);

  // const {
  //   data: reportsSelectImagesData = {},
  //   isLoading: isLoadingReportsSelectImages,
  // } = useQuery(
  //   ["reports-images", "select-images", studyId],
  //   () =>
  //     api.reports.getReportsImages({
  //       medicalstudyId: studyId,
  //       check: "select-images",
  //     }),
  //   {
  //     onSuccess(responseSelectImagesData) {
  //       const formatObj = {};
  //       Object.keys(responseSelectImagesData).forEach((key, index) => {
  //         const idx = index + 1;
  //         formatObj["imagePath" + idx] = responseSelectImagesData[key];
  //       });
  //       reportInfo.setReportWriteData((prev) => ({
  //         ...prev,
  //         selectImage: formatObj,
  //       }));
  //       setReportImageList(Object.values(formatObj));
  //     },
  //     refetchOnWindowFocus: false,
  //   }
  // );

  useEffect(() => {
    if (reportInfo.reportData?.selectImage) {
      console.log("222222222222222 reportInfo.reportData.selectImage", reportInfo.reportData.selectImage);
      setReportImageList(Object.values(reportInfo.reportData.selectImage));
    }
  }, [reportInfo.reportData]);

  const handleActiveImage = (image) => {
    console.log("updateTargetImage", image);
    setUpdateTargetImage(image);
  };

  return (
    <>
      <PicturesTitle
        colorTitle={lang.getLang("사진 추가 선택")}
        subTitle={lang.getLang(
          "출력하고자 하는 이미지를 선택합니다."
        )}
        paddingSize={props.paddingSize}
        viewPort={props.viewPort}
      />

      { sectionType === "view" ?  
      <Styles.Container viewPort={props.viewPort}>
        <div className="wrap">
          <div className="leftContainer" />
          <div className="rightContainer">
            {reportImageList?.map((path, index) => {
              return (
                <div
                  className="imgContainer"
                  key={"imgContainer" + index}
                  onClick={() => {

                    setSectionType("edit");
                    setEditTargetImage({
                      path,
                      index,
                    });

                    // modalOption.setChangeImageModal((e) => {
                    //   return {
                    //     ...e,
                    //     show: true,
                    //     studyId,
                    //     id,
                    //     isReport: true,
                    //     presetSelectedImagePath: path,
                    //     onSelectImage(selectedImage) {
                    //       if (selectedImage === path) return;
                    //       setReportWrite((prev) => {
                    //         const { selectImage, ...rest } = prev;

                    //         const newSelectImage = { ...selectImage };
                    //         Object.values(selectImage)?.forEach((_, idx) => {
                    //           if (idx === index) {
                    //             newSelectImage[`imagePath` + (index + 1)] =
                    //               selectedImage?.path ?? null;
                    //           }
                    //         });

                    //         return {
                    //           ...rest,
                    //           selectImage: newSelectImage,
                    //         };
                    //       });
                    //     },
                    //   };
                    // });
                  }}
                >
                  {path ? (
                    <div className="imgWrap imgBox">
                      <img src={formatImageWithPath(path)} alt="stomach" />
                    </div>
                  ) : (
                    <div className="imgWrap emptyBox">
                      <div className="resetImgWrap">
                        <img src={IconReset} alt="reset" />
                      </div>
                    </div>
                  )}
                </div>
              );
            })}
          </div>
        </div>
      </Styles.Container>
      : 
      <Styles.EditContainer viewPort={props.viewPort}>
        <div className="inner">
          <div className="editContainer">
            <ChangeImage
              viewPort={props.viewPort}
              paddingSize={props.paddingSize}
              id={id} 
              studyId={studyId} 
              isReport={true} 
              presetSelectedImagePath={reportImageList[0]} 
              editTargetImage={editTargetImage}
              onActiveImage={(image) => {
                handleActiveImage(image);
              }} 
            />
          </div>
          <div className="buttonContainer">
            <button
              type="button"
              className="submitButton"
              onClick={() => {
                const { path, index } = editTargetImage;

                setReportImageList((prev) => {
                  const newImageList = [...prev];
                  newImageList[index] = updateTargetImage.path;

                  props.onUpdateSelection(newImageList);
                  return newImageList;
                });


                setSectionType("view");
              }}
            >
              {lang.getLang("사진 변경")}
            </button>
            <button type="button" className="cancelButton" onClick={
              () => {
                setUpdateTargetImage(null);
                setEditTargetImage(null);
                setSectionType("view");
              }
            }>
              {lang.getLang("취소")}
            </button>
          </div>
        </div>
      </Styles.EditContainer> }
    </>
  );
}
