import connector from "service/connector";

function getFilterStr(parameter){
  let filter = parameter;
  return new URLSearchParams(filter).toString();
}

const getList = async (parameter, page) => {
  if(parameter.page == page){
    return await connector.authConnector(`studies?${getFilterStr(parameter)}`,"GET");
  }
}

const getBoard = async () => {
  return await connector.authConnector(`studies/info-boards`,"GET");
}

export {
  getList,
  getBoard,
};


export default {
  getList,
  getBoard,
}