
import Styles from "./styles";
import APTContainer from "../APTContainer";
import { useState } from "react";
import { useQuery } from "react-query";
import { api } from "service";

export default function ChangeImage(props) {
  const { id, studyId, isReport, editTargetImage, onSelectImage, onActiveImage, presetSelectedImagePath } = props;

  const [abnormality, setAbnormality] = useState(50);
  const [IMValue, setIMValue] = useState(50);


  const [activeReportSelectImage, setActiveReportSelectImage] = useState(
    presetSelectedImagePath
  );

  const imageFilterInfo = {
    id,
    studyId,
    is_apt: true,
  };

  const { data: imageData = [], isLoading: isLoadingImageData } = useQuery(
    ["image_list", imageFilterInfo],
    () => api.image.getList(imageFilterInfo),
    {
      refetchOnWindowFocus: false,
    }
  );

  if (isLoadingImageData) return <></>;

  return (
    <Styles.Container 
      viwePort={props.viwePort}
      paddingSize={props.paddingSize}
    >
      <div className="inner">
        <div className="aptContainer">
          <APTContainer
            listData={imageData}
            abnormality={abnormality}
            IMValue={IMValue}
            notUseDoubleClickModal
            notUseContextModal
            isReport={isReport}
            activeReportSelectImage={activeReportSelectImage}
            onActiveImage={onActiveImage}
          />
        </div>
      </div>
    </Styles.Container>
  );
}

