import styled from "styled-components";

// img
import Logo from "resources/image/icon/logo.svg";

const Container = styled.div`
  & .imgListWrap {
    display: flex;
    flex-wrap: wrap;
  }

  & .aptList,
  .leftContainer,
  .rightContainer {
    display: flex;
  }

  & .aptList {
    border-bottom: calc(100vw * (2 / 1920)) dashed #000000;
  }

  & .leftContainer {
    flex-shrink: 0;
    width: 100%;
    //calc(100vw * (567 / 1920));
    // border-right: calc(100vw * (2 / 1920)) dashed #000000;
    overflow: auto;
  }

  & .rightContainer {
    flex-grow: 1;
    overflow: auto;
  }

  & .imgList {
    &:hover {
      background-color: #4d4d4d;
    }

    &.on {
      background-color: #777777;
    }
  }

  & img {
    width: 100%;
    height: 100%;
    object-fit: fill;
    user-select: none;
  }
`;

export { Container };

export default { Container };
