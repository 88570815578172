import styled, { css } from "styled-components";

// img
import IconFilter from "resources/image/icon/icon_down_arrow.svg";

const Container = styled.div`

  & .study {
    position: relative;
    width: 100%;
  }

  & table {
    display: grid;

    & thead {
      border-top: calc(100vw * (3 / 1920)) solid #424447;
      border-bottom: calc(100vw * (3 / 1920)) solid #424447;
      text-align: center;
      font-size: calc(100vw * (24 / 1920));
      line-height: calc(100vw * (68 / 1920));
      
      & th {
        line-height: calc(100vw * (68 / 1920));
        height: calc(100vw * (68 / 1920));
        text-align: center;
        &:not(:last-child) {
          border-right: 1.5px dashed #424447;
        }
      }

      & th:nth-child(1) {
        width: calc(100vw * (150 / 1920));
      }
      & th:nth-child(2) {
        width: calc(100vw * (180 / 1920));
      }
      & th:nth-child(3) {
        width: calc(100vw * (60 / 1920));
      }
      & th:nth-child(4) {
        width: calc(100vw * (150 / 1920));
      }
      & th:nth-child(5) {
        width: calc(100vw * (150 / 1920));
      }
      & th:nth-child(6) {
        width: calc(100vw * (400 / 1920));
      }
      & th:nth-child(7) {
        width: calc(100vw * (270 / 1920));
      }
    }

    & tbody {
      height: calc(100vw * (680 / 1920));
      text-align: center;
      font-size: calc(100vw * (24 / 1920));
      overflow-y: scroll;

      & td {
        position: relative;
        border-bottom: 1px solid #424447;
        line-height: calc(100vw * (68 / 1920));
        height: calc(100vw * (68 / 1920));
        text-align: center;
        padding: 0 calc(100vw * (5 / 1920));
        &:not(:last-child) {
          border-right: 1.5px dashed #424447;
        }

        &:hover {
          & .hoverModal {
            display: block;
          } 
          & .hoverPatientName {
            display: block;
          }
        }
        & .hoverModal {
          position: absolute;
          display: none;
          top: calc(100vw * (0 / 1920));
          left: calc(100vw * (-20 / 1920));
          max-width: calc(100vw * (450 / 1920));
          width: max-content;
          padding: calc(100vw * (7 / 1920)) calc(100vw * (10 / 1920));
          font-size: calc(100vw * (24 / 1920));
          background-color: #34495E;
        }
        & .hoverPatientName {
          position: absolute;
          display: none;
          top: calc(100vw * (10 / 1920));
          left: calc(100vw * (0 / 1920));
          max-width: calc(100vw * (300 / 1920));
          width: max-content;
          padding: calc(100vw * (7 / 1920)) calc(100vw * (10 / 1920));
          font-size: calc(100vw * (24 / 1920));
          background-color: #34495E;
        }
      }
      
      & td:nth-child(1) {
        width: calc(100vw * (150 / 1920));
      }
      & td:nth-child(2) {
        width: calc(100vw * (180 / 1920));
      }
      & td:nth-child(3) {
        width: calc(100vw * (60 / 1920));
      }
      & td:nth-child(4) {
        width: calc(100vw * (150 / 1920));
      }
      & td:nth-child(5) {
        width: calc(100vw * (150 / 1920));
      }
      & td:nth-child(6) {
        width: calc(100vw * (400 / 1920));
      }
      & td:nth-child(7) {
        width: calc(100vw * (270 / 1920));
      }
    }
  }
`;

export { Container };

export default {
  Container,
};
